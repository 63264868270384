import { useAuth0 } from "@auth0/auth0-react";
import {
  faCheck,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Tool/Tools/template.scss";
import { useHistory } from "react-router-dom";
import { PathTool } from "../../Path/components/PathTool";
import mixpanel from "mixpanel-browser";

export const Storyboard = (props) => {
  const history = useHistory();
  useEffect(() => {
    mixpanel.track("Visited Storyboard Tool Page");
    window.analytics.page('Storyboard Tool');

  }, []);

  return (
    <div className="templateTool">
      <div className="bluebg"></div>
      <div className="container">
        <div className="intro">
          <div className="left">
            <img src={props.tool.image} alt="lifeline" />
          </div>
          <div className="right">
            <div className="top">
              <h3>
                Welcome to <br />
                <span className="highlight yellow">Storyboard</span>
              </h3>
            </div>
            <div className="bottom">
              <button
                onClick={() => {
                  history.push("/storyboard/dashboard");
                }}
                className="btn primary"
              >
                Start Storyboard Now! <FontAwesomeIcon icon={faRightLong} />{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="content">
          <p className="body-text">
            <b>Storyboard</b> allows you to record and send gratitude stories to
            those who have made a difference in your life and receive stories in
            return. <br />
            <br />
            How does sending a story contribute to career clarity? What you're
            thankful for and what you admire in others is a great way to surface
            what's important to you. When you can pinpoint what you appreciate
            about people and workplaces, you can make intentional career
            decisions that align with what matters most.
          </p>

          <div className="description">
            <div className="left white-background">
              <h3 className="heading2 underline blue">What you'll do</h3>

              <ol>
                <li>
                  <div className="item">1</div>
                  <p>
                    Identify someone who's made an impact on you, whether a
                    colleague, mentor, leader, or friend
                  </p>
                </li>

                <li>
                  <div className="item">2</div>
                  <p>
                    Think about a time you were grateful for them. What were
                    they doing? How were they being? How did it make you feel?
                  </p>
                </li>

                <li>
                  <div className="item">3</div>
                  <p>Record a video or write a note telling them your story.</p>
                </li>

                <li>
                  <div className="item">4</div>
                  <p>Press send and luxuriate in that endorphin rush!</p>
                </li>
              </ol>
            </div>
            <div className="left white-background yellow">
              <h3 className="heading2 underline yellow">Use To:</h3>

              <ol>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                    Reflect on what qualities you appreciate in a colleague,
                    mentor, or leader
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>Bring joy to someone's day</p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>Rekindle professional relationships</p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>Feel warm and fuzzy inside</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="moreTools">
        <div className="container">
          <h3>
            <span className="heading1 underline yellow">
              Want to go somewhere else?
            </span>
          </h3>

          <div className="tools">
            <PathTool
              tool={{
                name: "Strengths",
                id: 3,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Strengths.svg",
                Tool_Path: {
                  description: "Identify my strengths and how to use them",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Lifeline",
                id: 1,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Lifeline.svg",
                Tool_Path: {
                  description:
                    "Learn from your past to shape your future",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
