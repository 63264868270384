import { useEffect, useRef, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { RootStore } from "react-easy-diagram";
import "./styles/CollabDiagram.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DiagramContainer } from "./DiagramContainer";
import { HideSidebar, ShowSidebar } from "../../../hooks/hideSidebar";
import SidebarEntryCollab from "./SidebarEntryCollab";

const CollabDiagram = (props) => {
  const { apiServerUrl } = useEnv();
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const id = props.match.params.id;
  const storeRef = useRef(RootStore);
  const [mindmap, setMindmap] = useState(null);
  const [step, setStep] = useState(0);
  const [userName, setUserName] = useState("");
  const [newData, setNewData] = useState([]);
  let savingNodes = [];
  let savingLinks = [];

  const start = {
    role: "",
    data: [
      {
        title: "Questions",
        description: "What more do I want to know?",
      },
      {
        title: "People",
        description: "Who could I meet and listen to?",
      },
      {
        title: "Places",
        description: "Where could I go to learn more?",
      },
      {
        title: "Media",
        description: "What could I read, watch, or listen to?",
      },
    ],
  };

  const [data, setData] = useState(start);

  useEffect(() => {
    getMindMap();
    HideSidebar();

    return () => {
      ShowSidebar();
    };
  }, []);

  const saveCollab = async () => {
    const config = {
      url: `${apiServerUrl}/api/mindmap/collab/${id}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: newData,
    };

    const response = await axios(config);

    if (response.status === 200) {
      setStep(2);
    }
  };

  const saveEntry = async (entry) => {
    const { type, value } = entry;

    const newDataMap = data.data.map((item) => {
      if (item.title === type) {
        if (item.nodes) {
          item.nodes.push({
            title: value,
            collab: true,
            userName
          });
        } else {
          item.nodes = [{ title: value, collab: true, userName }];
        }
      }
      return item;
    });

    const wholeData = {
      role: data.role,
      data: newDataMap,
    };

    setData(wholeData);
    calculateData(wholeData);
    setNewData([
      ...newData,
      {
        title: value,
        type,
        userName
      },
    ]);
  };

  const getMindMap = async () => {
    const config = {
      url: `${apiServerUrl}/api/mindmap/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await axios(config);

    if (response.data.length > 0) {
      const newData = { ...data };
      newData.role = response.data[0].title;

      if (response.data[0].data?.data) {
        newData.data = response.data[0].data.data.data;
      }

      setData(newData);
      setMindmap(response.data[0]);
      calculateData(newData);
    }
  };

  const deleteEntry = (type, item) => {
    const newData = data.data.map((data) => {
      if (data.title === type) {
        const items = (data.nodes = data.nodes.filter((node) => {
          if (node.title === item.title) {
            return false;
          }
          return true;
        }));

        data.nodes = items;
      }
      return data;
    });

    const wholeData = {
      role: data.role,
      data: newData,
    };

    const allNewData = newData.filter((item) => {
      const obj = {
        title: item.title,
        type: type,
        userName: item.userName,
      };
      return item === obj;
    });

    setNewData(allNewData);

    setData(wholeData);
    calculateData(wholeData);
  };

  const calculateData = async (data) => {
    const nodes = [];
    const links = [];

    if (!data) return;

    const countSubNodes = (data) => {
      if (!data) return 0;
      let count = 0;
      data.forEach((item) => {
        if (item.nodes) {
          count += item.nodes.length;
        }
      });
      return count;
    };

    let Height = 350;

    if (countSubNodes(data.data) > 0) {
      Height = 350 + countSubNodes(data.data) * 70;
    }

    const totalLength = Height / data.data.length;
    const maxHeight = totalLength * data.data.length - 1;

    const positionofRoleNode = savingNodes.find((n) => n.id === "node-0")
      ?.position || [0, maxHeight / 2];

    const roleNode = {
      id: "node-0",
      type: "FirstComponent",
      position: positionofRoleNode,
      data: { title: data.role },
    };

    nodes.push(roleNode);

    data.data.forEach((item, index) => {
      let highestY = 0;
      let lastNode = null;

      // Get the node above this one
      if (index > 0) {
        // Get all nodes that are children of the previous node
        const getAllChildrenNodes = nodes.filter((n) =>
          n.id.includes(`node-${index}`)
        );

        // Go through all the children nodes and find the one with the highest Y value
        getAllChildrenNodes.forEach((node) => {
          if (node.position[1] > highestY) {
            highestY = node.position[1];
            lastNode = node;
          }
        });
      }

      let HeightOfTheBottomNode = 100;

      // Get the # of characters in the title of the last node
      const title = lastNode?.data.title.length || data.data[index - 1]?.length;

      // Set the height of the last node from the parent node, by looking at the number of lines, given one line is 25 chars
      if (title > 25) {
        HeightOfTheBottomNode = 110;
      }
      if (title > 50) {
        HeightOfTheBottomNode = 130;
      }
      if (title > 75) {
        HeightOfTheBottomNode = 150;
      }
      if (title > 100) {
        HeightOfTheBottomNode = 170;
      }
      if (title > 125) {
        HeightOfTheBottomNode = 190;
      }
      if (title > 150) {
        HeightOfTheBottomNode = 210;
      }
      if (title > 175) {
        HeightOfTheBottomNode = 230;
      }

      // Set the starting Y position of the parent node to the highest Y value of the last node + the height of the last node + 100
      let startingYOfParent = highestY + HeightOfTheBottomNode + 100 || 400;

      const positionOfThisNode = [300, startingYOfParent] || [
        300,
        startingYOfParent,
      ];
      const parentNode = {
        id: `node-${index + 1}`,
        type: "SecondComponent",
        position: positionOfThisNode,
        data: { title: item.title, description: item.description },
      };
      nodes.push(parentNode);
      links.push({
        source: { nodeId: "node-0", portId: `output` },
        target: { nodeId: `node-${index + 1}`, portId: "input" },
      });

      if (!item.nodes) return;

      // Set the second level
      item.nodes.forEach((node, nodeIndex) => {
        let ySeperator = 90;

        // Let the title of the last node determine the height of the next node
        const title =
          item.nodes[nodeIndex - 1]?.title?.length || node.title.length;

        if (title > 25) {
          ySeperator = 110;
        }
        if (title > 50) {
          ySeperator = 130;
        }
        if (title > 75) {
          ySeperator = 150;
        }
        if (title > 100) {
          ySeperator = 170;
        }
        if (title > 125) {
          ySeperator = 190;
        }
        if (title > 150) {
          ySeperator = 210;
        }
        if (title > 175) {
          ySeperator = 230;
        }

        const lastNode = nodes.find(
          (n) => n.id === `node-${index + 1}-${nodeIndex}`
        );

        if (lastNode && lastNode.position[1]) {
          ySeperator = lastNode?.position[1] + ySeperator || 300;
        }

        if (nodeIndex === 0) {
          ySeperator = startingYOfParent;
        }

        // Push nodes
        if (!node.collab) {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponent",
            data: { title: node.title },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
          });
        } else {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponentCollab",
            data: { title: node.title, userName: node.userName },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
            type: "collabArrow",
          });
        }
      });
    });

    setNodes(nodes);
    setLinks(links);
  };

  return (
    <div className="CollabDiagram" style={{ height: "100%" }}>
      {step === 0 && (
        <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {}} />
          <h3>Add To {mindmap?.user?.first_name}'s Mindmap</h3>
        </div>

        <div className="inner">
          <h3>What is your name?</h3>
          <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
        </div>

        <div className="bottom">
          <button
            onClick={() => {
              setStep(1);
            }}
            disabled={!userName || userName === ""}
            className="btn secondary"
          >
            Save
          </button>
        </div>
      </div>
        )}
      {step === 1 && (
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {}} />
          <h3>Add To {mindmap?.user?.first_name}'s Mindmap</h3>
        </div>

        <div className="inner">
          <div className="questions ask">
            <h4>Questions</h4>
            <h5>What do I want to know?</h5>

            <SidebarEntryCollab
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              type="Questions"
              newData={newData}
            />
          </div>
          <div className="questions ask">
            <h4>People</h4>
            <h5>Who could I meet and listen to?</h5>

            <SidebarEntryCollab
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              type="People"
              newData={newData}
            />
          </div>
          <div className="questions ask">
            <h4>Places</h4>
            <h5>Where could I go to learn more?</h5>
            <SidebarEntryCollab
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              type="Places"
              newData={newData}
            />
          </div>
          <div className="questions ask">
            <h4>Media</h4>
            <h5>What could I read, watch, or listen to?</h5>

            <SidebarEntryCollab
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              type="Media"
              newData={newData}
            />
          </div>
        </div>

        <div className="bottom">
          <button onClick={() => {
            setStep(0);
          }} className="btn blank">
            Back
          </button>
          <button
            onClick={() => {
              saveCollab();
            }}
            disabled={newData.length === 0}
            className="btn secondary"
          >
            Save
          </button>
        </div>
      </div>
      )}
      {step === 2 && (
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {}} />
          <h3>Add To {mindmap?.user?.first_name}'s Mindmap</h3>
        </div>

        <div className="inner">
          <p>Thanks for adding to {mindmap?.user?.first_name}'s mindmap!</p>
        </div>
      </div>
      )}
      <div className="right">
        <DiagramContainer
          initState={{
            nodes,
            links,
          }}
          savingLinks={savingLinks}
          savingNodes={savingNodes}
          storeRef={storeRef}
        />
      </div>
    </div>
  );
};

export { CollabDiagram };
