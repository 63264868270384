import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { RootStore } from "react-easy-diagram";
import "./styles/CreateDiagram.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SidebarEntry } from "./SidebarInput";
import { DiagramContainer } from "./DiagramContainer";
import { HideSidebar, ShowSidebar } from "../../../hooks/hideSidebar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateDiagram = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [nodes, setNodes] = useState([]);
  const history = useHistory();
  const [links, setLinks] = useState([]);
  const storeRef = useRef(RootStore);
  const [mindmap, setMindmap] = useState(null);
  let savingNodes = [];
  let savingLinks = [];

  const start = {
    role: "",
    data: [
      {
        title: "Questions",
        description: "What more do I want to know?",
      },
      {
        title: "People",
        description: "Who could I meet and listen to?",
      },
      {
        title: "Places",
        description: "Where could I go to learn more?",
      },
      {
        title: "Resources",
        description: "What could I read, watch, or listen to?",
      },
    ],
  };

  const [data, setData] = useState(start);

  useEffect(() => {
    getMindMap();
    HideSidebar();

    return () => {
      ShowSidebar();
    };
  }, []);

  const saveEntry = async (entry) => {
    const { type, value } = entry;

    const newData = data.data.map((item) => {
      if (item.title === type) {
        if (item.nodes) {
          item.nodes.push({
            title: value,
          });
        } else {
          item.nodes = [{ title: value }];
        }
      }
      return item;
    });

    const wholeData = {
      role: data.role,
      data: newData,
    };
    setData(wholeData);
    calculateData(wholeData);
  };

  const getMindMap = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/mindmap`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.length > 0) {
      const newData = { ...data };
      newData.role = response.data[0].title;

      if (response.data[0].data?.data) {
        newData.data = response.data[0].data.data.data;
      }

      setData(newData);
      setMindmap(response.data[0]);
      calculateData(newData);

      const nodeData = response.data[0].data;

      // if (nodeData) {
      //   storeRef.current?.importState(nodeData.nodes, nodeData?.links);
      // }
    }
  };

  const deleteEntry = (type, item) => {
    const newData = data.data.map((data) => {
      if (data.title === type) {
        const items = (data.nodes = data.nodes.filter((node) => {
          if (node.title === item.title) {
            return false;
          }
          return true;
        }));

        data.nodes = items;
      }
      return data;
    });

    const wholeData = {
      role: data.role,
      data: newData,
    };

    setData(wholeData);
    calculateData(wholeData);
  };

  const saveMindmap = async () => {
    try {
      const token = await getAccessTokenSilently();
      const savingData = {
        nodes: savingNodes,
        links: savingLinks,
        data,
      };

      const config = {
        url: `${apiServerUrl}/api/mindmap/${mindmap.id}`,
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        data: { data: savingData },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      await axios(config);
      history.push("/mindmap/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const calculateData = async (data) => {
    const nodes = [];
    const links = [];

    if (!data) return;

    const countSubNodes = (data) => {
      if (!data) return 0;
      let count = 0;
      data.forEach((item) => {
        if (item.nodes) {
          count += item.nodes.length;
        }
      });
      return count;
    };

    let Height = 350;

    if (countSubNodes(data.data) > 0) {
      Height = 350 + countSubNodes(data.data) * 70;
    }

    const totalLength = Height / data.data.length;
    const maxHeight = totalLength * data.data.length - 1;

    const positionofRoleNode = savingNodes.find((n) => n.id === "node-0")
      ?.position || [0, maxHeight / 2];

    const roleNode = {
      id: "node-0",
      type: "FirstComponent",
      position: positionofRoleNode,
      data: { title: data.role },
    };

    nodes.push(roleNode);

    data.data.forEach((item, index) => {
      let highestY = 0;
      let lastNode = null;

      // Get the node above this one
      if (index > 0) {
        // Get all nodes that are children of the previous node
        const getAllChildrenNodes = nodes.filter((n) =>
          n.id.includes(`node-${index}`)
        );

        // Go through all the children nodes and find the one with the highest Y value
        getAllChildrenNodes.forEach((node) => {
          if (node.position[1] > highestY) {
            highestY = node.position[1];
            lastNode = node;
          }
        });
      }

      let HeightOfTheBottomNode = 100;

      // Get the # of characters in the title of the last node
      const title = lastNode?.data.title.length || data.data[index - 1]?.length;

      // Set the height of the last node from the parent node, by looking at the number of lines, given one line is 25 chars
      if (title > 25) {
        HeightOfTheBottomNode = 110;
      }
      if (title > 50) {
        HeightOfTheBottomNode = 130;
      }
      if (title > 75) {
        HeightOfTheBottomNode = 150;
      }
      if (title > 100) {
        HeightOfTheBottomNode = 170;
      }
      if (title > 125) {
        HeightOfTheBottomNode = 190;
      }
      if (title > 150) {
        HeightOfTheBottomNode = 210;
      }
      if (title > 175) {
        HeightOfTheBottomNode = 230;
      }

      // Set the starting Y position of the parent node to the highest Y value of the last node + the height of the last node + 100
      let startingYOfParent = highestY + HeightOfTheBottomNode + 100 || 400;

      const positionOfThisNode = [300, startingYOfParent] || [
        300,
        startingYOfParent,
      ];
      const parentNode = {
        id: `node-${index + 1}`,
        type: "SecondComponent",
        position: positionOfThisNode,
        data: { title: item.title, description: item.description },
      };
      nodes.push(parentNode);
      links.push({
        source: { nodeId: "node-0", portId: `output` },
        target: { nodeId: `node-${index + 1}`, portId: "input" },
      });

      if (!item.nodes) return;

      // Set the second level
      item.nodes.forEach((node, nodeIndex) => {
        let ySeperator = 90;

        // Let the title of the last node determine the height of the next node
        const title =
          item.nodes[nodeIndex - 1]?.title?.length || node.title.length;

        if (title > 25) {
          ySeperator = 110;
        }
        if (title > 50) {
          ySeperator = 130;
        }
        if (title > 75) {
          ySeperator = 150;
        }
        if (title > 100) {
          ySeperator = 170;
        }
        if (title > 125) {
          ySeperator = 190;
        }
        if (title > 150) {
          ySeperator = 210;
        }
        if (title > 175) {
          ySeperator = 230;
        }

        const lastNode = nodes.find(
          (n) => n.id === `node-${index + 1}-${nodeIndex}`
        );

        if (lastNode && lastNode.position[1]) {
          ySeperator = lastNode?.position[1] + ySeperator || 300;
        }

        if (nodeIndex === 0) {
          ySeperator = startingYOfParent;
        }

        // Push nodes
        if (!node.collab) {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponent",
            data: { title: node.title },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
          });
        } else {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponentCollab",
            data: { title: node.title, userName: node.userName },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
            type: "collabArrow",
          });
        }
      });
    });

    setNodes(nodes);
    setLinks(links);
  };

  return (
    <div className="CreateDiagram" style={{ height: "100%" }}>
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {
            history.push("/mindmap/dashboard");
          }} />
          <h3>Choose Your Mindmap</h3>
        </div>

        <div className="inner">
          <div className="questions ask">
            <h4>Questions</h4>
            <h5>What do I want to know?</h5>

            <SidebarEntry
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              placeholder="How is the work/life balance?"
              type="Questions"
            />
          </div>
          <div className="questions ask">
            <h4>People</h4>
            <h5>Who could I meet and listen to?</h5>

            <SidebarEntry
              data={data}
              saveEntry={saveEntry}
              placeholder="Julia from Accounting"
              deleteEntry={deleteEntry}
              type="People"
            />
          </div>
          <div className="questions ask">
            <h4>Places</h4>
            <h5>Where could I go to learn more?</h5>
            <SidebarEntry
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              placeholder="Monthly industry meetup at Joe's Coffee"
              type="Places"
            />
          </div>
          <div className="questions ask">
            <h4>Resources</h4>
            <h5>What could I read, watch, or listen to?</h5>

            <SidebarEntry
              data={data}
              saveEntry={saveEntry}
              deleteEntry={deleteEntry}
              placeholder="How I Built This podcast on Sandy Lerner"
              type="Resources"
            />
          </div>
        </div>

        <div className="bottom">
          <button onClick={() => {
            history.push("/mindmap");
          }} className="btn blank">
            Back
          </button>
          <button
            onClick={() => {
              saveMindmap();
            }}
            className="btn secondary"
          >
            Continue
          </button>
        </div>
      </div>
      <div className="right">
        <DiagramContainer
          initState={{
            nodes,
            links,
          }}
          savingLinks={savingLinks}
          savingNodes={savingNodes}
          storeRef={storeRef}
        />
      </div>
    </div>
  );
};

export { CreateDiagram };
