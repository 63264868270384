import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from "moment";
import "../../styles/pages/Lifeline/add.scss";
import { useEnv } from "../../context/env.context";
import Slider from "@mui/material/Slider";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useHistory } from "react-router-dom";
import { Analytics } from "../../hooks/analytics";

export const MomentEdit = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [moment, setMoment] = useState({
    title: "",
    description: "",
    score: 0,
    type: "",
  });

  useEffect(() => {
    getMoment();
  }, []);

  const saveMoment = async (type) => {
    const token = await getAccessTokenSilently();

    const date = Moment({
      year: moment.year,
      month: Number(moment.month) || 0,
    });

    let newMoment = { ...moment };

    newMoment.date = date.format("YYYY-MM-DD HH:mm:ss");

    const response = await axios.patch(
      `${apiServerUrl}/api/moments/${moment.id}`,
      {
        ...newMoment,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      Analytics("Edited Moment to Lifeline");

      history.push("/lifeline", {
        message: "Moment updated successfully",
      });
    }
  };

  const marks = [
    {
      value: 20,
      label: "😔",
    },
    {
      value: 40,
      label: "😐",
    },
    {
      value: 60,
      label: "🙂",
    },
    {
      value: 80,
      label: "😃",
    },
    {
      value: 100,
      label: "🤩",
    },
  ];

  const deleteMoment = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.delete(
      `${apiServerUrl}/api/moments/${moment.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      history.push("/lifeline", {
        message: "Moment deleted successfully",
      });
    }
  };

  const getMoment = async () => {
    const token = await getAccessTokenSilently();
    const momentId = history.location.pathname.split("/")[3];
    const response = await axios.get(
      `${apiServerUrl}/api/moments/${momentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const moment = response.data;

    moment.year = Moment(moment.date).year();
    moment.month = Moment(moment.date).month();
    setMoment(moment);
  };

  return (
    <div className="lifelineAdd">
      <div className="white-background">
        {error && (
          <div className="error">
            <p>{error}</p>
          </div>
        )}

        <h2>
          <span className="highlight yellow">Edit your moment</span>
        </h2>

        <div className="form">
          <div className="form-group">
            <label>
              Pick one of those moments and jot down a brief description
            </label>
            <input
              type="text"
              value={moment.title}
              onChange={(e) => {
                const newMoment = { ...moment };
                newMoment.title = e.target.value;
                setMoment(newMoment);
              }}
              placeholder=" Got my first job at Giganticorp Worldwide"
            />
          </div>

          <div className="form-group">
            <label>Was this moment professional or personal?</label>
            <div className="buttons">
              <button
                className={`${
                  moment.type === "personal" ? "active" : ""
                } btn primary`}
                onClick={(e) => {
                  const newMoment = { ...moment };
                  newMoment.type = "personal";
                  setMoment(newMoment);
                }}
              >
                Personal
              </button>
              <button
                className={`${
                  moment.type === "professional" ? "active" : ""
                } btn primary`}
                onClick={(e) => {
                  const newMoment = { ...moment };
                  newMoment.type = "professional";
                  setMoment(newMoment);
                }}
              >
                Professional
              </button>
            </div>
          </div>

          <div className="form-group">
            <label>How did this moment make you feel?</label>
            <Slider
              aria-label="Choose your emotion"
              defaultValue={20}
              value={moment.score}
              step={20}
              onChange={(e, value) => {
                const newMoment = { ...moment };
                newMoment.score = value;
                setMoment(newMoment);
              }}
              min={20}
              max={100}
              valueLabelDisplay="off"
              marks={marks}
            />
          </div>

          <div
            style={{
              marginTop: "50px",
            }}
            className="form-group"
          >
            <label>
              What was important about this moment? What did you learn about
              yourself? How did it shape you?
            </label>
            <textarea
              onChange={(e) => {
                const newMoment = { ...moment };
                newMoment.description = e.target.value;
                setMoment(newMoment);
              }}
              value={moment.description}
              placeholder="e.g. This was the first job out of college that taught me better what I would not want in my career in the future"
            ></textarea>
          </div>

          <div className="form-group">
            <label>
              When did this moment happen? (Don't stress if you don't remember
              the exact month and year. Give it your best guess!)
            </label>
            <div className="buttons">
              <select
                onChange={(e) => {
                  const newMoment = { ...moment };
                  newMoment.month = e.target.value;
                  setMoment(newMoment);
                }}
                value={moment.month}
              >
                <option selected disabled>
                  Month
                </option>
                <option value={0}>January</option>
                <option value={1}>February</option>
                <option value={2}>March</option>
                <option value={3}>April</option>
                <option value={4}>May</option>
                <option value={5}>June</option>
                <option value={6}>July</option>
                <option value={7}>August</option>
                <option value={8}>September</option>
                <option value={9}>October</option>
                <option value={10}>November</option>
                <option value={11}>December</option>
              </select>

              <input
                onChange={(event) => {
                  const newMoment = { ...moment };
                  newMoment.year = event.target.value;
                  setMoment(newMoment);
                }}
                type="text"
                value={moment.year}
                placeholder="Enter Year"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <button
          className="btn primary grayButton"
          onClick={(e) => {
            history.goBack();
          }}
        >
          Back
        </button>
        <div className="end">
          <button
            className="btn blank"
            onClick={(e) => {
              deleteMoment();
            }}
          >
            Delete
          </button>
          <button
            className="btn primary"
            onClick={(e) => {
              if (
                !moment.title ||
                !moment.type ||
                !moment.score ||
                !moment.description ||
                !moment.year
              ) {
                setError("Please fill out all fields");
                return;
              }
              setError(false);
              saveMoment();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
