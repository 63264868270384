import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Learn/activities.scss";
import { Analytics } from "../../hooks/analytics";
import Icon from "../../images/icon_test.png";
import { LearningActivityCard } from "./components/LearningActivityCard";
export const LearnActivities = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [activities, setActivities] = useState([]);
  const [internalActivities, setInternalActivities] = useState([]);
  const [externalActivities, setExternalActivities] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${apiServerUrl}/api/learning-activity`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setActivities(response.data);
      const internal = response.data.filter((activity) => activity.linkType === "INTERNAL");
      const external = response.data.filter((activity) => activity.linkType === "EXTERNAL");
      setInternalActivities(internal);
      setExternalActivities(external);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="LearnActivities">
      <h3>Interactive Activities</h3>
      <p>
        Bite-sized interactive career design exercises that help you reflect on & explore
        your career.
      </p>
      <div className="activities">
        {internalActivities.map((activity) => {
          return (
            <LearningActivityCard activity={activity} />
          );
        })}
      </div>
      <h3 style={{
        marginTop: "50px"
      }}>Slide Deck Activities</h3>
      <p>
        Bite-sized activities that we are currently working on building out into interactive activities.
      </p>
      <div className="activities">
        {externalActivities.map((activity) => {
          return (
            <LearningActivityCard activity={activity} />
          );
        })}
      </div>
    </div>
  );
};
