import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faQuestion } from "@fortawesome/free-solid-svg-icons";

export const Rolemodel = ({ roleModel, getRoleModels, presenterView = false }) => {
  const [showDescription, setShowDescription] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [description, setDescription] = useState(roleModel.description);
  const { apiServerUrl } = useEnv();

  const saveDescription = async (id) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/role-model/${id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        description,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    const data = response.data;

    if (data.success) {
      setShowDescription(false);
      setDescription("");
      getRoleModels();
    }
  };

  return (
    <div className="roleModel">
      <img src={roleModel.image} alt={roleModel.name} />
      <h4>{roleModel.name}</h4>
      <p>{roleModel.why}</p>
      {roleModel.description && !showDescription && (
        <p className="description">{roleModel.description}</p>
      )}

      {showDescription && (
        <div>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            type="text"
            placeholder="Describe why this person is a role model?"
          />
          <button
            onClick={() => {
              saveDescription(roleModel.id, roleModel.description);
            }}
            className="btn secondary bottomButton"
          >
            Save
          </button>
        </div>
      )}

      {!roleModel.description && !showDescription && !presenterView && (
        <button
          onClick={() => {
            setShowDescription(true);
          }}
          className="btn secondary bottomButton"
        >
          Why them?
        </button>
      )}

      {roleModel.description && !showDescription && !presenterView && (
        <button
          onClick={() => {
            setShowDescription(true);
          }}
          className="btn secondary bottomButton"
        >
          <FontAwesomeIcon icon={faPencil} /> Edit
        </button>
      )}
      
    </div>
  );
};
