import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../styles/pages/Admin/memberships.scss";
import { isAdmin } from "../../hooks/isAdmin";
import { Sidebar } from "./Sidebar";
import moment from "moment";

export const AdminMemberships = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [memberships, setMemberships] = useState([]);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [emails, setEmails] = useState(null);

  useEffect(() => {
    getMe();
    getMemberships();
  }, []);

  const getMemberships = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/memberships`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMemberships(response.data);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const addMemberships = async (emails) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/memberships/multiple`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        emails: emails,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setSuccess(response.data);

    setTimeout(() => {
      setSuccess(null);
    }, 7000);

    getMemberships();
  };

  return (
    <div className="membershipsAdmin">
      <h2>
        <span className="heading1 highlight blue">Memberships</span>
      </h2>

      <Sidebar />

      <div className="right white-background">
        <div className="coupon top">
          <p className="body1">Name</p>
          <p className="body1">Email</p>
          <p className="body1">Date</p>
        </div>
        {memberships.length > 0 &&
          memberships.map((membership) => {
            return (
              <div className="coupon">
                <p className="body1">
                  {membership?.first_name} {membership?.last_name}
                </p>
                <p className="body1">{membership?.email}</p>
                <p className="body1">
                  {moment(membership?.Membership_User?.date).format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            );
          })}

        <div className="add">
        {success && (
        <div className="alert success">
          <p className="body1">Success! You sent {success.usersSent} users and we added {success.usersAdded} users.</p>
        </div>
        )}
          <h3>
            <span className="underline blue">Add Memberships</span>
          </h3>
          <textarea
            onChange={(e) => {
              setEmails(e.target.value);
            }}
            className="couponCode"
            placeholder="Emails (comma seperated)"
          />

          <button
            onClick={() => {
              addMemberships(emails);
            }}
            className="btn primary"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};
