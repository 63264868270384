import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../styles/pages/highlightReel/single.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Comment } from "./components/comment";
import { HighlightReelEdit } from "./edit";

export const HighlightSingle = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [highlight, setHighlight] = useState(props.highlight);
  const [editing, setEditing] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [me, setMe] = useState({});
  const item = useRef(null);
  const [isCollaborator, setIsCollaborator] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getData();

    const navLinks = document.querySelectorAll(".nav-bar__links");
    navLinks.forEach((link) => {
      link.style.display = "none";
    });

    return () => {
      navLinks.forEach((link) => {
        link.style.display = "flex";
      });
    };
  }, []);

  const getAvatar = (collaborator) => {
    if (collaborator.avatar) {
      return collaborator.avatar;
    } else {
      return `https://placehold.co/100x100?text=${
        collaborator.firstName.charAt(0) + collaborator.lastName.charAt(0)
      }`;
    }
  };

  const saveComment = async () => {
    const token = await getAccessTokenSilently();
    await axios.post(
      `${apiServerUrl}/api/highlights/${highlight.id}/comment`,
      {
        text: commentText,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    getSingleHighlight();
    setCommentText("");
  };

  const getData = async () => {
    const highlight = await getSingleHighlight();
    await getMe(highlight);
  };

  const getMe = useCallback(
    async (highlight) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${apiServerUrl}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMe(response.data);

        // Check if user is the poster or if they are a collaborator to the highlight.
        const isCollaboratorFunction = highlight.collaborators.find(
          (collaborator) => collaborator.id == isCollaborator
        );

        // If they are not the poster or a collaborator and its not featured.
        if (
          highlight.user.id !== response.data.id &&
          !isCollaboratorFunction &&
          highlight.featured !== true
        )
          return history.push(`/highlights`);
      } catch (error) {
        // They aren't a user so lets see if there ID from the URL is a collaborator
        const isCollaboratorFunction = highlight.collaborators.find(
          (collaborator) => collaborator.id == isCollaborator
        );
        // If its not push them to home
        if (!isCollaboratorFunction && highlight.featured !== true) {
          return history.push(`/highlights`);
        }
      }
    },
    [apiServerUrl]
  );

  const getSingleHighlight = useCallback(async () => {
    const response = await axios.get(
      `${apiServerUrl}/api/highlights/${highlight.id}`
    );
    setHighlight(response.data);
    return response.data;
  }, []);

  if (!highlight.user) {
    return <div></div>;
  }

  return (
    <div className="singleHighlight">
      <div className="highlightReel-single">
        <div className="content">
          <div className="white-background">
            <div className="left">
              <div className="content">
                <h2>
                  {highlight.title}
                  {highlight.link && (
                    <span>
                      <a rel="noreferrer" href={highlight.link} target="_blank">
                        <FontAwesomeIcon icon={faLink} />
                        Highlight Link
                      </a>
                    </span>
                  )}
                </h2>
                <p>{highlight.text}</p>
              </div>
              {highlight.photo && (
                <div
                  style={{
                    backgroundImage: `url(${highlight.photo})`,
                  }}
                  className="image"
                ></div>
              )}
            </div>
            <div className="right">
              <div className="top">
                {highlight.userId === me.id && (
                  <button onClick={() => setEditing(true)} className="edit">
                    <FontAwesomeIcon icon={faEdit} />
                    Edit my highlight
                  </button>
                )}
                {/* <button className="share">
                <FontAwesomeIcon
                  onClick={() => setEditing(true)}
                  icon={faShare}
                />
                Share
              </button> */}

                <button onClick={() => props.close()} className="close">
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div ref={item} className="collaborators">
                <h3>
                  <span>Collaborators</span>
                </h3>
                {/* {me.id === highlight.user.id && (
              <div className="collaborator">
                <FontAwesomeIcon icon={faSquare} />
                <h4>Add a collaborator</h4>
                <p>
                  Collaborators are those who have contributed to your
                  Highlights.
                </p>
                <button className="blue">
                  <FontAwesomeIcon icon={faUserPlus} /> Tag Collaborators
                </button>
              </div>
            )} */}
                {highlight.collaborators?.length === 0 && (
                  <div className="empty">
                    <p>There are no collaborators on this Highlight yet</p>
                  </div>
                )}
                {highlight.collaborators?.length > 0 &&
                  highlight.collaborators.map((collaborator) => (
                    <div className="collaborator">
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = `https://placehold.co/100x100?text=${
                            collaborator.first_name.charAt(0) +
                            collaborator.last_name.charAt(0)
                          }`;
                        }}
                        src={getAvatar(collaborator)}
                        alt={collaborator.firstName}
                      />
                      <h4>
                        {collaborator.firstName} {collaborator.lastName}
                      </h4>
                    </div>
                  ))}
              </div>
              <div className="comments">
                <h3>Comments</h3>

                {highlight.responses.map((comment) => (
                  <Comment comment={comment} />
                ))}

                {highlight.responses.length === 0 && (
                  <div className="empty">
                    <p>There are no comments on this Highlight yet</p>
                  </div>
                )}
              </div>
              {me && me.id && (
                <div className="entryForm">
                  <input
                    value={commentText}
                    onChange={(e) => {
                      setCommentText(e.target.value);
                    }}
                    type="text"
                    placeholder="Add a comment..."
                  />

                  <button
                    onClick={() => {
                      saveComment();
                    }}
                    disabled={commentText.length === 0 || commentText === ""}
                  >
                    Post
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {editing && (
          <div className="addHighlight">
            <HighlightReelEdit
              me={me}
              close={() => {
                setEditing(false);
                getSingleHighlight();
              }}
              highlight={highlight}
            />
            <div onClick={() => setEditing(false)} className="skrim"></div>
          </div>
        )}
      </div>
      <div className="skrim" onClick={() => props.close()}></div>
    </div>
  );
};
