import React, { useEffect, useState } from "react";
import "./styles/Visions.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/strengthsIcon.png";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import ContextMenu from "./ContextMenu";
import ZoomableBubbleChart from "../../Path/components/ZoomableBubbleChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const VisionsWidget = ({ id, size, programUser, me }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [step, setStep] = useState(0); // [1, 2]
  const [active, setActive] = useState(0);
  const [adjustedSize, setAdjustedSize] = useState(size);
  const [vision, setVision] = useState({});
  const [count, setCount] = useState(0);
  useEffect(() => {
    getVision();
  }, [adjustedSize]);

  const getCategory = (category) => {
    switch (category) {
      case "growth":
        return "PERSONAL GROWTH";
      case "relationships":
        return "RELATIONSHIPS";
      case "impact":
        return "IMPACT";
      default:
        return "PERSONAL GROWTH";
    }
  };

  const getVision = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);

      const vision = response.data[0];

      let count = 0;

      if (vision.cover) {
        count = count + 1;
      }

      count += vision.pages.length;

      setCount(count);

      setVision(vision);
    } catch (e) {
      console.log(e);
    }
  };

  if (!vision || !vision.cover) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Visions</h3>
        {!programUser && (
          <button
            className="btn"
            onClick={() => {
              history.push("/visions");
            }}
          >
            Go to Visions
          </button>
        )}
      </div>
    );
  }

  // Size 1 widget
  if (adjustedSize === 1) {
    return (
      <div className={`Widget Size-${adjustedSize} Visions`}>
        <h2>Visions</h2>
        <ContextMenu
          link="/visions"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        {vision?.cover && step === 0 && (
          <div className="coverBox box">
            <div
              style={{
                backgroundImage: `url(${vision?.cover.image})`,
              }}
              className="inside"
            >
              <div className="content">
                <span>ONE-YEAR VISION</span>
                <h2>{vision.cover.title || "Title will appear here"}</h2>
                <div className="bottom">
                  {me.first_name && (
                    <p>
                      <b>
                        {me.first_name} {me.last_name}
                      </b>
                    </p>
                  )}

                  <p>{vision.cover.bio}</p>
                </div>
              </div>
              {vision.cover.color && (
                <div
                  className="overlay"
                  style={{ backgroundColor: vision.cover.color }}
                ></div>
              )}
            </div>
          </div>
        )}

        {vision?.pages?.map((page, i) => (
          <div
            style={{
              display: step === i + 1 ? "block" : "none",
            }}
            className={`box`}
          >
            <div className={`inside ${page.category}`}>
              <div className="top">
                <span>{getCategory(page.category)}</span>
              </div>

              <h2>{page.summary || "Summarize in a few words"}</h2>

              <p>
                {page.response ||
                  "Your response to the prompt will show up in this space."}
              </p>

              {page.image && <img src={page.image} alt="" />}
            </div>
          </div>
        ))}

        <button
          className="empty"
          onClick={() => {
            history.push("/visions");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit my Visions{" "}
        </button>

        <div className="navigation">
          {new Array(count).fill(0).map((_, i) => (
            <button
              className={step === i ? "active" : ""}
              onClick={() => setStep(i)}
            ></button>
          ))}
        </div>
      </div>
    );
  }
  if (adjustedSize === 2) {
    return (
      <div className={`Widget Size-${adjustedSize} Visions`}>
        <h2>Visions</h2>
        <ContextMenu
          link="/visions"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className="slides">
        {vision?.cover && step === 0 && (
          <div className="coverBox box">
            <div
              style={{
                backgroundImage: `url(${vision?.cover.image})`,
              }}
              className="inside"
            >
              <div className="content">
                <span>ONE-YEAR VISION</span>
                <h2>{vision.cover.title || "Title will appear here"}</h2>
                <div className="bottom">
                  {me.first_name && (
                    <p>
                      <b>
                        {me.first_name} {me.last_name}
                      </b>
                    </p>
                  )}

                  <p>{vision.cover.bio}</p>
                </div>
              </div>
              {vision.cover.color && (
                <div
                  className="overlay"
                  style={{ backgroundColor: vision.cover.color }}
                ></div>
              )}
            </div>
          </div>
        )}

        {vision?.pages?.map((page, i) => (
          <div
            className={`box`}
          >
            <div className={`inside ${page.category}`}>
              <div className="top">
                <span>{getCategory(page.category)}</span>
              </div>

              <h2>{page.summary || "Summarize in a few words"}</h2>

              <p>
                {page.response ||
                  "Your response to the prompt will show up in this space."}
              </p>

              {page.image && <img src={page.image} alt="" />}
            </div>
          </div>
        ))}
        </div>
        <button
          className="empty"
          onClick={() => {
            history.push("/visions");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit my Vision{" "}
        </button>
      </div>
    );
  }
};
