import React, { useEffect, useState } from "react";
import "./styles/Enter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faCamera,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Analytics } from "../../hooks/analytics";
import { CareerInput } from "./components/CareerInput";
import mentor from "./images/Mentor.png";
import sponsor from "./images/Sponsor.png";
import peerAdvisor from "./images/PeerAdvisor.png";
import careerSupporter from "./images/CareerSupporter.png";
import industryExpert from "./images/IndustryExpert.png";
import skillsAdvisor from "./images/SkillsAdvisor.png";

import mentorFilled from "./images/filled/Mentor.png";
import sponsorFilled from "./images/filled/Sponsor.png";
import peerAdvisorFilled from "./images/filled/PeerAdvisor.png";
import careerSupporterFilled from "./images/filled/CareerSupporter.png";
import industryExpertFilled from "./images/filled/IndustryExpert.png";
import skillsAdvisorFilled from "./images/filled/SkillsAdvisor.png";

export const CareerCollaboratorsEnter = ({ location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeVision, setActiveVision] = useState({});
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState({});
  const [activeInputData, setActiveInputData] = useState(false);
  const history = useHistory();
  const [allCollaborators, setAllCollaborators] = useState({});
  const [fromEditing, setFromEditing] = useState(location.state?.editing);
  useEffect(() => {
    getCareerCollaborators();
    getMe();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const setActiveInput = (input) => {
    switch (input) {
      case "mentor":
        const data = {
          name: "Mentor",
          color: "#0B455F",
          type: "mentor",
          description:
            "A mentor is an experienced and trusted advisor who provides guidance, advice, and support based on their own career journey and insights. They help you navigate challenges, make informed decisions, and develop your professional skills.",
        };
        setActiveInputData(data);
        break;
      case "sponsor":
        const data1 = {
          name: "Sponsor",
          color: "#3485AA",
          type: "sponsor",
          description:
            "A sponsor is a senior leader or executive who advocates for you and helps open doors to opportunities. They use their influence to help you advance in your career, and can be instrumental in providing visibility and access to key networks and resources.",
        };
        setActiveInputData(data1);
        break;
      case "peer_advisor":
        const data2 = {
          name: "Peer Advisor",
          color: "#E26C29",
          type: "peer_advisor",
          description:
            "A peer advisor or thought partner is a colleague or friend who is at a similar career stage as you. They offer a relatable perspective, and can be a sounding board for ideas, challenges, and opportunities. They provide mutual support and accountability as you navigate your career paths together.",
        };
        setActiveInputData(data2);
        break;
      case "career_supporter":
        const data3 = {
          name: "Career Supporter",
          color: "#E1C7BE",
          type: "career_supporter",
          description:
            "Career supporters are individuals who provide encouragement, motivation, and positive reinforcement as you pursue your career goals. They believe in your potential and are invested in your success, and can include friends, family, or anyone who has a positive impact on your career journey.",
        };
        setActiveInputData(data3);
        break;
      case "industry_expert":
        const data4 = {
          name: "Industry Expert",
          color: "#FF9E67",
          type: "industry_expert",
          description:
            "An industry or functional expert has a deep understanding of a specific sector or functional area (e.g., marketing, finance, tech). They provide insights into trends, opportunities, and challenges in that field, and can help you develop the necessary skills and knowledge to succeed in that domain.",
        };
        setActiveInputData(data4);
        break;
      case "skills_advisor":
        const data5 = {
          name: "Skills Advisor",
          color: "#FEDE66",
          type: "skills_advisor",
          description:
            "A skills advisor is an expert in a specific skill area that is important for your career. They provide guidance and advice on how to develop and refine those skills, and can offer practical tips and strategies for applying those skills in real-world situations.",
        };
        setActiveInputData(data5);
        break;
      default:
        setActiveInputData(false);
    }
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    setMe(response.data);
  };

  const getCareerCollaborators = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setAllCollaborators(response.data);
  };

  return (
    <div className="enterCollaborators">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {}} />

          <h3>Board of Advisors</h3>
        </div>

        <div className="inner">
          <h3>Draft out your board</h3>
          <p>
            Tap on the “->” to see each role more in depth and add people who
            might be a good fit.
          </p>
          <p>
            As you think of people in your network who might fill a role on this
            board, consider your goal and who can help you work
            towards that.
          </p>

          <div className="goal">
            <h4>{me?.goal}</h4>
          </div>
        </div>
        <div className="bottom">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="btn blank"
          >
            Back
          </button>
          {activeVision.id && (
            <button onClick={() => {}} className="btn secondary">
              Save Page
            </button>
          )}
          {!activeVision.id && (
            <button
              onClick={() => {
                if (fromEditing) {
                  return history.push("/board-of-advisors/dashboard");
                }
                history.push("/board-of-advisors/review");
              }}
              className="btn secondary"
            >
              Continue
            </button>
          )}
        </div>
      </div>

      <div className="right">
        <div className="slides">
          <div
            onClick={() => {
              setActiveInput("mentor");
            }}
            style={{
              backgroundColor:
                allCollaborators.mentor?.length > 0 ? "#0B455F" : "#fff",
            }}
            className={`box ${
              allCollaborators.mentor?.length > 0 ? "white" : ""
            }`}
          >
            <div className="top">
              {!allCollaborators.mentor && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
              {!allCollaborators.mentor ? (
              <img src={mentor} alt="" />
              ) : (
                <img src={mentorFilled} alt="" />
              )}
              <h3>Mentor</h3>
            </div>

            <p>
              Trusted advisor who provides guidance and support based on their
              own journey
            </p>

            {!allCollaborators?.mentor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.mentor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            onClick={() => {
              setActiveInput("sponsor");
            }}
            className={`box ${
              allCollaborators.sponsor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                allCollaborators.sponsor?.length > 0 ? "#2A7394" : "#fff",
            }}
          >
            <div className="top">
              {!allCollaborators.sponsor && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
               {!allCollaborators.sponsor ? (
              <img src={sponsor} alt="" />
              ) : (
                <img src={sponsorFilled} alt="" />
              )}
              <h3>Sponsor</h3>
            </div>

            <p>Senior leader who uses their influence to advocate for you</p>

            {!allCollaborators?.sponsor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.sponsor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            onClick={() => {
              setActiveInput("peer_advisor");
            }}
            className={`box ${
              allCollaborators.peer_advisor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                allCollaborators.peer_advisor?.length > 0 ? "#E26C29" : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.peer_advisor && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
               {!allCollaborators.peer_advisor ? (
              <img src={peerAdvisor} alt="" />
              ) : (
                <img src={peerAdvisorFilled} alt="" />
              )}
              <h3>Peer Advisor</h3>
            </div>

            <p>
              Colleague or friend at a similar career stage who can serve as a
              thought partner
            </p>

            {!allCollaborators?.peer_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.peer_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            onClick={() => {
              setActiveInput("career_supporter");
            }}
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.career_supporter?.length > 0
                  ? "#E1C7BE"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.career_supporter && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
              
              {!allCollaborators.career_supporter ? (
                <img src={careerSupporter} alt="" />
              ) : (
                <img src={careerSupporterFilled} alt="" />
              )}

              <h3>Career Supporter</h3>
            </div>

            <p>
              Someone who provides encouragement and motivation you pursue your
              career goals
            </p>

            {!allCollaborators?.career_supporter && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.career_supporter?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            onClick={() => {
              setActiveInput("industry_expert");
            }}
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.industry_expert?.length > 0
                  ? "#FF9E67"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.industry_expert && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
              {!allCollaborators.industry_expert ? (
                <img src={industryExpert} alt="" />
              ) : (
                <img src={industryExpertFilled} alt="" />
              )}
              <h3>Industry Expert</h3>
            </div>

            <p>
              Industry or functional expert who can help you develop necessary
              knowledge
            </p>

            {!allCollaborators?.industry_expert && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.industry_expert?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            onClick={() => {
              setActiveInput("skills_advisor");
            }}
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.skills_advisor?.length > 0
                  ? "#FEDE66"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.skills_advisor && (
                <FontAwesomeIcon icon={faArrowCircleRight} className="camera" />
              )}
              {!allCollaborators.skills_advisor ? (
                <img src={skillsAdvisor} alt="" />
              ) : (
                <img src={skillsAdvisorFilled} alt="" />
              )}
              <h3>Skills Advisor</h3>
            </div>

            <p>
              Expert in a specific skill area who can provide guidance on how to
              develop and refine those skills
            </p>

            {!allCollaborators?.skills_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.skills_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {activeInputData && (
        <CareerInput
          getCareerCollaborators={getCareerCollaborators}
          inputs={allCollaborators[activeInputData.type]}
          activeInput={activeInputData}
          setActiveInput={setActiveInput}
        />
      )}
    </div>
  );
};
