import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../../styles/pages/storyboard/view.scss";
import { CrewImage } from "../../components/CrewImage";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import { Analytics } from "../../hooks/analytics";

export const View = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { id } = useParams();
  const [story, setStory] = React.useState();
  const [reply, setReply] = React.useState("");
  const history = useHistory();
  let array = Array(100).fill("");


  useEffect(() => {
    getStory(id);
  }, []);

  const sendReply = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      storyId: id,
      text: reply,
    };
    await axios.post(`${apiServerUrl}/api/responses/${story.id}`, body, config);
    Analytics("Sent Reply to story");
    setReply("");
    getStory(id);
  };

  const getStory = async (id) => {
    try {
      
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/story/id/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setStory(response.data.story);
    setReply("");
  } catch (error) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
  }
  };

  if (!story) {
    return <div></div>;
  }

  if (story.type === "video") {
    return (
      <div className="view">
        <button
          onClick={() => {
            history.push("/");
          }}
          className="btn back"
        >
          <FontAwesomeIcon icon={faArrowLeftLong} /> Back
        </button>
        <div className="videoContainer container">
          <div className="video">
            <video controls className="video" src={story.mediaUrl}></video>
          </div>

          <div className="right">
          <div className="info">
            <div className="author">
              <CrewImage me={story.sendingUser} />
            </div>
            <div className="name">
              <h3>
                {story.sendingUser.first_name} {story.sendingUser.last_name}
              </h3>
              <p>{moment(story.createdAt).format("MM/DD/YYYY")}</p>
            </div>
          </div>
            {story?.responses?.length > 0 && (
              <div className="replies">
                <h2 className="underline blue">Replies</h2>
                {story.responses.map((response) => (
                  <div className="replyContainer">
                    <div className="reply">
                      <h3>
                        {response.sendingUser.first_name}{" "}
                        {response.sendingUser.last_name}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: response.text }}
                      ></p>
                      <p className="date">
                        {moment(response.createdAt).format("MM/DD/YYYY")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {story?.recievingUserId > 0 && (
            <div className="replyForm">
              <h2 className="underline blue">Add your reply</h2>
              <textarea
                value={reply}
                className="replyText"
                onChange={(e) => setReply(e.target.value)}
              ></textarea>
              <button
                disabled={reply.length === 0}
                className="replyButton btn primary"
                onClick={() => {
                  sendReply();
                }}
              >
                Send Reply
              </button>
            </div>
            )}
            {!story?.recievingUserId && (
              <div className="reply">
                <h2 className="underline blue">{story.toFirstName} hasn't viewed their story yet</h2>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="view">
      <button
        onClick={() => {
          history.push("/");
        }}
        className="btn back"
      >
        <FontAwesomeIcon icon={faArrowLeftLong} /> Back
      </button>
      <div className="container">
      <div
          style={{
            backgroundColor: story.color || '#58c5f3'
          }}
          className="customize"
        >
          {array.map((item, index) => {
            return <span>{story.emoji}</span>;
          })}
        </div>
        <div className="textContainer">
          <div className="text">
            <p dangerouslySetInnerHTML={{ __html: story.text }}></p>
          </div>
          <div className="info">
            <div className="author">
              <CrewImage me={story.sendingUser} />
            </div>
            <div className="name">
              <h3>
                {story.sendingUser.first_name} {story.sendingUser.last_name}
              </h3>
              <p>{moment(story.createdAt).format("MM/DD/YYYY")}</p>
            </div>
          </div>
        </div>

        {story?.responses?.length > 0 && (
          <div className="replies">
            <h2 className="underline blue">Replies</h2>
            {story.responses.map((response) => (
              <div className="replyContainer">
                <div className="reply">
                  <h3>
                    {response.sendingUser.first_name}{" "}
                    {response.sendingUser.last_name}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: response.text }}></p>
                  <p className="date">
                    {moment(response.createdAt).format("MM/DD/YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

          {story?.recievingUserId > 0 && (
            <div className="replyForm">
              <h2 className="underline blue">Add your reply</h2>
              <textarea
                value={reply}
                className="replyText"
                onChange={(e) => setReply(e.target.value)}
              ></textarea>
              <button
                disabled={reply.length === 0}
                className="replyButton btn primary"
                onClick={() => {
                  sendReply();
                }}
              >
                Send Reply
              </button>
            </div>
            )}
            {!story?.recievingUserId && (
              <div className="reply">
                <h2 className="underline blue">{story.toFirstName} hasn't viewed their story yet</h2>
            </div>
            )}
      </div>
    </div>
  );
};
