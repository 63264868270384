export function HideSidebar() {
  const nav = document.getElementsByClassName("nav-bar__container")[0];
  if (nav) {
    nav.style = "display:none";
  }

  const container = document.getElementsByClassName("content_container")[0];
  if (container) {
    container.style = "max-width: 100%;width:100%;";
  }
}

export function ShowSidebar() {
  const nav = document.getElementsByClassName("nav-bar__container")[0];
  if (nav) {
    nav.style = "display:flex";
  }

  const container = document.getElementsByClassName("content_container")[0];
  if (container) {
    container.style = "max-width: 100%;width:100%;";
  }
}
