import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Activities/single.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { isAdmin } from "../../../hooks/isAdmin";
import { ActivityUser } from "./components/ActivityUser";

export const ActivitiesSingle = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [activity_users, setActivityUsers] = useState([]);
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [activity, setActivity] = useState(null);
  let id = props.match.params.id;

  useEffect(() => {
    getMe();
    getActivity();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getActivity = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const groupUsers = response.data.lesson.crew.group.users;

    const activity_users = groupUsers.map((gu) => {
      const filtered = response.data.users.filter((u) => {
        if (u.id === gu.id) {
          return true;
        }

        return false;
      });

      if (filtered.length > 0) {
        const newGu = {
          ...gu,
          link: filtered[0].Activity_User.link,
          completed: true,
        };
        return newGu;
      } else {
        const newGu = { ...gu, completed: false };
        return newGu;
      }
    });

    setActivityUsers(activity_users);

    setActivity(response.data);
  };

  if (!activity) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ActivitySingle">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>crews</NavLink>
        </div>
        /
        <div className="breadcrumb">
          <NavLink to={`/admin/crews/${activity.lesson.crew.id}`}>
            {activity.lesson.crew.name}
          </NavLink>
        </div>
        /
        <div className="breadcrumb">
          <NavLink to={`/admin/lessons/${activity.lesson.id}`}>
            {activity.lesson.name}
          </NavLink>
        </div>
        /<div className="breadcrumb">{activity.name}</div>
      </div>
      <div className="container white-background">
        <NavLink to={`/admin/activities/${activity.id}/edit`} className="edit">
          Edit
        </NavLink>
        <h2>
          <span className="heading1 highlight blue">{activity.name}</span>
        </h2>
        <p
          style={{
            textTransform: "capitalize",
          }}
          className="body-text pill"
        >
          {activity.type.toLowerCase()}
        </p>
        <p className="body-text ">{activity.description}</p>
        <div className="info">
          {activity.linkType === "INTERNAL" && (
            <a
              style={{
                width: "160px",
              }}
              className="btn secondary"
              href={activity.url}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faVideo} /> Start Activity
            </a>
          )}
          {activity.linkType === "EXTERNAL" && (
            <p>
              External Activity. Each member has personalized links. Please fill
              them in below.
            </p>
          )}
        </div>

        {activity.linkType === "EXTERNAL" && (
          <div
            style={{
              marginTop: "3rem",
            }}
            className="activity_users"
          >
            <h2>
              <span className="heading1 highlight blue">
                Personalized Links
              </span>
            </h2>
            {activity_users.map((au) => {
              return <ActivityUser activityId={id} user={au} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
