import React from "react";
import { Link } from "react-router-dom";
import "../../../styles/pages/highlightReel/components/highlightTop.scss";

export const HighlightTop = (props) => {
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };
  const isThereAnImage = () => {
    if (props.highlight.photo) {
      return true;
    }
    return false;
  };

  return (
    <div className="highlight_moment highlightTop">
      {isThereAnImage() && (
      <div className="left">
        <img alt="highlight" src={props.highlight.photo} />
      </div>
      )}
    <div className={`right ${isThereAnImage() ? '' : 'full'}`}>
      <h3>FEATURED</h3>
        <h2><span>{props.highlight.title}</span></h2>

        <p className="highlight_moment_text">{truncateString(props.highlight.text, 120)}
        </p>

        <button
          className="btn secondary"
          onClick={() => {
            props.setActiveHighlight(props.highlight);
          }}
        >
          View More
        </button>

      </div>
    </div>
  );
};
