import React, { useEffect, useState } from "react";
import "./styles/Strengths.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/strengthsIcon.png";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import ContextMenu from "./ContextMenu";
import ZoomableBubbleChart from "../../Path/components/ZoomableBubbleChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const StrengthsWidget = ({ id, size, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [strengths, setStrengths] = useState([]);
  const [strengthsGraph, setStrengthsGraph] = useState([]);
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [step, setStep] = useState(1); // [1, 2]
  const [active, setActive] = useState(0);
  const [adjustedSize, setAdjustedSize] = useState(size);
  const [activeInvite, setActiveInvite] = useState(null); // [1, 2]
  const [invites, setInvites] = useState([]);
  useEffect(() => {
    getStrengths();
    getInvites();
  }, [adjustedSize]);

  const getInvites = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const onlyComments = response.data.filter((x) => x.comment);
    setInvites(onlyComments);
  };

  const getStrengths = async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/strengths/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.length === 0) return;

    setActive(response.data[0]);
    setStrengths(response.data);

    const invites = response.data;

    const countedStrengths = invites.reduce((accumulator, cv) => {
      let index = false;
      let found = null;

      // See if we already have this value in the accumulator
      accumulator.forEach((y, number) => {
        let inArray = y._id === cv.text;
        if (inArray && !found) {
          found = true;
          index = number;
          return;
        }
        return index;
      });

      // If its found add to the index
      if (index !== false) {
        accumulator[index].value++;
        return accumulator;
      }
      // If not add it to the accumulator
      accumulator.push({
        _id: cv.text,
        value: 1,
        createdAt: cv.createdAt,
      });

      return accumulator;
    }, []);

    const sortedStrengths = countedStrengths.sort((a, b) => {
      return b.value - a.value;
    });

    setStrengthsGraph(sortedStrengths);
  };



  if (!strengths || strengths.length === 0) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Strengths</h3>
        {!programUser && (
        <button
          className="btn"
          onClick={() => {
            history.push("/strengths");
          }}
        >
          Go to Strengths
        </button>
        )}
      </div>
    );
  }

  // Size 1 widget
  if (adjustedSize === 1) {
    return (
      <div className={`Widget Size-${adjustedSize} Strengths`}>
        <h2>Strengths</h2>
        <ContextMenu
          link="/strengths"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        {step === 2 && (
          <div className="list">
            <ul>
              {strengths.map((value, index) => (
                <li className={active === value ? "active" : ""}>
                  <span>{index + 1}</span>
                  <p>{value.text}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {step === 1 && (
          <div className="chart list">
            {strengthsGraph.length > 0 && (
              <ZoomableBubbleChart data={strengthsGraph} />
            )}
          </div>
        )}
        {step > 2 && (
          <div className="feedback list">
            <div className="icon">
              <FontAwesomeIcon icon={faFeather} />
              <h2>Feedback</h2>
            </div>
            <h3>"{activeInvite.comment}"</h3>
            <h5>{activeInvite.firstName}</h5>
          </div>
        )}

        <button
          className="empty"
          onClick={() => {
            history.push("/strengths");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit my strengths{" "}
        </button>

        <div className="navigation">
          <button
            className={step === 1 ? "active" : ""}
            onClick={() => setStep(1)}
          ></button>
          <button
            className={step === 2 ? "active" : ""}
            onClick={() => setStep(2)}
          ></button>
          {invites.length > 0 &&
            invites.map((invite, idx) => (
              <button
                className={step === idx + 3 ? "active" : ""}
                onClick={() => {
                  setActiveInvite(invite);
                  setStep(idx + 3);
                }}
              ></button>
            ))}
        </div>
      </div>
    );
  }
  if (adjustedSize === 2) {
    return (
      <div className={`Widget Size-${adjustedSize} Strengths`}>
        <h2>Strengths</h2>
        <ContextMenu
          link="/strengths"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div class="sides">
          <div className="chart list">
            {strengthsGraph.length > 0 && (
              <ZoomableBubbleChart data={strengthsGraph} />
            )}
          </div>
          <div className="list">
            <ul>
              {strengths.map((value, index) => (
                <li className={active === value ? "active" : ""}>
                  <span>{index + 1}</span>
                  <p>{value.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button
          className="empty"
          onClick={() => {
            history.push("/strengths");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit my strengths{" "}
        </button>
      </div>
    );
  }
};
