import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../context/env.context";
import axios from "axios";
import "../styles/pages/waitlist.scss"
import { useHistory } from "react-router-dom";
import { Analytics } from "../hooks/analytics";
export const FinishedOnboarding = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();


  useEffect(() => {
    getVideo();
    Analytics("Finished Onboarding");
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));


  const getVideo = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/video/user`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if(response.data.videos?.id) {
      history.push(`/video-review`);
      return;
    }

    await delay(6000);

    getVideo();
  }


  return (
    <div className="waitlist">

<lottie-player src="https://assets6.lottiefiles.com/datafiles/XpFCWApEzLI29va/data.json"  background="transparent"  speed="1"  style={{
        width: "300px",
        height: "300px",
        margin: "0 auto",
      }}  loop autoplay></lottie-player>
      <h3 style={{
        color:"#fff"
      }}>Your story is almost uploaded!</h3>
      
    </div>
  );
};
