import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Groups/edit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isAdmin } from "../../../hooks/isAdmin";

export const GroupEdit = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [name, setName] = useState("");
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const {id} = props.match.params;
  const [group, setGroup] = useState({
    name: "",
    users: [],
  });

  useEffect(() => {
    getMe();
    getGroup();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);


    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const removeGroupMember = async (user) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/groups/user/${user}`,
      method: "DELETE",
      data: {
        groupId: id,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    

  };

  const getGroup = async () => {
    const token = await getAccessTokenSilently();

    const config = {
        url: `${apiServerUrl}/api/groups/${id}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };
    config.headers = {
        Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setSelected(response.data.users);
    setGroup(response.data);
    };

  const filterBy = (item) => {
    return selected.filter((i) => i.id === item.id).length === 0;
  };

  const handleSearch = async (query) => {
    setIsLoading(true);

    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users?search=${query}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data.length > 0) {
      setOptions(response.data);
    } else {
      setOptions([]);
    }

    setIsLoading(false);
  };

  

  const editGroup = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/groups`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        name: name,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      addGroupMember(response.data.id);
    }
  };

  const addGroupMember = async (groupId) => {
    const token = await getAccessTokenSilently();

    let users = selected.map((user) => {
      const config = {
        url: `${apiServerUrl}/api/groups/user/${user.id}`,
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: {
          groupId: groupId,
        },
      };

      config.headers = {
        Authorization: `Bearer ${token}`,
      };

      return axios(config);
    });

    await Promise.all(users);

    history.push("/admin");

  };

  const addGroupMemberSingle = async (userId) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/groups/user/${userId}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        groupId: id,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      getGroup();
    }
  };

  if(!group.users) {
    return <div>Loading...</div>
  }

  return (
    <div className="GroupEdit">
      <h2 className="heading1 highlight blue">Edit {group.name} Group</h2>

      <div className="white-background container">
        <div className="inputBox">
          <label htmlFor="name">Name</label>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="text"
            name="name"
            id="name"
            value={group.name}
          />
        </div>

        <div className="inputBox">
          <label htmlFor="people">Users</label>
          <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey={(option) => `${option.first_name} ${option.last_name}`}
            minLength={2}
            onChange={(selected) => {
              if(selected && selected[0] && selected[0]?.id > 0) {
                addGroupMemberSingle(selected[0].id);
              }
            }}
            onSearch={handleSearch}
            options={options}
            placeholder="Search for a user..."
            renderMenuItemChildren={(option) => (
              <div key={option.id}>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/100x100?text=${
                      option.first_name.charAt(0) + option.last_name.charAt(0)
                    }`;
                  }}
                  src={option.avatar}
                />

                <span>
                  {option.first_name} {option.last_name}
                </span>
              </div>
            )}
          />

          {selected.length > 0 && (
            <div className="selected">
              {selected.map((user) => (
                <div className="item" key={user.id}>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `https://placehold.co/100x100?text=${
                        user.first_name.charAt(0) + user.last_name.charAt(0)
                      }`;
                    }}
                    src={user.avatar}
                  />
                  <span>
                    {user.first_name} {user.last_name}
                  </span>

                  <button
                    className="btn secondary purple"
                    onClick={() => {
                      setSelected(selected.filter((i) => i.id !== user.id));
                      removeGroupMember(user.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>

                  </div>
              ))}
              </div>
          )}
        </div>

        <button className="btn primary" onClick={()=>{
            editGroup();
        }}>Edit Group</button>
      </div>
    </div>
  );
};
