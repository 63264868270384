import { Diagram, Port, createLinkDefault } from "react-easy-diagram";
import { observer } from "mobx-react-lite";

export const DiagramContainer = ({
  initState,
  storeRef,
  savingNodes,
  savingLinks,
}) => {
  const ThirdComponent = observer(({ entity }) => {
    return (
      <div
        style={{
          padding: 0,
          width: 250,
          fontSize: 10,
        }}
      >
        <div className="secondComp">
          <h3>{entity.data.title}</h3>
        </div>
        {Array.from(entity.ports).map(([id]) => (
          <Port id={id} key={id} />
        ))}
      </div>
    );
  });

  const ThirdComponentCollab = observer(({ entity }) => {
    return (
      <div
        style={{
          padding: 0,
          width: 250,
          fontSize: 10,
        }}
      >
        <div style={{

        }} className="secondComp collab">
          <h3>{entity.data.title}</h3>
          <h4>{entity.data.userName}</h4>

        </div>
        {Array.from(entity.ports).map(([id]) => (
          <Port id={id} key={id} />
        ))}
      </div>
    );
  });

  const SecondComponent = observer(({ entity }) => {
    return (
      <div
        style={{
          padding: 0,
          width: 220,
          fontSize: 10,
        }}
      >
        <div className="secondComp">
          <h3>{entity.data.title}</h3>
          <p>{entity.data.description}</p>
        </div>
        {Array.from(entity.ports).map(([id]) => (
          <Port id={id} key={id} />
        ))}
      </div>
    );
  });

  const FirstComponent = observer(({ entity }) => {
    return (
      <div
        style={{
          padding: 0,
          width: 220,
          fontSize: 10,
        }}
      >
        <div className="firstComp">
          <h4>My topic</h4>
          <h3>{entity.data.title}</h3>
        </div>
        {Array.from(entity.ports).map(([id]) => (
          <Port id={id} key={id} />
        ))}
      </div>
    );
  });

  return (
    <Diagram
      initState={initState}
      storeRef={storeRef}
      settings={{
        callbacks: {
          onNodesAddResult: (info, store) => {
            if (info.addedNodes.length > 0) {
              savingNodes = info.addedNodes.map((n) => n.export());
            }
          },
          onLinksAddResult: (info, store) => {
            if (info.addedLinks.length > 0) {
              savingLinks = info.addedLinks.map((l) => l.export());
            }
          },
          onNodePositionChanged: (info, store) => {
            // Replace the node in the array
            const index = savingNodes.findIndex((n) => n.id === info.node.id);
            if (index > -1) {
              savingNodes[index] = info.node.export();
            }
          },
        },
        links: {
          components: {
            collabArrow: createLinkDefault({
              mainLine: {
                style: {
                  base: {
                    stroke: "#E4C037",
                    markerEnd: "url(#custom_arrow)", // There 6 built-in markers: 'default_{circle|arrow}_marker', 'default_{circle|arrow}_marker_selected', 'default_{circle|arrow}_marker_hovered'
                    markerStart: "url(#custom_circle)",
                    strokeDasharray: "4",
                    strokeWidth: 1,
                  },
                  hovered: {
                    markerStart: "url(#custom_circle_hovered)",
                  },
                  selected: {
                    markerStart: "url(#custom_circle_selected)",
                  },
                },
              },
            }),
          },
        },
        nodes: {
          components: {
            SecondComponent: {
              component: SecondComponent,
              settings: {
                ports: [
                  {
                    id: "input",
                    type: "input",
                    position: "left-center",
                  },
                  {
                    id: "output",
                    type: "output",
                    position: "right-center",
                  },
                ],
              },
            },
            FirstComponent: {
              component: FirstComponent,
              settings: {
                ports: [
                  {
                    id: "output",
                    type: "output",
                    position: "right-center",
                  },
                ],
              },
            },
            ThirdComponent: {
              component: ThirdComponent,
              settings: {
                ports: [
                  {
                    id: "input",
                    type: "input",
                    position: "left-center",
                  },
                ],
              },
            },
            ThirdComponentCollab: {
              component: ThirdComponentCollab,
              settings: {
                ports: [
                  {
                    id: "input",
                    type: "input",
                    position: "left-center",
                  },
                ],
              },
            },
          },
        },
      }}
    />
  );
};
