import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/RoleModalProgramModal.scss";
import React, { useState } from "react";
export const ProgramModal = ({ setShowModal }) => {
  return (
    <div className="modal ChooseRoleModelsModal">
      <div className="modal-content">
        <FontAwesomeIcon
          className="close"
          icon={faTimes}
          onClick={() => setShowModal(false)}
        />
        <div className="left">
          <div className="box">
            <img src={'https://crewavatars.s3.amazonaws.com/LEARNING_ACTIVITY-1683131348639-google-oauth2%7C103604631623770300891.png'} alt="box" />
          </div>
        </div>
        <div className="right">
          <h3>Here's what to expect</h3>
          <h2>In this activity, you'll identify three of your role models.</h2>
          <h4>Here's what to do before your next session:</h4>

          <ol>
            <li>
              <p>Think of someone who you admire or look up to.</p>
            </li>
            <li>
              <p>
                Add their name, a photo, and a brief description of who they are
              </p>
            </li>
            <li>
              <p>
                Repeat this process for two additional role models. That's it!
                The rest of the activity will take place in your coaching
                session
              </p>
            </li>
          </ol>

          <div className="actions">
            <button
              className="btn secondary"
              onClick={() => setShowModal(false)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="skrim"
      ></div>
    </div>
  );
};
