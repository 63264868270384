import React, { useEffect, useState, useRef } from "react";
import "../../../styles/pages/Values/components/OnboardedValueFlowAdd.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";

export const OnboardedValueFlowAdd = ({
  value,
  setActiveValue,
  activeImages,
  getValues,
  setActiveImages,
  key,
  me,
}) => {
  const [existing, setExisting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [adding, setAdding] = useState(false);
  const [newValue, setNewValue] = useState({});
  const { apiServerUrl } = useEnv();
  const input = useRef(null);

  useEffect(() => {}, []);

  const saveNewValue = async () => {
    const value = {
      ...newValue,
      roleModels: activeImages,
      userId: me.id,
    };
    const token = await getAccessTokenSilently();
    const response = await fetch(`${apiServerUrl}/api/role-model/values`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    const data = await response.json();
    setAdding(false);
    setNewValue({
      text: "",
      summary: "",
    });
    setTimeout(() => {
      getValues();
    }, 400);
    setActiveImages([]);
    setActiveValue({
      id: data.id,
    });
    Analytics("10 Images - Saved New Value");
    return data;
  };

  if (!adding) {
    return (
      <div className={`OnboardedValueFlowAdd notAdding`} key={key}>
        <button
          onClick={() => {
            setActiveValue({
              id: "new",
            });
            setAdding(true);
            setTimeout(() => {
              input?.current?.focus();
            }, 1);
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Value
        </button>
      </div>
    );
  }

  return (
    <div
      className={`OnboardedValueFlowAdd ${existing ? "editing" : ""}`}
      key={key}
    >
      <div className="value__image">
        <input
          value={newValue.text}
          ref={input}
          onChange={(e) => {
            const value = { ...newValue };
            value.text = e.target.value;
            setNewValue(value);
          }}
          type="text"
          placeholder="Enter Title"
        />

        <div className="actionBox">
          <textarea
            onChange={(e) => {
              const value = { ...newValue };
              value.summary = e.target.value;
              setNewValue(value);
            }}
            value={newValue.summary}
            className="textArea"
            placeholder="Now, describe what it means to you"
          ></textarea>
          {!activeImages || activeImages.length < 1 ? (
            <div className="pill">
              <p>Tap on photo(s) that relate to this value</p>
            </div>
          ) : (
            <div className="pill">
              <p>{activeImages.length} selected</p>
            </div>
          )}
          <div className="buttons">
            <button
              className="btn blank save cancel"
              onClick={() => setAdding(false)}
            >
              Cancel
            </button>
            <button
              className="btn blank save"
              disabled={
                !activeImages ||
                activeImages.length < 1 ||
                !newValue.summary ||
                !newValue.text
              }
              onClick={() => {
                saveNewValue();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
