import React, { useState, useEffect } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/components/dropdowns/profile-dropdown.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faEdit, faUser } from "@fortawesome/free-solid-svg-icons";
import { CrewImage } from "../CrewImage";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";

export const ProfileDropdown = () => {
  const [me, setMe] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    let user = response.data;

    mixpanel.identify(user.id);

    mixpanel.people.set({
      $name: user.first_name + " " + user.last_name,
      $email: user.email,
    });

    LogRocket.identify(user.id, {
      name: user.first_name + " " + user.last_name,
      email: user.email,
    });

    window.analytics.identify(user.id, {
      name: user.first_name + " " + user.last_name,
      email: user.email,
    });

    setMe(response.data);
  };

  if (!me) {
    return <div></div>;
  }

  return (
    <div className="profile-dropdown">
       <Link to="/profile/edit">
      <div className="image">
        <CrewImage
          clicked={() => {
            setExpanded(!expanded);
          }}
          me={me}
        />
        <p>
          {me.first_name} {me.last_name}
        </p>
        </div>
      </Link>

    </div>
  );
};
