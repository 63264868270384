import React, { useEffect, useState } from "react";
import "../../../styles/pages/Programs/dashboard.scss";
import "moment-timezone";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChevronLeft,
  faClock,
  faLightbulb,
  faPencil,
  faTimes,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import hero from "../images/hero.png";

export const ProgramComponent = ({
  crew,
  finalStage,
  insights,
  me,
  addingReflection,
  setAddingReflection,
  newReflection,
  setNewReflection,
  saveInsight,
  reflections,
  allSessions,
  recordings,
  showBio,
  setShowBio,
}) => {
  const history = useHistory();
  const [bio, setBio] = useState(false);
  const [activeSession, setActiveSession] = useState({});
  const [withinAnHour, setWithinAnHour] = useState(false);

  const colors = [
    {
      color: "#fff8dc",
      icon: "#FEDE66",
    },
    {
      color: "#ebf3f7",
      icon: "#2A7394",
    },
    {
      color: "#fde8dc",
      icon: "#EE8041",
    },
    {
      color: "#f6ecff",
      icon: "#A266DF",
    },
    {
      color: "#fff8dc",
      icon: "#FEDE66",
    },
    {
      color: "#ebf3f7",
      icon: "#2A7394",
    },
    {
      color: "#fde8dc",
      icon: "#EE8041",
    },
    {
      color: "#f6ecff",
      icon: "#A266DF",
    },
  ];

  // Check if the session is within an hour
  useEffect(() => {
    const now = moment();
    const session = moment(activeSession.startDate);
    const hoursAway = session.diff(now, "hours");
    if (hoursAway === 0) {
      setWithinAnHour(false);
      return true;
    }
    setWithinAnHour(true);
    return false;
  }, [activeSession]);

  useEffect(() => {
    const bio = crew?.coach?.bio || "Coming Soon...";
    setBio(bio.replace(/\n/g, "<br>"));
    if (crew.activeSession) {
      setActiveSession(crew.activeSession);
    }
  }, [crew]);

  const getTitle = (type) => {
    if (type.includes("lifeline")) {
      return "Lifeline Tool";
    } else if (type.includes("strengths")) {
      return "Strengths Tool";
    } else if (type.includes("values")) {
      return "Values Tool";
    } else if (type.includes("visions")) {
      return "Visions Tool";
    } else if (type.includes("topic")) {
      return "Tying It All Together Tool";
    }

    return "";
  };
  const getFilteredSessions = (sessions) => {
    const now = moment().startOf("day");

    if (!sessions) return [];

    const sortedSessions = sessions.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate));
    });

    return sortedSessions.filter((session) => {
      return moment(session.startDate).isAfter(now);
    });
  };

  return (
    <div className="programsDashboard">
      <div className="center">
        <Link to="/dashboard">
          <FontAwesomeIcon icon={faChevronLeft} /> Back to Canvas
        </Link>

        <div
          style={{
            backgroundImage: `url(${crew?.company?.header_image})`,
          }}
          className="header"
        >
          <h4>Your Program</h4>
          <div className="timeline">
            <div className="line"></div>
            <div className="sessions">
              {allSessions.map((session) => {
                return (
                  <div
                    onClick={() => {
                      setActiveSession(session);
                    }}
                    className="session"
                  >
                    <div className={`number ${session.past ? "done" : ""}`}>
                      {session.order}
                    </div>

                    <h4>{session.learningActivity?.title}</h4>
                    <h5>{moment(session.startDate).format("MMMM Do")}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {getFilteredSessions(crew.program?.sessions).length !== 0 &&
          !activeSession.learningActivity?.comingSoon && (
            <div className="sessionData">
              <div className="comingUp">
                <div className="items">
                  <span>Session {activeSession.order}</span>
                  <span className="date">
                    {moment(activeSession.startDate).format("MMMM Do")} at{" "}
                    {moment(activeSession.startDate).format("hh:mm a")}
                  </span>
                </div>

                <h2>{activeSession?.learningActivity?.title}</h2>
                <p>{activeSession?.description}</p>

                <div className="actions">
                  <button
                    onClick={() => {
                      if (
                        activeSession?.learningActivity?.linkType === "INTERNAL"
                      ) {
                        history.push(activeSession?.learningActivity?.url);
                      } else {
                        window.open(
                          activeSession?.learningActivity?.url,
                          "_blank"
                        );
                      }
                      return;
                    }}
                    className="btn blank"
                  >
                    Start Pre-Work
                  </button>

                  <button
                    onClick={() => {
                      window.open(activeSession?.meetingLink, "_blank");
                    }}
                    disabled={withinAnHour}
                    className="zoom"
                  >
                    <FontAwesomeIcon icon={faClock} /> Join Meeting
                  </button>
                </div>
              </div>
            </div>
          )}

        {getFilteredSessions(crew.program?.sessions).length !== 0 &&
          activeSession.learningActivity?.comingSoon && (
            <div className="sessionData">
              <div className="comingUp">
                <div className="items">
                  <span>Session {activeSession.order}</span>
                  <span className="date">
                    {moment(activeSession.startDate).format("MMMM Do")} at{" "}
                    {moment(activeSession.startDate).format("hh:mm a")}
                  </span>
                </div>

                <h2>{activeSession?.learningActivity?.title}</h2>
                <p>{activeSession?.description}</p>

                <div className="actions">
                  <button disabled className="btn blank">
                    Coming Soon!
                  </button>

                  <button
                    onClick={() => {
                      window.open(activeSession?.meetingLink, "_blank");
                    }}
                    disabled={withinAnHour}
                    className="zoom"
                  >
                    <FontAwesomeIcon icon={faClock} /> Join Meeting
                  </button>
                </div>
              </div>
            </div>
          )}

        {getFilteredSessions(crew.program?.sessions).length === 0 && (
          <div className="done">
            <div className="left">
              <h4>You did it!</h4>
              <h3>
                Way to go, {me?.first_name}! We'd love to hear about your Crew
                experience.
              </h3>

              <p>Fill out our end-of-program survey.</p>
              <button
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSeSsINJWh4e7kNt5TYUdvsx1EAeq_-_0d7yQQ_t3Jceu_PkjQ/viewform",
                    "_blank"
                  );
                }}
                className="btn secondary"
              >
                Access Survey
              </button>
            </div>
            <div
              style={{
                backgroundImage: `url(${hero})`,
              }}
              className="right"
            ></div>
          </div>
        )}

        {finalStage && reflections.length < 3 && (
          <div className="reflections">
            <div className="top">
              <h3>
                <FontAwesomeIcon icon={faLightbulb} /> REFLECTION{" "}
                <span>COMPLETE DURING SESSION</span>
              </h3>
              {!addingReflection && (
                <>
                  <h2>
                    What are your three biggest takeaways from the Crew program?
                  </h2>
                  <button
                    onClick={() => {
                      setAddingReflection(true);
                    }}
                    className="btn secondary"
                  >
                    <FontAwesomeIcon icon={faPencil} /> Add a reflection
                  </button>
                </>
              )}
              {addingReflection && (
                <>
                  <textarea
                    placeholder="Add a reflection"
                    className="reflection"
                    onChange={(e) => {
                      setNewReflection({
                        description: e.target.value,
                        type: "programReflection",
                        question:
                          "What are your three biggest takeaways from the Crew program?",
                      });
                    }}
                  ></textarea>
                  <button
                    className="btn secondary"
                    onClick={() => {
                      saveInsight(newReflection);
                    }}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        {finalStage && (
          <div className="insights">
            <div className="top">
              <h3>Insights</h3>
            </div>

            <div className="allInsights">
              {reflections && reflections.length > 0 && (
                <>
                  {reflections.map((reflection) => {
                    return (
                      <div className="insight reflection">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M9.70644 17.457H14.3794M12.0429 3.45703V4.45703M18.4069 6.09307L17.6998 6.80018M21.043 12.457H20.043M4.04297 12.457H3.04297M6.38606 6.80018L5.67895 6.09307M8.50738 15.9926C6.55476 14.04 6.55476 10.8741 8.50738 8.92152C10.46 6.9689 13.6258 6.9689 15.5784 8.92152C17.5311 10.8741 17.5311 14.04 15.5784 15.9926L15.0314 16.5397C14.3985 17.1726 14.0429 18.0309 14.0429 18.926V19.457C14.0429 20.5616 13.1475 21.457 12.0429 21.457C10.9383 21.457 10.0429 20.5616 10.0429 19.457V18.926C10.0429 18.0309 9.68736 17.1726 9.05448 16.5397L8.50738 15.9926Z"
                            stroke="#0b455f"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <h4>Final Reflection</h4>
                        <p>{reflection.description}</p>
                        <span>
                          Added {moment(reflection.createdAt).format("MMMM Do")}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}
              {insights.map((insight) => {
                return (
                  <div className="insight">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M9.70644 17.457H14.3794M12.0429 3.45703V4.45703M18.4069 6.09307L17.6998 6.80018M21.043 12.457H20.043M4.04297 12.457H3.04297M6.38606 6.80018L5.67895 6.09307M8.50738 15.9926C6.55476 14.04 6.55476 10.8741 8.50738 8.92152C10.46 6.9689 13.6258 6.9689 15.5784 8.92152C17.5311 10.8741 17.5311 14.04 15.5784 15.9926L15.0314 16.5397C14.3985 17.1726 14.0429 18.0309 14.0429 18.926V19.457C14.0429 20.5616 13.1475 21.457 12.0429 21.457C10.9383 21.457 10.0429 20.5616 10.0429 19.457V18.926C10.0429 18.0309 9.68736 17.1726 9.05448 16.5397L8.50738 15.9926Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <h4>{getTitle(insight.type)}</h4>
                    <p>{insight.description}</p>
                    <span>
                      Added {moment(insight.createdAt).format("MMMM Do")}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="resources">
          <h3>Your Resources</h3>
          <div className="items">
            {recordings.map((session, index) => {
              return (
                <div
                  style={{
                    backgroundColor: colors[index + 1]?.color || "#fff8dc",
                  }}
                  className="resource"
                >
                  <div
                    style={{
                      backgroundColor: colors[index + 1]?.icon || "#FEDE66",
                    }}
                    className="icon"
                  >
                    <FontAwesomeIcon icon={faVideo} />
                  </div>
                  <h4>
                    Session {session.order} Recording:{" "}
                    {session.learningActivity.title}
                  </h4>
                  <a
                    href={session.sessionRecordingLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Watch Recording
                  </a>
                </div>
              );
            })}
            <div
              style={{
                backgroundColor: colors[0]?.color || "#fff8dc",
              }}
              className="resource"
            >
              <div
                style={{
                  backgroundColor: colors[0]?.icon || "#FEDE66",
                }}
                className="icon"
              >
                <FontAwesomeIcon icon={faBook} />
              </div>
              <h4>Welcome Pack</h4>
              <p>Learn more about what to expect from your Crew program.</p>
              <a
                href={
                  crew?.welcomePackUrl ||
                  "https://docs.google.com/presentation/d/1pXzVf0fjMWhkkfSo5p96n7ourlThED7FeFNAKDRftH0/edit?usp=sharing"
                }
                target="_blank"
                rel="noreferrer"
              >
                Open
              </a>
            </div>
          </div>
        </div>

        <div className="bottomContent">
          <div className="meetYourCrew">
            <h3>Meet your crew</h3>
            <div className="users">
              <div className="user coach">
                <div className="icon">
                  {!crew?.coach?.avatar && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M16.5716 7.25686C16.5716 9.52912 14.7296 11.3712 12.4573 11.3712C10.1851 11.3712 8.34304 9.52912 8.34304 7.25686C8.34304 4.98461 10.1851 3.14258 12.4573 3.14258C14.7296 3.14258 16.5716 4.98461 16.5716 7.25686Z"
                        stroke="#0B455F"
                        strokeWidth="2.05714"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4573 14.4569C8.48087 14.4569 5.25732 17.6804 5.25732 21.6569H19.6573C19.6573 17.6804 16.4338 14.4569 12.4573 14.4569Z"
                        stroke="#0B455F"
                        strokeWidth="2.05714"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {crew?.coach?.avatar && (
                    <img
                      src={crew?.coach?.avatar}
                      onError={`this.onError = null; this.src = 'https://placehold.co/100x100?text=${
                        crew?.coach?.first_name.charAt(0) +
                        crew?.coach?.last_name.charAt(0)
                      }'`}
                      alt={crew?.coach?.first_name}
                    />
                  )}
                </div>
                <div className="name coach">
                  <div className="info">
                    <h2>
                      {crew?.coach?.first_name} {crew?.coach?.last_name}
                    </h2>
                    <h3>Crew Coach</h3>
                  </div>
                  <span
                    onClick={() => {
                      setShowBio(!showBio);
                    }}
                    className="bio"
                  >
                    Read Bio
                  </span>
                </div>
              </div>
              {crew.users?.map((user) => {
                if (user.admin) return null;
                return (
                  <div key={user.id} className="user">
                    <div className="icon">
                      {!user.avatar && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M16.5716 7.25686C16.5716 9.52912 14.7296 11.3712 12.4573 11.3712C10.1851 11.3712 8.34304 9.52912 8.34304 7.25686C8.34304 4.98461 10.1851 3.14258 12.4573 3.14258C14.7296 3.14258 16.5716 4.98461 16.5716 7.25686Z"
                            stroke="#0B455F"
                            strokeWidth="2.05714"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.4573 14.4569C8.48087 14.4569 5.25732 17.6804 5.25732 21.6569H19.6573C19.6573 17.6804 16.4338 14.4569 12.4573 14.4569Z"
                            stroke="#0B455F"
                            strokeWidth="2.05714"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      {user.avatar && (
                        <img
                          onerror={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `https://placehold.co/100x100?text=${
                              user?.first_name.charAt(0) +
                              user?.last_name.charAt(0)
                            }`;
                          }}
                          src={user.avatar}
                          alt={user.first_name}
                        />
                      )}
                    </div>
                    <div className="name">
                      <h2>
                        {user.first_name} {user.last_name}
                      </h2>
                      <h3>{user.title || "Crew User"}</h3>
                    </div>
                  </div>
                );
              })}

              {crew.users?.length < 1 && (
                <div className="user">
                  <div className="icon">
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                  <div className="name">
                    <h2>
                      Waiting on the rest of your crew to join. <br />
                    </h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showBio && (
        <div className="modal">
          <div className="modal-content">
            <FontAwesomeIcon icon={faTimes} onClick={() => setShowBio(false)} />
            <div className="left">
              <img
                onError={() => {
                  this.onerror = null;
                  this.src =
                    "https://placehold.co/100x100?text=" +
                    crew.coach.first_name.charAt(0) +
                    crew.coach.last_name.charAt(0);
                }}
                src={
                  crew.coach.avatar ||
                  "https://placehold.co/100x100?text=" +
                    crew.coach.first_name.charAt(0) +
                    crew.coach.last_name.charAt(0)
                }
                alt={crew.coach.first_name}
              />
            </div>

            <div className="right">
              <h4>Meet Your Coach</h4>
              <h3>
                {crew.coach?.first_name} {crew.coach?.last_name}
              </h3>
              <p dangerouslySetInnerHTML={{ __html: bio }}></p>

              <button
                onClick={() => setShowBio(false)}
                className="btn secondary"
              >
                Close
              </button>
            </div>
          </div>
          <div className="skrim" onClick={() => setShowBio(false)}></div>
        </div>
      )}
    </div>
  );
};
