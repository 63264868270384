import React, { useEffect, useState } from "react";
import "../../styles/components/Chat/message.scss";

export const ChatMessage = ({ chat, continueLoop, userInputs, divRef }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(chat.message);
  const [continued, setContinued] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let message = chat.message?.replace(/{([^{}]+)}/g, function (keyExpr, key) {
        return userInputs[key] || "";
      });

    setMessage(message);

    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div id="chatMessage" className="chatMessage">
      <div className="chatMessageBox">
        <img alt="avatar" className={`${chat.showImage ? '' : 'hide'}`} src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg" />
        <div className="chatMessageTitle">
          <h4 className={`${chat.showImage ? '' : 'hide'}`}>Roxanne Burbach {chat.app}</h4>
          {!chat.programType && (
            <div  className={`bubble ${chat.showImage ? 'showImage' : 'hideImage'}`}>
              {!loading && <p>{message}</p>}
              {loading && <div class="dot-flashing"></div>}
              {chat.break && !loading && !continued && (
                <button
                  onClick={() => {
                    continueLoop();
                    setContinued(true);
                  }}
                >
                  Continue
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
