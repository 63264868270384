import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithHistory } from "./auth0-provider-with-history";
import { EnvProvider } from "./context/env.context";
import "./styles/styles.scss";
import { NotificationContainer } from "react-notifications";

if (window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

ReactDOM.render(
    <BrowserRouter>
      <EnvProvider>
        <Auth0ProviderWithHistory>
          <NotificationContainer />
          <App />
        </Auth0ProviderWithHistory>
      </EnvProvider>
    </BrowserRouter>,
  document.getElementById("root")
);
