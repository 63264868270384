import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import "./styles/mailingList.scss";

export const MailingList = ({ crewMailingList, userEmails, crew }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [open, setOpen] = useState(false);
  const [activeCrewMailingList, setActiveCrewMailingList] =
    useState(crewMailingList);
  const [selectedTemplate, setSelectedTemplate] = useState("RCG");
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(false);

  const minDateTime = moment().add(1, "days").format("YYYY-MM-DDTHH:mm");

  const templateDefaults = {
    RCG: {
      email1: moment()
        .add(2, "day")
        .set({ hour: 12, minute: 0 })
        .format("YYYY-MM-DDTHH:mm"),
      email2: moment()
        .add(3, "day")
        .set({ hour: 12, minute: 0 })
        .format("YYYY-MM-DDTHH:mm"),
      email3: moment()
        .add(4, "day")
        .set({ hour: 12, minute: 0 })
        .format("YYYY-MM-DDTHH:mm"),
    },
  };

  useEffect(() => {
    setSchedule(templateDefaults[selectedTemplate] || {});
  }, [selectedTemplate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    const token = await getAccessTokenSilently();

    const convertToUTC = (obj) => {
      const convertedObj = {};

      for (const [key, value] of Object.entries(obj)) {
        convertedObj[key] = moment(value).utc().format();
      }

      return convertedObj;
    };

    const utcDateObject = convertToUTC(schedule);

    setLoading(true);
    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}/mailingList`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        userEmails,
        emailTemplateGroup: selectedTemplate,
        schedule: utcDateObject,
        crewName: crew.name,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setActiveCrewMailingList(response.data.newCrewMailingList);
    setLoading(false);
  };

  const handleScheduleChange = (emailKey, date) => {
    setSchedule((prev) => ({
      ...prev,
      [emailKey]: date,
    }));
  };

  const renderTemplateFields = () => {
    if (!schedule || Object.keys(schedule).length === 0) return null;

    return Object.keys(schedule).map((emailKey, index) => (
      <TextField
        key={emailKey}
        label={`Email ${index + 1} Send Date`}
        type="datetime-local"
        value={schedule[emailKey]}
        onChange={(e) => handleScheduleChange(emailKey, e.target.value)}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 3600,
          min: minDateTime,
        }}
        margin="normal"
      />
    ));
  };
  return (
    <div className="crew-mailing-list">
      {activeCrewMailingList ? (
        <div className="associated">
          <span className="associated-text">
            Mailing list associated. Last sync:{" "}
            {new Date(activeCrewMailingList.updatedAt).toLocaleString()} local
            time
          </span>{" "}
          <br />
          <br />
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            View Schedule
          </Button>
        </div>
      ) : (
        <div className="not-associated">
          <span className="not-associated-text">
            Mailing list not associated
          </span>
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpen}
            disabled={userEmails?.length === 0}
          >
            Create
          </Button>
        </div>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {activeCrewMailingList ? "Scheduled Emails" : "Create Mailing List"}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <p>Please wait a moment while the mailing is being created...</p>
          ) : activeCrewMailingList ? (
            <div>
              <p>All times are local time (browser time)</p>
              <div>
                <b>Mailchimp Segment Name:</b>
                <br />
                {activeCrewMailingList.mailingSegmentName}
                <hr />
                <b>Recipients:</b>
                <br />
                {activeCrewMailingList.recipients.map((x) => {
                  return (
                    <span>
                      {x}
                      <br />
                    </span>
                  );
                })}
                <hr />
                <b>Schedule:</b>
                <br />
                {activeCrewMailingList.schedule
                  .sort((a, b) => new Date(a.utcTime) - new Date(b.utcTime))
                  .map((x) => {
                    return (
                      <span>
                        {x.template}
                        <br />
                        {moment
                          .tz(x.utcTime, "America/Los_Angeles")
                          .format("MM/DD/YYYY h:mm a")}
                        <br />
                      </span>
                    );
                  })}
                <hr />
                <b>Created at:</b>
                <br />
                {moment
                  .tz(activeCrewMailingList.createdAt, "America/Los_Angeles")
                  .format("MM/DD/YYYY h:mm a")}
                <br />
                <hr />
                <b>Updated at:</b>
                <br />
                {moment
                  .tz(activeCrewMailingList.updatedAt, "America/Los_Angeles")
                  .format("MM/DD/YYYY h:mm a")}
              </div>
            </div>
          ) : (
            <>
              <InputLabel id="template-group-label">
                Select email template group (Times are in local browser time)
              </InputLabel>
              <Select
                labelId="template-group-label"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                fullWidth
                label="Select email template group"
              >
                <MenuItem value="RCG">Recent College Graduate</MenuItem>
              </Select>

              {renderTemplateFields()}
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            {!activeCrewMailingList && (
              <Button onClick={handleCreate} color="primary">
                Create
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
