import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export const Prompts = ({
  selectedBox,
  setSelectedBox,
  setChoosenPrompts,
  choosenPrompts,
}) => {
  const growth = [
    "How have you used your strengths?",
    "How have you lived your values?",
    "How have you grown?",
    "What have you learned?",
    "How do you feel?",
  ];

  const relationships = [
    "What do you colleagues or customers say about working with you?",
    "What does your manager say about working with you?",
    "What do your customers say about working with you?",
    "How have you contributed to the culture of your team?",
    "How have you contributed to the culture of your company?",
  ];

  const impact = [
    "What impact have you made?",
    "What challenges have you overcome? How?",
    "What results have you achieved?",
  ];
  return (
    <div className="inner">
      <div className="subtitle">
        <h4>Pick your prompts</h4>
        <p>Choose one from each category</p>
      </div>

      <div className="prompts">
        <div
          className={`prompt growth ${
            selectedBox === "growth1" || selectedBox === "growth2"
              ? "expanded"
              : ""
          }`}
        >
          <div className="title">
            <h3>Personal Growth</h3>
            {selectedBox === "growth1" || selectedBox === "growth2" ? (
              <span
                onClick={() => {
                  setSelectedBox(null);
                }}
              >
                Show Less <FontAwesomeIcon icon={faChevronDown} />
              </span>
            ) : (
              <span
                onClick={() => {
                  setSelectedBox("growth1");
                }}
              >
                Show More <FontAwesomeIcon icon={faChevronUp} />
              </span>
            )}
            <p>Prompts about yourself and who you hope to become</p>
          </div>

          <div className="expanding">
            {growth.map((prompt) => {
              return (
                <div
                  onClick={() => {
                    const newChoosenPrompts = { ...choosenPrompts };
                    newChoosenPrompts[selectedBox || "growth1"] = prompt;
                    setChoosenPrompts(newChoosenPrompts);
                  }}
                  className={`box blue ${
                    choosenPrompts[selectedBox] === prompt ? "selected" : ""
                  }`}
                >
                  <p>{prompt}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`prompt relationships ${
            selectedBox === "relationships" ? "expanded" : ""
          }`}
        >
          <div className="title">
            <h3>Relationships</h3>
            {selectedBox === "relationships" ? (
              <span
                onClick={() => {
                  setSelectedBox(null);
                }}
              >
                Show Less <FontAwesomeIcon icon={faChevronDown} />
              </span>
            ) : (
              <span
                onClick={() => {
                  setSelectedBox("relationships");
                }}
              >
                Show More <FontAwesomeIcon icon={faChevronUp} />
              </span>
            )}
            <p>Prompts about how you interact with those around you</p>
          </div>

          <div className="expanding">
            {relationships.map((prompt) => {
              return (
                <div
                  onClick={() => {
                    const newChoosenPrompts = { ...choosenPrompts };
                    newChoosenPrompts.relationships = prompt;
                    setChoosenPrompts(newChoosenPrompts);
                  }}
                  className={`box yellow ${
                    choosenPrompts.relationships === prompt ? "selected" : ""
                  }`}
                >
                  <p>{prompt}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`prompt impact ${
            selectedBox === "impact1" || selectedBox === "impact2"
              ? "expanded"
              : ""
          }`}
        >
          <div className="title">
            <h3>Impact</h3>
            {selectedBox === "impact1" || selectedBox === "impact2" ? (
              <span
                onClick={() => {
                  setSelectedBox(null);
                }}
              >
                Show Less <FontAwesomeIcon icon={faChevronDown} />
              </span>
            ) : (
              <span
                onClick={() => {
                  setSelectedBox("impact1");
                }}
              >
                Show More <FontAwesomeIcon icon={faChevronUp} />
              </span>
            )}
            <p>Prompts about what results you achieve</p>
          </div>

          <div className="expanding">
            {impact.map((prompt) => {
              return (
                <div
                  onClick={() => {
                    const newChoosenPrompts = { ...choosenPrompts };
                    newChoosenPrompts[selectedBox || "impact1"] = prompt;
                    setChoosenPrompts(newChoosenPrompts);
                  }}
                  className={`box red ${
                    choosenPrompts[selectedBox] === prompt ? "selected" : ""
                  }`}
                >
                  <p>{prompt}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
