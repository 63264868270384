import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/index.scss";
import { useHistory } from "react-router-dom";
import { VisionsOnboarding } from "./components/VisionsOnboarding";
import { ChoosePrompts } from "./components/ChoosePrompts";

export const Visions = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState(null);
  const [step, setStep] = useState(0);
  const [visions, setVisions] = useState([]); 
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getMe();
    getVisions();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getVisions = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if(response.data.length > 0) {
      return history.push("/visions/dashboard");
    }

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  if(loading) {
      return <div></div>
  }
  if (step === 0) {
    return <VisionsOnboarding setStep={(step)=>{
        setStep(step)
    }} />;
  }

  if(step === 1) {
    return <ChoosePrompts setStep={(step)=>{
        setStep(step)
    }} />
  }
};
