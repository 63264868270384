import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useEnv } from "../../context/env.context";

export const PartialMembershipSuccess = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const { apiServerUrl } = useEnv();

  useEffect(() => {
    const nav = document.querySelector(".nav-bar__container");
    nav.style.display = "none";
    savePartialMembership();
  }, []);

  const savePartialMembership = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${apiServerUrl}/api/memberships/partial/${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const membership = await response.json();
    const url = membership.membership?.learningActivities[0]?.url;
    return (window.location.href = url);
  };

  return <></>;
};
