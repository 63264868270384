import { useAuth0 } from "@auth0/auth0-react";
import {
  faCheck,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Tool/Tools/template.scss";
import { useHistory } from "react-router-dom";
import { PathTool } from "../../Path/components/PathTool";
import mixpanel from "mixpanel-browser";

export const Strengths = (props) => {
  const history = useHistory();
  useEffect(() => {
    mixpanel.track("Visited Strengths Tool Page");
    window.analytics.page('Strengths Tool');
  }, []);

  return (
    <div className="templateTool">
      <div className="bluebg"></div>
      <div className="container">
        <div className="intro">
          <div className="left">
            <img src={props.tool.image} alt="lifeline" />
          </div>
          <div className="right">
            <div className="top">
              <h3>
                Welcome to <br />
                <span className="highlight yellow">Strengths</span>
              </h3>
            </div>
            <div className="bottom">
              <button
                onClick={() => {
                  history.push("/Strengths/");
                }}
                className="btn primary"
              >
                Start Strengths Now! <FontAwesomeIcon icon={faRightLong} />{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="content">
          <p className="body-text">
            Knowing what you're good at (your strengths) helps you to identify
            work opportunities that light you up and tell career stories about
            how you make an impact.
            <br />
            <br />
            It's hard to be aware of all the great strengths you have or how
            they show up in the workplace. In this tool, we'll help you identify
            your strengths and use your network to uncover some that you may not
            be aware of.
          </p>

          <div className="description">
            <div className="left white-background">
              <h3 className="heading2 underline blue">What You'll Do:</h3>

              <ol>
                <li>
                  <div className="item">1</div>
                  <p>Make a list of ten words that describe you at your best</p>
                </li>
                <li>
                  <div className="item">2</div>
                  <p>
                  Invite 2-3 people to share their perspectives on your strengths
                  </p>
                </li>
                <li>
                  <div className="item">3</div>
                  <p>
                  Notice what's consistent and different between your list and what others say are your strengths
                  </p>
                </li>
                <li>
                  <div className="item">4</div>
                  <p>
                  Pick your top five strengths
                  </p>
                </li>
                <li>
                  <div className="item">5</div>
                  <p>
                  Think about what your strengths say about you and how you work
                  </p>
                </li>
              </ol>
            </div>
            <div className="left white-background yellow">
              <h3 className="heading2 underline yellow">Use To:</h3>

              <ol>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                  Build awareness of who you are and what you bring to the table
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                  Get outside perspectives on your strengths from people who know you well
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>Get a confidence boost</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="moreTools">
        <div className="container">
          <h3>
            <span className="heading1 underline yellow">
              Want to go somewhere else?
            </span>
          </h3>

          <div className="tools">
            <PathTool
              tool={{
                name: "Lifeline",
                id: 1,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Lifeline.svg",
                Tool_Path: {
                  description:
                    "Learn from your past to shape your future",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Storyboard",
                id: 2,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Storyboard.svg",
                Tool_Path: {
                  description:
                    "I want to thank those that have helped me in my career",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
