import { useAuth0 } from "@auth0/auth0-react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import "../../../styles/pages/Learn/components/LearningActivityCard.scss";

export const LearningActivityCard = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();

  const [showDropdown, setShowDropdown] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [activity, setActivity] = useState(props.activity);
  const history = useHistory();

  useEffect(() => {
    if (props.activity.users.length > 0) {
      setIsDone(true);
    }
  }, [props.activity]);

  if (!activity) {
    return null;
  }

  const markAsDone = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/learning-activity/done/${activity.id}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    setActivity(response.data);
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      rel="noreferrer"
      onClick={() => {
        markAsDone();
        if(activity.linkType === "INTERNAL") {
          history.push(activity.url);
        } else {
          window.open(activity.url, "_blank");
        }
        return;
      }}
      target="_blank"
      className="learningActivityCard"
    >
      {isDone && (
        <div className="done">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div className="top">
        <img alt="logo" src={activity.image} />
      </div>
      <div className="content">
        <h6>{activity.timeToComplete} MINUTES</h6>
        <h4>{activity.title}</h4>
        <p>{activity.description}</p>
      </div>
    </a>
  );
};
