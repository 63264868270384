import React, { useEffect, useState } from "react";
import "./styles/dashboard.scss";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CoachingDashboard = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [coachingData, setCoachingData] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCoaching();
  }, []);

  const getCoaching = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/coach`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Get all the sessions they have.
    const sessions = response.data.reduce((acc, curr) => {
      const sessions = curr.program.sessions;
      return [...acc, ...sessions];
    }, []);

    const upcomingSessions = sessions.filter((session) => {
      const sessionDate = new Date(session.startDate);
      const now = new Date();
      return sessionDate > now;
    });

    const sortedSessions = upcomingSessions.sort((a, b) => {
      const aDate = new Date(a.startDate);
      const bDate = new Date(b.startDate);
      return aDate - bDate;
    });

    const users = response.data.reduce((acc, curr) => {
      const users = curr.users;
      return [...acc, ...users];
    }, []);

    setUsers(users);
    setUpcomingSessions(sortedSessions);
    setCoachingData(response.data);
  };

  return (
    <div className="CoachingDashboard">
      <h2>Crew Coaching</h2>

      <div className="white-background activeCrews">
        <h5>Active Crews ({coachingData.length})</h5>
        <div className="coachTable">
          <div className="coach header">
            <p>Crew Name</p>
            <p>Start Date</p>
            <div></div>
          </div>
          {coachingData.map((crew) => {
            return (
              <div key={crew.id} className="coach">
                <p>{crew.name}</p>
                <p>{moment(crew.startDate).format("MM/DD/YYYY")}</p>
                <div>
                  <button
                    onClick={() => {
                      history.push(`/coaching/program/${crew.id}`);
                    }}
                    className="btn secondary"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="white-background activeCrews">
        <h5>Upcoming Sessions ({upcomingSessions.length})</h5>
        <div className="coachTable">
          <div className="coach header">
            <p>Session</p>
            <p>Date</p>
          </div>
          {upcomingSessions.map((session) => {
            return (
              <div key={session.id} className="coach">
                <p>{session.name}</p>
                <p>{moment(session.startDate).format("MM/DD/YYYY hh:mm a")}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="white-background usersContainer">
        <h5>Crew Members ({users.length})</h5>
        <div className="users">
          {users.map((user) => {
            return (
              <div key={user.id} className="user">
                <div className="icon">
                  {!user.avatar && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M16.5716 7.25686C16.5716 9.52912 14.7296 11.3712 12.4573 11.3712C10.1851 11.3712 8.34304 9.52912 8.34304 7.25686C8.34304 4.98461 10.1851 3.14258 12.4573 3.14258C14.7296 3.14258 16.5716 4.98461 16.5716 7.25686Z"
                        stroke="#0B455F"
                        strokeWidth="2.05714"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4573 14.4569C8.48087 14.4569 5.25732 17.6804 5.25732 21.6569H19.6573C19.6573 17.6804 16.4338 14.4569 12.4573 14.4569Z"
                        stroke="#0B455F"
                        strokeWidth="2.05714"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {user.avatar && (
                    <img src={user.avatar} alt={user.first_name} />
                  )}
                </div>
                <div className="name">
                  <h2>
                    {user.first_name}
                    <br />
                    {user.last_name}
                  </h2>
                  <h3>{user.title || "Crew User"}</h3>
                  <p className="email">{user.email}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
