import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/LessonTemplates/single.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faEdit,
  faPlus,
  faTrash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { isAdmin } from "../../../hooks/isAdmin";


export const LessonTemplatesSingle = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [lessonTemplate, setLessonTemplate] = useState(null);
  let id = props.match.params.id;
  const [addActivityTemplate, setAddActivityTemplate] = useState(false);
  const [newActivityTemplate, setNewActivityTemplate] = useState({
    name: "",
    description: "",
    type: "",
    timeToComplete: "",
    url: "",
    lessonTemplateId: id,
  });

  useEffect(() => {
    getMe();
    getLessonTemplate();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const deleteActivityTemplate = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    getLessonTemplate();
  };

  const getLessonTemplate = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/lessonTemplates/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setLessonTemplate(response.data);
  };

  const addActivityTemplateAPI = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activitiesTemplates`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.data = newActivityTemplate;
    await axios(config);
    setNewActivityTemplate({
      name: "",
      description: "",
      type: "",
      timeToComplete: "",
      url: "",
      lessonTemplateId: id,
    });
    setAddActivityTemplate(false);
    getLessonTemplate();
  };

  if (!lessonTemplate) {
    return <div>Loading...</div>;
  }

  return (
    <div className="LessonTemplateSingle">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/program-management"}>Programs</NavLink>
        </div>
        /
        <div className="breadcrumb">
        {lessonTemplate.program?.name}
        </div>
        /<div className="breadcrumb">{lessonTemplate.name}</div>
      </div>
      <div className="container white-background">
        <NavLink to={`/admin/lessonTemplates/${lessonTemplate.id}/edit`} className="edit">
          Edit
        </NavLink>
        <h2 className="heading1 highlight blue">{lessonTemplate.name}</h2>
        <p className="body-text ">{lessonTemplate.description}</p>

        <div className="row"></div>
      </div>
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
        className="container white-background"
      >
        <h2 className="heading2 highlight yellow">{lessonTemplate.name} Activity Templates</h2>

        <button
          onClick={() => {
            setAddActivityTemplate(true);
          }}
          className="btn secondary right"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Activity
        </button>

        {lessonTemplate.activityTemplates.map((activity) => (
          <div className="lessonTemplate">
            <div className="lessonTemplate-info">
              <div className="lessonTemplate-title">
                <h3 className="heading2 underline blue">{activity.name}</h3>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="body-text pill"
                >
                  {activity.type.toLowerCase()}
                </p>
              </div>
              <div className="lessonTemplate-description">
                <p className="body-text">{activity.description}</p>
              </div>
            </div>
            <div className="lessonTemplate-actions">
              <NavLink to={`/admin/activities/${activity.id}`}>
                <button className="btn secondary">
                  <FontAwesomeIcon icon={faBook} /> View ActivityTemplate
                </button>
              </NavLink>

              <NavLink to={`/admin/activities/${activity.id}/edit`}>
                <button className="btn secondary purple">
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </NavLink>

              <button
                onClick={() => {
                  deleteActivityTemplate(activity.id);
                }}
                className="btn secondary red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}

        {lessonTemplate.activityTemplates.length === 0 && (
          <div className="no-lessonTemplates">
            <p className="body-text">No activities yet!</p>
          </div>
        )}
      </div>
      {addActivityTemplate && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="heading2 highlight yellow">Add Activity</h2>
            <div className="row">
              <div className="inputBox">
                <label className="body-text">Activity Name</label>
                <input
                  onChange={(e) => {
                    const newActivityTemplateObject = { ...newActivityTemplate };
                    newActivityTemplateObject.name = e.target.value;
                    setNewActivityTemplate(newActivityTemplateObject);
                  }}
                  type="text"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Description</label>
                <textarea
                  onChange={(e) => {
                    const newActivityTemplateObject = { ...newActivityTemplate };
                    newActivityTemplateObject.description = e.target.value;
                    setNewActivityTemplate(newActivityTemplateObject);
                  }}
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Url</label>
                <input
                  onChange={(e) => {
                    const newActivityTemplateObject = { ...newActivityTemplate };
                    newActivityTemplateObject.url = e.target.value;
                    setNewActivityTemplate(newActivityTemplateObject);
                  }}
                  type="text"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Time To Complete (minutes)</label>
                <input
                  onChange={(e) => {
                    const newActivityTemplateObject = { ...newActivityTemplate };
                    newActivityTemplateObject.timeToComplete = e.target.value;
                    setNewActivityTemplate(newActivityTemplateObject);
                  }}
                  type="number"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Type</label>
                <select
                  onChange={(e) => {
                    const newActivityTemplateObject = { ...newActivityTemplate };
                    newActivityTemplateObject.type = e.target.value;
                    setNewActivityTemplate(newActivityTemplateObject);
                  }}
                  className="input"
                >
                  <option disabled selected value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option value="PRE-WORK">Pre-Work</option>
                  <option value="IN-LESSON">In-Lesson</option>
                  <option value="POST-WORK">Post-Work</option>
                </select>
              </div>

              <button className="btn secondary" onClick={addActivityTemplateAPI}>
                <FontAwesomeIcon icon={faPlus} /> Add Activity
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setAddActivityTemplate(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
