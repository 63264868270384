import React, { useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const InviteRow = ({ invite, index, resend }) => {
  const [me, setMe] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);

  useEffect(() => {}, []);

  return (
    <div className="invite" key={index}>
      {success && (
        <div className="success">
          <span>Invite Succesfully Resent!</span>
        </div>
      )}
      <img
        alt="icon"
        src={`https://placehold.co/100x100?text=${invite.firstName.charAt(0)}`}
      />
      <span>
        {invite.firstName} {invite.lastName}
      </span>
      <span className="small">{invite.email}</span>
      <span className="small">
        Sent: {moment(invite.createdAt).format("MMM DD, YYYY")}
      </span>
      <div className="actions">
        {invite.strengths.length > 0 && (
          <button
            onClick={() => {
              setShowResults(!showResults);
            }}
            className="btn blank"
          >
            View Results
          </button>
        )}
        {invite.strengths.length === 0 && (
          <button
            onClick={() => {
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              resend(invite);
            }}
            className="btn blank"
          >
            Resend Request
          </button>
        )}
      </div>
      {showResults && (
        <div className="modal">
          <div className="modal-content">
            <FontAwesomeIcon
              className="close"
              icon={faTimes}
              onClick={() => setShowResults(false)}
            />

            <div className="header">
              <div className="left">
                <img
                  alt="icon"
                  src={`https://placehold.co/100x100?text=${invite.firstName.charAt(
                    0
                  )}`}
                />
              </div>
              <div className="right">
                <h3>{invite.firstName}'s Feedback</h3>
                <h4>Left on: {moment(invite.createdAt).format("MM/DD/YY")}</h4>
              </div>
            </div>

            <h2>Their view of your strengths</h2>
            <p>
              These are the 10 keywords they selected that feel best represent
              you.
            </p>

            <div className="list">
              {invite.strengths.map((strength, index) => {
                return (
                  <div className="strength" key={index}>
                    <span>{strength.text}</span>
                  </div>
                );
              })}
            </div>

            <h4>{invite.firstName} chose these strengths because...</h4>

            <div className="comment">
              <div className="left">
                <img
                  alt="icon"
                  src={`https://placehold.co/100x100?text=${invite.firstName.charAt(
                    0
                  )}`}
                />
              </div>
              <div className="right">
                <h4>{invite.firstName}</h4>
                <p>{invite.comment}</p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setShowResults(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
