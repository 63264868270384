import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import moment from "moment";
import "../../styles/pages/Learn/index.scss";
import SessionBox from "../../components/SessionBox";
import { Analytics } from "../../hooks/analytics";

export const Learn = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState();
  const [program, setProgram] = useState();

  useEffect(() => {
    async function getData() {
      Analytics("Visited Program Home Page");
      await getMe();
      getProgram();
    }

    getData();
  }, []);

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios(config);

      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        user_id: response.data.id,
        app_id: "yqnbhk7a",
        name: `${response.data.first_name} ${response.data.last_name}`, // Full name
        email: `${response.data.email}`, // Email address
        created_at: response.data.created_at,
      });

      setMe(response.data);
    } catch (error) {
      // If they are only going to be a storyboard user * BETA
      // probally need to add a query param to this too.

      localStorage.setItem("storyboardUser", true);
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  };

  const getProgram = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me/programs`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      const lessons = response.data.reduce(
        (acc, program, index) => {
          if (moment().isBetween(program.startTime, program.endTime)) {
            program.isFirst = index === 0;
            acc.current.push(program);
          } else if (moment(program.endTime) > moment()) {
            program.isFirst = index === 0;
            acc.upcoming.push(program);
          } else {
            program.isFirst = index === 0;
            acc.completed.push(program);
          }

          return acc;
        },
        {
          current: [],
          upcoming: [],
          completed: [],
        }
      );

      setProgram(lessons);
    } catch (error) {
      console.log(error);
    }
  };

  if (!me || !program) return <div></div>;

  return (
    <div className="LearnDashboard">
      <h2 className="highlight yellow heading1">
        Welcome, {me?.first_name} {me?.last_name}!
      </h2>

      {program && program.current && program.current.length > 0 && (
        <div>
          <h3
            style={{
              marginBottom: "2rem",
              marginTop: "5rem",
            }}
            className="heading1 underline blue"
          >
            This Week
          </h3>
          <SessionBox
            showExpand={false}
            sessions={program.current[0]}
            activities={program.current[0].activities}
          />
        </div>
      )}

      <h3
        style={{
          marginBottom: "2rem",
          marginTop: "5rem",
        }}
        className="heading1 underline blue"
      >
        Upcoming Schedule
      </h3>

      {program?.upcoming.map((program) => (
        <SessionBox
          showExpand={true}
          sessions={program}
          activities={program.activities}
        />
      ))}
    </div>
  );
};
