import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ValueInviteList = ({ invite }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [resentEmail, setResentEmail] = useState(false);
  const history = useHistory();

  const sendEmail = async (invite) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/invite/${invite.id}/email`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        personalMessage: invite.note,
        email: invite.email,
        firstName: invite.firstName,
        to: invite.email,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    setResentEmail(true);
  };

  return (
    <div className="invite">
      <div className="photo">
        {!invite.coach && (
          <img
            src={`https://placehold.co/100x100?text=${invite?.firstName.charAt(
              0
            )}`}
            alt="avatar"
          />
        )}
        {invite.coach && (
          <img
            src={`https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg`}
            alt="avatar"
          />
        )}
      </div>
      <div className="invite-name">
        <h3>{invite.firstName}</h3>
        {invite.coach && <span>Coach</span>}
      </div>
      <div className="invite-email">{invite.email || "No Email"}</div>
      <div className="invite-status">
        Sent {moment(invite.createdAt).format("MMM DD, YYYY")}
      </div>
      {!resentEmail && (
        <div className="invite-status">
          {invite.response ? (
            <div className="invite-status">
              <button
                onClick={() => {
                  history.push(`/values/feedback/${invite.id}`);
                }}
                className="btn secondary"
              >
                View Results
              </button>
            </div>
          ) : (
            <div>
              {invite.email ? (
                <button
                  onClick={() => {
                    sendEmail(invite);
                  }}
                  className="btn secondary resend"
                >
                  Resend Request
                </button>
              ) : (
                <button disabled className="btn secondary">
                  Sent
                </button>
              )}
            </div>
          )}
        </div>
      )}
      {resentEmail && (
        <div>
          <button disabled className="btn secondary">
            Resent Email
          </button>
        </div>
      )}
    </div>
  );
};
