import React, { useCallback, useEffect } from "react";
import "../../../styles/pages/highlightReel/components/ShareWidget.scss";
import { SharableMoment } from "./SharableMoment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faLink } from "@fortawesome/free-solid-svg-icons";

export const ShareWidget = (props) => {
  const [moments, setMoments] = React.useState(props.moments);
  const [hasSharedMoment, setHasSharedMoment] = React.useState(false);

  useEffect(() => {
    setMoments(props.moments);
    setCount();
  }, [props.moments]);

  const setCount = useCallback(() => {
    let count = moments.filter((moment) => {
      return moment.featured;
    });

    setHasSharedMoment(count.length > 0);
  }, [moments]);

  return (
    <div className={`ShareWidget Widget ${props.fullSize ? "full" : ""}`}>
      <div className="modal">
        <div className="white-background">
          <FontAwesomeIcon
            className="close"
            onClick={() => {
              props.close();
            }}
            icon={faClose}
          />
          <h3>
            <span className="underline yellow">Share Your Highlight Reel!</span>
          </h3>
          <div className="coach">
            <img
              alt="coach"
              src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
            />
            <p>
              You can share your Highlight Reel with your coach or anyone you
              wish. Only your featured highlights will be shared on your public
              highlight reel. You can toggle your featured highlights in the
              below section.
            </p>
          </div>

          {hasSharedMoment && (
            <div className="link">
              <h4>Shareable Link</h4>
              <div className="link-container">
                <FontAwesomeIcon icon={faLink} />
                <input
                  type="text"
                  value={`${window.location.protocol}//${window.location.host}/highlights/share/${props.me.id}`}
                />
              </div>

              <button
                onClick={() => {
                  window.open(`/highlights/share/${props.me.id}`, "_blank");
                }}
                style={{
                  marginTop: "10px",
                }}
                className="btn secondary"
              >
                <FontAwesomeIcon icon={faEye} />
                View Your Public Highlight Reel
              </button>
            </div>
          )}

          {!hasSharedMoment && (
            <div className="link">
              <h4>Shareable Link</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                To get a sharable Highlight Reel you must have one featured
                highlight.
              </p>
            </div>
          )}

          <h4>
            My Highlights <span>Featured Highlight</span>
          </h4>

          {moments.map((moment, index) => (
            <SharableMoment
              changed={(moment) => {
                const newMoments = moments;
                newMoments.find((m) => m.id === moment.id).featured =
                  moment.featured;
                setMoments(newMoments);
                setCount(newMoments);
              }}
              moment={moment}
              key={moment.id}
            />
          ))}

          {moments.length === 0 && <p>No Highlights Added Yet</p>}
        </div>
        <div
          onClick={() => {
            props.close();
          }}
          className="skrim"
        ></div>
      </div>
    </div>
  );
};
