import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../context/env.context";
import axios from "axios";
import "../styles/pages/dashboard.scss";
import { useHistory } from "react-router-dom";
import { isStoryboard } from "../hooks/isStoryboard";

export const Dashboard = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [loading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const pathId = localStorage.getItem("pathId");

    if (pathId) {
      return history.push(`/path/${pathId}/dashboard`);
    }

    window.analytics.page("Path Dashboard");

    getMe();

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;";

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
    };
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "yqnbhk7a",
      user_id: response.data.id,
      name: `${response.data.first_name} ${response.data.last_name}`, // Full name
      email: `${response.data.email}`, // Email address
      created_at: response.data.created_at,
    });

    // If they are a SB only user then redirect them to the SB dashboard
    if (isStoryboard(response.data)) {
      return history.push("/storyboard");
    }

    // If they are a first time user, then set the role and redirect them to the storyboard page
    const storyboardUser = localStorage.getItem("storyboardUser");
    if (storyboardUser) {
      return setStoryboardUser();
    }

    return history.push(`/path/1/dashboard`);
  };

  const setStoryboardUser = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/storyboard`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      localStorage.removeItem("storyboardUser");

      history.push(`/storyboard`);
    }
  };

  if (loading) {
    return <div></div>;
  }
};
