import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useEnv } from "../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const EmailInterestFormSignup = ({ match }) => {
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const findInterest = async (email, tenantId) => {
    const config = {
      url: `${apiServerUrl}/api/interest/email/${encodeURIComponent(email)}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const response = await axios(config);
      history.push(
        `/signup/interest/${response.data.id}?skipOnboarding=true${
          tenantId ? "&tenantId=" + tenantId : ""
        }`
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) nav.style = "display:none";
    const container = document.getElementsByClassName("content_container")[0];
    if (container) container.style = "max-width: 100%;width:100%;";

    const params = new URLSearchParams(window.location.search);
    const email = match.params.email;
    const tenantId = params.get("tenantId");

    findInterest(email, tenantId);
  }, []);

  return <div></div>;
};
