import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Crews/edit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { isAdmin } from "../../../hooks/isAdmin";

export const ActivitiesEdit = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [newActivity, setNewActivity] = useState();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  let id = props.match.params.id;

  useEffect(() => {
    getMe();
    getActivity();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const editActivity = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities/${id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        ...newActivity,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      history.push(`/admin/activities/${newActivity.id}`);
    }
  };

  const getActivity = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const data = { ...response.data };
    setNewActivity(data);
  };

  if (!newActivity?.id) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ProgramEdit">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>crews</NavLink>{" "}
        </div>
        /
        <div className="breadcrumb">
          <div className="breadcrumb">
            <NavLink to={`/admin/crews/${newActivity.lesson.crewId}`}>
              {newActivity.lesson.crew.name}
            </NavLink>
          </div>
        </div>
        /
        <div className="breadcrumb">
          <NavLink to={`/admin/lessons/${newActivity.lesson.id}`}>
            {newActivity.lesson.name}
          </NavLink>{" "}
        </div>
        /
        <div className="breadcrumb">
          <NavLink to={`/admin/activities/${newActivity.id}`}>
            {newActivity.name}
          </NavLink>{" "}
        </div>
      </div>
      <div className="container white-background">
        <h2 className="heading1 highlight blue">Edit {newActivity.name}</h2>
        <div className="row">
          <div className="inputBox">
            <label className="body-text">Activity Name</label>
            <input
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.name = e.target.value;
                setNewActivity(newActivityObject);
              }}
              type="text"
              value={newActivity.name}
              className="input"
            />
          </div>
          <div className="inputBox">
            <label className="body-text">Description</label>
            <textarea
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.description = e.target.value;
                setNewActivity(newActivityObject);
              }}
              value={newActivity.description}
              className="input"
            />
          </div>
          <div className="inputBox">
            <label className="body-text">Url</label>
            <input
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.url = e.target.value;
                setNewActivity(newActivityObject);
              }}
              value={newActivity.url}
              type="text"
              className="input"
            />
          </div>
          <div className="inputBox">
            <label className="body-text">Time To Complete (minutes)</label>
            <input
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.timeToComplete = e.target.value;
                setNewActivity(newActivityObject);
              }}
              type="number"
              value={newActivity.timeToComplete}
              className="input"
            />
          </div>
          <div className="inputBox">
            <label className="body-text">Type</label>
            <select
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.type = e.target.value;
                setNewActivity(newActivityObject);
              }}
              className="input"
              value={newActivity.type}
            >
              <option disabled selected value>
                {" "}
                -- select an option --{" "}
              </option>
              <option value="PRE-WORK">Pre-Work</option>
              <option value="IN-LESSON">In-Lesson</option>
              <option value="POST-WORK">Post-Work</option>
            </select>
          </div>

          <div className="inputBox">
            <label className="body-text">Link Type</label>
            <select
              onChange={(e) => {
                const newActivityObject = { ...newActivity };
                newActivityObject.linkType = e.target.value;
                setNewActivity(newActivityObject);
              }}
              className="input"
              value={newActivity.linkType}
            >
              <option disabled selected value>
                {" "}
                -- select an option --{" "}
              </option>
              <option value="EXTERNAL">External</option>
              <option value="INTERNAL">Internal</option>
            </select>
          </div>

          <button
            className="btn secondary"
            onClick={() => {
              editActivity();
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Activity
          </button>
        </div>
      </div>
    </div>
  );
};
