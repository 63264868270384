import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Route, useHistory } from "react-router-dom";
import { Loader } from "./loader";

export const ProtectedRoute = ({ component, ...args }) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  // Do they have access to just this tool?
  const doTheyHaveAccessToThisTool = () => {
    if (args.member.type === "partial") {
      const allow = args.member.learningActivities.filter((activity) => {
        if (activity.id === args.toolId) {
          return true;
        }

        return false;
      });

      return allow.length > 0;
    }

    return false;
  };

  // If they are not a full member and they are authenticated, check if they have access to this tool
  if (args.member.type !== "full" && isAuthenticated) {
    if (!doTheyHaveAccessToThisTool()) {
      history.push("/membership");
    }
  }

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />,
      })}
      {...args}
    />
  );
};
