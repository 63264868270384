import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../../context/env.context";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../styles/pages/storyboard/videoreview.scss";

export const VideoReview = () => {
  const [video, setVideo] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = React.useState();
  const [view, setView] = React.useState("stories");
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/video/user`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.videos && response.data.videos.id) {
      setVideo(response.data.videos);
      return;
    }

    setTimeout(() => {
      getVideo();
    }, 4000);
  };

  return (
    <div className="videoreview">
      <h3>Great! Here's your story</h3>
      {video?.mediaUrl && (
        <video preload="metadata" controls>
          <source src={`${video.mediaUrl}#t=0.001`} type="video/mp4" />
        </video>
      )}
      <h3>Ready to send your first story?</h3>
      <Link to={"/storyboard/create"} className="btn primary">
        Let's do it!
      </Link>

      <Link to={"/storyboard/dashboard"}>Take me to my board</Link>
    </div>
  );
};
