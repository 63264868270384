import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Values/valuesCreation.scss";
import { Analytics } from "../../hooks/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { OnboardedValueFlow } from "./components/OnboardedValueFlow";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { OnboardedValueFlowAdd } from "./components/OnboardedValueFlowAdd";

export const ValuesCreation = (props) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [valueImages, setValueImages] = useState([]); // [{id: 1, url: "https://via.placeholder.com/150"}
  const [me, setMe] = useState();
  const [step, setStep] = useState(1);
  const [values, setValues] = useState([]);
  const [valuesOnboarding, setValuesOnboarding] = useState([]);
  const [activeValue, setActiveValue] = useState(); // {id: 1, text: "value", description: "description", image: "https://via.placeholder.com/150"
  const [activeImages, setActiveImages] = useState([]); // [{id: 1, url: "https://via.placeholder.com/150"}

  useEffect(() => {
    getMe();
    getValueImages();
    getValues(true);

    Analytics("Started Values Creation");

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width:96rem";
      }
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const saveActiveValue = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/${activeValue.id}/images`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        images: activeImages.map((img) => img.id),
        summary: activeValue.summary,
        text: activeValue.text,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);

    // Reset the active images
    setActiveImages([]);

    Analytics("10 Images - Edited Onboarding Value");

    // Remove this from onboarded values
    const newValuesOnboarding = valuesOnboarding.filter(
      (v) => v.id !== activeValue.id
    );
    setValuesOnboarding(newValuesOnboarding);

    // Reset the active value
    setActiveValue({});
    getValues();
  };

  const deleteValue = async (valueId) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/values/${valueId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("10 Images - Deleted Value");

    getValues();
  };

  const getValues = async (fromOnboarding) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/all`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const filteredValues = response.data.reduce(
      (acc, value) => {
        if (value.fromOnboarding) {
          acc.onBoarding.push(value);
        } else {
          acc.values.push(value);
        }
        return acc;
      },
      { onBoarding: [], values: [] }
    );

    setValues(filteredValues.values);
    setValuesOnboarding(filteredValues.onBoarding);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  const getValueImages = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/value-image`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValueImages(response.data.valueImages);
  };

  return (
    <div className="valuesCreation">
      <div className="white-background">
        {step === 1 && (
          <div className="left">
            <h2>
              <FontAwesomeIcon
                onClick={() => {
                  history.goBack();
                }}
                icon={faTimes}
              />{" "}
              Identify your values
            </h2>

            {valuesOnboarding && valuesOnboarding.length > 0 ? (
              <h3>Review Existing Values</h3>
            ) : (
              <h3>Write out your values</h3>
            )}

            {valuesOnboarding && valuesOnboarding.length > 0 ? (
              <p>
                <b>Instructions:</b>
                <ol>
                  <li>
                    Confirm if these values from onboarding still hold true.
                  </li>
                  <li>
                    Provide a description of what that value means to you.
                  </li>
                  <li>
                    Click a photo on the right to associate with your value.
                  </li>
                </ol>
              </p>
            ) : (
              <p>
                <b>Instructions:</b>
                <ol>
                  <li>
                    Choose a word or phrase that sumarizes one of your values.
                  </li>
                  <li>
                    Provide a description of what that value means to you.
                  </li>
                  <li>
                    Click a photo on the right to associate with your value.
                  </li>
                </ol>
              </p>
            )}

            <p>
              Aim to write out at least 5-7 values. You'll be able to add to and
              edit this list later.
            </p>

            <div className="valuesScrolling">
              {valuesOnboarding &&
                valuesOnboarding.length > 0 &&
                valuesOnboarding.map((value) => {
                  return (
                    <OnboardedValueFlow
                      key={value.id}
                      remove={async (value) => {
                        await deleteValue(value.id);
                        setActiveImages([]);
                        setActiveValue({});
                      }}
                      setActiveValue={(value) => {
                        setActiveValue(value);
                      }}
                      activeImages={activeImages}
                      value={value}
                      save={async () => {
                        saveActiveValue();
                      }}
                    />
                  );
                })}

              {(!valuesOnboarding || valuesOnboarding.length === 0) && (
                <OnboardedValueFlowAdd
                  key={"add"}
                  setActiveImages={(images) => {
                    setActiveImages(images);
                  }}
                  me={me}
                  remove={async (value) => {
                    await deleteValue(value.id);
                    setActiveImages([]);
                    setActiveValue({});
                  }}
                  setActiveValue={(value) => {
                    setActiveValue(value);
                  }}
                  activeImages={activeImages}
                  getValues={getValues}
                />
              )}

              {values.length > 0 &&
                (!valuesOnboarding || valuesOnboarding.length === 0) &&
                values.map((value) => (
                  <OnboardedValueFlow
                    key={value.id}
                    setActiveImages={(images) => {
                      setActiveImages(images);
                    }}
                    remove={async (value) => {
                      await deleteValue(value.id);
                      setActiveImages([]);
                      setActiveValue({});
                    }}
                    setActiveValue={(value) => {
                      setActiveValue(value);
                    }}
                    activeImages={activeImages}
                    value={value}
                    save={async () => {
                      saveActiveValue();
                    }}
                  />
                ))}
            </div>

            <div className="bottom">
              <button
                className="btn blank"
                onClick={() => history.push("/dashboard")}
              >
                Close &amp; Exit
              </button>

              <button
                disabled={valueImages.length < 3}
                className="btn primary"
                onClick={async () => {
                  history.push("/values/dashboard");
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="right">
            <div className="top">
              <div className="left">
                <h3>My Images</h3>
                <p>
                  Add photos that represent who you are and what matters to you
                </p>
              </div>
              <div className="right">
                <a
                  onClick={() => {
                    history.push("/values", {
                      restart: true,
                    });
                  }}
                  to="/values/"
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Images
                </a>
              </div>
            </div>
            <ResponsiveMasonry
              columnsCount={3}
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 3,
                1200: 3,
                1400: 4,
              }}
              className="masonry-grid"
            >
              <Masonry gutter={15}>
                {valueImages.map((highlight) => (
                  <div
                    onClick={() => {
                      if (!activeValue.id) return false;
                      if (
                        activeImages.filter((img) => img.id === highlight.id)
                          .length > 0
                      ) {
                        const newActiveImages = activeImages.filter(
                          (img) => img.id !== highlight.id
                        );
                        setActiveImages(newActiveImages);
                      } else {
                        const newActiveImages = [...activeImages, highlight];
                        setActiveImages(newActiveImages);
                      }
                    }}
                    key={highlight.id}
                    className="single-highlight"
                  >
                    <div
                      className={`${!activeValue?.id ? "noHover" : ""} photo ${
                        activeImages.filter((item) => {
                          return item.id === highlight.id;
                        }).length === 1
                          ? "selected"
                          : ""
                      }`}
                    >
                      <img src={highlight.url} alt="value" />
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
      </div>
    </div>
  );
};
