import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/storyboard/components/StoryModal.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import axios from "axios";

export const StoryModal = ({ clicked, story, me }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [goodStory, setGoodStory] = useState(story);
  const [reply, setReply] = useState("");
  useEffect(() => {}, []);

  const sendReply = async () => {
    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        storyId: goodStory.id,
        text: reply,
      };
      await axios.post(
        `${apiServerUrl}/api/responses/${goodStory.id}`,
        body,
        config
      );
      Analytics("Sent Reply to story");
      getStory();
      setReply("");
    } catch (error) {
      loginWithRedirect();
    }
  };

  const getStory = async () => {
    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${apiServerUrl}/api/story/id/${goodStory.id}`,
        config
      );
      setGoodStory(response.data.story);
    } catch (error) {}
  };

  return (
    <div className="storyModal">
      <div className="modal">
        <div className={`left`}>
          <div
            className={`story ${
              goodStory?.backgroundType !== null
                ? goodStory.backgroundType
                : goodStory.type === "text"
                ? "blue"
                : ""
            }`}
          >
            {goodStory.type === "text" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: goodStory.text,
                }}
              ></div>
            )}
            {goodStory.type === "video" && (
              <div className="video">
                <video
                  controls
                  className="video"
                  src={goodStory.mediaUrl}
                ></video>
              </div>
            )}
          </div>
          <div className="info">
            {goodStory.sendingUser ? (
              <img src={goodStory.sendingUser?.avatar} alt="" />
            ) : (
              <img src={me.avatar} alt="" />
            )}
            <div className="userInfo">
              {goodStory.sendingUser ? (
                <h4>
                  {goodStory.sendingUser?.first_name}{" "}
                  {goodStory.sendingUser?.last_name}
                </h4>
              ) : (
                <h4>Me</h4>
              )}

              <h5>{moment(goodStory.createdAt).format("MMMM Do, YYYY")}</h5>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="header">
            <h5>Replies</h5>
            {!me && <button className="btn secondary">Save this Story</button>}
          </div>
          <div className="replies">
            {goodStory?.responses?.length > 0 &&
              goodStory.responses.map((response) => (
                <div className="replyContainer">
                  <div className="reply">
                    <h3>
                      {response.sendingUser.first_name}{" "}
                      {response.sendingUser.last_name}
                      <span className="date">
                        {moment(response.createdAt).format("MM/DD/YYYY")}
                      </span>
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: response.text }}></p>
                  </div>
                </div>
              ))}
            {(!goodStory.responses || goodStory?.responses?.length === 0) && (
              <div className="replyContainer">
                <div className="reply">
                  <h3>No replies yet</h3>
                </div>
              </div>
            )}
          </div>
          <div className="replyForm">
            <textarea
              value={reply}
              onChange={(event) => {
                setReply(event.target.value);
              }}
              placeholder="Add reply"
            ></textarea>
            <button
              onClick={() => {
                sendReply();
              }}
              className="btn"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          clicked();
        }}
        className="skrim"
      ></div>
    </div>
  );
};
