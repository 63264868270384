import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { useEnv } from "../../../../context/env.context";
import "../../../../styles/pages/Admin/Activities/components/activity_user.scss";

export const ActivityUser = (props) => {
  const [link, setLink] = React.useState(props.user.link);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const saveLink = async (e) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/activities/user`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        link: link,
        userId: props.user.id,
        activityId: props.activityId,
      }
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
  }

  
return (
    <div className="activity_user">
      <div className="activity_user__name">
        <p className="body-text">{props.user.first_name} {props.user.last_name}</p>
      </div>
      <div className="activity_user__link">
        <input onChange={(e)=>{
          setLink(e.target.value)
        }} type="text" value={link} />
      </div>
      <div className="activity_user__save">
        <button style={{
          margin:'0',
        }} className="btn secondary" onClick={saveLink}>Save</button>
        </div>
    </div>
  )
};


