import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/CareerInput.scss";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";

export const CareerInput = ({
  activeInput,
  setActiveInput,
  getCareerCollaborators,
  inputs = [],
}) => {
  const [text, setText] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [colorText, setColorText] = useState("#0B455F");
  const [showInspiration, setShowInspiration] = useState(false);
  const [connections, setConnections] = useState([]);
  const [showConnections, setShowConnections] = useState(false);

  useEffect(() => {
    getConnections();
    if (
      activeInput.type === "mentor" ||
      activeInput.type === "sponsor" ||
      activeInput.type === "peer_advisor"
    ) {
      setColorText("#fff");
    }
  }, [activeInput]);


  const deleteCollaborator = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    getCareerCollaborators();

  }

  const getConnections = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator/connections`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    setConnections(response.data);
  };

  const getConnectionType = (connection) => {
    if (connection.type === "collaborator") {
      return "Collaborator on highlight Reel";
    } else {
      return "Sent a story";
    }
  };

  const saveInput = async (text) => {
    const token = await getAccessTokenSilently();

    const data = {
      name: text,
      type: activeInput.type,
    };

    const config = {
      url: `${apiServerUrl}/api/career-collaborator`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    setText("");

    getCareerCollaborators();
  };
  return (
    <div className="careerInput">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon icon={faTimes} onClick={() => {}} />

          <h3>Board of Advisors</h3>
        </div>

        <div className="inner">
          <h3>Do you have a {activeInput.name}?</h3>
          <p>
            Consider people in your network who might fill this role in the
            context of your current career goal
          </p>
          <p>
            If no one comes to mind, that's okay! The goal of this activity is
            to identify gaps in your board. You can go Back to your board to
            look at other roles.
          </p>

          <div className="inputForm">
            <div className="top">
              <label>Name</label>
              <span
                onClick={() => {
                  setShowInspiration(true);
                }}
              >
                Need inspiration?
              </span>
            </div>
            <input
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text || ""}
              placeholder="Jackson Lee"
              type="text"
            ></input>

            <button
              className="btn secondary"
              onClick={() => {
                saveInput(text);
              }}
            >
              Save
            </button>

            {connections && connections.length > 0 && (
              <div className="connections">
                <span
                  onClick={() => {
                    setShowConnections(!showConnections);
                  }}
                >
                  See previous collaborators
                </span>
                {showConnections && (
                  <>
                    {connections.map((connection) => (
                      <div onClick={()=>{
                        setText(connection.name)
                        setShowConnections(false)
                      }}className="connection">
                        <p>{connection.name}</p>
                        <p>{getConnectionType(connection)}</p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="bottom">
          <button
            onClick={() => {
              setActiveInput(false);
            }}
            className="btn blank"
          >
            Back To Your Board
          </button>
        </div>
      </div>
      <div className="right">
        <div className="white-background">
          <div className="top">
            <h3>{activeInput.name}</h3>
          </div>

          <p>{activeInput.description}</p>

          <div className="boxes">
            {inputs?.map((input) => {
              return (
                <div
                  style={{
                    backgroundColor: activeInput.color,
                  }}
                  className="box"
                >
                  <div onClick={()=>{
                    deleteCollaborator(input.id)
                  }} className="delete">
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                  <div className="inner">
                    <h3
                      style={{
                        color: colorText,
                      }}
                    >
                      {input.name}
                    </h3>
                  </div>
                </div>
              );
            })}
            <div className="empty">
              <h4>No collaborators here (yet!)</h4>
            </div>
          </div>
        </div>
      </div>
      {showInspiration && (
        <div className="modal">
          <div className="modal-content">
            <h2>
              If you’re having a hard time thinking of potential collaborators,
              here are some things you can consider...
            </h2>
            <div className="boxes">
              <div className="box">
                <h4>
                  Try looking through your calendar and seeing who you have
                  previously called or met with
                </h4>
              </div>
              <div className="box">
                <h4>
                  Think about who you have talked to lately, whether that be
                  in-person, or over text or email
                </h4>
              </div>
              <div className="box">
                <h4>
                  Consider recent interactions you have had with people on
                  LinkedIn or on other job platforms
                </h4>
              </div>
            </div>
          </div>

          <div
            className="skrim"
            onClick={() => {
              setShowInspiration(false);
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
