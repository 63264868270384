import React, { useEffect, useState } from "react";
import "../../styles/pages/highlightReel.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Highlight } from "./components/highlight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faImage,
  faLightbulb,
  faPlus,
  faShare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { HighlightTop } from "./components/highlightTop";
import { HighlightReelAdd } from "./add";
import { HighlightReelSignup } from "./signup";
import { Analytics } from "../../hooks/analytics";
import { ShareWidget } from "./components/ShareWidget";
import { HighlightSingle } from "./highlight";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow } from "swiper";
import { HighlightReelOnboarding } from "../HighlightReel/components/HighlightReelOnboarding";
import { ProgramHighlightReelOnboarding } from "./components/ProgramHighlightReelOnboarding";
import { get } from "sortablejs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import hrIcon from "./components/images/hrLogo.png";

export const HighlightReel = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [highlightReel, setHighlightReel] = useState([]);
  const [moments, setMoments] = useState([]);
  const [me, setMe] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [adding, setAdding] = useState(false);
  const [slides, setSlides] = useState([]);
  const [nonUser, setNonUser] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [activeHighlight, setActiveHighlight] = useState(null);
  const [showHighlight, setShowHighlight] = useState(false);
  const SwiperRef = React.useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [reload, setReload] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [programUser, setProgramUser] = useState(false);
  const [showProgramIntro, setShowProgramIntro] = useState(false);
  const [coach, setCoach] = useState({});
  const [showCongrats, setShowCongrats] = useState(false);
  const [insight, setInsight] = useState({});
  const [insights, setInsights] = useState([]);
  const [addingNewInsight, setAddingNewInsight] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getData();

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, [reload]);

  const getInsights = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/insights/tool/highlight`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setInsights(response.data);
  };

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: insight,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    getInsights();
    setAddingNewInsight(false);
  };

  const getData = async () => {
    const me = await getMe();
    getInsights();

    const isProgramUser = me.crew?.length > 0 || false;

    setProgramUser(isProgramUser);

    Analytics("Visited Highlight Reel");

    if (!isProgramUser) {
      const hasSeenHighlightReelWelcome = localStorage.getItem(
        "hasSeenHighlightReelWelcome"
      );

      if (hasSeenHighlightReelWelcome) {
        setStep(1);
      }
    }

    if (props.location?.state?.add) {
      setAdding(true);
    }
  };

  const getHighlightReel = async (id) => {
    setSlides([]);
    const response = await axios.get(
      `${apiServerUrl}/api/highlights/user/${id}/featured`,
      {}
    );

    const slides = response.data.map((highlight) => {
      return (
        <HighlightTop
          setActiveHighlight={(highlight) => {
            setActiveHighlight(highlight);
            setShowHighlight(true);
          }}
          highlight={highlight}
        />
      );
    });
    setSlides(slides);
    setHighlightReel(response.data);
  };

  const getMoments = async (id, showOnboarding = false) => {
    const response = await axios.get(
      `${apiServerUrl}/api/highlights/user/${id}`,
      {}
    );
    setMoments(response.data);

    if (response.data.length > 0) {
      setStep(1);
    }

    if (response.data.length === 4) {
      const hasSeenCongrats = localStorage.getItem(
        "hasSeenHighlightReelCongrats"
      );

      if (!hasSeenCongrats) {
        setShowCongrats(true);
        localStorage.setItem("hasSeenHighlightReelCongrats", true);
      }
    }

    setLoading(false);
  };

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();
      setNonUser(false);

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios(config);
      setMe(response.data);
      if (response.data.crew.length > 0) {
        setCoach(response.data.crew[0].coach);
      }
      getHighlightReel(response.data.id);
      getMoments(response.data.id, true);
      return response.data;
    } catch (error) {
      setLoading(false);
      setNonUser(true);
      return null;
    }
  };

  if (loading) {
    return <div></div>;
  }

  if (nonUser) {
    return (
      <div className="highlightReel">
        <div className="top">
          <div className="container">
            <h2>Highlight Reel</h2>
            <button onClick={() => setAdding(true)}>
              <FontAwesomeIcon icon={faAdd} />
              Add Highlight
            </button>
          </div>
        </div>
        <div
          style={{
            marginTop: "50px",
          }}
          className="container"
        >
          <h3 className="subtext">My Highlights</h3>

          <div className="grid">
            <p>
              No highlights yet. Add moments to your highlight reel by clicking
              the button above
            </p>
          </div>
        </div>

        <div className="addHighlight">
          <HighlightReelSignup
            me={me}
            start={() => {
              setShowWelcome(false);
              setAdding(true);
            }}
          />
          <div className="skrim"></div>
        </div>
      </div>
    );
  }

  if (step === 0 && !programUser && !loading) {
    return <HighlightReelOnboarding me={me} next={() => setStep(1)} />;
  }

  if (step === 0 && programUser && !loading) {
    return (
      <ProgramHighlightReelOnboarding
        me={me}
        next={() => {
          setStep(1);
          setShowProgramIntro(true);
        }}
      />
    );
  }

  return (
    <div className="highlightReel">
      <div className="top">
        <div className="container">
          <div>
            <h3>Activity</h3>
            <h2>Highlight Reel</h2>
          </div>

          {programUser && (
            <button
              className="btn secondary"
              onClick={() => history.push("/program")}
            >
              Back to Program
            </button>
          )}
        </div>
      </div>

      {programUser && moments.length < 4 && (
        <div className="container">
          <div className="nextSteps">
            <div className="orange">
              <h5>NEXT STEPS</h5>
              <p>
                Complete at least 4 highlights before your next session.{" "}
                <span
                  onClick={() => {
                    setAdding(true);
                  }}
                >
                  Add another →
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      {programUser && (
        <div className="container">
          <div className="insights">
            <h2>My Insights</h2>
            {insights.length === 0 && (
              <div className="blue">
                <div className="top">
                  <FontAwesomeIcon icon={faLightbulb} />
                  <p>Insight</p>
                  <span>Complete After Session</span>
                </div>

                <h3>
                  What did you learn about yourself from capturing your
                  highlights?
                </h3>
                <textarea
                  name=""
                  id=""
                  onChange={(e) => {
                    setInsight({
                      description: e.target.value,
                      question:
                        "What did you learn about yourself from capturing your highlights?",
                      type: "highlight1",
                    });
                  }}
                ></textarea>

                <button
                  className="btn primary"
                  onClick={() => {
                    saveInsight();
                  }}
                >
                  Save
                </button>
              </div>
            )}
            {insights.length > 0 && !addingNewInsight && (
              <div className="insightContainer">
                {insights.map((insight) => (
                  <div className="insight">
                    <FontAwesomeIcon icon={faLightbulb} />

                    <h4>{insight.question}</h4>
                    <p>{insight.description}</p>
                  </div>
                ))}
                <div className="insight blank">
                  <FontAwesomeIcon icon={faLightbulb} />

                  <h4>
                    What else are you taking away from completing this activity?
                  </h4>

                  <button
                    className="btn primary"
                    onClick={() => {
                      setAddingNewInsight(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Insight
                  </button>
                </div>
              </div>
            )}
            {insights.length > 0 && addingNewInsight && (
              <div className="blue">
                <div className="top">
                  <FontAwesomeIcon icon={faLightbulb} />
                  <p>Insight</p>
                  <span>Complete After Session</span>
                </div>

                <h3>
                  What else are you taking away from completing this activity?
                </h3>
                <textarea
                  name=""
                  id=""
                  onChange={(e) => {
                    setInsight({
                      description: e.target.value,
                      question:
                        "What else are you taking away from completing this activity?",
                      type: "highlight2",
                    });
                  }}
                ></textarea>

                <button
                  className="btn primary"
                  onClick={() => {
                    saveInsight();
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {highlightReel.length > 0 && (
        <div className="container">
          <h4 className="subtitle">Featured Highlights</h4>
        </div>
      )}
      {highlightReel.length > 0 && (
        <div className="topbg">
          <div
            className={`swiper ${highlightReel.length === 1 ? "hideNav" : ""}`}
          >
            <Swiper
              effect={"default"}
              ref={SwiperRef}
              grabCursor={true}
              centeredSlides={true}
              spaceBetween={70}
              pagination={{
                enabled: false,
              }}
              onProgress={(swiper) => {
                if (swiper.isBeginning) {
                  setIsBeginning(true);
                } else {
                  setIsBeginning(false);
                }
                if (swiper.isEnd) {
                  setIsEnd(true);
                } else {
                  setIsEnd(false);
                }
              }}
              slidesPerView={"auto"}
              breakpoints={{
                320: {
                  slidesPerView: "auto",
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: "auto",
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: "auto",
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: "auto",
                  spaceBetween: 50,
                },
              }}
              modules={[EffectCoverflow]}
              className="mySwiper"
            >
              {highlightReel.map((highlight) => (
                <SwiperSlide>
                  <HighlightTop
                    setActiveHighlight={(highlight) => {
                      setActiveHighlight(highlight);
                      setShowHighlight(true);
                    }}
                    highlight={highlight}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="navigation">
              <button
                className={`${isBeginning ? "disabled" : ""}`}
                onClick={() => {
                  SwiperRef.current.swiper.slidePrev();
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className={`${isEnd ? "disabled" : ""}`}
                onClick={() => {
                  SwiperRef.current.swiper.slideNext();
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: "70px",
        }}
        className="container"
      >
        <h4>All Highlights</h4>
        <div className="buttons">
          <button onClick={() => setAdding(true)}>
            <FontAwesomeIcon icon={faAdd} />
            Add Highlight
          </button>
          <button className="share" onClick={() => setShowShare(true)}>
            <FontAwesomeIcon icon={faShare} />
            {"  "}
            Share
          </button>
        </div>
        <div className="grid">
          {moments && moments.length > 0 && (
            <ResponsiveMasonry
              columnsCount={3}
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter={15}>
                {moments.map((highlight) => {
                  return (
                    <Highlight
                      getHighlightReel={() => {
                        getMe();
                      }}
                      setActiveHighlight={(highlight) => {
                        setActiveHighlight(highlight);
                        setShowHighlight(true);
                      }}
                      key={highlight.id}
                      highlight={highlight}
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          )}
          {moments && moments.length === 0 && (
            <div className="noHighlights">
              <div
                onClick={() => {
                  setAdding(true);
                }}
                className="card"
              >
                <div className="plus">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <h3>Add your first highlight</h3>
                <p>
                  Think back on your career and identify a moment in which you
                  felt confident, joyful, or proud.
                </p>
                <div
                  onClick={() => {
                    setAdding(true);
                  }}
                  className="arrow"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {adding && (
        <div className="addHighlight">
          <HighlightReelAdd
            me={me}
            close={() => setAdding(false)}
            highlightReel={highlightReel}
            setHighlightReel={setHighlightReel}
          />
          <div onClick={() => setAdding(false)} className="skrim"></div>
        </div>
      )}
      {showHighlight && (
        <HighlightSingle
          highlight={activeHighlight}
          getHighlightReel={() => {
            setReload(!reload);
          }}
          close={() => setShowHighlight(false)}
        />
      )}
      {showProgramIntro && (
        <div className="modal programIntro">
          <div className="modal-content">
            <div className="close">
              <button
                onClick={() => {
                  setShowProgramIntro(false);
                  setShowWelcome(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="left">
              <div className="box">
                <img src={hrIcon} alt="" />
              </div>
            </div>
            <div className="right">
              <h2>Let’s build your Highlight Reel!</h2>
              <p>
                In this activity, we’ll help you identify some highlights in
                your career. Actors and athletes have their highlight reels. Now
                you get one too!
              </p>
              <h3>Here's what to do before your next session:</h3>
              <div className="list">
                <div className="circle">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16667 2.5V5.83333M2.5 4.16667H5.83333M5 14.1667V17.5M3.33333 15.8333H6.66667M10.8333 2.5L12.7381 8.21429L17.5 10L12.7381 11.7857L10.8333 17.5L8.92857 11.7857L4.16667 10L8.92857 8.21429L10.8333 2.5Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p>
                  On the next screen, capture at least four highlights: moments
                  in your career when you were at your very best.
                </p>
              </div>
              <div className="list">
                <div className="circle">
                  <FontAwesomeIcon icon={faImage} />
                </div>
                <p>
                  Make them visual! Add photos or screenshots that bring your
                  highlights to life. It helps to celebrate and cement the
                  memory.
                </p>
              </div>
              <div className="coachTips step">
                <div className="number">
                  <img src={coach?.avatar} alt="" />
                </div>
                <div className="right">
                  <h4>Coach Tips from {coach?.first_name}</h4>
                  <ul>
                    <li>
                      Pick moments based on how they made you feel, not what you
                      think will be impressive to others.
                    </li>
                    <li>
                      Be kind to yourself. We all have highlights. Don’t be
                      afraid to add moments that may seem like “small” wins.
                    </li>
                    <li>
                      Tell a specific story. Try to avoid generalizations like
                      “Learned how to facilitate” and aim for “I facilitated my
                      team's launch meeting for an important new project.”
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="actions">
              <p>Ready to begin?</p>
              <button
                className="btn primary"
                onClick={() => {
                  setShowProgramIntro(false);
                  setAdding(true);
                }}
              >
                Lets Begin
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setShowProgramIntro(false);
              setShowWelcome(false);
              setAdding(true);
            }}
            className="skrim"
          ></div>
        </div>
      )}
      {showCongrats && (
        <div className="modal congrats programIntro">
          <div className="modal-content">
            <div className="left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="102"
                height="115"
                viewBox="0 0 102 115"
                fill="none"
              >
                <path
                  d="M42.3086 27L44.3086 34"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.639 18C47.8495 16.5062 47.9758 13.437 54.7966 13.1111C61.6174 12.7852 59.218 8.90123 57.1658 7"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M99.8803 43C100.885 41.5062 100.724 38.437 92.0433 38.1111C83.3623 37.7852 86.416 33.9012 89.028 32"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M69.3086 60L73.3086 62"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M66.3008 50C67.4602 51.2857 71.0834 53.6 76.3008 52.5714C81.5182 51.5429 85.1414 54.4286 86.3008 56"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28.0977 1C27.1558 2.45268 25.8582 6.55139 28.202 11.3248C30.5459 16.0982 28.6963 20.3448 27.4785 21.8714"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9087 50.0022L36.6316 92.2447M13.9087 50.0022L12.6554 57.7456L12.0287 61.6173L11.3237 65.9729M13.9087 50.0022L24.8498 57.4093L27.5851 59.2611L32.3738 62.4999M35.7909 64.8164L33.0556 62.9646L32.372 62.5015L32.3738 62.4999M11.3237 65.9729L26.8677 98.0752M11.3237 65.9729L10.9321 68.3927L10.1489 73.2322L8.58238 82.9111M26.8677 98.0752L23.6887 100.006L17.3307 103.868M26.8677 98.0752L35.5467 92.8925L36.6316 92.2447M8.58238 82.9111L17.3307 103.868M8.58238 82.9111L8.2691 84.8469L7.64255 88.7184L6.38945 96.4613L3.88324 111.947L17.3307 103.868M36.6316 92.2447L41.5559 89.2939L44.0181 87.8185L45.2492 87.0808L45.8648 86.712M32.3738 62.4999L57.6731 79.6307L45.8648 86.712M32.3738 62.4999L45.8648 86.712M45.8648 86.712L47.5746 85.6874L49.7632 84.376"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <circle cx="56.8086" cy="60.5" r="2.5" fill="#FF9E67" />
                <circle cx="69.3086" cy="7" r="2" fill="#FF9E67" />
                <circle cx="79.8086" cy="43.5" r="1.5" fill="#FF9E67" />
                <circle cx="34.8086" cy="23.5" r="1.5" fill="#FF9E67" />
                <path
                  d="M62.3086 40L69.3086 41"
                  stroke="#FF9E67"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.8398 10.4395L42.7683 4.56006"
                  stroke="#FF9E67"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.1758 31L25.8098 37.5622"
                  stroke="#FF9E67"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52.3086 31C52.3086 27.9894 54.4086 22.8714 62.8086 26.4841C73.3086 31 64.5586 17 72.4336 17C78.7336 17 80.3086 20.6127 80.3086 22.4191"
                  stroke="#FF9E67"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M44.8081 50C46.6455 47.3442 48.2849 42.2005 40.1441 42.8714C32.0034 43.5423 32.7947 39.2367 34.208 37"
                  stroke="#FF9E67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M39.6372 59.8016C45.8138 64.0501 50.9508 68.4987 54.2659 72.238C55.9287 74.1137 57.0947 75.768 57.7172 77.0964C58.3739 78.4977 58.2784 79.201 58.0744 79.4975C57.8705 79.794 57.2479 80.1348 55.7044 80.0226C54.2412 79.9164 52.2793 79.4192 49.9331 78.5372C45.2554 76.7787 39.2638 73.5725 33.0872 69.324C26.9107 65.0755 21.7737 60.6268 18.4585 56.8875C16.7957 55.0119 15.6298 53.3576 15.0072 52.0292C14.3505 50.6279 14.4461 49.9246 14.65 49.6281C14.8539 49.3316 15.4766 48.9908 17.02 49.1029C18.4832 49.2092 20.4451 49.7063 22.7914 50.5884C27.469 52.3468 33.4607 55.553 39.6372 59.8016Z"
                  fill="#FFF5F0"
                  stroke="#FF9E67"
                  stroke-width="2"
                />
              </svg>
            </div>
            <div className="right">
              <h2>Let's take a moment to celebrate</h2>
              <p>
                Awesome job on completing your first four highlights! You’re
                finished with the pre-work for your upcoming coaching session,
                but you are welcome to keep adding highlights!
              </p>
              <button
                onClick={() => setShowCongrats(false)}
                className="btn primary"
              >
                View Your Highlights
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setShowCongrats(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
      {showShare && (
        <div>
          <ShareWidget
            close={() => {
              setShowShare(!showShare);
            }}
            moments={moments}
            me={me}
          />
        </div>
      )}
    </div>
  );
};
