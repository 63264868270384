import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Values/dashboard.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { ValueInviteList } from "./components/Invite";
import { Analytics } from "../../hooks/analytics";

export const ValuesDashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [values, setValues] = useState([]);
  const [active, setActive] = useState();
  const [invites, setInvites] = useState([]);
  const [insightText, setInsightText] = useState("");
  const [insights, setInsights] = useState([]);
  const [programUser, setProgramUser] = useState(false);
  const [valueImages, setValueImages] = useState([]);
  const [presenterView, setPresenterView] = useState(false);
  const [me, setMe] = useState({});
  const [addingInsight, setAddingInsight] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getMe();
    getValues();
    getInsights();
    getInvites();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    // They are a program user.
    if (response.data.crew.length > 0) {
      setProgramUser(true);
      getValueImages(response.data);
    }
  };

  const getValueImages = async (me) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/value-image`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValueImages(response.data.valueImages);
  };

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "values2",
        toolId: 9,
        question:
          question ||
          `What's clearer to you about your values now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    Analytics("10 Images - Added Insight");

    setAddingInsight(false);
    setInsightText("");

    getInsights();
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/insights`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setInsights(response.data);
  };

  const getValues = async (fromOnboarding) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/all`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const filteredValues = response.data.reduce(
      (acc, value) => {
        if (value.fromOnboarding) {
          acc.onBoarding.push(value);
        } else {
          acc.values.push(value);
        }
        return acc;
      },
      { onBoarding: [], values: [] }
    );

    if (!filteredValues.values) {
      return history.push("/values/");
    }

    setActive(filteredValues.values[0]);

    setValues(filteredValues.values);
  };

  const getInvites = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/invite`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const newInvites = response.data.filter((invite) => {
      if (invite.firstName) {
        return true;
      }

      return false;
    });

    const splitResponses = newInvites.reduce(
      (acc, invite) => {
        if (invite.email === "coaches@joinacrew.com") {
          invite.firstName = "Roxanne";
          invite.coach = true;
        }
        if (invite.response !== null) {
          acc.done.push(invite);
        } else {
          acc.pending.push(invite);
        }
        return acc;
      },
      { done: [], pending: [] }
    );

    setInvites(splitResponses);
  };

  return (
    <div className="valuesDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Values</h2>

          {/* If they are not a program user, show this message */}
          {!programUser && (
            <p
              style={{
                marginTop: "-20px",
              }}
            >
              Yahoo, your values are added! 🎉 Keep an eye out for any incoming
              feedback to help you refine your values.
            </p>
          )}

          {/* If they are a program user, show this message */}
          {programUser && (
            <p
              style={{
                marginTop: "-20px",
              }}
            >
              Congrats! You've completed your Crew session on values. Add some
              insights and deepen your learning below.
            </p>
          )}
        </div>

        <div className="right">
          {programUser && (!values || values.length === 0) && (
            <button
              onClick={() => {
                history.push("/program");
              }}
              className="btn secondary"
            >
              Back to Program
            </button>
          )}
          {values.length > 0 && (
            <button
              onClick={() => {
                history.push("/dashboard");
              }}
              className="btn secondary"
            >
              Back to Canvas
            </button>
          )}
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you about your values now that you've completed
              this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {values.length > 0 && (
        <div className="white-background">
          <div className="top">
            <h3>My Values</h3>
            <button
              className="btn empty"
              onClick={() => {
                history.push("/values/creation");
              }}
            >
              <FontAwesomeIcon icon={faPencil} /> Edit My Values
            </button>
          </div>
          <div className="content">
            <div className="left">
              {values.map((value) => (
                <div
                  onClick={() => {
                    setActive(value);
                  }}
                  className={`value ${active.id === value.id ? "active" : ""}`}
                >
                  <div className="value-name">{value.text}</div>
                </div>
              ))}
            </div>
            <div className="right">
              <div className="header">
                <h3>{active?.text}</h3>
                <p>{active?.summary}</p>
              </div>
              <ResponsiveMasonry
                columnsCount={3}
                columnsCountBreakPoints={{
                  350: 1,
                  750: 2,
                  900: 3,
                  1200: 3,
                  1400: 4,
                }}
                className="masonry-grid"
              >
                <Masonry gutter={15}>
                  {active?.valueImage &&
                    active?.valueImage?.map((highlight) => (
                      <div key={highlight.id} className="single-highlight">
                        <div className="photo">
                          <img src={highlight.url} alt="value" />
                        </div>
                      </div>
                    ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </div>
      )}

      {valueImages.length > 0 && (
        <div className="white-background">
          <div className="top">
            <h3>My Images</h3>
            <div className="buttonContainer">
              <button
                className="btn empty"
                onClick={() => {
                  setPresenterView(true);
                }}
              >
                Presenter View
              </button>
              <button
                className="btn empty"
                onClick={() => {
                  history.push("/values", {
                    restart: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPencil} /> Edit My Images
              </button>
            </div>
          </div>
          {me.crew[0].activeSession?.name === "Values" && (
            <div className="inSession">
              <h5>In-Session</h5>
              <p>
                Be prepared to share your 10 Images during your coaching session
                using presenter view
              </p>
            </div>
          )}

          {values.length === 0 && (
            <div className="inSession steps">
              <h5>Next Steps</h5>
              <p>
                Now that you have finished your 10 images, start thinking about
                what these images represent about you and your values.
              </p>
              <button
                onClick={() => {
                  history.push("/values/creation");
                }}
                className="btn secondary"
              >
                <FontAwesomeIcon icon={faPlus} /> Write out your values
              </button>
            </div>
          )}

          <div className="valueImageContainer">
            <ResponsiveMasonry
              columnsCount={3}
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 3,
                1200: 3,
                1400: 4,
              }}
              className="masonry-grid"
            >
              <Masonry gutter={15}>
                {valueImages.map((highlight) => (
                  <div key={highlight.id} className="single-highlight">
                    <div className="photo">
                      <img src={highlight.url} alt="value" />
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      )}

      <div className="white-background invites">
        <div className="top">
          <h3>Collaborators</h3>
          {(invites?.pending?.length > 0 || invites?.done?.length > 0) && (
            <button
              className="btn empty"
              onClick={() => {
                history.push("/values", {
                  invite: true,
                });
              }}
            >
              + Add a collaborator
            </button>
          )}
        </div>

        {invites?.pending?.length === 0 &&
          !programUser &&
          invites?.done?.length === 0 && (
            <div className="empty">
              <h4>No one has been invited yet!</h4>
              <h5>Start getting feedback by adding a collaborator</h5>
            </div>
          )}

        {invites?.pending?.length === 0 &&
          programUser &&
          invites?.done?.length === 0 && (
            <div className="emptyProgram">
              <h5>
                Did you love the 10 Images activity in your group coaching
                session?
              </h5>
              <h3>
                You can get more input on your values by sharing your 10 Images
                with people in your life. They might surface additional insights
                beyond what came up in the session.
              </h3>
              <button
                className="btn secondary"
                onClick={() => {
                  history.push("/values", {
                    invite: true,
                  });
                }}
              >
                + Add a collaborator
              </button>
            </div>
          )}

        {(invites?.pending?.length > 0 || invites?.done?.length > 0) && (
          <div className="invites-list">
            {invites.done?.length > 0 && (
              <>
                <h3>Responded</h3>
                {invites?.done?.map((invite) => (
                  <ValueInviteList invite={invite} />
                ))}
              </>
            )}
            {invites.pending?.length > 0 && (
              <>
                <h3>Not Responded</h3>
                {invites?.pending?.map((invite) => (
                  <ValueInviteList invite={invite} />
                ))}
              </>
            )}
          </div>
        )}
      </div>
      {presenterView && (
        <div className="presenterView">
          <div className="container">
            <div className="header">
              <h3>My Images</h3>
              <button
                className="btn empty"
                onClick={() => {
                  setPresenterView(false);
                }}
              >
                Exit Presenter View
              </button>
            </div>
            <div className="valueImageContainer">
              <ResponsiveMasonry
                columnsCount={3}
                columnsCountBreakPoints={{
                  350: 1,
                  750: 2,
                  900: 3,
                  1200: 4,
                  1400: 5,
                }}
                className="masonry-grid"
              >
                <Masonry gutter={15}>
                  {valueImages.map((highlight) => (
                    <div key={highlight.id} className="single-highlight">
                      <div className="photo">
                        <img src={highlight.url} alt="value" />
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
