import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Lessons/single.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faEdit,
  faPlus,
  faTrash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { isAdmin } from "../../../hooks/isAdmin";


export const LessonsSingle = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [lesson, setLesson] = useState(null);
  let id = props.match.params.id;
  const [addActivity, setAddActivity] = useState(false);
  const [newActivity, setNewActivity] = useState({
    name: "",
    description: "",
    type: "",
    timeToComplete: "",
    url: "",
    lessonId: id,
  });

  useEffect(() => {
    getMe();
    getLesson();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const deleteActivity = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    getLesson();
  };

  const getLesson = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/lessons/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setLesson(response.data);
  };

  const addActivityAPI = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/activities`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.data = newActivity;
    await axios(config);
    setNewActivity({
      name: "",
      description: "",
      type: "",
      timeToComplete: "",
      url: "",
      lessonId: id,
    });
    setAddActivity(false);
    getLesson();
  };

  if (!lesson) {
    return <div>Loading...</div>;
  }

  return (
    <div className="LessonSingle">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>crews</NavLink>
        </div>
        /
        <div className="breadcrumb">
          <NavLink to={`/admin/crews/${lesson.crew.id}`}>
            {lesson.crew.name}
          </NavLink>
        </div>
        /<div className="breadcrumb">{lesson.name}</div>
      </div>
      <div className="container white-background">
        <NavLink to={`/admin/lessons/${lesson.id}/edit`} className="edit">
          Edit
        </NavLink>
        <h2><span className="heading1 highlight blue">{lesson.name}</span></h2>
        <p className="body-text ">{lesson.description}</p>
        <div className="info">
          <div className="info-item">
            <p className="body-text">
              <FontAwesomeIcon icon={faCalendar} />{" "}
              {moment(lesson.startTime).format("MM/DD/YYYY  hh:mm a")} -{" "}
              <FontAwesomeIcon icon={faCalendar} />{" "}
              {moment(lesson.endTime).format("MM/DD/YYYY  hh:mm a")}
            </p>
          </div>
          <a
            style={{
              width: "160px",
            }}
            className="btn secondary"
            href={lesson.meetingLink}
            target="_blank"
          >
            <FontAwesomeIcon icon={faVideo} /> Join Meeting
          </a>
        </div>

        <div className="row"></div>
      </div>
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
        className="container white-background"
      >
        <h2 className="heading2 highlight yellow">{lesson.name} Activities</h2>

        <button
          onClick={() => {
            setAddActivity(true);
          }}
          className="btn secondary right"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Activity
        </button>

        {lesson.activities.map((activity) => (
          <div className="lesson">
            <div className="lesson-info">
              <div className="lesson-title">
                <h3 className="heading2 underline blue">{activity.name}</h3>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="body-text pill"
                >
                  {activity.type.toLowerCase()}
                </p>
              </div>
              <div className="lesson-description">
                <p className="body-text">{activity.description}</p>
              </div>
            </div>
            <div className="lesson-actions">
              <NavLink to={`/admin/activities/${activity.id}`}>
                <button className="btn secondary">
                  <FontAwesomeIcon icon={faBook} /> View Activity
                </button>
              </NavLink>

              <NavLink to={`/admin/activities/${activity.id}/edit`}>
                <button className="btn secondary purple">
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </NavLink>

              <button
                onClick={() => {
                  deleteActivity(activity.id);
                }}
                className="btn secondary red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}

        {lesson.activities.length === 0 && (
          <div className="no-lessons">
            <p className="body-text">No activities yet!</p>
          </div>
        )}
      </div>
      {addActivity && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="heading2 highlight yellow">Add Activity</h2>
            <div className="row">
              <div className="inputBox">
                <label className="body-text">Activity Name</label>
                <input
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.name = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  type="text"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Description</label>
                <textarea
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.description = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Url</label>
                <input
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.url = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  type="text"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Time To Complete (minutes)</label>
                <input
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.timeToComplete = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  type="number"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Type</label>
                <select
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.type = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  className="input"
                >
                  <option disabled selected value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option value="PRE-WORK">Pre-Work</option>
                  <option value="IN-LESSON">In-Lesson</option>
                  <option value="POST-WORK">Post-Work</option>
                </select>
              </div>

              <div className="inputBox">
                <label className="body-text">Link Type</label>
                <select
                  onChange={(e) => {
                    const newActivityObject = { ...newActivity };
                    newActivityObject.linkType = e.target.value;
                    setNewActivity(newActivityObject);
                  }}
                  className="input"
                >
                  <option disabled selected value>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  <option value="EXTERNAL">External</option>
                  <option value="INTERNAL">Internal</option>
                </select>
              </div>

              <button className="btn secondary" onClick={addActivityAPI}>
                <FontAwesomeIcon icon={faPlus} /> Add Activity
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setAddActivity(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
