import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/components/admin/LessonTimeEditor.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
export const SessionTimeEditor = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [showAttendance, setShowAttendance] = useState(false);
  const [users, setUsers] = useState([]);
  const [session, setSession] = useState(() => {
    const session = { ...props.session };
    if (session.startDate) {
      session.startDate = moment(session.startDate).toDate();
    } else {
      session.startDate = "";
    }
    if (session.endDate) {
      session.endDate = moment(session.endDate).toDate();
    } else {
      session.endDate = "";
    }

    return session;
  });
  const [saved, setSaved] = useState(false);

  const saveSession = async () => {
    const token = await getAccessTokenSilently();

    const data = { ...session };

    const config = {
      url: `${apiServerUrl}/api/sessions/${session.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.status === 200) {
      setSaved(true);
    }
  };

  const saveAttendance = async () => {
    const token = await getAccessTokenSilently();

    const data = session.users.map((u) => {
      return {
        userId: u.id,
        attended: u.attended,
        sessionId: session.id,
      };
    });

    const config = {
      url: `${apiServerUrl}/api/attendance`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        data: data,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.status === 200) {
      setSaved(true);
      setShowAttendance(false);
    }
  };

  return (
    <div key={props.key} className="LessonTimeEditor">
      <div className="inputs">
        <h4 className="highlight yellow">{props.session.name}</h4>
        <div className="dates">
          <div className="inputBox">
            <label className="body-text">Start Time</label>
            <DatePicker
              dateFormat="Pp"
              selected={session.startDate}
              showTimeSelect
              onChange={(e) => {
                const newSessionObject = { ...session };
                newSessionObject.startDate = e;
                setSession(newSessionObject);
              }}
            />
          </div>
          <div className="inputBox">
            <label className="body-text">End Time</label>
            <DatePicker
              dateFormat="Pp"
              selected={session.endDate}
              showTimeSelect
              onChange={(e) => {
                const newSessionObject = { ...session };
                newSessionObject.endDate = e;
                setSession(newSessionObject);
              }}
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Meeting Url</label>
            <input
              onChange={(e) => {
                const newSessionObject = { ...session };
                newSessionObject.meetingLink = e.target.value;
                setSession(newSessionObject);
              }}
              type="text"
              value={session.meetingLink}
              className="input session"
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Session Recording</label>
            <input
              onChange={(e) => {
                const newSessionObject = { ...session };
                newSessionObject.sessionRecordingLink = e.target.value;
                setSession(newSessionObject);
              }}
              type="text"
              value={session.sessionRecordingLink}
              className="input session"
            />
          </div>
        </div>

        <div className="attendanceBox">
          <button
            onClick={() => {
              setShowAttendance(!showAttendance);
            }}
            className="btn secondary mark"
          >
            Mark Attendance
          </button>
          {showAttendance && (
            <div className="names">
              {session.users.map((u) => {
                return (
                  <div key={u.id} className="user">
                    <input
                      onChange={() => {
                        const newUsers = [...session.users];
                        const index = newUsers.findIndex(
                          (user) => user.id === u.id
                        );
                        newUsers[index].attended = !newUsers[index].attended;

                        const newSession = { ...session };

                        newSession.users = newUsers;

                        setSession(newSession);
                      }}
                      checked={u.attended}
                      type="checkbox"
                    />
                    <label>
                      {u.first_name} {u.last_name}
                    </label>
                  </div>
                );
              })}
            </div>
          )}

          {showAttendance && (
          <button
            onClick={() => {
              saveAttendance();
            }}
            className="btn secondary saveAttendance"
          >
            Save Attendance
          </button>
          )}
        </div>

        {!showAttendance && (
          <>
            {!saved && (
              <div className="inputBox button">
                <button
                  onClick={() => {
                    saveSession();
                  }}
                  className="btn secondary"
                >
                  Save
                </button>
              </div>
            )}

            {saved && (
              <div className="inputBox button">
                <button
                  onClick={() => {
                    saveSession();
                  }}
                  className="btn secondary purple"
                >
                  Saved!
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
