import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import box from "../svgs/box.svg";
import "../../../styles/pages/Strengths/components/ProgramModal.scss";
import React, { useState } from "react";
export const ProgramModal = ({ setShowModal }) => {
  const [step, setStep] = useState(1);
  return (
    <div className="modal ProgramModal">
      <div className="modal-content">
        <FontAwesomeIcon
          className="close"
          icon={faTimes}
          onClick={() => setShowModal(false)}
        />
        <div className="left">
          <div className="box">
            <img src={box} alt="box" />
          </div>
        </div>
        {step === 1 && (
          <div className="right">
            <h3>Here's what to expect</h3>
            <h2>
              In this activity, you'll uncover your strengths with help from
              people who know you well.
            </h2>
            <h4>Here's what to do before your next session:</h4>

            <ol>
              <li>
                <p>First, you'll create a list of your top 10 strengths</p>
              </li>
              <li>
                <p>
                  Then, you'll get input on your strengths from people who know
                  you well
                </p>
              </li>
              <li>
                <p>
                  Finally, you'll come to the session with a list of your top 5
                  strengths that you have narrowed down based on what you
                  learned
                </p>
              </li>
            </ol>

            <div className="actions">

              <button
                className="btn secondary"
                onClick={() => setStep(2)}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="right">
            <h3>Let's identify your strengths</h3>
            <h2>
            It can be tough to come up with a list of your strengths from a blank page. We've put together a list of strengths that we've divided into three categories to help make it more manageable.
            </h2>

            <div className="item">
              <h4>What I do</h4>
              <p>
              Strengths related to the type of work or tasks you're doing
              </p>
            </div>

            <div className="item">
              <h4>How I Work</h4>
              <p>
              Strengths related to how you get work done, usually with other people
              </p>
            </div>

            <div className="item">
              <h4>Who I am</h4>
              <p>
              Strengths that relate to your personal qualities
              </p>
            </div>

            <p className="bold">You can pick from any of these categories to fill out your top 10 strengths. There is no rule or limit as to how many you may pick from each category.</p>

            <div className="actions">

              <button
                className="btn secondary"
                onClick={() => setShowModal(false)}
              >
                Let's Begin!
              </button>
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="skrim"
      ></div>
    </div>
  );
};
