import React, { useEffect, useState } from "react";
import "./styles/coverPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import example1 from "./images/modal1.png";
import example2 from "./images/example2.png";
import example3 from "./images/example3.png";
import { Analytics } from "../../hooks/analytics";

export const CoverPage = ({ location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [allPages, setAllPages] = useState([]);
  const [step, setStep] = useState(0);
  const { apiServerUrl } = useEnv();
  const [cover, setCover] = useState({});
  const [me, setMe] = useState({});
  const [modalStep, setModalStep] = useState(1);
  const [vision, setVision] = useState({});
  const [showIntro, setShowIntro] = useState(true);
  const history = useHistory();
  useEffect(() => {
    getVisions();
    getMe();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getCategory = (category) => {
    switch (category) {
      case "growth":
        return "PERSONAL GROWTH";
      case "relationships":
        return "RELATIONSHIPS";
      case "impact":
        return "IMPACT";
      default:
        return "PERSONAL GROWTH";
    }
  };

  const saveCover = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions/cover`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        title: cover.title,
        image: cover.image,
        color: cover.color,
        bio: cover.bio,
        visionId: vision.id,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    Analytics("Visions - Saved Cover");
    Analytics("Visions - Finished");


    history.push("/visions/dashboard");
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/visions/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const newCover = { ...cover };
    newCover.image = upload.data.image;

    setCover(newCover);
  };

  const getVisions = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Get all the pages that are not the active page

    setAllPages(response.data[0].pages);
    setVision(response.data[0]);
  };

  return (
    <div className="coverPage">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              history.goBack();
            }}
          />

          <h3>Creating your cover</h3>
        </div>

        <div className="inner">
          {step === 0 && (
            <div className="inputBox">
              <label>Come up with a title for your one-year vision</label>
              <p>
                Try to be specific with your title to articulate what this
                vision is about.{" "}
              </p>

              <textarea
                onChange={(e) => {
                  if (e.target.value.length > 75) {
                    return;
                  }
                  const newCover = { ...cover };
                  newCover.title = e.target.value;
                  setCover(newCover);
                }}
                value={cover.title}
                placeholder="Type your title here"
              />
              <div className="counter">{cover.title?.length || 0} / 75</div>
            </div>
          )}
          {step === 1 && (
            <>
              <div className="inputBox">
                <label>Add an image to your vision</label>
                <div className="titleBox">
                  <p>{cover.title}</p>
                  <FontAwesomeIcon
                    onClick={() => {
                      setStep(0);
                    }}
                    icon={faPencil}
                  />
                </div>
              </div>
              <div className="inputBox photo">
                <p>Select a photo that helps bring this vision to life</p>
                <div className="photoUpload">
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadAvatar(e.target.files);
                    }}
                  />
                  {cover.image && <img src={cover.image} alt="cover" />}
                  {!cover.image && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="74"
                        height="52"
                        viewBox="0 0 74 52"
                        fill="none"
                      >
                        <rect width="74" height="52" rx="8" fill="#FF9E67" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.3293 17.1163C20.0225 17.1163 22.2058 14.933 22.2058 12.2398C22.2058 9.54656 20.0225 7.36328 17.3293 7.36328C14.6361 7.36328 12.4528 9.54656 12.4528 12.2398C12.4528 14.933 14.6361 17.1163 17.3293 17.1163ZM34.4313 27.1646C33.5207 28.4772 31.581 28.481 30.6653 27.172L26.7152 21.5253C25.8017 20.2195 23.868 20.2195 22.9545 21.5253L12.1098 37.0278C11.0458 38.5489 12.134 40.6381 13.9902 40.6381L25.0849 40.6381L40.0854 40.6381L59.7015 40.6381C61.5536 40.6381 62.6427 38.5571 61.587 37.0353L46.4711 15.2447C45.5584 13.9289 43.6127 13.9289 42.7 15.2447L34.4313 27.1646Z"
                          fill="#E26C29"
                        />
                      </svg>
                      <h4>Upload Photo</h4>
                      <p>Drag and drop or tap to browse files</p>
                    </>
                  )}
                </div>
              </div>
              <div className="inputBox">
                <label>Choose an overlay color</label>
                <div className="colors">
                  <div
                    onClick={() => {
                      const newCover = { ...cover };
                      newCover.color = "#94B9C9";
                      setCover(newCover);
                    }}
                    className="blue"
                  ></div>
                  <div
                    onClick={() => {
                      const newCover = { ...cover };
                      newCover.color = "#FF9E67";
                      setCover(newCover);
                    }}
                    className="orange"
                  ></div>
                  <div
                    onClick={() => {
                      const newCover = { ...cover };
                      newCover.color = "#FEDE66";
                      setCover(newCover);
                    }}
                    className="yellow"
                  ></div>
                  <div
                    onClick={() => {
                      const newCover = { ...cover };
                      newCover.color = "#E1C7BE";
                      setCover(newCover);
                    }}
                    className="tan"
                  ></div>
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="inputBox">
                <label>Come up with a bio for your feature on this cover</label>
                <p>
                  This can be a short blurb about who you hope to be a year from
                  now. Need inspo?
                </p>

                <textarea
                  onChange={(e) => {
                    if (e.target.value.length > 50) {
                      return;
                    }
                    const newCover = { ...cover };
                    newCover.bio = e.target.value;
                    setCover(newCover);
                  }}
                  value={cover.bio}
                  placeholder="Type your bio here"
                />

                <div className="counter">{cover?.bio?.length || 0} / 50</div>
              </div>
            </>
          )}
        </div>

        <div className="bottom">
          <button
            onClick={() => {
              if (step === 0) {
                return history.goBack();
              }
              setStep(step - 1);
            }}
            className="btn blank"
          >
            Back
          </button>
          <button
            onClick={() => {
              if (step === 2) {
                return saveCover();
              }
              setStep(step + 1);
            }}
            className="btn secondary"
          >
            Continue
          </button>
        </div>
      </div>

      <div className="right">
        {step < 2 && (
          <div className="coverBox">
            <div
              style={{
                backgroundImage: `url(${cover.image})`,
              }}
              className="inside"
            >
              <div className="content">
                <span>ONE-YEAR VISION</span>
                <h2>{cover.title || "Title will appear here"}</h2>
                <div className="bottom">
                  {me.first_name && (
                    <p>
                      <b>
                        {me.first_name} {me.last_name}
                      </b>
                    </p>
                  )}

                  <p>{cover.bio}</p>
                </div>
              </div>
              {cover.color && (
                <div
                  className="overlay"
                  style={{ backgroundColor: cover.color }}
                ></div>
              )}
            </div>
          </div>
        )}
        {step >= 2 && (
          <div className="scrollingBox">


            <div className="pages">
              <div className="cover">
                <div className="coverBox">
                  <div
                    style={{
                      backgroundImage: `url(${cover.image})`,
                    }}
                    className="inside"
                  >
                    <div className="content">
                      <span>ONE-YEAR VISION</span>
                      <h2>{cover.title || "Title will appear here"}</h2>
                      <div className="bottom">
                        {me.first_name && (
                          <p>
                            <b>
                              {me.first_name} {me.last_name}
                            </b>
                          </p>
                        )}

                        <p>{cover.bio}</p>
                      </div>
                    </div>
                    {cover.color && (
                      <div
                        className="overlay"
                        style={{ backgroundColor: cover.color }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>

              {allPages.map((page, index) => (
                <div className="item">
                <div className={`box`}>
                  <div className={`inside ${page.category}`}>
                    <div className="top">
                      <span>{getCategory(page.category)}</span>
                    </div>

                    <h2>{page.summary || "Summarize in a few words"}</h2>

                    <p>
                      {page.response ||
                        "Your response to the prompt will show up in this space."}
                    </p>

                    {page.image && <img src={page.image} alt="" />}
                  </div>
                </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {showIntro && (
        <div className="modal">
          {modalStep === 1 && (
            <div className="modal-content">
              <FontAwesomeIcon
                onClick={() => {
                  setShowIntro(false);
                }}
                icon={faTimes}
              />

              <h2>Putting the finishing touches on your one-year vision</h2>
              <h5>How it works</h5>

              <div className="icons">
                <div className="item">
                  <span>1</span>
                  <div className="content">
                    <h4>Sum up your vision with a title</h4>
                    <p>
                      Distill the main takeaway from the prompts you answered
                    </p>
                  </div>
                </div>
                <div className="item">
                  <span>2</span>
                  <div className="content">
                    <h4>Make it visual</h4>
                    <p>Find an image that brings your vision to life</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  setModalStep(2);
                }}
                className="btn secondary"
              >
                Next
              </button>
            </div>
          )}
          {modalStep === 2 && (
            <div className="modal-content">
              <FontAwesomeIcon
                onClick={() => {
                  setShowIntro(false);
                }}
                icon={faTimes}
              />
              <h2>Putting the finishing touches on your one-year vision</h2>
              <h5>Examples</h5>

              <div className="images">
                <img src={example1} alt="example1" />
                <img src={example2} alt="example2" />
                <img src={example3} alt="example3" />
              </div>

              <div className="actions">
                <button
                  onClick={() => {
                    setModalStep(1);
                  }}
                  className="btn text"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setShowIntro(false);
                  }}
                  className="btn secondary"
                >
                  Let's Begin
                </button>
              </div>
            </div>
          )}
          <div
            onClick={() => {
              setShowIntro(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
