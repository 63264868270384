import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../styles/pages/profile-edit.scss";
import { Analytics } from "../hooks/analytics";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
export const ProfileEdit = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = React.useState();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [isCoach, setIsCoach] = React.useState(false);

  useEffect(() => {
    getMe();
  }, []);

  const deleteMe = async () => {
    const token = await getAccessTokenSilently();
    let URL = `${apiServerUrl}/api/users/`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    Analytics("Profile Deleted");

    logout({
      returnTo: window.location.origin,
    });
  };

  const saveMe = async () => {
    const token = await getAccessTokenSilently();
    const meObject = { ...me };
    let URL = `${apiServerUrl}/api/users/me`;
    await axios.patch(URL, meObject, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    Analytics("Profile Edit");

    history.push({
      pathname: "/dashboard",
    });
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    const rolesFilter = response.data.roles.filter((role) => role.name === "Coach");

    if (rolesFilter.length > 0) {
      setIsCoach(true);
    }
  };

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/users/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const avatar = upload.data.avatar;
    let newMe = { ...me };
    newMe.avatar = avatar;
    Analytics("Updated Avatar");
    setMe(newMe);
  };

  if (!me) {
    return null;
  }

  return (
    <div className="profile-edit">
      <h2>Edit Profile</h2>
      <div className="dashboard__header">
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `https://placehold.co/100x100?text=${
              me.first_name.charAt(0) + me.last_name.charAt(0)
            }`;
          }}
          src={
            me?.avatar ||
            `https://placehold.co/100x100?text=${
              me.first_name?.charAt(0) + me.last_name?.charAt(0)
            }`
          }
          alt="avatar"
        />

        <div className="input">
          <input
            name="file"
            type="file"
            accept="image/jpeg"
            onChange={(e) => {
              uploadAvatar(e.target.files);
            }}
            className="btn empty"
          />
          <button className="btn primary">Upload Image</button>
        </div>
      </div>

      <div className="settings">
        <h3>Account Settings</h3>
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              onChange={(e) => {
                const newMe = { ...me };
                newMe.first_name = e.target.value;
                setMe(newMe);
              }}
              type="text"
              id="first_name"
              value={me?.first_name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              onChange={(e) => {
                const newMe = { ...me };
                newMe.last_name = e.target.value;
                setMe(newMe);
              }}
              type="text"
              id="last_name"
              value={me?.last_name}
            />
          </div>
        </div>
        {isCoach && (
        <div className="form-container full">
           <div className="form-group">
          <label>Coaching Bio</label>
          <textarea
            onChange={(e) => {
              const newMe = { ...me };
              newMe.bio = e.target.value;
              setMe(newMe);
            }}
            value={me?.bio}
          ></textarea>
          </div>
        </div>
        )}
        <div className="form-container full">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              onChange={(e) => {
                const newMe = { ...me };
                newMe.email = e.target.value;
                setMe(newMe);
              }}
              type="text"
              id="email"
              value={me?.email}
            />
          </div>
        </div>
        <div className="actions">
          <button
            onClick={() => {
              saveMe();
            }}
            className="btn blue"
          >
            Save
          </button>
          <button
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
            className="btn blue opacity"
          >
            Log out
          </button>
</div>
          <React.Fragment>
            <button
              className="btn delete blue opacity"
              variant="outlined"
              onClick={() => {
                setOpen(true);
              }}
            >
              Delete Account
            </button>
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Delete Account</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete your account? This action
                  cannot be undone. All of your data will be deleted
                  permanently.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Disagree
                </Button>
                <Button
                  onClick={async () => {
                    await deleteMe();
                    setOpen(false);
                  }}
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>

      </div>
    </div>
  );
};
