import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { Video } from "../../components/video";
import { TextStory } from "../../components/textstory";

import "../../styles/pages/storyboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { StoryModal } from "./components/StoryModal";
import { StoryboardWelcomeModal } from "./components/StoryboardWelcomeModal";
export const StoryboardDashboard = (props) => {
  const [video, setVideo] = useState(false);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState();
  const [view, setView] = useState("stories");
  const [stories, setStories] = useState([]);
  const [receivedStories, setReceivedStories] = useState([]);
  const [crewImages, setCrewImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeStory, setActiveStory] = useState({});
  const defaultStory = {
    id: 0,
    type: "text",
    sendingUser: {
      first_name: "Roxanne",
      last_name: "Burbach",
      avatar:
        "https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg",
    },
    backgroundType: "blue",
    text: `<p>Welcome to Stories! Expressing gratitude boosts your well-being and helps you nurture your professional relationships. <br /><br />Send a Story (like this one) to show appreciation for someone who has positively impacted your life and career. It's going to feel great!</p>`,
    replies: [],
  };

  const [loadedReceived, setLoadedReceived] = React.useState(false);
  const [loadedSent, setLoadedSent] = React.useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  useEffect(() => {
    async function getData() {
      await getMe();
      getVideo();
      getReceieved();
      getSent();
    }

    const hasSeenStoryboard = localStorage.getItem("hasSeenStoryboard");

    if (!hasSeenStoryboard) {
      setShowWelcomeModal(true);
    }

    getData();
  }, []);

  const getVideo = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/video/user`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      if (response.data.videos) {
        setVideo(response.data.videos);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSent = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/story/sent`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.stories) {
      setStories(response.data.stories);
      setLoadedSent(true);
    }
  };

  const getReceieved = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/story/received`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.stories) {
      setReceivedStories(response.data.stories);
      setLoadedReceived(true);

      const crewImages = response.data.stories.map((story) => {
        story.noImage =
          story.sendingUser.first_name.charAt(0) +
          story.sendingUser.last_name.charAt(0);
        return story;
      });

      setCrewImages(crewImages);
    }
  };

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios(config);

      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        user_id: response.data.id,
        app_id: "yqnbhk7a",
        name: `${response.data.first_name} ${response.data.last_name}`, // Full name
        email: `${response.data.email}`, // Email address
        created_at: response.data.created_at,
      });

      setMe(response.data);
    } catch (error) {
      // If they are only going to be a storyboard user * BETA
      // probally need to add a query param to this too.

      localStorage.setItem("storyboardUser", true);
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  };

  if (!loadedReceived && !loadedSent) {
    return <div></div>;
  }

  return (
    <div className="storyboard-dashboard">
      <div className="top">
        <div className="left">
          <h3>Activity</h3>
          <h2>Stories</h2>
        </div>
        <div className="right">
          <button
            onClick={() => {
              props.history.push("/storyboard/add");
            }}
            className="btn blank"
          >
            Add Story
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="selector">
        <h3
          className={view === "stories" ? "active underline yellow" : ""}
          onClick={() => {
            setView("stories");
          }}
        >
          Received
        </h3>
        <h3
          className={view === "sent" ? "active" : ""}
          onClick={() => {
            setView("sent");
          }}
        >
          Sent
        </h3>
      </div>

      {props.history?.location?.state?.message && (
        <div className="message">
          <p>{props.history?.location?.state?.message}</p>
        </div>
      )}

      {view === "stories" && (
        <div
          className={`stories ${receivedStories.length === 0 ? "blank" : ""}`}
        >
          {!receivedStories ||
            (receivedStories.length === 0 && (
              <div className="story">
                <TextStory
                  refresh={() => {
                    getReceieved();
                  }}
                  clicked={() => {
                    setActiveStory(defaultStory);
                    setShowModal(!showModal);
                  }}
                  hideAction={true}
                  modalFlow={true}
                  type={"received"}
                  story={defaultStory}
                />
              </div>
            ))}
          {receivedStories.map((story) => {
            return (
              <div className="story" key={story.id}>
                {story.type === "video" && (
                  <Video
                    refresh={() => {
                      getReceieved();
                    }}
                    clicked={() => {
                      setActiveStory(story);
                      setShowModal(!showModal);
                    }}
                    modalFlow={true}
                    type={"received"}
                    story={story}
                  />
                )}
                {story.type === "text" && (
                  <TextStory
                    refresh={() => {
                      getReceieved();
                    }}
                    clicked={() => {
                      setActiveStory(story);
                      setShowModal(!showModal);
                    }}
                    modalFlow={true}
                    type={"received"}
                    story={story}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      {view === "sent" && (
        <div className={`stories ${stories.length === 0 ? "blank" : ""}`}>
          {stories.length === 0 && (
            <div className="none">
              <p>
                <span>😩</span>
                Oh boy, this is embarassing.
                <br /> <br />
                It can feel a little daunting to record yourself or write
                something vulnerable or personal to anyone. You're not alone.
                But rewards from doing it are worth the awkwardness, trust us{" "}
                <br /> <br />
                <b>So start creating and sending stories!</b> There's nothing to
                lose and so much to gain
              </p>
            </div>
          )}
          {stories.length === 0 && (
            <svg
              className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="172"
              height="194"
              viewBox="0 0 172 194"
              fill="none"
            >
              <path
                d="M12.4921 3.34589C-24.5079 104.346 55.5216 95.1474 100.492 104.346C144.492 113.346 150.612 149.148 155.383 187.318C157.24 202.172 122.613 167.974 115.3 157.51C114.067 156.276 130.524 165.846 133.629 167.974C147.113 177.213 155.113 193.154 156.113 189.154C160.724 170.707 165.613 150.154 168.131 141.971"
                stroke="black"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </svg>
          )}

          {stories.map((story) => {
            return (
              <div className="story" key={story.id}>
                {story.type === "video" && (
                  <Video
                    refresh={() => {
                      getSent();
                    }}
                    clicked={() => {
                      setActiveStory(story);
                      setShowModal(!showModal);
                    }}
                    modalFlow={true}
                    type={"sent"}
                    story={story}
                  />
                )}
                {story.type === "text" && (
                  <TextStory
                    refresh={() => {
                      getSent();
                    }}
                    clicked={() => {
                      setActiveStory(story);
                      setShowModal(!showModal);
                    }}
                    modalFlow={true}
                    type={"sent"}
                    story={story}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      {showModal && (
        <StoryModal
          me={me}
          story={activeStory}
          clicked={() => {
            setShowModal(!showModal);
          }}
        />
      )}
      {showWelcomeModal && (
        <StoryboardWelcomeModal
          clicked={() => {
            setShowWelcomeModal(false);
            localStorage.setItem("hasSeenStoryboard", true);
          }}
        />
      )}
    </div>
  );
};
