import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/index.scss";
import { RoleModelOnboarding } from "./components/RoleModelOnboarding";
import { ChooseRoleModels } from "./components/ChooseRoleModels";

export const RoleModels = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const isEditing = props.location?.state?.editing || false;

  useEffect(() => {
    getMe();

    if(isEditing) {
      setStep(1);
      props.location.state.editing = false;
    }
    getRoleModels();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getRoleModels = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const data = response.data;

    // doesItExist(data);
  };

  // const doesItExist = (data) => {
  //   if(data.career_supporter || data.industry_expert || data.mentor || data.peer_advisor || data.sponsor) {
  //     history.push("/board-of-advisors/dashboard");
  //   }
  // };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
    setLoading(false);
  };

  if (loading) {
    return <div></div>;
  }
  if (step === 0) {
    return (
      <RoleModelOnboarding
        setStep={(step) => {
          setStep(step);
        }}
      />
    );
  }
  if (step === 1) {
    return (
      <ChooseRoleModels
        setStep={(step) => {
          setStep(step);
        }}
        me={me}
      />
    );
  }
};
