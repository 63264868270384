import React from "react";
import ReactBubbleChart from "react-bubble-chart";

export default class ZoomableBubbleChart extends React.Component {
  state = {
    key: "start",
    data: this.props.data,
  };

  render() {
    var tooltipProps = [
      {
        css: "symbol",
        prop: "_id",
      },
      {
        css: "value",
        prop: "value",
        display: "",
      },
    ];

    var colorLegend = [
      //reds from dark to light
      "#E3F8E0",
      "#E3F8E0",
      "#C1F4B8",
      "#88D47B",
      {
        color: "#2B9D19",
        textColor: "#fff",
      },
      {
        color: "#105C04",
        textColor: "#fff",
      },
    ];

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <ReactBubbleChart
          className="chart__bubble"
          colorLegend={colorLegend}
          data={this.props.data}
          selectedColor="red"
          selectedTextColor="green"
          fixedDomain={{ min: 0, max: 4 }}
          fontSizeFactor={0.5}
          legend={false}
          legendSpacing={0}
          tooltip={false}
          tooltipProps={tooltipProps}
        />
      </div>
    );
  }

  handleClick = (data) => {
    const isGroup = data.children !== undefined;

    this.setState(() => ({
      key: isGroup ? data.children._id : "updated",
      data: isGroup ? data.children : this.props.data,
    }));
  };
}
