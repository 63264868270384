import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/pages/Lifeline/moment.scss";
import { useEnv } from "../../context/env.context";
import { useHistory } from "react-router-dom";
import dater from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLightbulb,
  faRotateLeft,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";

export const Moment = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [moment, setMoment] = useState({});
  const history = useHistory();
  const [array, setArray] = useState(new Array(100).fill(0));
  const [currentInsight, setCurrentInsight] = useState({
    description: null,
  });

  useEffect(() => {
    getMoment();
    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${apiServerUrl}/api/insights`,
      {
        momentId: moment.id,
        description: currentInsight.description,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      getMoment();
      Analytics("Lifeline insight added");
      setCurrentInsight({ description: null });
    }
  };

  const getMoment = async () => {
    const token = await getAccessTokenSilently();
    const momentId = history.location.pathname.split("/")[3];
    const response = await axios.get(
      `${apiServerUrl}/api/moments/${momentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMoment(response.data);
  };

  const convertToEmoji = (score) => {
    if (score > 80) {
      return "🤩";
    } else if (score > 60) {
      return "😃";
    } else if (score > 40) {
      return "🙂";
    } else if (score > 20) {
      return "😐";
    } else {
      return "😕";
    }
  };

  return (
    <div className="lifelineMoment">
      <div className="fullSlide">
        <div className="container">
          <button
            className="backButton btn primary"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faRotateLeft} />
            Back
          </button>
        </div>
        <div className="content">
          <div className={`${moment.type} emojiBox`}>
            {array.map((item, index) => {
              return (
                <div className="emoji">{convertToEmoji(moment.score)}</div>
              );
            })}
          </div>

          <h2>
            <span className={`${moment.type} highlight`}>{moment.title}</span>
          </h2>
          <p className="bodyText">{moment.description}</p>
          <p className="year">{dater(moment.date).format("MMMM YYYY")}</p>

          <div className="actions">
            <button
              className="btn primary blank"
              onClick={() => history.push(`/lifeline/moment/${moment.id}/edit`)}
            >
              <FontAwesomeIcon icon={faEdit} />
              Edit Moment
            </button>
            <button className="btn primary">
              Share
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          </div>

          <div className="border"></div>

          <h2>
            <span className="underline yellow">Insights</span>
          </h2>

          {moment.insights && moment.insights.length > 0 ? (
            <div className="insights">
              {moment.insights.map((insight) => {
                return (
                  <div className="insight">
                    <div className="svg">
                      <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                    <div className="text">
                      <p className="bodyText">{insight.description}</p>
                      <p className="date">
                        {dater(insight.createdAt).format(
                          "MMMM DD, YYYY @ hh:MM a"
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              style={{
                width: "100%",
              }}
              className="bodyText"
            >
              No insights yet
            </p>
          )}

          <div className="border"></div>
          <h2>
            <span className="underline yellow">Add an insight</span>
          </h2>
          <div className="form">
            <label className="label">
              Is there anything you notice about this moment that stands out?
            </label>
            <textarea
              value={currentInsight.description}
              onChange={(e) => {
                const newInsight = { ...currentInsight };
                newInsight.description = e.target.value;
                setCurrentInsight(newInsight);
              }}
              placeholder="e.g. This was the first job out of college that taught me better what I would not want in my career in the future."
            />
            <button
              className="btn primary"
              onClick={() => {
                saveInsight();
              }}
            >
              Add Insight
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
