import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../context/env.context";
import axios from "axios";
import "../styles/pages/waitlist.scss"
import { LinkedinShareButton } from 'react-share';
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom";
import { Analytics } from "../hooks/analytics";

export const Waitlist = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = React.useState();
  const history = useHistory();


  useEffect(() => {
    getMe();
  }, []);

 

  const getMe = async () =>{
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if(!response.data.waitlist) {
      history.push("/dashboard");
    }

    Analytics("Entered Waitlist");
    setMe(response.data);
  }

  if(!me) {
    return (
        <div>Loading...</div>
    )
  }

  return (
    <div className="waitlist">

       <h3>You're officially on the waitlist, {me.first_name}! 🎉</h3>
       <p>We're onboarding new Storyboard users every day and will be in touch when we're ready for you!</p>
       <p className="skip">Want to skip the line?</p>
       <LinkedinShareButton 
            url={`https://storyboard.joinacrew.com`}
            title="THIS NEEDS REPLACED"
        >
                   <button> Help spread the love!</button>

        </LinkedinShareButton>
    </div>
  );
};
