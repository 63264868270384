import React from "react";
export const CrewImage = (props) => {
  if(!props.me?.avatar) {
    return (
      <img
        onClick={() => props.clicked()}
        src={`https://placehold.co/100x100?text=${
          props.me?.first_name.charAt(0) + props.me?.last_name.charAt(0)
        }`}
        alt="avatar"
      />
    );
  }
  return (
    <img
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = `https://placehold.co/100x100?text=${
          props.me?.first_name.charAt(0) + props.me?.last_name.charAt(0)
        }`;
      }}
      onClick={() => props.clicked()}
      src={props.me?.avatar}
      alt="avatar"
    />
  );
};
