import { useEffect, useState } from "react";
import "./styles/ContextMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faLink, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ContextMenu = ({ setSize, size, link }) => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();

  useEffect(() => {}, [size]);
  return (
    <div className="contextMenu">
      <div className="icon">
        <FontAwesomeIcon
          icon={faEllipsis}
          onClick={() => {
            setExpand(!expand);
          }}
        />
      </div>
      {expand && (
        <div className="menu">
          {size === 2 && (
            <div
              className="nav"
              onClick={() => {
                setSize(1);
                setExpand(false);
              }}
            >
              <FontAwesomeIcon icon={faMinus} /> Minimize
            </div>
          )}
          {size === 1 && (
            <div
              className="nav"
              onClick={() => {
                setSize(2);
                setExpand(false);
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Expand
            </div>
          )}
          <div
              className="nav"
              onClick={() => {
                history.push(link);
              }}
            >
              <FontAwesomeIcon icon={faLink} /> Visit Tool
            </div>
        </div>
      )}
    </div>
  );
};

export default ContextMenu;
