import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/companies.scss";
import { Sidebar } from "./Sidebar";

export const AdminCompanies = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [companies, setCompanies] = useState([]);
  const [active, setActive] = useState(null);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/companies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCompanies(response.data);
  };

  const uploadAvatar = async (files, key) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/users/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const newActive = { ...active };

    newActive[key] = upload.data.avatar;

    setActive(newActive);
  };

  const saveCompany = async () => {
    const token = await getAccessTokenSilently();
    const companyObject = { ...active };
    let URL = `${apiServerUrl}/api/companies/${active.id}`;
    await axios.patch(URL, companyObject, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    getCompanies();
    setActive(null);
  };

  const newCompany = async () => {
    const token = await getAccessTokenSilently();
    const companyObject = { ...active };
    let URL = `${apiServerUrl}/api/companies`;
    await axios.post(URL, companyObject, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    getCompanies();
    setActive(null);
    setAdding(false);
  };

  return (
    <div className="companiesAdmin">
      <h2>
        <span className="heading1 highlight blue">Admin Companies</span>
      </h2>

      <Sidebar />

      <div className="right white-background">
        <div className="top">
          <h2>Companies</h2>
          <button
            className="btn primary"
            onClick={() => {
              setActive({});
              setAdding(true);
            }}
          >
            Add Company
          </button>
        </div>
        {companies.map((company) => (
          <div className="company">
            <h3>{company.name}</h3>
            <button
              onClick={() => {
                setActive(company);
              }}
              className="btn primary"
            >
              Edit
            </button>
          </div>
        ))}
      </div>

      {active && (
        <div className="modal">
          <div className="modal-content">
            <h4>Edit {active.name}</h4>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                saveCompany();
              }}
            >
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <input
                  onChange={(e) => {
                    setActive({ ...active, name: e.target.value });
                  }}
                  type="text"
                  id="name"
                  value={active.name}
                />
              </div>

              <div className="input-group image">
                <label htmlFor="logo">Logo</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "logo");
                  }}
                  type="file"
                  id="logo"
                />
                <img src={active.logo} alt="" />
              </div>

              <div className="input-group image">
                <label htmlFor="banner">Header Image</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "header_image");
                  }}
                  type="file"
                  id="banner"
                />
                <img src={active.header_image} alt="" />
              </div>

              <div className="input-group image">
                <label htmlFor="banner">Marketing Image</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "marketing_image");
                  }}
                  type="file"
                  id="banner"
                />
                <img src={active.marketing_image} alt="" />
              </div>

              <button type="submit" className="btn primary">
                Save
              </button>
            </form>
          </div>
          <div
            className="skrim"
            onClick={() => {
              setActive(null);
            }}
          ></div>
        </div>
      )}

      {adding && (
        <div className="modal">
          <div className="modal-content">
            <h4>Add Company</h4>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                newCompany();
              }}
            >
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <input
                  onChange={(e) => {
                    setActive({ ...active, name: e.target.value });
                  }}
                  type="text"
                  id="name"
                  value={active.name}
                />
              </div>

              <div className="input-group image">
                <label htmlFor="logo">Logo</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "logo");
                  }}
                  type="file"
                  id="logo"
                />
                <img src={active.logo} alt="" />
              </div>

              <div className="input-group image">
                <label htmlFor="banner">Header Image</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "header_image");
                  }}
                  type="file"
                  id="banner"
                />
                <img src={active.header_image} alt="" />
              </div>

              <div className="input-group image">
                <label htmlFor="banner">Marketing Image</label>
                <input
                  onChange={(e) => {
                    uploadAvatar(e.target.files, "marketing_image");
                  }}
                  type="file"
                  id="banner"
                />
                <img src={active.marketing_image} alt="" />
              </div>

              <button type="submit" className="btn primary">
                Save
              </button>
            </form>
          </div>
          <div
            className="skrim"
            onClick={() => {
              setActive(null);
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
