import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../styles/pages/Admin/coupons.scss";
import { isAdmin } from "../../hooks/isAdmin";
import { Sidebar } from "./Sidebar";

export const AdminCouponsCreate = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [coupon, setCoupon] = useState({uses:0});
  const [me, setMe] = useState({
    id: null,
  });

  useEffect(() => {
    getMe();
  }, []);

  const createCoupon = async () => {
    const token = await getAccessTokenSilently();

    const config = {
        url: `${apiServerUrl}/api/coupons`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
    };
    config.headers = {
        Authorization: `Bearer ${token}`,
    };
    config.data = {
        ...coupon,
    };
    const response = await axios(config);

    history.push("/admin/coupons");
    };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  return (
    <div className="couponsAdmin">
      <h2 className="heading1 highlight blue">Create Coupon</h2>

      <Sidebar />

      <div className="right white-background add">
        <div className="input">
          <label>Coupon Code</label>
          <select
            onChange={(e) => {
              setCoupon({
                ...coupon,
                type: e.target.value,
              });
            }}
          >
            <option disabled selected>
              Choose One
            </option>
            <option value="PERCENT">Percent</option>
            <option value="AMOUNT">Amount</option>
            <option value="FREE">Free</option>
          </select>
        </div>
        <div className="input">
          <label>Coupon Code</label>
          <input
            onChange={(e) => {
              setCoupon({
                ...coupon,
                code: e.target.value,
              });
            }}
            type="text"
            placeholder="Coupon Code"
          />
        </div>
        <div className="input">
          <label>Amount (in % or $)</label>
          <input
            onChange={(e) => {
              setCoupon({
                ...coupon,
                amount: e.target.value,
              });
            }}
            type="number"
            placeholder="20"
          />
        </div>

        <button
          className="btn primary"
          onClick={async () => {
            createCoupon();
          }}
        >
          Create Coupon
        </button>
      </div>
    </div>
  );
};
