import React, { useEffect, useState } from "react";
import "../../styles/pages/rosesandthorns.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import moment from "moment";
import { Past } from "./components/Past";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarAlt,
  faCalendarWeek,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
export const RosesAndThorns = () => {
  const [memories, setMemories] = useState({
    roses: [],
    thorns: [],
  });
  const [past, setPast] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [showLifeLine, setShowLifeLine] = useState(false);

  const [rose, setRose] = useState();
  const [thorn, setThorn] = useState();
  const [lifelineAdd, setLifelineAdd] = useState();
  const [item, setItem] = useState({});

  const today = moment();
  const from_date = today.startOf("week").format("MM/DD");
  const to_date = today.endOf("week").format("MM/DD");

  useEffect(() => {
    getCurrent();
    getPast();
  }, []);

  const addToLifeline = async (memory) => {
    setShowLifeLine(!showLifeLine);

    setLifelineAdd(memory);
  };

  const deleteMemory = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/memories/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    getCurrent();
  };

  const saveToDatabase = async (type) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/moments`,
      method: "POST",
      data: {
        score: item.score,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        description: lifelineAdd.text,
        type: item.type,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setShowLifeLine(false);
  };

  const addMemory = async (memory) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/memories`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    if (memory === "rose") {
      config.data = {
        text: rose.text,
        type: "rose",
      };
    } else {
      config.data = {
        text: thorn.text,
        type: "thorn",
      };
    }
    const response = await axios(config);
    getCurrent();
  };

  const getCurrent = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/memories/current`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data) {
      // Lets order them
      const reducedMemories = response.data?.reduce(
        (acc, moment) => {
          if (moment.type === "rose") {
            acc.roses.push(moment);
          } else {
            acc.thorns.push(moment);
          }
          return acc;
        },
        {
          roses: [],
          thorns: [],
        }
      );

      setMemories(reducedMemories);
    }
  };

  const getPast = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/memories/past`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setPast(response.data);
  };

  return (
    <div className="roses_and_thorns">
      <div className="white-background">
        <h2 className="underline blue title">Current Week</h2>
        {memories.roses.length === 0 && memories.thorns.length === 0 && (
          <div className="intro">
            <h3 className="">
              You haven't started your 🌹 Roses and 🌵 Thorns for the week of{" "}
              {from_date} yet, let's add some!
            </h3>
          </div>
        )}

        <div className="section">
          <h3 className="underline">🌹 Roses</h3>
          <div className="memories">
            {memories?.roses?.map((memory) => (
              <div className="memory">
                <div className="memory__text">🌹 {memory.text}</div>
                <div>
                  <span
                    onClick={() => {
                      addToLifeline(memory);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span
                    onClick={() => {
                      deleteMemory(memory.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />{" "}
                  </span>
                </div>
              </div>
            ))}

            <div className="form">
              <textarea
                className="form__input"
                value={rose?.text}
                onChange={(e) => {
                  setRose({
                    text: e.target.value,
                    type: "rose",
                  });
                }}
                placeholder="What went well this week?"
              />
              <button
                onClick={() => {
                  addMemory("rose");
                  setRose({
                    text: "",
                  });
                }}
                className="btn primary"
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="section">
          <h3 className="underline">🌵 Thorns</h3>
          <div className="memories">
            {memories?.thorns?.map((memory) => (
              <div className="memory">
                <div className="memory__text">🌵 {memory.text}</div>
                <span
                  onClick={() => {
                    deleteMemory(memory.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />{" "}
                </span>
              </div>
            ))}

            <div className="form">
              <textarea
                className="form__input"
                value={thorn?.text}
                onChange={(e) => {
                  setThorn({
                    text: e.target.value,
                    type: "thorn",
                  });
                }}
                placeholder="What didn't go well this week?"
              />
              <button
                onClick={() => {
                  addMemory("thorn");
                  setThorn({
                    text: "",
                  });
                }}
                className="btn primary"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "60px",
        }}
        className="white-background"
      >
        <h2 className="underline blue title">Past Weeks</h2>
        {!past ||
          (past.length === 0 && (
            <div className="intro">
              <h3 className="">
                You don't have any past roses and thorns yet!
              </h3>
            </div>
          ))}

        {past &&
          past.map((past) => {
            return <Past past={past} />;
          })}
      </div>

      {showLifeLine && (
        <div className="lifeline">
          <div className="lifeline__content">
            <h2 className="underline blue title">Add Memory to Lifeline</h2>
            <label>
              From a scale of 0(low) to a high(100) how do you rate this moment?
            </label>
            <input
              onChange={(e) => {
                const newMoment = { ...item };
                newMoment.score = e.target.value;
                setItem(newMoment);
              }}
              type="number"
              max={100}
              value={moment.score}
              min={0}
              placeholder="50"
            />

            <label>Was this a professional or personal moment?</label>
            <select
              onChange={(e) => {
                const newMoment = { ...item };
                newMoment.type = e.target.value;
                setItem(newMoment);
              }}
              value={moment.type}
            >
              <option value="professional">Professional</option>
              <option value="personal">Personal</option>
            </select>

            <button
              onClick={() => {
                saveToDatabase();
              }}
              className="btn primary"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
