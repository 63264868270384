import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/dashboard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faPencil,
  faPlus,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Rolemodel } from "./components/Rolemodel";

export const RoleModelsDashboard = ({ location, me }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [insights, setInsights] = useState([]);
  const [roleModels, setRoleModels] = useState({});
  const [addingInsight, setAddingInsight] = useState(false);
  const [insightText, setInsightText] = useState("");
  const [active, setActive] = useState({});
  const [values, setValues] = useState([]);
  const [presenterView, setPresenterView] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getRoleModels();
    getInsights();
    getValues();
  }, []);

  const getValues = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model/values/all`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValues(response.data);
    setActive(response.data[0]);
  };

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "roleModels1",
        toolId: 9,
        question:
          question ||
          `What's clearer to you now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("Role Models - Added Insight");
    setAddingInsight(false);
    setInsightText("");
    getInsights();
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/roleModels`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setInsights(response.data);
  };

  const getRoleModels = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setRoleModels(response.data);
  };

  return (
    <div className="roleModelsDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Role Models</h2>
        </div>

        <div className="right">
          <button
            onClick={() => {
              history.push("/program");
            }}
            className="btn secondary"
          >
            Back to Program
          </button>
        </div>
      </div>

      <div className="white-background visions">
        <div className="top">
          <h3>Your Role Models</h3>
          <div className="buttonContainer">
            <button
              onClick={() => {
                setPresenterView(true);
              }}
              className="btn primary"
            >
              Presenter View
            </button>
            <button
              className="btn empty"
              onClick={() => {
                history.push("/role-models", {
                  editing: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faShare} /> Edit My Role Models
            </button>
          </div>
        </div>

        <div className="inSession steps">
          <div className="top">
            <h5>Next Steps</h5>
            <span>During Session</span>
          </div>
          <p>
            Congrats! You have successfully added your role models. During the session you will identify the reasons why you admire them.
          </p>
        </div>

        <div className="roleModels">
          {roleModels && roleModels.length > 0 && (
            <div className="roleModels">
              {roleModels.map((roleModel) => (
                <Rolemodel
                  getRoleModels={getRoleModels}
                  roleModel={roleModel}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you now that you've completed this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>

      <div className="white-background">
        <div className="top">
          <h3>My Values</h3>
          {values && values.length > 0 && (
            <button
              className="btn empty"
              onClick={() => {
                history.push("/role-models/values");
              }}
            >
              <FontAwesomeIcon icon={faPencil} /> Edit My Values
            </button>
          )}
        </div>

        {(!values || values.length === 0) > 0 && (
          <div className="inSession steps">
            <div className="top">
              <h5>Next Steps</h5>
              <span>After Session</span>
            </div>
            <p>
              You'll be identifying the values that your role models embody.
              Follow the below steps to complete the activity.
            </p>
            <button
              className="btn primary empty"
              onClick={() => history.push("/role-models/values")}
            >
              Start Values
            </button>
          </div>
        )}

        {values && values.length > 0 && (
          <div className="content valuesContent">
            <p
              style={{
                width: "100%",
              }}
            >
              Did you identify any new values as a result of this activity? If
              so, add them to your existing values below.
            </p>
            <div className="left">
              {values.map((value) => (
                <div
                  onClick={() => {
                    setActive(value);
                  }}
                  className={`value ${active.id === value.id ? "active" : ""}`}
                >
                  <div className="value-name">{value.text}</div>
                </div>
              ))}
            </div>
            <div className="right">
              <div className="header">
                <h3>{active?.text}</h3>
                <p>{active?.summary}</p>
              </div>
              <ResponsiveMasonry
                columnsCount={3}
                columnsCountBreakPoints={{
                  350: 1,
                  750: 2,
                  900: 3,
                  1200: 3,
                  1400: 4,
                }}
                className="masonry-grid"
              >
                <Masonry gutter={15}>
                  {active?.roleModel &&
                    active?.roleModel?.map((highlight) => (
                      <div key={highlight.id} className="single-highlight">
                        <div className="photo">
                          <img src={highlight.image} alt="value" />
                        </div>
                      </div>
                    ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        )}
      </div>
      {presenterView && (
        <div className="presenterView">
          <div className="container">
            <div className="header">
              <h3>Role Models</h3>
              <button
                className="btn empty"
                onClick={() => {
                  setPresenterView(false);
                }}
              >
                Exit Presenter View
              </button>
            </div>
            <div className="valueImageContainer roleModels">
              {roleModels.map((roleModel) => (
                <Rolemodel
                  getRoleModels={getRoleModels}
                  roleModel={roleModel}
                  presenterView={true}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
