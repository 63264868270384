import { useAuth0 } from "@auth0/auth0-react";
import {
  faArrowRight,
  faStar,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import "../../../styles/pages/highlightReel/components/link.scss";

export const Highlight = (props) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [highlight, setHighlight] = React.useState(props.highlight);
  const [hiding, setHiding] = React.useState(false);

  useEffect(() => {}, []);

  const hide = async () => {
    setHiding(true);
    const token = await getAccessTokenSilently();
    const response = await axios.patch(
      `${apiServerUrl}/api/highlights/${props.highlight.id}/hide`,
      {
        hide: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setHighlight(response.data);
    setTimeout(() => {
      props.getHighlightReel();
      setHiding(false);
    }, 3000);
  };

  const makeFeatured = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.patch(
      `${apiServerUrl}/api/highlights/${props.highlight.id}/featured`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setHighlight(response.data);
    props.getHighlightReel();
  };

  return (
    <div className="highlight_moment link">
      {!hiding && (
        <div
          onClick={() => {
            hide();
          }}
          className="fill hide"
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      {hiding && (
        <div
          onClick={() => {
            hide();
          }}
          className="fill hide"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      )}
      {highlight.featured && (
        <div
          onClick={() => {
            makeFeatured();
          }}
          className="fill highlight_moment_featured"
        >
          <FontAwesomeIcon icon={faStar} />
        </div>
      )}
      {!highlight.featured && (
        <div
          onClick={() => {
            makeFeatured();
          }}
          className="empty highlight_moment_featured"
        >
          <FontAwesomeIcon icon={faStar} />
        </div>
      )}
      {highlight.photo && <img alt="highlight" src={highlight.photo} />}
      {!highlight.photo && <div className="blank"></div>}

      <h2 className="highlight yellow">
        <span>{highlight.title}</span>
      </h2>
      <p className="highlight_moment_text">{highlight.text}</p>

      <button
        className="btn secondary"
        onClick={() => {
          props.setActiveHighlight(highlight);
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};
