import React, { useEffect, useState } from "react";
import "./styles/BoardOfAdvisors.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/icon.svg";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import ContextMenu from "./ContextMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mentor from "../../CareerCollaborators/images/Mentor.png";
import sponsor from "../../CareerCollaborators/images/Sponsor.png";
import peerAdvisor from "../../CareerCollaborators/images/PeerAdvisor.png";
import careerSupporter from "../../CareerCollaborators/images/CareerSupporter.png";
import industryExpert from "../../CareerCollaborators/images/IndustryExpert.png";
import skillsAdvisor from "../../CareerCollaborators/images/SkillsAdvisor.png";

import mentorFilled from "../../CareerCollaborators/images/filled/Mentor.png";
import sponsorFilled from "../../CareerCollaborators/images/filled/Sponsor.png";
import peerAdvisorFilled from "../../CareerCollaborators/images/filled/PeerAdvisor.png";
import careerSupporterFilled from "../../CareerCollaborators/images/filled/CareerSupporter.png";
import industryExpertFilled from "../../CareerCollaborators/images/filled/IndustryExpert.png";
import skillsAdvisorFilled from "../../CareerCollaborators/images/filled/SkillsAdvisor.png";

export const BoardOfAdvisorsWidget = ({ id, size, location, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [collaborators, setCollaborators] = useState([]);
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [page, setPage] = useState(0);

  const [adjustedSize, setAdjustedSize] = useState(size);

  useEffect(() => {
    getCareerCollaborators(id);
  }, [adjustedSize]);

  const getCareerCollaborators = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setCollaborators(response.data);
  };

  if (!collaborators || collaborators.length === 0) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Board of Advisors</h3>
        {!programUser && (
          <button
            className="btn"
            onClick={() => {
              history.push("/board-of-advisors");
            }}
          >
            Go to Board of Advisors
          </button>
        )}
      </div>
    );
  }

  // Size 1 widget
  if (adjustedSize === 1) {
    return (
      <div className={`Widget Size-${adjustedSize} BoardOfAdvisors`}>
        <h2>Advisors</h2>
        <ContextMenu
          link="/board-of-advisors"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className="types">
          <div className="slides">
            {page === 0 && (
              <div
                style={{
                  backgroundColor:'#0B455F'
                }}
                className={`box white`}
              >
                <div className="top">
                  <img src={mentorFilled} alt="" />
                  <h3>Mentor</h3>
                </div>

                <p>
                  Trusted advisor who provides guidance and support based on
                  their own journey
                </p>

                {!collaborators?.mentor && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.mentor?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}

            {page === 1 && (
              <div
                className={`box`}
                style={{
                  backgroundColor: "#2A7394",
                }}
              >
                <div className="top">
                  <img src={sponsorFilled} alt="" />
                  <h3>Sponsor</h3>
                </div>

                <p>
                  Senior leader who uses their influence to advocate for you
                </p>

                {!collaborators?.sponsor && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.sponsor?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}

            {page === 2 && (
              <div
                className={`box`}
                style={{
                  backgroundColor: "#E26C29",
                }}
              >
                <div className="top">
                  <img src={peerAdvisorFilled} alt="" />
                  <h3>Peer Advisor</h3>
                </div>

                <p>
                  Colleague or friend at a similar career stage who can serve as
                  a thought partner
                </p>

                {!collaborators?.peer_advisor && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.peer_advisor?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}

            {page === 3 && (
              <div
                className={`box`}
                style={{
                  backgroundColor: "#E1C7BE",
                }}
              >
                <div className="top">
                  <img src={careerSupporter} alt="" />
                  <h3>Career Supporter</h3>
                </div>

                <p>
                  Someone who provides encouragement and motivation you pursue
                  your career goals
                </p>

                {!collaborators?.career_supporter && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.career_supporter?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}

            {page === 4 && (
              <div
                className={`box`}
                style={{
                  backgroundColor: "#FF9E67",
                }}
              >
                <div className="top">
                  <img src={industryExpertFilled} alt="" />
                  <h3>Industry Expert</h3>
                </div>

                <p>
                  Industry or functional expert who can help you develop
                  necessary knowledge
                </p>

                {!collaborators?.industry_expert && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.industry_expert?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}

            {page === 5 && (
              <div
                className={`box`}
                style={{
                  backgroundColor: "#FEDE66",
                }}
              >
                <div className="top">
                  <img src={skillsAdvisor} alt="" />
                  <h3>Skills Advisor</h3>
                </div>

                <p>
                  Expert in a specific skill area who can provide guidance on
                  how to develop and refine those skills
                </p>

                {!collaborators?.skills_advisor && (
                  <div className="empty">
                    <h4>No collaborators here (yet!)</h4>
                  </div>
                )}

                <div className="names">
                  {collaborators?.skills_advisor?.map((mentor) => (
                    <div className="person">{mentor.name}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <button
          className="empty"
          onClick={() => {
            history.push("/board-of-advisors", {
              add: true,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Review Advisors{" "}
        </button>

        <div className="navigation">
          {[1, 1, 1, 1, 1, 1].map((highlight, index) => (
            <button
              key={highlight.id}
              className={page === index ? "active" : ""}
              onClick={() => setPage(index)}
            ></button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`Widget Size-${adjustedSize} BoardOfAdvisors`}>
      <h2>Board of Advisors</h2>
      <ContextMenu
        link="/board-of-advisors"
        size={adjustedSize}
        setSize={(size) => {
          setAdjustedSize(size);
        }}
      />

      <div className="types">
        <div className="slides">
          <div
            style={{
              backgroundColor: '#0B455F'
            }}
            className={`box white`}
          >
            <div className="top">
              {!collaborators.mentor ? (
                <img src={mentor} alt="" />
              ) : (
                <img src={mentorFilled} alt="" />
              )}
              <h3>Mentor</h3>
            </div>

            <p>
              Trusted advisor who provides guidance and support based on their
              own journey
            </p>

            {!collaborators?.mentor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.mentor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor: "#2A7394",
            }}
          >
            <div className="top">
              {!collaborators.sponsor ? (
                <img src={sponsor} alt="" />
              ) : (
                <img src={sponsorFilled} alt="" />
              )}
              <h3>Sponsor</h3>
            </div>

            <p>Senior leader who uses their influence to advocate for you</p>

            {!collaborators?.sponsor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.sponsor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor: "#E26C29",
            }}
          >
            <div className="top">
              <img src={peerAdvisorFilled} alt="" />
              <h3>Peer Advisor</h3>
            </div>

            <p>
              Colleague or friend at a similar career stage who can serve as a
              thought partner
            </p>

            {!collaborators?.peer_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.peer_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor: "#E1C7BE",
            }}
          >
            <div className="top">
              <img src={careerSupporter} alt="" />
              <h3>Career Supporter</h3>
            </div>

            <p>
              Someone who provides encouragement and motivation you pursue your
              career goals
            </p>

            {!collaborators?.career_supporter && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.career_supporter?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor: "#FF9E67",
            }}
          >
            <div className="top">
              <img src={industryExpert} alt="" />
              <h3>Industry Expert</h3>
            </div>

            <p>
              Industry or functional expert who can help you develop necessary
              knowledge
            </p>

            {!collaborators?.industry_expert && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.industry_expert?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor: "#FEDE66",
            }}
          >
            <div className="top">
              <img src={skillsAdvisor} alt="" />
              <h3>Skills Advisor</h3>
            </div>

            <p>
              Expert in a specific skill area who can provide guidance on how to
              develop and refine those skills
            </p>

            {!collaborators?.skills_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.skills_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>
        </div>
        <button
          className="empty"
          onClick={() => {
            history.push("/board-of-advisors", {
              add: true,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Review Advisors{" "}
        </button>
      </div>
    </div>
  );
};
