import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import "../../../styles/components/Chat/addons/highlight.scss";

export const Final = ({ chat, continueLoop, divRef }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  const onboardingComplete = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me/onboarding`,
      method: "PATCH",
      data: {
        finishedOnboarding: true,
      },
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);

    window.Intercom("update", {
      finishedOnboarding: true,
    });

    Analytics("Finished Onboarding");

    return response;
  };

  return (
    <div id="chatMessage" className="chatMessage addon-strengths addon">
      <div className="chatMessageBox">
      <img
          alt="avatar"
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
        <h4>Roxanne Burbach {chat.app}</h4>
          <div className={`bubble ${!loading ? 'yellow' : ''}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Ready to continue the journey?</h3>
                  <span>Take me to my Home experience!</span>
                </div>
                <div className="right">
                  <button
                    onClick={async () => {
                      await onboardingComplete();
                      window.location.href = "/dashboard";
                    }}
                    className="btn secondary"
                  >
                    Lets Begin
                  </button>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
