import React, { useCallback, useEffect, useState } from "react";
import "../../styles/pages/highlightReel/add.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faChevronDown,
  faChevronUp,
  faLink,
  faLock,
  faTimes,
  faTrash,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";

export const HighlightReelAdd = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [me, setMe] = useState(null);
  const { apiServerUrl } = useEnv();
  const [highlight, setHighlight] = useState({
    featured: false,
  });
  const history = useHistory();
  const [collaborators, setCollaborators] = useState([]);
  const [addingCollaborator, setAddingCollaborator] = useState(false);
  const [collabError, setCollabError] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState({
    message: `Hi, I just added you as a collaborator on my new highlight! I just wanted to let you know that I really appreciate your help and support. I hope you enjoy the highlight!`,
  });
  const [photo, setPhoto] = useState(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("me"));
    setMe(me);
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const checkValidation = () => {
    if (highlight.title && highlight.text) {
      return false;
    } else {
      return true;
    }
  };

  const submitNewHighlight = async () => {
    const token = await getAccessTokenSilently();

    const newHighlight = {
      ...highlight,
      collaborators: collaborators,
      photo: photo || null,
    };

    await axios.post(`${apiServerUrl}/api/highlights`, newHighlight, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    Analytics("Created Highlight");
    history.push(`/highlights`, {
      created: true,
    });
  };

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/highlights/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    setPhoto(upload.data.avatar);
  };

  return (
    <div className="highlightReel-add">
      <div className="white-background">
        <div className="form">
          {step === 1 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    props.close();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Your Highlight</h2>
              </div>
              <div className="form-group">
                <label htmlFor="type">
                  Title <span>Required</span>
                </label>
                <input
                  placeholder="E.g Received great feedback on Project X"
                  type="text"
                  onChange={(event) => {
                    setHighlight({ ...highlight, title: event.target.value });
                  }}
                  value={highlight.title}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Description <span>Required</span>
                </label>
                <textarea
                  placeholder="What made this a highlight? How did it make you feel?"
                  type="text"
                  onChange={(event) => {
                    setHighlight({ ...highlight, text: event.target.value });
                  }}
                  value={highlight.text}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Relevant Link <span>Optional</span>
                </label>
                <input
                  placeholder="Add any relevant urls"
                  type="text"
                  onChange={(event) => {
                    setHighlight({ ...highlight, link: event.target.value });
                  }}
                  value={highlight.link}
                  name="type"
                  id="type"
                />
              </div>
              <div className="photoBox">
                <h4>Make it visual</h4>
                <div className="inputPhotoBox">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      uploadAvatar(event.target.files);
                    }}
                  />
                  <div className="icon">
                    {!photo && <FontAwesomeIcon icon={faCamera} />}
                    {photo && <img src={photo} alt="" />}
                  </div>
                  <h5>Add Cover Photo</h5>
                  <span>Optional</span>
                </div>
              </div>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    props.close();
                  }}
                >
                  Back
                </button>
                <button
                  className="btn primary"
                  disabled={checkValidation()}
                  type="button"
                  onClick={async () => {
                    setStep(2);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    props.close();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Collaborators</h2>
              </div>

              <div className="form-group info">
                <h3>Choose A Reel</h3>
                <p>
                  Select or create a Reel for your Highlight. You can use
                  different reels for different purposes.
                </p>
                <div className="reel">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                  <div className="text">
                    <h4>Private Reel</h4>
                    <p>Only you can see this highlight</p>
                  </div>
                  <input
                    type="radio"
                    checked={!highlight.featured}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setHighlight({
                          ...highlight,
                          featured: false,
                        });
                      }
                    }}
                    name="type"
                    id="type"
                  />
                </div>

                <div className="reel">
                  <div className="icon">
                    <FontAwesomeIcon icon={faUnlock} />
                  </div>
                  <div className="text">
                    <h4>Public Reel</h4>
                    <p>Others can see this highlight</p>
                  </div>
                  <input
                    type="radio"
                    checked={highlight.featured}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setHighlight({
                          ...highlight,
                          featured: true,
                        });
                      }
                    }}
                    name="type"
                    id="type"
                  />
                </div>
              </div>

              <div className="collaborators">
                <h5
                  onClick={() => {
                    setAddingCollaborator(!addingCollaborator);
                  }}
                >
                  Tag Collaborators
                  {!addingCollaborator && (
                    <FontAwesomeIcon className="arrow" icon={faChevronDown} />
                  )}
                  {addingCollaborator && (
                    <FontAwesomeIcon className="arrow" icon={faChevronUp} />
                  )}
                  <span>Optional</span>
                </h5>
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "1.5em",
                    marginTop: "10px",
                  }}
                >
                  Collaborators are the people who have contributed to your
                  Highlights. Tag people by first and last name, and you can
                  optionally invite them to your highlight.
                </p>
                {addingCollaborator && (
                  <div className="collaboratorInput">
                    {collabError && <p className="error">{collabError}</p>}
                    <div className="form-group">
                      <label>
                        First Name <span>Required</span>
                      </label>
                      <input
                        placeholder="First Name"
                        type="text"
                        value={newCollaborator.firstName}
                        onChange={(event) => {
                          setNewCollaborator({
                            ...newCollaborator,
                            firstName: event.target.value,
                          });
                        }}
                        name="type"
                        id="type"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Last Name <span>Required</span>
                      </label>
                      <input
                        placeholder="Last Name"
                        type="text"
                        value={newCollaborator.lastName}
                        onChange={(event) => {
                          setNewCollaborator({
                            ...newCollaborator,
                            lastName: event.target.value,
                          });
                        }}
                        name="type"
                        id="type"
                      />
                    </div>
                    <div className="form-group full">
                      <label>
                        Do you want to invite them to your highlight?{" "}
                        <span>Optional</span>
                      </label>
                      <input
                        placeholder="Email"
                        type="text"
                        value={newCollaborator.email}
                        onChange={(event) => {
                          setNewCollaborator({
                            ...newCollaborator,
                            email: event.target.value,
                          });
                        }}
                        name="type"
                        id="type"
                      />
                    </div>
                    {newCollaborator.email && (
                      <div className="form-group full">
                        <label>Your Personal Message</label>
                        <textarea
                          onChange={(e) => {
                            setNewCollaborator({
                              ...newCollaborator,
                              message: e.target.value,
                            });
                          }}
                          value={newCollaborator.message}
                        ></textarea>
                      </div>
                    )}
                    <div className="infoSection">
                      <h4>
                        All Set?
                        <span>
                          Don't worry, we won't invite them until you publish
                          your highlight.
                        </span>
                      </h4>
                      <button
                        className="btn secondary"
                        type="button"
                        onClick={async () => {
                          if (
                            !newCollaborator.firstName ||
                            !newCollaborator.lastName
                          ) {
                            setCollabError("Please enter all required fields");
                            return;
                          }
                          setCollabError(false);
                          const newCollaborators = [
                            ...collaborators,
                            newCollaborator,
                          ];
                          setCollaborators(newCollaborators);
                          Analytics("Added Collaborator");
                          setNewCollaborator({
                            firstName: "",
                            lastName: "",
                            email: "",
                          });
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}

                {collaborators.length > 0 && (
                  <h5
                    style={{
                      borderTop: "none",
                    }}
                  >
                    Highlight Collaborators
                  </h5>
                )}

                {collaborators.map((collaborator, index) => {
                  return (
                    <div className="collaborator" key={index}>
                      <img
                        alt="avatar"
                        src={`https://placehold.co/100x100?text=${
                          collaborator.firstName.charAt(0) +
                          collaborator.lastName.charAt(0)
                        }`}
                      />
                      <p>
                        {collaborator.firstName} {collaborator.lastName}
                      </p>
                      <FontAwesomeIcon
                        className="delete"
                        onClick={() => {
                          const newCollaborators = collaborators.filter(
                            (collaborator, i) => i !== index
                          );
                          setCollaborators(newCollaborators);
                        }}
                        icon={faTrash}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    setStep(1);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn primary"
                  disabled={checkValidation()}
                  type="button"
                  onClick={async () => {
                    submitNewHighlight();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          <div className="right">
            <div className="white-background">
              <div className="preview">
                <div className="highlightArea">
                  <div className="highlightMoment">
                    {photo && (
                      <img
                        alt="highlight"
                        src={photo}
                        className="highlightPhoto"
                      />
                    )}
                    {!photo && <div className="noPhoto"></div>}
                    {highlight.title && <h3>{highlight.title}</h3>}
                    {!highlight.title && <h3>Add Title</h3>}

                    {highlight.text && <p>{highlight.text}</p>}
                    {!highlight.text && <p>Add Description</p>}
                    {highlight.link && (
                      <a rel="noreferrer" target="_blank" href={highlight.link}>
                        <FontAwesomeIcon icon={faLink} /> {highlight.link}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
