import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/dashboard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faLink,
  faPencil,
  faPlus,
  faShare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";
import CelebrateBox from "./images/celebrateBox.png";
import CopyToClipboard from "react-copy-to-clipboard";

export const VisionsDashboard = ({ location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [insights, setInsights] = useState([]);
  const [visions, setVisions] = useState({});
  const [me, setMe] = useState({});
  const [programUser, setProgramUser] = useState(false);
  const [addingInsight, setAddingInsight] = useState(false);
  const [insightText, setInsightText] = useState("");
  const [presenterView, setPresenterView] = useState(false);
  const [celebrate, setCelebrate] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [copied, setCopied] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (location.state?.celebrate) {
      setCelebrate(true);
    }
    getMe();
    getVisions();
    getInsights();
  }, []);

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "visions1",
        toolId: 9,
        question:
          question ||
          `What's clearer to you about your one-year vision now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    Analytics("10 Images - Added Insight");

    setAddingInsight(false);
    setInsightText("");

    getInsights();
  };

  const getCategory = (category) => {
    switch (category) {
      case "growth":
        return "PERSONAL GROWTH";
      case "relationships":
        return "RELATIONSHIPS";
      case "impact":
        return "IMPACT";
      default:
        return "PERSONAL GROWTH";
    }
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    // They are a program user.
    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setInsights(response.data);
  };

  const getVisions = async (fromOnboarding) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setVisions(response.data[0]);
  };

  return (
    <div className="visionsDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Visions</h2>
        </div>

        <div className="right">
          {programUser && (
            <button
              onClick={() => {
                history.push("/program");
              }}
              className="btn secondary"
            >
              Back to Program
            </button>
          )}
        </div>
      </div>

      <div className="white-background visions">
        <div className="top">
          <h3>My One-Year Vision</h3>
          <div className="buttonContainer">
            <button
              className="btn empty"
              onClick={() => {
                setPresenterView(true);
              }}
            >
              Presenter View
            </button>
            <button
              className="btn empty"
              onClick={() => {
                setShowShare(true);
              }}
            >
              <FontAwesomeIcon icon={faShare} /> Share my vision
            </button>
          </div>
        </div>

        {programUser && !visions?.cover && (
          <div className="inSession steps">
            <div className="top">
              <h5>Next Steps</h5>
              <span>Complete During Session</span>
            </div>
            <p>
              Together with your crew, you will be coming up with a cover page
              to visualize your vision for the next year.
            </p>
            <button
              onClick={() => {
                history.push("/visions/cover");
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Create Cover Page
            </button>
          </div>
        )}

        {!programUser && !visions?.cover && (
          <div className="inSession steps">
            <div className="top">
              <h5>Next Steps</h5>
              <span>Complete Cover Page</span>
            </div>
            <p>
              Come up with a cover page to visualize your vision for the next
              year.
            </p>
            <button
              onClick={() => {
                history.push("/visions/cover");
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Create Cover Page
            </button>
          </div>
        )}

        <div className="pages">
          {visions?.cover && (
            <div className="coverBox box">
              <button
                onClick={() => {
                  history.push(`/visions/cover/edit/${visions.cover.id}`, {
                    edit: true,
                  });
                }}
                className="empty btn"
              >
                Edit Cover
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <div
                style={{
                  backgroundImage: `url(${visions.cover.image})`,
                }}
                className="inside"
              >
                <div className="content">
                  <span>ONE-YEAR VISION</span>

                  <h2>{visions.cover.title || "Title will appear here"}</h2>
                  <div className="bottom">
                    {me?.first_name && (
                      <p>
                        <b>
                          {me?.first_name} {me?.last_name}
                        </b>
                      </p>
                    )}

                    <p>{visions.cover.bio}</p>
                  </div>
                </div>
                {visions.cover.color && (
                  <div
                    className="overlay"
                    style={{ backgroundColor: visions.cover.color }}
                  ></div>
                )}
              </div>
            </div>
          )}
          {visions &&
            visions.pages &&
            visions?.pages.map((page) => (
              <div className={`box`}>
                <div className={`inside ${page.category}`}>
                  <div className="top">
                    <span>{getCategory(page.category)}</span>
                    <button
                      onClick={() => {
                        history.push("/visions/enter", {
                          page: page,
                          edit: true,
                        });
                      }}
                      className="empty btn"
                    >
                      Edit Page
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  </div>
                  {page.prompt && <p className="promptText">{page.prompt}</p>}
                  <h2>{page.summary || "Summarize in a few words"}</h2>

                  <p>
                    {page.response ||
                      "Your response to the prompt will show up in this space."}
                  </p>

                  {page.image && <img src={page.image} alt="" />}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you about your one-year vision now that you've
              completed this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {celebrate && (
        <div className="modal">
          <div className="modal-content">
            <div className="left">
              <img src={CelebrateBox} alt="" />
            </div>
            <div className="right">
              <h2>Let’s take a moment to celebrate!</h2>
              <p>
                Awesome job on completing the pages of your one-year vision!
                <br />
                <br />
                You can continue to build on this vision by adding more pages as
                you reflect on your personal growth, relationships, and impact.
                <br />
                <br />
                <b>Please wait until your session to design your cover page.</b>
              </p>
              <button
                className="btn secondary"
                onClick={() => {
                  setCelebrate(false);
                }}
              >
                View your One-Year Vision
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setCelebrate(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}

      {showShare && (
        <div className="modal">
          <div className="modal-content shareModal">
            <FontAwesomeIcon
              onClick={() => {
                setShowShare(false);
              }}
              className="close"
              icon={faTimes}
            />
            <h3>Share your One-Year Vision!</h3>
            <p>You can share your vision with your coach or anyone you wish.</p>
            <label>Shareable Link</label>
            <div className="inputBox">
              <FontAwesomeIcon icon={faLink} />
              <input
                disabled
                type="text"
                value={`${window.location.protocol}//${window.location.host}/visions/vision/${visions?.id}`}
              />

              <div className="copy">
                <CopyToClipboard
                  text={`${window.location.protocol}//${window.location.host}/visions/vision/${visions?.id}`}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }}
                >
                  {copied ? <p>Copied</p> : <p>Copy Link</p>}
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setShowShare(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}

      {presenterView && (
        <div className="presenterView">
          <div className="container">
            <div className="header">
              <h3>My One-Year Vision</h3>
              <button
                className="btn empty"
                onClick={() => {
                  setPresenterView(false);
                }}
              >
                Exit Presenter View
              </button>
            </div>
            <div className="content">
              <div className="pages">
                {visions.cover && (
                  <div className="coverBox box">
                    <div
                      style={{
                        backgroundImage: `url(${visions.cover.image})`,
                      }}
                      className="inside"
                    >
                      <div className="content">
                        <span>ONE-YEAR VISION</span>
                        <h2>
                          {visions.cover.title || "Title will appear here"}
                        </h2>
                        <div className="bottom">
                          {me?.first_name && (
                            <p>
                              <b>
                                {me?.first_name} {me?.last_name}
                              </b>
                            </p>
                          )}

                          <p>{visions.cover.bio}</p>
                        </div>
                      </div>
                      {visions.cover.color && (
                        <div
                          className="overlay"
                          style={{ backgroundColor: visions.cover.color }}
                        ></div>
                      )}
                    </div>
                  </div>
                )}
                {visions &&
                  visions.pages &&
                  visions?.pages.map((page) => (
                    <div className={`box`}>
                      <div className={`inside ${page.category}`}>
                        <div className="top">
                          <span>{getCategory(page.category)}</span>
                        </div>

                        <h2>{page.summary || "Summarize in a few words"}</h2>

                        <p>
                          {page.response ||
                            "Your response to the prompt will show up in this space."}
                        </p>

                        {page.image && <img src={page.image} alt="" />}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
