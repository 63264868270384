import React, { useEffect, useState } from "react";
import "../../styles/pages/Company/signup.scss";
import Reveal from "reveal.js";
import bg from "./svgs/bg3.jpg";
import bg2 from "./svgs/bg2.jpg";
import Footer from "./svgs/good_logo.png";
import { useAuth0 } from "@auth0/auth0-react";

export const CompanySignup = () => {
  const [step, setStep] = useState(0);
  const { loginWithRedirect } = useAuth0();
  const [reveal, setReveal] = useState(null);

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    setTimeout(() => {
      const newReveal = new Reveal();
      newReveal.initialize({
        controls: false,
        progress: false,
        history: false,
        keyboard: false,
      });
      newReveal.on("slidechanged", (event) => {
        setStep(event.indexh);
      });

      setReveal(newReveal);

      return () => {
        newReveal.destroy();
      };
    }, 100);
  }, []);

  const nextSlide = () => {
    reveal.next();
  };

  return (
    <div
      style={{
        backgroundImage: step === 1 ? `` : `url(${bg})`,
      }}
      className="CompanySignup"
    >
      <div className="reveal">
        <div className="slides">
          <section className="photo">
            <h2>Welcome to Crew</h2>
            <h3>The Career Design Platform</h3>

            <div className="controlsItem">
              <button
                onClick={() => {
                  nextSlide();
                }}
                className="btn primary"
              >
                Let's begin
              </button>
            </div>
          </section>
          <section className="paragraph">
            <h2 style={{
              marginTop:'80px'
            }}>We're here for you</h2>

            <p>
              Crew is partnering with your company to walk alongside you during your
              first days on the job. We're committed to helping you shape a
              career that reflects what really matters to you.{" "}
            </p>

            <div className="controlsItem">
              <button
                onClick={() => {
                  loginWithRedirect({
                    appState: { returnTo: "/company/onboarding" },
                  });
                }}
                className="btn primary"
              >
                Create my account
              </button>
            </div>

            <div className="bottom">
              <span>Powered By </span>
              <img src={Footer} alt="footer" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
