import React, { useEffect } from "react";
import "../../styles/pages/Programs/dashboard.scss";
import "moment-timezone";
import { Canvas } from "../Canvas";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";

export const DashboardRouter = ({ me, member }) => {
  const history = useHistory();

  useEffect(() => {
    doTheyHaveAccessToThisTool();
  }, []);

  const getFilteredSessions = (sessions) => {
    const now = moment().startOf("day");

    if (!sessions) return [];

    const sortedSessions = sessions.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate));
    });

    return sortedSessions.filter((session) => {
      return moment(session.startDate).isAfter(now);
    });
  };

  const doTheyHaveAccessToThisTool = () => {
    if (member.type === "partial") {
      member.learningActivities.forEach((element) => {
        history.push(element.url);
      });
    }
  };

  return (
    <Canvas
      crew={me?.crew[0]}
      isProgram={getFilteredSessions(me?.crew[0]?.program.sessions).length > 0}
      me={me}
    />
  );
};
