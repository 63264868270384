import React, { useEffect } from "react";
import "../../styles/components/create/NavigationBox.scss";

export const NavigationBox = (props) => {
  useEffect(() => {}, []);

  return (
    <div className="NavigationBox">
      <div className={`${props.step === 1 ? "active" : ""} box`}>1</div>
      <div className={`${props.step === 2 ? "active" : ""} box`}>2</div>
      <div className={`${props.step === 3 ? "active" : ""} box`}>3</div>
    </div>
  );
};
