import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../../styles/pages/Admin/index.scss";
import { Sidebar } from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { isAdmin } from "../../hooks/isAdmin";

export const Admin = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getMe();
    getGroups();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }
  };

  const getGroups = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/groups`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setGroups(response.data);
  };

  return (
    <div className="admin">
      <h2 className="heading1 highlight blue">Groups Admin</h2>

      <Sidebar />

      <div className="right white-background">
        <div className="row">
          <h3 className="heading2 underline yellow">Groups</h3>
          <button
            onClick={() => {
              history.push("/admin/groups/add");
            }}
            className="btn primary"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <div className="crews">
        {groups.length === 0 && (
            <p>No Groups Right Now.</p>
          )}
          {groups.map((group) => (
            <div className="crew" key={group.id}>
              <NavLink to={`/admin/groups/${group.id}/edit`}>
                <h6>{group.name}</h6>
                <div className="info">
                  <FontAwesomeIcon icon={faUsers} /> {group?.users?.length}
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
