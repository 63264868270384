import React, { useEffect, useRef, useState } from "react";
import "./styles/ChooseRoleModels.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPencil,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { ProgramModal } from "./ProgramModal";

export const ChooseRoleModels = ({ setStep }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [roleModels, setRoleModels] = useState([]);
  const [programUser, setProgramUser] = useState(false);
  const [me, setMe] = useState({});
  const [editingRoleModel, setEditingRoleModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newRoleModel, setNewRoleModel] = useState({
    name: "",
    why: "",
  });

  // References to the main input fields
  const firstBox = useRef();
  const history = useHistory();

  useEffect(() => {
    // Make sure the first input is focused
    firstBox.current.focus();

    const showModal = localStorage.getItem("viewedRoleModalModal");

    if (!showModal) {
      setShowModal(true);
    }

    getRoleModels();
    getMe();
  }, []);

  // Save role model
  const saveEdit = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model/${editingRoleModel.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: newRoleModel,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    getRoleModels();
    setNewRoleModel({
      name: "",
      image: false,
      why: "",
    });
    setEditingRoleModel(null);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }

    setMe(response.data);

    return response.data;
  };

  // Delete role model
  const deleteRoleModel = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    getRoleModels();
  };

  // Check if the fields are valid for each step
  const checkValid = (step) => {
    if (!newRoleModel.name || !newRoleModel.why) {
      NotificationManager.error("Please fill out all fields");
      return "Please fill out all fields";
    } else if (!newRoleModel.image) {
      NotificationManager.error("Please upload an image");
      return "Please upload an image";
    }
    return true;
  };

  // Save role model
  const save = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: newRoleModel,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    getRoleModels();
    setNewRoleModel({
      name: "",
      image: false,
      why: "",
    });
  };

  // Upload image
  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/highlights/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    setNewRoleModel({ ...newRoleModel, image: upload.data.avatar });
  };

  // Get role models
  const getRoleModels = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    setRoleModels(response.data);
  };

  return (
    <div className="chooseRoleModels">
      {showModal && programUser && (
        <ProgramModal
          setShowModal={(e) => {
            localStorage.setItem("viewedRoleModalModal", true);
            setShowModal(e);
          }}
        />
      )}

      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              history.push("/program");
            }}
          />

          <h3>Add Your Role Models</h3>
        </div>

        <div className="inner">
          <div className="subtitle">
            <p>
              Remember, a role model is someone you admire, who inspires you, or
              that you hope to emulate in some respect.
              <br />
              <br />
              They might be someone well-known, like an author, business leader,
              or media personality. Or, they might be someone you have a
              relationship with, like a friend, family member, or professional
              connection.
              <br />
              <br />
              You don't need to idolize your role models, and they don't have to
              be perfect (no one is!). You just need to be inspired by something
              about who they are, how they show up, or what they do.
              <br />
              <br />
            </p>
            <p className="opaque">Your Role Models Will Show Below</p>
          </div>

          {(!roleModels || roleModels.length) === 0 && (
            <div className="loading">
              <div className="spinner">
                <h4>Enter your first role model</h4>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          )}

          {roleModels.length > 2 && (
            <div className="completed">
              <h4>
                You've completed the pre-work for your upcoming session. Add
                more role models if you'd like, or press continue.
              </h4>
            </div>
          )}

          {roleModels.map((roleModel) => (
            <div className="roleModel">
              <div className="image">
                <img src={roleModel.image} alt="" />
              </div>
              <div className="content">
                <h4>{roleModel.name}</h4>
              </div>
              <div className="actions">
                <div className="edit">
                  <FontAwesomeIcon
                    onClick={() => {
                      setEditingRoleModel(roleModel);
                      setNewRoleModel(roleModel);
                    }}
                    icon={faPencil}
                  />
                </div>
                <div className="delete">
                  <FontAwesomeIcon
                    onClick={() => {
                      deleteRoleModel(roleModel.id);
                    }}
                    icon={faTimes}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className="goals"></div>

          <div className="bottom">
            <button onClick={()=>{
              setStep(0);
            }} className="btn blank">Back</button>
            <button
              disabled={roleModels.length < 3}
              onClick={() => {
                history.push("/role-models/dashboard");
              }}
              className="btn secondary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div className="right">
        <div className="white-background">
          <h3>Add Role Model</h3>
          <p>Who is someone you look up to?</p>

          <div className="model">
            <div className="left">
              <div className="image">
                {!newRoleModel.image && (
                  <div className="uploadBox">
                    <input
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        uploadAvatar(event.target.files);
                      }}
                    />
                    <div className="blankImage">
                      <FontAwesomeIcon icon={faUser} />
                      <h4>Upload Photo</h4>
                    </div>
                  </div>
                )}
                {newRoleModel.image && (
                  <div className="uploadBox">
                    <img src={newRoleModel.image} alt="" />
                    <input
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        uploadAvatar(event.target.files);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="right">
              <TextField
                onChange={(e) => {
                  setNewRoleModel({ ...newRoleModel, name: e.target.value });
                }}
                inputRef={firstBox}
                id="outlined-basic"
                value={newRoleModel.name}
                label="Name"
                variant="outlined"
              />
              <TextField
                onChange={(e) => {
                  setNewRoleModel({ ...newRoleModel, why: e.target.value });
                }}
                id="outlined-basic"
                value={newRoleModel.why}
                label="Briefly describe who this person is (ex. an author, your grandmother, etc.)."
                variant="outlined"
              />
            </div>
          </div>
          <div className="actions">
            {editingRoleModel && (
              <button
                onClick={async () => {
                  setNewRoleModel({
                    name: "",
                    image: false,
                    why: "",
                  });
                  setEditingRoleModel(null);
                }}
                className="btn secondary discard"
              >
                Discard
              </button>
            )}
            <button
              onClick={async () => {
                const status = checkValid(0);
                if (status !== true) return;

                if (editingRoleModel) {
                  return saveEdit();
                }

                save();
              }}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
