import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Strengths/sort.scss";
import { ReactSortable } from "react-sortablejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import { Analytics } from "../../hooks/analytics";

export const StrengthSort = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = React.useState();
  const [strengths, setStrengths] = React.useState([]);


  useEffect(() => {
    getStrengths();
    window.analytics.page('Strengths Sort');

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);


  const getStrengths = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${apiServerUrl}/api/strengths`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const strengths = response.data;

      strengths.sort((a, b) => {
        return a.rating - b.rating;
      });

      setStrengths(strengths);
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrder = async (list) => {
    const token = await getAccessTokenSilently();

    await list.forEach(async (strength, index) => {
      const config = {
        url: `${apiServerUrl}/api/strengths/${strength.id}/rating`,
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        data: {
          rating: index,
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios(config);
    });

    Analytics("Sorted Strengths");
    
    return true
  };

  if (strengths.length < 1)
    return (
      <div className="strengthsSort">
       
      </div>
    );

  return (
    <div className="strengthsSort">
      <div className="top">
        <h2>Time to rank your strengths</h2>
        <p>Here are your strengths. Now we’d like you to think about how to rank you strengths against each other.</p>
      </div>
      <div className="white-background">

        <div classsName="strengthsSortContainer">
          <ReactSortable
            // here they are!
            group="groupName"
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            list={strengths}
            setList={(list) => {
              setStrengths(list);
            }}
          >
            {strengths.map((strength, index) => (
              <div key={strength.id} className="strengthContainer">
                <span>{index + 1}</span>
                <div className="strength">
                  <div className="strength-name">
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                    {strength.text}
                  </div>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>
      </div>
      <div className="bottom">
        <button className="btn blank" onClick={() => history.push('/strengths/invites')}>
          Back
        </button>
        <button
          className="btn primary"
          onClick={async () => {
            await updateOrder(strengths);
            history.push("/strengths/overview");
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
