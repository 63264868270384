import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import "../../../styles/components/Chat/addons/strengthInviteModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../images/marking-black.png";
import { Analytics } from "../../../hooks/analytics";
import CopyToClipboard from "react-copy-to-clipboard";

export const StrengthInviteModal = ({ me, setShowInviteModal, close }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [currentInvite, setCurrentInvite] = useState({});
  const [invites, setInvites] = useState([]);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState("email");
  const [activeInvite, setActiveInvite] = useState();
  const history = useHistory();

  useEffect(() => {
    getInvites();
  }, []);

  const getInvites = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let invites = response.data;

    invites = invites.filter(
      (invite) =>
        invite.firstName !== null &&
        invite.firstName !== undefined &&
        invite.firstName !== "" &&
        invite.email !== null &&
        invite.email !== undefined &&
        invite.email !== "" &&
        invite.email !== me.ema
    );

    setInvites(invites);
  };

  const sendEmail = async (activeInvite) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/strengths/invite/${activeInvite.id}/email`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        personalMessage: currentInvite.note,
        email: currentInvite.email,
        firstName: currentInvite.firstName,
        to: currentInvite.email,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);
    await saveInvite(activeInvite);
    setCurrentInvite({
      firstName: "",
      email: "",
      note: "",
    });
    getInvites();
    setSuccess(`Request sent to ${currentInvite.firstName} was successful!`);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);

    Analytics("Sent Strength Invite Email");
  };

  const saveInvite = async (activeInvite) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/strengths/invite/${activeInvite.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        firstName: currentInvite.firstName,
        email: currentInvite.email,
        message: currentInvite.note,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    return response;
  };

  const createInvite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${apiServerUrl}/api/strengths/invite`,
        {
          firstName: currentInvite.firstName,
          email: currentInvite.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invite = response.data;
      sendEmail(invite);
      setActiveInvite(invite);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="strengthInviteModal" className="strengthInviteModal">
      <div className="left">
        <h2>
          <FontAwesomeIcon
            onClick={() => {
              setShowInviteModal(false);
              close();
            }}
            icon={faTimes}
          />{" "}
          Identify Your Strengths
        </h2>

        <div className="inputField">
          <label>How would you like to send your invite?</label>
          <div className="group">
            <button
              onClick={() => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.note = ``;
                setCurrentInvite(currentInviteCopy);
                setType("email");
              }}
              className={`${type === "email" ? "active" : ""}`}
            >
              Email
            </button>
            <button
              onClick={() => {
                createInvite();
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.note = `Hey ${
                  currentInvite.firstName || "NAME"
                }, I'm doing some career exploration and could use your perspective on my strengths! Here's the link to the quick assesment. It shouldn't take you more than 5 minutes of your time. Thank you so much in advance!`;
                setCurrentInvite(currentInviteCopy);
                setType("link");
              }}
              className={`${type === "link" ? "active" : ""}`}
            >
              Share a Link
            </button>
          </div>
        </div>
        <div className="inputField">
          <label>What's their first name?</label>
          <input
            value={currentInvite.firstName}
            onChange={(e) => {
              const currentInviteCopy = { ...currentInvite };
              currentInviteCopy.firstName = e.target.value;
              setCurrentInvite(currentInviteCopy);
            }}
            placeholder="Allison"
            type="text"
          />
        </div>
        <div className="inputField">
          {type === "email" ? (
            <label>Add a personal note</label>
          ) : (
            <label>Edit the note (optional)</label>
          )}
          <textarea
            value={currentInvite.note}
            onChange={(e) => {
              const currentInviteCopy = { ...currentInvite };
              currentInviteCopy.note = e.target.value;
              setCurrentInvite(currentInviteCopy);
            }}
            placeholder="Hi Allison, I'm working on a project and would love your input on my strengths. Would you be willing to take a quick survey?"
            type="text"
          />
        </div>
        {type === "email" && (
          <div className="inputField">
            <label>Lastly, what's their email?</label>
            <input
              value={currentInvite.email}
              onChange={(e) => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.email = e.target.value;
                setCurrentInvite(currentInviteCopy);
              }}
              placeholder="email@domain.com"
              type="text"
            />
          </div>
        )}

        {type === "email" ? (
          <button
            onClick={() => {
              createInvite();
            }}
            className="btn secondary"
          >
            Send Request
          </button>
        ) : (
          <CopyToClipboard
          text={`${currentInvite.note} ${window.location.protocol}//${window.location.host}/strengths/invite/${activeInvite?.id}`}
          onCopy={async () => {
            await saveInvite(activeInvite);
            setCurrentInvite({
              firstName: "",
              email: "",
              note: `Hey ${
                currentInvite.firstName || "NAME"
              }, I'm doing some career exploration and could use your perspective on my strengths! Here's the link to the quick assesment. It shouldn't take you more than 5 minutes of your time. Thank you so much in advance!`
            });
            getInvites();
          }}
        >
          <button className="btn secondary">
            Copy Shareable Link
          </button>
          </CopyToClipboard>
        )}
        {success && (
          <div className="success">
            <p>
              <FontAwesomeIcon icon={faCheck} /> {success}
            </p>
          </div>
        )}

        <div className="inviteList inputField">
          <label>Invite List({invites.length})</label>
          {invites.length === 0 && <p>No requests sent yet</p>}
          {invites.map((invite) => {
            return (
              <div className="inviteContainer">
                <p>{invite.firstName}</p>
                <p>{invite.email}</p>
              </div>
            );
          })}
        </div>

        <div className="actions">
          <button
            onClick={() => {
              setShowInviteModal(false);
            }}
            className="btn blank"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              history.push("/strengths/overview");
            }}
            className="btn primary"
          >
            Continue
          </button>
        </div>
      </div>
      <div className="right">
        {type === "email" && (
          <div className="email">
            <h3>Invite others to give you their perspective. </h3>
            <p>Here's the invite email you'll be sending them</p>

            <div className="emailContent">
              <div className="emailHeader">
                <img src={logo} alt="logo" />
              </div>

              <h2>Hello, {currentInvite.firstName}</h2>
              <p>{me.first_name} is requesting your help!</p>

              <p>
                <b>Here's the Context:</b>
                {me.first_name} is working on further understanding themselves,
                who they are, and what is important to them in their career and
                life. Right now, they are learning about strengths.
              </p>
              <p>
                <b>This is where you come in!</b>
                {me.first_name} would like your input on their strengths. They
                think you can help because you know them well. You can share
                some strengths that maybe they wouldn't have thought of
                themselves.
              </p>

              <div className="blueBox">
                <b>Note from: {me.first_name}</b>
                <p>{currentInvite.note}</p>

                <button disabled className="btn primary">
                  Share {me.first_name}'s Strengths
                </button>
              </div>
            </div>
          </div>
        )}
        {type === "link" && (
          <div className="link email">
            <h3>Invite others to give you their perspective.</h3>
            <p>
              Here's your message and link. Share it via text, through DM, or
              however you'd like!
            </p>
            <div className="bubbleContainer">
              <div className="left">
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/100x100?text=${
                      me?.first_name.charAt(0) + me?.last_name.charAt(0)
                    }`;
                  }}
                  src={me.avatar}
                  alt="avatar"
                />
              </div>
              <div className="right">
                <span>Me</span>
                <p>{currentInvite.note}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
