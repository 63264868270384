import React, { useEffect, useState } from "react";
import "./styles/CareerCollaboratorsOnboarding.scss";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import TopLeft from "../images/TopLeft.svg";
import Logo from "../images/pngLogo.png";
import here from "../images/here.png";
import TopRight from "../images/TopRight.svg";
import mentor from "../images/Mentor.png";
import sponsorFilled from "../images/filled/Sponsor.png";
import peerAdvisor from "../images/PeerAdvisor.png";
import careerSupporter from "../images/CareerSupporter.png";
import industryExpert from "../images/IndustryExpert.png";
import skillsAdvisorFilled from "../images/filled/SkillsAdvisor.png";

export const CareerCollaboratorsOnboarding = ({ setStep, me }) => {
  const [slideNumber, setSlideNumber] = useState(0);

  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: true,
    });
    setTimeout(() => {
      let moose = new Reveal();
      moose.initialize();
      moose.on("slidechanged", (event) => {
        setSlideNumber(event.indexh);

        if (event.indexh === 4) {
          setStep(1);
        }
      });
    }, 100);

    return () => {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <div
      id="CareerCollaboratorsOnboarding"
      className={`CareerCollaboratorsOnboarding ${
        slideNumber === 0 ? "blue" : ""
      }`}
    >
      {slideNumber === 0 && (
        <>
          <img src={TopLeft} className="topLeft" alt="topLeft" />
          <img src={Logo} className="logo" alt="logo" />
        </>
      )}

      {slideNumber !== 0 && (
        <img src={TopRight} className="topRight" alt="topRight" />
      )}

      <div className="reveal">
        <div className="slides">
          <section className="titleSlide">
            <div className="left">
              <h2>Session Pre-Work</h2>
              <h1>Board of Advisors</h1>
              <p>
                <FontAwesomeIcon icon={faClock} /> This will take you about 30
                minutes
              </p>
            </div>
          </section>

          <section className="content">
            <h2>At Crew, we like to say that careers are a team sport.</h2>
            <p>
            On top of doing good work in our day-to-day roles, we all need support, input, and advocacy from other people to make progress on our career goals.
            </p>
            <p>
              <b>
                But it’s not just about the size of your professional network.
              </b>
            </p>
            <p>
              In fact, being intentional about a handful of key relationships
              can have an outsized career impact.
            </p>

            <img className="here" src={here} alt="here" />
          </section>

          <section className="content">
            <h2>You need a Board of Advisors</h2>
            <p>
            Your career Board of Advisors is a group of people that you turn to for guidance, support, and advice as you navigate your career. 
            </p>

            <p>
              <b>The people on your board may change based on your current career goals, but we always suggest  that you assemble a Board that includes the following roles:</b>
            </p>

            <div className="boxes">
              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#0B455F" />
                    <path
                      d="M12 13.5H12.0075M15 13.5H15.0075M18 13.5H18.0075M12.75 18H9.75C8.92157 18 8.25 17.3284 8.25 16.5V10.5C8.25 9.67157 8.92157 9 9.75 9H20.25C21.0784 9 21.75 9.67157 21.75 10.5V16.5C21.75 17.3284 21.0784 18 20.25 18H16.5L12.75 21.75V18Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Mentor</h3>
                </div>
                <p>
                  Provides career advice and support from their experience to
                  help you grow professionally
                </p>
              </div>

              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#3485AA" />
                    <path
                      d="M21.75 15.9416C19.6655 16.7853 17.387 17.25 15 17.25C12.613 17.25 10.3345 16.7853 8.25 15.9416M18 10.5V9C18 8.17157 17.3284 7.5 16.5 7.5H13.5C12.6716 7.5 12 8.17157 12 9V10.5M15 15H15.0075M9.75 21H20.25C21.0784 21 21.75 20.3284 21.75 19.5V12C21.75 11.1716 21.0784 10.5 20.25 10.5H9.75C8.92157 10.5 8.25 11.1716 8.25 12V19.5C8.25 20.3284 8.92157 21 9.75 21Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Sponsor</h3>
                </div>
                <p>
                  Can boost your career, a senior leader who uses their
                  influence to open doors and advance your career
                </p>
              </div>

              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#E26C29" />
                    <path
                      d="M19.1665 21.6663H23.3332V19.9997C23.3332 18.619 22.2139 17.4997 20.8332 17.4997C20.0368 17.4997 19.3275 17.872 18.8696 18.4521M19.1665 21.6663H10.8332M19.1665 21.6663V19.9997C19.1665 19.4528 19.0612 18.9305 18.8696 18.4521M10.8332 21.6663H6.6665V19.9997C6.6665 18.619 7.78579 17.4997 9.1665 17.4997C9.96284 17.4997 10.6722 17.872 11.13 18.4521M10.8332 21.6663V19.9997C10.8332 19.4528 10.9385 18.9305 11.13 18.4521M11.13 18.4521C11.7444 16.9172 13.2455 15.833 14.9998 15.833C16.7542 15.833 18.2553 16.9172 18.8696 18.4521M17.4998 10.833C17.4998 12.2137 16.3805 13.333 14.9998 13.333C13.6191 13.333 12.4998 12.2137 12.4998 10.833C12.4998 9.4523 13.6191 8.33301 14.9998 8.33301C16.3805 8.33301 17.4998 9.4523 17.4998 10.833ZM22.4998 13.333C22.4998 14.2535 21.7536 14.9997 20.8332 14.9997C19.9127 14.9997 19.1665 14.2535 19.1665 13.333C19.1665 12.4125 19.9127 11.6663 20.8332 11.6663C21.7536 11.6663 22.4998 12.4125 22.4998 13.333ZM10.8332 13.333C10.8332 14.2535 10.087 14.9997 9.1665 14.9997C8.24603 14.9997 7.49984 14.2535 7.49984 13.333C7.49984 12.4125 8.24603 11.6663 9.1665 11.6663C10.087 11.6663 10.8332 12.4125 10.8332 13.333Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Peer Advisor</h3>
                </div>
                <p>
                  Like a friend at the same stage who provides relatable
                  insights and supports your growth.
                </p>
              </div>

              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#C4988A" />
                    <path
                      d="M9.23851 10.7385C7.9205 12.0565 7.9205 14.1935 9.23851 15.5115L15 21.273L20.7615 15.5115C22.0795 14.1935 22.0795 12.0565 20.7615 10.7385C19.4435 9.4205 17.3065 9.4205 15.9885 10.7385L15 11.7271L14.0115 10.7385C12.6935 9.4205 10.5565 9.4205 9.23851 10.7385Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3>Career Supporter</h3>
                </div>
                <p>
                  Motivates and believes in your goals, be they friends, family,
                  or anyone backing your journey.
                </p>
              </div>

              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#FF9E67" />
                    <path
                      d="M9 9V8H8V9H9ZM21 9H22V8H21V9ZM10.5429 14.2929C10.1524 14.6834 10.1524 15.3166 10.5429 15.7071C10.9334 16.0976 11.5666 16.0976 11.9571 15.7071L10.5429 14.2929ZM13.5 12.75L14.2071 12.0429C13.8166 11.6524 13.1834 11.6524 12.7929 12.0429L13.5 12.75ZM15.75 15L15.0429 15.7071C15.4334 16.0976 16.0666 16.0976 16.4571 15.7071L15.75 15ZM19.4571 12.7071C19.8476 12.3166 19.8476 11.6834 19.4571 11.2929C19.0666 10.9024 18.4334 10.9024 18.0429 11.2929L19.4571 12.7071ZM11.2929 21.0429C10.9024 21.4334 10.9024 22.0666 11.2929 22.4571C11.6834 22.8476 12.3166 22.8476 12.7071 22.4571L11.2929 21.0429ZM15 18.75L15.7071 18.0429C15.3166 17.6524 14.6834 17.6524 14.2929 18.0429L15 18.75ZM17.2929 22.4571C17.6834 22.8476 18.3166 22.8476 18.7071 22.4571C19.0976 22.0666 19.0976 21.4334 18.7071 21.0429L17.2929 22.4571ZM8.25 8C7.69772 8 7.25 8.44772 7.25 9C7.25 9.55228 7.69772 10 8.25 10V8ZM21.75 10C22.3023 10 22.75 9.55228 22.75 9C22.75 8.44772 22.3023 8 21.75 8V10ZM9 10H21V8H9V10ZM20 9V18H22V9H20ZM20.25 17.75H9.75V19.75H20.25V17.75ZM10 18V9H8V18H10ZM9.75 17.75C9.88807 17.75 10 17.8619 10 18H8C8 18.9665 8.7835 19.75 9.75 19.75V17.75ZM20 18C20 17.8619 20.1119 17.75 20.25 17.75V19.75C21.2165 19.75 22 18.9665 22 18H20ZM11.9571 15.7071L14.2071 13.4571L12.7929 12.0429L10.5429 14.2929L11.9571 15.7071ZM12.7929 13.4571L15.0429 15.7071L16.4571 14.2929L14.2071 12.0429L12.7929 13.4571ZM16.4571 15.7071L19.4571 12.7071L18.0429 11.2929L15.0429 14.2929L16.4571 15.7071ZM12.7071 22.4571L15.7071 19.4571L14.2929 18.0429L11.2929 21.0429L12.7071 22.4571ZM14.2929 19.4571L17.2929 22.4571L18.7071 21.0429L15.7071 18.0429L14.2929 19.4571ZM8.25 10H21.75V8H8.25V10Z"
                      fill="white"
                    />
                  </svg>
                  <h3>Industry Expert</h3>
                </div>
                <p>
                  Serves as a guide in a specific field, like marketing or tech,
                  sharing key insights and helping you level up.
                </p>
              </div>

              <div className="box">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <rect width="30" height="30" rx="15" fill="#DBB31C" />
                    <path
                      d="M12.75 9.75H11.25C10.4216 9.75 9.75 10.4216 9.75 11.25V20.25C9.75 21.0784 10.4216 21.75 11.25 21.75H18.75C19.5784 21.75 20.25 21.0784 20.25 20.25V11.25C20.25 10.4216 19.5784 9.75 18.75 9.75H17.25M12.75 9.75C12.75 10.5784 13.4216 11.25 14.25 11.25H15.75C16.5784 11.25 17.25 10.5784 17.25 9.75M12.75 9.75C12.75 8.92157 13.4216 8.25 14.25 8.25H15.75C16.5784 8.25 17.25 8.92157 17.25 9.75M15 15H17.25M15 18H17.25M12.75 15H12.7575M12.75 18H12.7575"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                  <h3>Skills Advisor</h3>
                </div>
                <p>
                  Coaches you, sharing tips and strategies for honing and using
                  them in the real world.
                </p>
              </div>
            </div>
          </section>

          <section className="content sides">
            <div className="left">
              <h2>Building out your board</h2>
              <p>
              You will be identifying people in your network who might be a good fit for your Board of Advisors based on your current careers goals.
              </p>
              <p>
                <b>Empty spaces? No problem!</b>
              </p>
              <p>
              Very few people come into this activity with every role filled. You may even find that most of your roles are unfilled to start. That's ok. This activity is intended to help you identify the gaps in your network so that you can start to take steps to fill them.
              </p>
            </div>

            <div className="right">
              <div className="box">
                <div className="top">
                <img src={mentor} alt="peerAdvisor" />

                  <h3>Mentor</h3>
                </div>

                <p>Trusted advisor who provides guidance and support based on their own journey</p>

                <div className="empty">
                  <h4>No collaborators here (yet!)</h4>
                </div>
              </div>

              <div className="box blue">
                <div className="top">
                <img src={sponsorFilled} alt="peerAdvisor" />

                  <h3>Sponsor</h3>
                </div>

                <p>Senior leader who uses their influence to advocate for you</p>

                <div className="names">
                 <h4>Andrew Johnson</h4>
                 <h4>Corey Lee</h4>
                </div>
              </div>

              <div className="box">
                <div className="top">
                  <img src={peerAdvisor} alt="peerAdvisor" />
                  <h3>Peer Advisor</h3>
                </div>

                <p>Colleague or friend at a similar career stage who can serve as a thought partner</p>

                <div className="empty">
                  <h4>No collaborators here (yet!)</h4>
                </div>
              </div>

              <div className="box">
                <div className="top">
                <img src={careerSupporter} alt="peerAdvisor" />

                  <h3>Career Supporter</h3>
                </div>

                <p>Someone who provides encouragement and  motivation you pursue your career goals</p>

                <div className="empty">
                  <h4>No collaborators here (yet!)</h4>
                </div>
              </div>

              <div className="box">
                <div className="top">
                <img src={industryExpert} alt="peerAdvisor" />

                  <h3>Industry Expert</h3>
                </div>

                <p>Industry or functional expert who can help you develop necessary knowledge</p>

                <div className="empty">
                  <h4>No collaborators here (yet!)</h4>
                </div>
              </div>

              <div className="box yellow">
                <div className="top">
                <img src={skillsAdvisorFilled} alt="peerAdvisor" />

                  <h3>Skills Advisor</h3>
                </div>

                <p>Expert in a specific skill area who can provide guidance on how to develop and refine  those skills</p>

                <div className="names">
                 <h4>Gabe Vasquez</h4>
                </div>
              </div>
            </div>
          </section>

          <section></section>
        </div>
      </div>
    </div>
  );
};
