import React, { useEffect } from "react";
import "../../styles/pages/highlightReel/welcome.scss";
import "react-month-picker-input/dist/react-month-picker-input.css";
import "chartjs-adapter-moment";
import { useAuth0 } from "@auth0/auth0-react";


export const HighlightReelSignup = (props) => {

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
  }, []);



  return (
    <div className="highlightReel-welcome">
      <div className="white-background">
      <div className="left">
          <h2>Welcome to Highlight Reel</h2>
          <p>
            Highlight Reel helps give you an extra boost of confidence and helps
            you keep track of the highlighted moments that you’re genuinely
            proud of.{" "}
          </p>
          <h5>Examples of highlights...</h5>
          <ul>
            <li>Getting a promotion</li>
            <li>Being recognized for your work</li>
            <li>Being featured in a publication</li>
          </ul>

          <h5>Now let's add your first highlight</h5>
        </div>
        <div className="right">
          <div className="video">
            <iframe
              src="https://player.vimeo.com/video/811065628?h=60e49f5584&title=0&byline=0"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              title="Highlight Reel Welcome Video"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <button onClick={()=>{
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          });
        }} className="btn primary">Signup For Free</button>
      </div>
    </div>
  );
};
