import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/dashboard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";
import { DiagramContainer } from "./components/DiagramContainer";
import { RootStore } from "react-easy-diagram";

export const MindmapDashboard = ({ location, me }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [insights, setInsights] = useState([]);
  const [addingInsight, setAddingInsight] = useState(false);
  const [insightText, setInsightText] = useState("");
  const storeRef = useRef(RootStore);
  const [links, setLinks] = useState([]);
  const [mindmap, setMindmap] = useState({});
  const [data, setData] = useState({});
  const [nodes, setNodes] = useState([]);
  const [showPresenterView, setShowPresenterView] = useState(false);
  let savingNodes = [];
  let savingLinks = [];

  const history = useHistory();

  useEffect(() => {
    getInsights();
    getMindMap();
  }, []);

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "mindmap1",
        toolId: 9,
        question:
          question ||
          `What's clearer to you now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("Insights - Added Insight");
    setAddingInsight(false);
    setInsightText("");
    getInsights();
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/mindmap`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setInsights(response.data);
  };

  const getMindMap = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/mindmap`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.length > 0) {
      const newData = { ...data };
      newData.role = response.data[0].title;

      if (response.data[0].data?.data) {
        newData.data = response.data[0].data.data.data;
      }

      setData(newData);
      setMindmap(response.data[0]);
      calculateData(newData);

      // if (nodeData) {
      //   storeRef.current?.importState(nodeData.nodes, nodeData?.links);
      // }
    }
  };

  const calculateData = async (data) => {
    const nodes = [];
    const links = [];

    if (!data) return;

    const countSubNodes = (data) => {
      if (!data) return 0;
      let count = 0;
      data.forEach((item) => {
        if (item.nodes) {
          count += item.nodes.length;
        }
      });
      return count;
    };

    let Height = 350;

    if (countSubNodes(data.data) > 0) {
      Height = 350 + countSubNodes(data.data) * 70;
    }

    const totalLength = Height / data.data.length;
    const maxHeight = totalLength * data.data.length - 1;

    const positionofRoleNode = savingNodes.find((n) => n.id === "node-0")
      ?.position || [0, maxHeight / 2];

    const roleNode = {
      id: "node-0",
      type: "FirstComponent",
      position: positionofRoleNode,
      data: { title: data.role },
    };

    nodes.push(roleNode);

    data.data.forEach((item, index) => {
      let highestY = 0;
      let lastNode = null;

      // Get the node above this one
      if (index > 0) {
        // Get all nodes that are children of the previous node
        const getAllChildrenNodes = nodes.filter((n) =>
          n.id.includes(`node-${index}`)
        );

        // Go through all the children nodes and find the one with the highest Y value
        getAllChildrenNodes.forEach((node) => {
          if (node.position[1] > highestY) {
            highestY = node.position[1];
            lastNode = node;
          }
        });
      }

      let HeightOfTheBottomNode = 100;

      // Get the # of characters in the title of the last node
      const title = lastNode?.data.title.length || data.data[index - 1]?.length;

      // Set the height of the last node from the parent node, by looking at the number of lines, given one line is 25 chars
      if (title > 25) {
        HeightOfTheBottomNode = 110;
      }
      if (title > 50) {
        HeightOfTheBottomNode = 130;
      }
      if (title > 75) {
        HeightOfTheBottomNode = 150;
      }
      if (title > 100) {
        HeightOfTheBottomNode = 170;
      }
      if (title > 125) {
        HeightOfTheBottomNode = 190;
      }
      if (title > 150) {
        HeightOfTheBottomNode = 210;
      }
      if (title > 175) {
        HeightOfTheBottomNode = 230;
      }

      // Set the starting Y position of the parent node to the highest Y value of the last node + the height of the last node + 100
      let startingYOfParent = highestY + HeightOfTheBottomNode + 100 || 400;

      const positionOfThisNode = [300, startingYOfParent] || [
        300,
        startingYOfParent,
      ];
      const parentNode = {
        id: `node-${index + 1}`,
        type: "SecondComponent",
        position: positionOfThisNode,
        data: { title: item.title, description: item.description },
      };
      nodes.push(parentNode);
      links.push({
        source: { nodeId: "node-0", portId: `output` },
        target: { nodeId: `node-${index + 1}`, portId: "input" },
      });

      if (!item.nodes) return;

      // Set the second level
      item.nodes.forEach((node, nodeIndex) => {
        let ySeperator = 90;

        // Let the title of the last node determine the height of the next node
        const title =
          item.nodes[nodeIndex - 1]?.title?.length || node.title.length;

        if (title > 25) {
          ySeperator = 110;
        }
        if (title > 50) {
          ySeperator = 130;
        }
        if (title > 75) {
          ySeperator = 150;
        }
        if (title > 100) {
          ySeperator = 170;
        }
        if (title > 125) {
          ySeperator = 190;
        }
        if (title > 150) {
          ySeperator = 210;
        }
        if (title > 175) {
          ySeperator = 230;
        }

        const lastNode = nodes.find(
          (n) => n.id === `node-${index + 1}-${nodeIndex}`
        );

        if (lastNode && lastNode.position[1]) {
          ySeperator = lastNode?.position[1] + ySeperator || 300;
        }

        if (nodeIndex === 0) {
          ySeperator = startingYOfParent;
        }

        // Push nodes
        if (!node.collab) {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponent",
            data: { title: node.title },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
          });
        } else {
          nodes.push({
            id: `node-${index + 1}-${nodeIndex + 1}`,
            type: "ThirdComponentCollab",
            data: { title: node.title, userName: node.userName },
            position: [parentNode.position[0] + 400, ySeperator],
          });
          // Push links
          links.push({
            source: { nodeId: `node-${index + 1}`, portId: `output` },
            target: {
              nodeId: `node-${index + 1}-${nodeIndex + 1}`,
              portId: "input",
            },
            type: "collabArrow",
          });
        }
      });
    });

    setNodes(nodes);
    setLinks(links);
  };

  return (
    <div className="mindmapDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Mindmap</h2>
        </div>

        <div className="right">
          <button
            onClick={() => {
              history.push("/program");
            }}
            className="btn secondary"
          >
            Back to Program
          </button>
        </div>
      </div>

      <div className="white-background">
        <div className="top edit">
          <h3>
            Your Mindmap
            <span
              onClick={() => {
                history.push(`/mindmap/diagram`);
              }}
            >
              <FontAwesomeIcon icon={faPencil} /> Edit
            </span>
          </h3>

          <button
            onClick={() => {
              setShowPresenterView(true);
            }}
            className="btn presenterViewButton"
          >
            Presenter View
          </button>
        </div>
        <div className="inSession steps">
          <div className="top">
            <h5>Next Steps</h5>
            <span>During Session</span>
          </div>
          <p>
            Congrats! You've successfully completed your mindmap. During your
            session, you'll share this link with your coach and crew so that
            they can add more ideas to your mindmap.
          </p>

          <div className="link">
            <input
              type="text"
              value={`https://${window.location.host}/mindmap/collab/${mindmap.id}`}
              readOnly
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://${window.location.host}/mindmap/collab/${mindmap.id}`
                );
              }}
            >
              Copy
            </button>
          </div>
        </div>
        <div className="content">
          <DiagramContainer
            initState={{
              nodes,
              links,
            }}
            savingLinks={savingLinks}
            savingNodes={savingNodes}
            storeRef={storeRef}
          />
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you now that you've completed this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>
      {showPresenterView && (
        <div className="presenterView">
          <div className="container">
            <div className="header">
              <button
                className="btn empty"
                onClick={() => {
                  setShowPresenterView(false);
                }}
              >
                Exit Presenter View
              </button>
            </div>
            <div className="content">
              <DiagramContainer
                initState={{
                  nodes,
                  links,
                }}
                savingLinks={savingLinks}
                savingNodes={savingNodes}
                storeRef={storeRef}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
