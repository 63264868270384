import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/pages/Admin/sidebar.scss";

export const Sidebar = (props) => {
  return (
    <div className="Sidebar">
      <ul className="white-background">
        <li>
          <NavLink to="/admin/crews">Crews</NavLink>
        </li>
        <li>
          <NavLink to="/admin/coupons">Coupons</NavLink>
        </li>
        <li>
          <NavLink to="/admin/memberships">Memberships</NavLink>
        </li>
        <li>
          <NavLink to="/admin/learningActivities">Learning Activities</NavLink>
        </li>
        <li>
          <NavLink to="/admin/companies">Companies</NavLink>
        </li>
        <li>
          <NavLink to="/admin/interests">Sign Ups</NavLink>
        </li>
        <li>
          <NavLink to="/admin/crews/stats">Stats</NavLink>
        </li>
        <li>
          <NavLink to="/admin/users">Users</NavLink>
        </li>
      </ul>
    </div>
  );
};
