import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import "./styles/index.scss";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";

import { CSVDownload, CSVLink } from "react-csv";

export const AdminInterests = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [timesThatWork, setTimesThatWork] = useState({});
  const [highlightedTime, setHighlightedTime] = useState(null);
  const [rows, setRows] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addingToCrew, setAddingToCrew] = useState(false);
  const [viewArchived, setViewArchived] = useState(false);
  const [crews, setCrews] = useState([]);
  const [form, setForm] = useState({
    crew: "",
    timeslot: "",
  });

  useEffect(() => {
    getInterests();
    getCrews();
  }, []);

  const columns = [
    { field: "name", headerName: "Name", width: 120, resizable: true },
    { field: "email", headerName: "Email", width: 150 },
    { field: "friday_10", headerName: "Fri. 10am", width: 85 },
    { field: "thursday_10", headerName: "Thurs. 10am", width: 105 },
    { field: "thursday_3", headerName: "Thurs. 3pm", width: 105 },
    { field: "wednesday_10", headerName: "Wed. 10am", width: 120 },
    { field: "wednesday_3", headerName: "Wed. 3pm", width: 110 },
    { field: "type", headerName: "Type", width: 110 },
    { field: "startDate", headerName: "Start Date", width: 100 },
    { field: "timezone", headerName: "Time Zone", width: 100 },
    { field: "managerEmail", headerName: "Manager Email", width: 150 },
    {
      field: "managerName",
      headerName: "Manager Name",
      width: 120,
      resizable: true,
    },
  ];

  const getCrews = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${apiServerUrl}/api/crews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCrews(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const softDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      const ids = selectedRows.map((row) => {
        const response = axios.delete(
          `${apiServerUrl}/api/interest/${row.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response;
      });

      Promise.all(ids).then(() => {
        getInterests();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const addToCrew = async () => {
    try {
      const token = await getAccessTokenSilently();
      const ids = selectedRows.map((row) => row.id);
      await axios.post(
        `${apiServerUrl}/api/interest/crew/${form.crew}`,
        {
          ids,
          timeslot: form.timeslot,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAddingToCrew(false);
      getInterests();
      setForm({
        crew: "",
        timeslot: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getFilteredInterests = async (time) => {
    try {
      // Set Time to url encoded string
      const token = await getAccessTokenSilently();
      let url = `${apiServerUrl}/api/interest/filter/${time}`;

      if (viewArchived) {
        url = `${apiServerUrl}/api/interest/filter/${time}?deleted=true`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const choices = response.data.map((interest) => {
        const times = {
          "Fridays 10am EST": interest.choices.includes("Fridays 10am EST")
            ? true
            : false,
          "Thursdays 10am EST": interest.choices.includes("Thursdays 10am EST")
            ? true
            : false,
          "Thursdays 3pm EST": interest.choices.includes("Thursdays 3pm EST")
            ? true
            : false,
          "Wednesdays 10am EST": interest.choices.includes(
            "Wednesdays 10am EST"
          )
            ? true
            : false,
          "Wednesdays 3pm EST": interest.choices.includes("Wednesdays 3pm EST")
            ? true
            : false,
        };

        interest = { ...interest, ...times };

        return interest;
      });

      const rows = choices.map((interest) => {
        return {
          id: interest.id,
          name: interest.name,
          email: interest.email,
          friday_10: interest["Fridays 10am EST"] ? "Yes" : "",
          thursday_10: interest["Thursdays 10am EST"] ? "Yes" : "",
          thursday_3: interest["Thursdays 3pm EST"] ? "Yes" : "",
          wednesday_10: interest["Wednesdays 10am EST"] ? "Yes" : "",
          wednesday_3: interest["Wednesdays 3pm EST"] ? "Yes" : "",
          type: interest.type,
          startDate: moment(interest.startDate).format("MM/DD/YYYY"),
          timezone: interest.timezone,
          signUpTime: moment(interest.createdAt).format("MM/DD/YYYY"),
          managerEmail: interest.managerEmail,
          managerName: interest.managerName,
        };
      });

      setRows(rows);
    } catch (error) {
      console.error(error);
    }
  };

  const getInterests = async (getDeleted = false) => {
    try {
      const token = await getAccessTokenSilently();
      let url = `${apiServerUrl}/api/interest`;

      if (getDeleted) {
        url = `${apiServerUrl}/api/interest?deleted=true`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalResults(response.data.length);

      const choices = response.data.map((interest) => {
        const times = {
          "Fridays 10am EST": interest.choices.includes("Fridays 10am EST")
            ? true
            : false,
          "Thursdays 10am EST": interest.choices.includes("Thursdays 10am EST")
            ? true
            : false,
          "Thursdays 3pm EST": interest.choices.includes("Thursdays 3pm EST")
            ? true
            : false,
          "Wednesdays 10am EST": interest.choices.includes(
            "Wednesdays 10am EST"
          )
            ? true
            : false,
          "Wednesdays 3pm EST": interest.choices.includes("Wednesdays 3pm EST")
            ? true
            : false,
        };

        interest = { ...interest, ...times };

        return interest;
      });

      const rows = choices.map((interest) => {
        return {
          id: interest.id,
          name: interest.name,
          email: interest.email,
          friday_10: interest["Fridays 10am EST"] ? "✓" : "",
          thursday_10: interest["Thursdays 10am EST"] ? "✓" : "",
          thursday_3: interest["Thursdays 3pm EST"] ? "✓" : "",
          wednesday_10: interest["Wednesdays 10am EST"] ? "✓" : "",
          wednesday_3: interest["Wednesdays 3pm EST"] ? "✓" : "",
          type: interest.type,
          startDate: moment(interest.startDate).format("MM/DD/YYYY"),
          timezone: interest.timezone,
          signUpTime: moment(interest.createdAt).format("MM/DD/YYYY"),
          managerEmail: interest.managerEmail,
          managerName: interest.managerName,
        };
      });

      setRows(rows);

      const timesThatWork = response.data.reduce((acc, interest) => {
        interest.choices.forEach((choice) => {
          if (!acc[choice]) {
            acc[choice] = [];
          }
          acc[choice].push(interest);
        });
        return acc;
      }, {});

      setTimesThatWork(timesThatWork);
    } catch (error) {
      console.error(error);
    }
  };

  const download = async () => {};

  return (
    <div className="interestsAdmin">
      <div>
        <div className="week white-background">
          <h3>Interested Crew Members</h3>

          <div className="timesThatWork">
            <h4>Times</h4>
            <div className="times">
              {Object.keys(timesThatWork).map((time) => {
                return (
                  <div
                    className={`${
                      highlightedTime === time ? "active" : ""
                    } time`}
                    onClick={() => {
                      if (highlightedTime === time) {
                        getInterests();
                        setHighlightedTime(null);
                      } else {
                        setHighlightedTime(time);
                        getFilteredInterests(time);
                      }
                    }}
                    key={time}
                  >
                    <h4>{time}</h4>
                    <p>
                      {timesThatWork[time].length} / {totalResults}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="personContainer">
            <div className="top">
              <h4>Sign Ups</h4>

              <CSVLink className="btn secondary" data={rows}>
                Download
              </CSVLink>

              {selectedRows.length === 0 && (
                <button
                  className="btn secondary"
                  onClick={() => {
                    setViewArchived(!viewArchived);
                    getInterests(viewArchived ? false : true);
                  }}
                >
                  {viewArchived ? "View Active" : "View Archived"}
                </button>
              )}

              {selectedRows.length > 0 && (
                <button
                  className="btn secondary"
                  onClick={() => setAddingToCrew(true)}
                >
                  Add to Crew
                </button>
              )}

              {selectedRows.length > 0 && (
                <button
                  className="btn secondary red"
                  onClick={() => softDelete()}
                >
                  Archive
                </button>
              )}
            </div>

            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 50 },
                },
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedRowData = rows.filter((row) => {
                  return ids.includes(row.id);
                });
                setSelectedRows(selectedRowData);
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
          </div>
        </div>
      </div>
      {addingToCrew && (
        <React.Fragment className="interestModal">
          <Dialog
            open={addingToCrew}
            onClose={() => {
              setAddingToCrew(false);
            }}
            className="interestModal"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Add To Crew</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will add the selected users to a crew and send them an
                email to opt out. Then in three days they will be sent an email
                to sign up for the platform.
              </DialogContentText>

              <div className="select">
                <label htmlFor="demo-simple-select-label">Crew</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.crew}
                  label="Age"
                  onChange={(crew) => {
                    const newForm = { ...form };
                    newForm.crew = crew.target.value;
                    setForm(newForm);
                  }}
                >
                  {crews.map((crew) => {
                    return <MenuItem value={crew.id}>{crew.name}</MenuItem>;
                  })}
                </Select>
              </div>

              {/* Do not display timeslot if there is an interest with type intern */}
              {!selectedRows.find((row) => row.type === "intern") && (
                <div className="select">
                  <label htmlFor="demo-simple-select-label">Timeslot</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={form.timeslot}
                    label="Age"
                    onChange={(crew) => {
                      const newForm = { ...form };
                      newForm.timeslot = crew.target.value;
                      setForm(newForm);
                    }}
                  >
                    <MenuItem value="Wednesdays from 10am EST to 12pm EST">
                      Wednesday 10am EST
                    </MenuItem>
                    <MenuItem value="Thursdays from 10am EST to 12pm EST">
                      Thursday 10am EST
                    </MenuItem>
                    <MenuItem value="Fridays from 10am EST to 12PM EST">
                      Friday 10am EST
                    </MenuItem>
                    <MenuItem value="Wednesdays from 3pm EST to 5pm EST">
                      Wednesday 3pm EST
                    </MenuItem>
                    <MenuItem value="Thursdays from 3pm EST - 5pm EST">
                      Thursday 3pm EST
                    </MenuItem>
                  </Select>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setAddingToCrew(false);
                }}
              >
                Exit
              </Button>
              <Button
                onClick={async () => {
                  addToCrew();
                }}
                autoFocus
              >
                Add to Crew
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </div>
  );
};
