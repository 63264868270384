import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../../styles/pages/Admin/learningActivities.scss";
import { Sidebar } from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { isAdmin } from "../../hooks/isAdmin";


export const AdminLearningActivities = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    getMe();
    getPrograms();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getPrograms = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/learning-activity`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setPrograms(response.data);
  };

  return (
    <div className="learningActivitiesAdmin">
      <h2 className="heading1 highlight blue">Learning Activities Management</h2>

      <Sidebar />

      <div className="right white-background">
        <div className="row">
          <h3 className="heading2 underline yellow">Learning Activities</h3>
          <button
            onClick={() => {
              history.push("/admin/learningActivities/create");
            }}
            className="btn primary"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <div className="programs">
          {programs.length === 0 && <p>No Learning Activities Right Now.</p>}
          {programs.map((program) => (
            <div className="program" key={program.id}>
              <h3>{program.title}</h3>
              <p>{program.description}</p>
              <button onClick={()=>{
                history.push(`/admin/learningActivities/edit/${program.id}`)
              }} className="btn secondary">Edit</button>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
};
