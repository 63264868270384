import React, { useEffect, useState } from "react";
import "./styles/Review.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Analytics } from "../../hooks/analytics";

import mentor from "./images/Mentor.png";
import sponsor from "./images/Sponsor.png";
import peerAdvisor from "./images/PeerAdvisor.png";
import careerSupporter from "./images/CareerSupporter.png";
import industryExpert from "./images/IndustryExpert.png";
import skillsAdvisor from "./images/SkillsAdvisor.png";

import mentorFilled from "./images/filled/Mentor.png";
import sponsorFilled from "./images/filled/Sponsor.png";
import peerAdvisorFilled from "./images/filled/PeerAdvisor.png";
import careerSupporterFilled from "./images/filled/CareerSupporter.png";
import industryExpertFilled from "./images/filled/IndustryExpert.png";
import skillsAdvisorFilled from "./images/filled/SkillsAdvisor.png";


export const CareerCollaboratorsReview = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [activeVision, setActiveVision] = useState({});
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState({});
  const [insight, setInsight] = useState({});
  const history = useHistory();
  const [allCollaborators, setAllCollaborators] = useState({});
  const [step, setStep] = useState(0);
  useEffect(() => {
    getCareerCollaborators();
    getMe();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    setMe(response.data);
  };

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: insight,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    setInsight({});
    setStep(step + 1);
  };

  const getCareerCollaborators = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setAllCollaborators(response.data);
  };

  return (
    <div className="reviewCollaborators">
      {step === 0 && (
        <div className="sidebar">
          <div className="title">
            <FontAwesomeIcon icon={faTimes} onClick={() => {}} />

            <h3>Board of Advisors</h3>
          </div>

          <div className="inner">
            <h3>Review your board</h3>
            <p>
            Great work exploring and adding people to these roles. 
            </p>
            <p>
            You can continue to add people to your Board of Advisors over time.
            </p>

          </div>
          <div className="bottom">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn blank"
            >
              Back
            </button>
            <button
                onClick={() => {
                  setStep(1)
                }}
                className="btn secondary"
              >
                Continue
              </button>
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="sidebar">
          <div className="title">
            <FontAwesomeIcon icon={faTimes} onClick={() => {}} />

            <h3>Board of Advisors</h3>
          </div>

          <div className="inner">
            <h3>Looking at your board...</h3>
            <p>
            What are your Board's strengths?
            </p>

            <div className="goal">
              <h4>{me?.goal}</h4>
            </div>

            <div className="formInput">
              <textarea
                onChange={(e) => {
                  setInsight({
                    question:
                      "What do you see as strengths in this board of career collaborators, as it aligns with your goal?",
                    description: e.target.value,
                    type: "careerCollaborators1",
                  });
                }}
                type="text"
                placeholder="Enter your response here"
              ></textarea>
            </div>
          </div>
          <div className="bottom">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn blank"
            >
              Back
            </button>
            {activeVision.id && (
              <button onClick={() => {}} className="btn secondary">
                Save Page
              </button>
            )}
            {!activeVision.id && (
              <button
                onClick={() => {
                  saveInsight(insight);
                }}
                disabled={!insight.description}
                className="btn secondary"
              >
                Continue
              </button>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="sidebar">
          <div className="title">
            <FontAwesomeIcon icon={faTimes} onClick={() => {}} />

            <h3>Board of Advisors</h3>
          </div>

          <div className="inner">
            <h3>Looking at your board...</h3>
            <p>
            How might you strengthen your Board?
            </p>

            <div className="goal">
              <h4>{me?.goal}</h4>
            </div>

            <div className="formInput">
              <textarea
                onChange={(e) => {
                  setInsight({
                    question:
                      "How might you strengthen your Board?",
                    description: e.target.value,
                    type: "careerCollaborators2",
                  });
                }}
                type="text"
                placeholder="Enter your response here"
              ></textarea>
            </div>
          </div>
          <div className="bottom">
            <button
              onClick={() => {
                setStep(step - 1);
              }}
              className="btn blank"
            >
              Back
            </button>
            <button
              onClick={async () => {
                await saveInsight(insight);
                history.push("/board-of-advisors/dashboard");
              }}
              disabled={!insight.description}
              className="btn secondary"
            >
              Continue
            </button>
          </div>
        </div>
      )}

      <div className="right">
        <div className="slides">
          <div
            style={{
              backgroundColor:
                allCollaborators.mentor?.length > 0 ? "#0B455F" : "#fff",
            }}
            className={`box ${
              allCollaborators.mentor?.length > 0 ? "white" : ""
            }`}
          >
            <div className="top">
            {!allCollaborators.mentor ? (
              <img src={mentor} alt="" />
              ) : (
                <img src={mentorFilled} alt="" />
              )}
              <h3>Mentor</h3>
            </div>

            <p>Trusted advisor who provides guidance and support based on their own journey</p>

            {!allCollaborators?.mentor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.mentor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box ${
              allCollaborators.sponsor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                allCollaborators.sponsor?.length > 0 ? "#2A7394" : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.sponsor ? (
              <img src={sponsor} alt="" />
              ) : (
                <img src={sponsorFilled} alt="" />
              )}
              <h3>Sponsor</h3>
            </div>

            <p>Senior leader who uses their influence to advocate for you</p>

            {!allCollaborators?.sponsor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.sponsor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box ${
              allCollaborators.peer_advisor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                allCollaborators.peer_advisor?.length > 0 ? "#E26C29" : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.peer_advisor ? (
              <img src={peerAdvisor} alt="" />
              ) : (
                <img src={peerAdvisorFilled} alt="" />
              )}
              <h3>Peer Advisor</h3>
            </div>

            <p>Colleague or friend at a similar career stage who can serve as a thought partner</p>

            {!allCollaborators?.peer_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.peer_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.career_supporter?.length > 0
                  ? "#E1C7BE"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.career_supporter ? (
              <img src={careerSupporter} alt="" />
              ) : (
                <img src={careerSupporterFilled} alt="" />
              )}
              <h3>Career Supporter</h3>
            </div>

            <p>Someone who provides encouragement and  motivation you pursue your career goals</p>

            {!allCollaborators?.career_supporter && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.career_supporter?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.industry_expert?.length > 0
                  ? "#FF9E67"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.industry_expert ? (
              <img src={industryExpert} alt="" />
              ) : (
                <img src={industryExpertFilled} alt="" />
              )}
              <h3>Industry Expert</h3>
            </div>

            <p>Industry or functional expert who can help you develop necessary knowledge</p>

            {!allCollaborators?.industry_expert && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.industry_expert?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                allCollaborators.skills_advisor?.length > 0
                  ? "#FEDE66"
                  : "#fff",
            }}
          >
            <div className="top">
            {!allCollaborators.skills_advisor ? (
              <img src={skillsAdvisor} alt="" />
              ) : (
                <img src={skillsAdvisorFilled} alt="" />
              )}
              <h3>Skills Advisor</h3>
            </div>

            <p>
            Expert in a specific skill area who can provide guidance on how to develop and refine  those skills
            </p>

            {!allCollaborators?.skills_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {allCollaborators?.skills_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
