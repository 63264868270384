import React, { useEffect, useState } from "react";
import "chartjs-adapter-moment";
import {
  faEdit,
  faLightbulb,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { Analytics } from "../../../hooks/analytics";

export const ContentBoxAdd = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [showHelp, setShowHelp] = useState(false);
  const [newInsight, setNewInsight] = useState("");
  const [addingInsight, setAddingInsight] = useState(false);
  const [insights, setInsights] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingInsight, setEditingInsight] = useState("");
  useEffect(() => {
    getInsights();
  }, []);

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/lifeline`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // If they have already done their insights update it.
    setInsights(response.data);
  };

  const editInsight = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/${editingInsight.id}`,
      method: "PATCH",
      data: {
        description: editingInsight.description,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const call = await axios(config);

    if (call.status === 200) {
      Analytics("Updated Insight");
      setEditingInsight("");
      setEditing(false);
      getInsights();
    } else {
      throw new Error("Something went wrong");
    }
  };

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: newInsight,
        type: "lifeline1",
        question: "General",
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const call = await axios(config);

    if (call.status === 200) {
      Analytics("Created Insight");
      setNewInsight("");
      setAddingInsight(false);
      getInsights();
    } else {
      throw new Error("Something went wrong");
    }
  };

  return (
    <div className="cBoxAdd">
      <div className="emoji"></div>
      <div className="content">
        <h3>
          My Insights ({insights.length})
          <button
            onClick={() => {
              setAddingInsight(!addingInsight);
            }}
          >
            + Insight <FontAwesomeIcon icon={faLightbulb} />
          </button>
        </h3>

        {addingInsight && (
          <div className="addInsight">
            <div className="input">
              <label htmlFor="title">What have you identified?</label>
              <textarea
                value={newInsight}
                onChange={(e) => {
                  setNewInsight(e.target.value);
                }}
                placeholder="E.g My low moments both involved high stress about finances."
                name="title"
                id="title"
                rows="2"
              ></textarea>
              <button
                className="btn secondary"
                disabled={newInsight && newInsight.length > 0 ? false : true}
                onClick={() => {
                  saveInsight();
                }}
              >
                Save Insight
              </button>
            </div>
          </div>
        )}

        <div className="insights">
          {insights.length > 0 &&
            insights.map((insight) => {
              return (
                <div className="insight" key={insight.id}>
                  <p>{insight.description}</p>
                  <FontAwesomeIcon
                    onClick={() => {
                      setEditing(true);
                      setEditingInsight(insight);
                    }}
                    icon={faEdit}
                  />
                </div>
              );
            })}
        </div>
      </div>

      {showHelp && (
        <div className="helpBox white-background">
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowHelp(false)} />
          <div className="left">
            <h2 className="underline blue">Some helpful hints</h2>
            <p>
              Click on any text to update and create your own career moment –
              provide details about how it made you feel, the reason it was
              impactful, and the approximate date when the moment happened.
              You'll need to add 3 moments for both personal and professional
              moments to move on to the next step, but we recommend adding
              around 6-8 to maximize your learning!
            </p>

            {/* <p className="bold">
              If you need more help, you can watch this quick video
            </p> */}
          </div>
        </div>
      )}

      {editing && (
        <div className="helpBox editingBox white-background">
          <FontAwesomeIcon icon={faTimes} onClick={() => setEditing(false)} />
            <h2 className="underline blue">Edit Insight</h2>
            <div className="form-control">
              <label htmlFor="title">What have you identified?</label>
              <textarea
                value={editingInsight.description}
                onChange={(e) => {
                  setEditingInsight({
                    ...editingInsight,
                    description: e.target.value,
                  });
                }}
                placeholder="E.g My low moments both involved high stress about finances."
                name="title"
                id="title"
                rows="2"
              ></textarea>
              <button onClick={()=> {
                editInsight()
              }} className="btn secondary">Edit</button>
          </div>
        </div>
      )}

      {showHelp && (
        <div className="skrim" onClick={() => setShowHelp(false)}></div>
      )}
    </div>
  );
};
