import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import "../../../styles/pages/Admin/Crews/stats.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBackspace,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const ProgramStats = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [stats, setStats] = useState({
    strengths: [],
    values: [],
    highlights: [],
    careerCollaborators: [],
    visions: [],
  });
  const [users, setUsers] = useState([]);
  const [sessionsByOrder, setSessionsByOrder] = useState([]);
  const [crew, setCrew] = useState({});
  const [statsByUser, setStatsByUser] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [noshowData, setNoshowData] = useState([]);

  let id = props.match.params.id;

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${apiServerUrl}/api/crews/stats/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.users);

      const statsData = await getStatsBySession(response.data.users);
      const usersData = await getStatsByUser(response.data.users);
      const noShowButUseTool = await getNoshowData(response.data.users);
      const attendanceDataReduced = await getAttendanceData(
        response.data.program.sessions
      );

      const ByOrder = response.data.program.sessions.sort(
        (a, b) => a.order - b.order
      );

      setSessionsByOrder(ByOrder);
      setStats(statsData);
      setStatsByUser(usersData);
      setCrew(response.data);
      setAttendanceData(attendanceDataReduced);
      setNoshowData(noShowButUseTool);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getNoshowData = async (data) => {
    return data.filter((acc, user) => {
      const sortedAttendances = user.attendances.sort(
        (a, b) => a.session.order - b.session.order
      );

      const isNoShow = sortedAttendances.filter((attendance) => {
        if (
          attendance.session.learningActivityId === 13 &&
          attendance.attended === false &&
          user.valueImagesCount > 0
        ) {
          return true;
        }
        if (
          attendance.session.learningActivityId === 6 &&
          attendance.attended === false &&
          user.careerCollaboratorsCount > 0
        ) {
          return true;
        }
        if (
          attendance.session.learningActivityId === 3 &&
          attendance.attended === false &&
          user.highlightCount > 0
        ) {
          return true;
        }
        if (
          attendance.session.learningActivityId === 4 &&
          attendance.attended === false &&
          user.strengthsCount > 0
        ) {
          return true;
        }
        if (
          attendance.session.learningActivityId === 10 &&
          attendance.attended === false &&
          user.visionsCount > 0
        ) {
          return true;
        }
        return false;
      });

      if (isNoShow.length > 0) {
        acc.push(user);
      }
      return acc;
    }, []);
  };

  const getAttendanceData = async (sessions) => {
    return sessions.reduce(
      (acc, session) => {
        session.attendances.map((attendance) => {
          acc.total += 1;
          if (attendance.attended) {
            acc.attended += 1;
          }

          return null;
        });
        return acc;
      },
      { attended: 0, total: 0 }
    );
  };

  const sessionsById = (item) => {
    return sessionsByOrder.find((session) => {
      return session.learningActivityId === item.learningActivityId;
    });
  };

  const getStatsByUser = async (data) => {
    return data.reduce((acc, user) => {
      const userObj = {};
      userObj.user = user;
      if (user.strengthsCount > 0) {
        userObj.strengths = true;
      }
      if (user.valueImagesCount > 0) {
        userObj.values = true;
      }
      if (user.highlightCount > 0) {
        userObj.highlights = true;
      }
      if (user.careerCollaborsCount > 0) {
        userObj.careerCollaborators = true;
      }
      if (user.visionsCount > 0) {
        userObj.visions = true;
      }
      acc.push(userObj);
      return acc;
    }, []);
  };

  const getStatsBySession = async (data) => {
    return data.reduce(
      (acc, user) => {
        if (user.strengthsCount > 0) acc.strengths.push(user);
        if (user.valueImagesCount > 0) acc.values.push(user);
        if (user.highlightCount > 0) acc.highlights.push(user);
        if (user.careerCollaborsCount > 0) {
          acc.careerCollaborators.push(user);
        }
        if (user.visionsCount > 0) acc.visions.push(user);
        return acc;
      },
      {
        strengths: [],
        values: [],
        highlights: [],
        careerCollaborators: [],
        visions: [],
      }
    );
  };

  const order = [
    { index: "highlights", title: "Highlights", learningActivityId: 3 },
    { index: "values", title: "Values", learningActivityId: 13 },
    { index: "strengths", title: "Strengths", learningActivityId: 4 },
    { index: "visions", title: "Visions", learningActivityId: 10 },
    {
      index: "careerCollaborators",
      title: "Board of Advisors",
      learningActivityId: 6,
    },
  ];

  const isDone = (time) => {
    return moment().isAfter(time?.endDate);
  };

  const getPercentage = (user) => {
    let completed = 0;
    if (user.strengths > 0) {
      completed += 1;
    }
    if (user.values > 0) {
      completed += 1;
    }
    if (user.highlights > 0) {
      completed += 1;
    }
    if (user.careerCollaborators > 0) {
      completed += 1;
    }
    if (user.visions > 0) {
      completed += 1;
    }
    return <span>{((completed / 5) * 100).toFixed(0)}%</span>;
  };

  const removedUsers = (completedUsers) => {
    return users.filter((user) => {
      if (completedUsers.find((u) => u.id === user.id)) {
        return false;
      } else {
        return true;
      }
    });
  };

  const getAttendance = (session) => {
    if (!session) return null;
    const attended = session.attendances.filter((a) => a.attended);

    return (
      <div className="attendance">
        <div className="top">
          <h3>Attendance</h3>
          <span>
            {attended.length} / {users.length}
          </span>
        </div>
        <div className="names">
          {attended.map((a) => {
            return (
              <div key={a.user_id}>
                <p>
                  {a.user.first_name} {a.user.last_name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (!users.length || !sessionsByOrder.length) return <div>Loading...</div>;

  return (
    <div className="ProgramStats">
      <div className="back">
        <Link to="/admin/crews">
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Link>
      </div>
      <h2>Stats By Session</h2>
      <p
        style={{
          width: "100%",
          display: "block",
          marginBottom: "0px",
        }}
      >
        Coach:{" "}
        <b>
          {crew.coach?.first_name} {crew.coach?.last_name}
        </b>
      </p>
      {order.map((item) => {
        // Determine if the session is in this crew
        if (!sessionsById(item)) return null;

        return (
          <div
            className={`statsBox ${
              isDone(sessionsById(item)) ? "active" : "togo"
            }`}
          >
            <div className="title">
              <h3>{item.title}</h3>
              <span>
                {stats[item.index].length} / {users.length}{" "}
              </span>
              <span>
                {((stats[item.index].length / users.length) * 100).toFixed(0)}
                {"%"}
              </span>
            </div>

            <h5>
              {sessionsByOrder.length > 0 &&
                moment(sessionsById(item)?.startDate).format("MMM Do YYYY")}
            </h5>

            <div className="split">
              <div className="left">
                <h4>Completed Activity</h4>
                {stats[item.index].map((user) => (
                  <div key={user._id}>
                    <p>
                      {user.first_name} {user.last_name}
                    </p>
                  </div>
                ))}
              </div>

              <div className="left">
                <h4>Didn't Complete</h4>
                {removedUsers(stats[item.index]).map((user) => (
                  <div key={user._id}>
                    <p>
                      {user.first_name} {user.last_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {getAttendance(sessionsById(item))}
          </div>
        );
      })}

      <div className="statsBox singleStat">
        <div className="title">
          <h3>Crew Attendance</h3>
        </div>

        <p>
          {(
            (attendanceData.attended / attendanceData.total) * 100 || 0
          ).toFixed(2)}
          %
        </p>
      </div>

      <div className="statsBox singleStat">
        <div className="title">
          <h3>% Signed Up & Created Account</h3>
        </div>

        <p>
          {(
            (crew.interests?.signedUpCount / crew.interests?.interestCount) *
              100 || 0
          ).toFixed(2)}
          %
        </p>
      </div>

      <h2 className="subtitle">Stats By User</h2>
      <div className="table">
        <div className="header">
          <div className={`col`}>
            <h4>Name</h4>
          </div>
          <div
            className={`col ${isDone(sessionsByOrder[1]) ? "active" : "togo"}`}
          >
            <h4>Highlight Reel</h4>
          </div>
          <div
            className={`col ${isDone(sessionsByOrder[2]) ? "active" : "togo"}`}
          >
            <h4>Values</h4>
          </div>
          <div
            className={`col ${isDone(sessionsByOrder[3]) ? "active" : "togo"}`}
          >
            <h4>Strengths</h4>
          </div>
          <div
            className={`col ${isDone(sessionsByOrder[4]) ? "active" : "togo"}`}
          >
            <h4>Visions</h4>
          </div>
          <div
            className={`col ${isDone(sessionsByOrder[5]) ? "active" : "togo"}`}
          >
            <h4>Board of Advisors</h4>
          </div>
        </div>
        {statsByUser.map((user) => {
          return (
            <div className="row">
              <div className="col name">
                {user.user.first_name} {user.user.last_name}
                {getPercentage(user)}
              </div>
              <div
                className={`col ${
                  isDone(sessionsByOrder[1]) ? "active" : "togo"
                }`}
              >
                {user?.highlights ? <FontAwesomeIcon icon={faCheck} /> : ""}
              </div>
              <div
                className={`col ${
                  isDone(sessionsByOrder[2]) ? "active" : "togo"
                }`}
              >
                {user?.values ? <FontAwesomeIcon icon={faCheck} /> : ""}
              </div>
              <div
                className={`col ${
                  isDone(sessionsByOrder[3]) ? "active" : "togo"
                }`}
              >
                {user?.strengths ? <FontAwesomeIcon icon={faCheck} /> : ""}
              </div>
              <div
                className={`col ${
                  isDone(sessionsByOrder[4]) ? "active" : "togo"
                }`}
              >
                {user?.visions ? <FontAwesomeIcon icon={faCheck} /> : ""}
              </div>
              <div
                className={`col ${
                  isDone(sessionsByOrder[5]) ? "active" : "togo"
                }`}
              >
                {user?.careerCollaborators ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
