import '../styles/PrivacyPolicy.scss';
export const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicy">
      <h1>
        <strong>Privacy Policy</strong>
      </h1>

      <p>
        <strong>Last Updated on September 27, 2021</strong>
      </p>

      <p>
        Your privacy is important to us. This Privacy Policy (“
        <strong>Policy</strong>”) applies to services provided by Join a Crew,
        Inc.. (“<strong>we</strong>”, “<strong>us</strong>”, or “
        <strong>Company</strong>”) and our website, mobile app, or other
        platform (collectively, the “<strong>Services</strong>”) explains what
        information we collect from users of our Services (a “
        <strong>user</strong>”, “<strong>you</strong>”, or “
        <strong>your</strong>”), including information that may be used to
        personally identify you (“<strong>Personal Information</strong>”) and
        how we use it. We encourage you to read the details below. This Policy
        applies to any user or visitor to or user of our Services. We will be
        the controller of your personal data provided to, or collected by or
        for, or processed in connection with, our Services.
      </p>

      <p>
        We reserve the right to change this Policy at any time. We will notify
        you of any changes to this Policy by sending notice to the primary email
        address specified in your account or by a prominent notice on our
        website. Significant changes will go into effect thirty (30) days
        following such notification. Non-material changes or clarifications will
        take effect immediately. You should periodically check our website,
        including this privacy page, for updates. You acknowledge that your
        continued use of our Services after we publish or send a notice about
        our changes to this Policy means that the collection, use and sharing of
        your Personal Information is subject to the updated Policy.
      </p>

      <h2>What Information Do We Collect?</h2>

      <p>
        We will only collect and process Personal Information about you where we
        have lawful bases. Lawful bases include consent{" "}
        <strong>(where you have given consent)</strong>, contract (where
        processing is necessary for the performance of a contract with you (e.g.
        to deliver the Services you have requested)) and “legitimate interests”.
      </p>

      <p>
        We collect information that you give us when you create an account,
        contact us, or otherwise access or use our Services, any information you
        voluntarily submit to us, and information regarding how you use the
        Services. Specifically, the foregoing includes:
      </p>

      <ul>
        <li>
          Your internet protocol address (i.e., IP address) and, if you access
          the Services from a mobile application, your unique mobile device ID
          number and non-email authentication.
        </li>
        <li>
          Your name, email address, telephone number, profession, other
          employment information, date of birth, profile picture, your responses
          to surveys that we may ask you to complete for research purposes or to
          help direct Company activities, the contact information of your
          representative, your social media account information, and information
          regarding your personal plans and objectives as it relates to future
          employment opportunities.
        </li>
        <li>
          Details of any financial transactions you participate in on the
          Services, including the amount, currency, and method of payment.
        </li>
        <li>
          Browser and device information and information collected through
          technologies such as cookies, pixel tags, and other technologies.
        </li>
      </ul>

      <h2>How Do We Use The Information We Collect?</h2>

      <p>We use the information we collect to:</p>

      <ul>
        <li>
          Deliver and improve the Services and your overall user experience.
        </li>
        <li>
          To protect, investigate, and deter against fraudulent, unauthorized,
          or illegal activity.
        </li>
        <li>
          To link or combine user information with other Personal Information.
        </li>
        <li>
          To compare and verify information for accuracy and update our records.
        </li>
        <li>
          Email, text, message, or otherwise contact you with information and
          updates about us and the Services
        </li>
        <li>
          To respond to your comments and questions and provide customer
          service.
        </li>
        <li>
          To send you information including confirmations, invoices, technical
          notices, updates, security alerts, and support and administrative
          messages.
        </li>
        <li>
          Analyze how you use the Services with tools such as Google Analytics
          and other tools to help us understand traffic patterns and know if
          there are problems with the Services.
        </li>
        <li>
          Create targeted advertising to promote the Services and engage our
          users.
        </li>
      </ul>

      <h2>Do We Share Your Personal Information?</h2>

      <p>
        We do not rent, sell, or share your Personal Information with other
        people or non-affiliated third parties except with your consent or as
        necessary to complete any transaction or provide any service you have
        requested or authorized.
      </p>

      <p>
        To help us do our work, we may provide limited access to some of your
        Personal Information to the following third parties:
      </p>

      <ul>
        <li>
          <strong>Partners:</strong> Sometimes we collaborate with other
          organizations to deliver the Services. In these cases, we may share
          your name, contact information and other details you provided when
          making an account with our partners.
        </li>
        <li>
          <strong>Affiliates:</strong> We may share your information with our
          affiliates and/or subsidiaries.
        </li>
        <li>
          <strong>Service Providers:</strong> We work with a wide range of third
          party providers, notably our database administrators, cloud computing
          services, advertising services, data analysts, application service
          providers, bulk SMS services, and other non-governmental
          organizations.
        </li>
        <li>
          <strong>Payment processors:</strong> We work with payment processors
          such as Stripe to help process credit card transactions and other
          payment methods made through the Services. These payment processors
          will store certain information about you. Please refer to their
          privacy policies to learn more about how they use your Personal
          Information.
        </li>
        <li>
          <strong>Other Users:</strong> We may share redacted information of our
          users with other users of our Services for the purpose of getting your
          consent to make introductions for relevant opportunities.
        </li>
      </ul>

      <p>
        We may store information, using our internal tools, such as your contact
        information and employment details, that are necessary to enable us to
        operate effectively and deliver our services to you. We may also
        transfer your Personal Information to a third party as a result of a
        merger, acquisition, reorganization or similar transaction; when
        required by law or to respond to legal process; to protect our
        customers; to protect lives; to maintain the security of the Services;
        and to protect the rights or property of Company. In such event, and to
        the extent legally permitted, we will notify you and, if there are
        material changes in relation to the processing of your Personal
        Information, give you an opportunity to consent to such changes.
      </p>

      <h2>Protection of Company and Others</h2>

      <p>
        We will also share Personal Information with companies, organizations or
        individuals outside of Company if we have a good-faith belief that
        access, use, preservation, or disclosure of your Personal Information is
        reasonably necessary to detect or protect against fraud or security
        issues, enforce our terms of use, meet any enforceable government
        request, defend against legal claims, or protect against harm our legal
        rights or safety.
      </p>

      <h2>How Do We Use Tracking Technologies?</h2>

      <p>
        A "cookie" is a small file placed on your hard drive by some of our web
        pages. We, or third parties we do business with, may use cookies to help
        us analyze our web page flow, customize our services, content and
        advertising, measure promotional effectiveness and promote trust and
        safety. Cookies are commonly used at most major transactional websites
        in much the same way we use them in our Services.
      </p>

      <p>
        You may delete and block all cookies from our Services, but parts of the
        Services may not work. We want to be open about our cookie use. Even if
        you are only browsing the Services, certain information (including
        computer and connection information, browser type and version, operating
        system and platform details, and the time of accessing the Services) is
        automatically collected about you. This information will be collected
        every time you access the Services and it will be used for the purposes
        outlined in this Privacy Policy.
      </p>

      <p>
        You can reduce the information cookies collected from your device. An
        easy way of doing this is often to change the settings in your browser.
        If you do that you should know that (a) your use of the Services may be
        adversely affected (and possibly entirely prevented), (b) your
        experience of this and other sites that use cookies to enhance or
        personalize your experience may be adversely affected, and (c) you may
        not be presented with advertising that reflects the way that you use our
        and other sites. You find out how to make these changes to your browser
        at this site:{" "}
        <a href="http://www.allaboutcookies.org/manage-cookies/">
          www.allaboutcookies.org/manage-cookies/
        </a>
        . Unless you have adjusted your browser settings so that it will refuse
        cookies, our system will send cookies as soon as access our Services. By
        using the Services you consent to this, unless you change your browser
        settings.
      </p>

      <p>
        Web beacons may be used to track the traffic patterns of users from one
        page to another in order to maximize web traffic flow. Our third-party
        advertising service providers may also use web beacons to recognize you
        when you visit the Site or access the Services and to help determine how
        you found the Services. If you would like more information about this
        and to know your choices about not having this information used by these
        companies, please visit: the Digital Advertising Alliance’s website,{" "}
        <a href="http://www.aboutads.info/">http://www.aboutads.info/</a>, or
        the Network Advertising Initiative’s website,{" "}
        <a href="https://networkadvertising.org/consumer/opt_out.asp">
          https://networkadvertising.org/consumer/opt_out.asp
        </a>
        .
      </p>

      <p>
        Session replay technologies, such as FullStory, Inc., may be used to
        collect information regarding visitor behavior on the Service. For more
        information about FullStory, please see the FullStory privacy policy
        available at{" "}
        <a href="https://www.fullstory.com/legal/privacy/">
          https://www.fullstory.com/legal/privacy/
        </a>
        . You can opt out of FullStory’s collection and Processing of data
        generated by your use of the Services by going to{" "}
        <a href="https://www.fullstory.com/optout/">
          https://www.fullstory.com/optout/
        </a>
        .
      </p>

      <h2>How Do We Secure Your Personal Information?</h2>

      <p>
        We take reasonable steps to protect your Personal Information against
        unauthorized access, alteration, disclosure, misuse, or destruction.
        Unfortunately, no data transmission or storage system can be guaranteed
        to be 100% secure. The safety and security of your Personal Information
        also depends on you. If you have an account with us, you are responsible
        for keeping your membership details confidential. Your account is
        protected by your account password and we urge you to take steps to keep
        your Personal Information safe by not disclosing your password and by
        logging out of your account after each use. We further protect your
        Personal Information from potential security breaches by implementing
        certain technological security measures including encryption, firewalls
        and secure socket layer technology. However, these measures do not
        guarantee that your Personal Information will not be accessed,
        disclosed, altered or destroyed by breach of such firewalls and secure
        server software. By using the Services, you acknowledge that you
        understand and agree to assume these risks.
      </p>

      <p>
        We retain your personal data while your account is in existence or as
        needed to provide you Services. This includes data you or others
        provided to us and data generated or inferred from your use of the
        Services.
      </p>

      <h2>Your Choices Regarding Information</h2>

      <p>
        You have several choices regarding the use of Personal Information on
        the Services:
      </p>

      <h3>Changing or Deleting Your Personal Data.</h3>
      <p>
        All users may review, update, correct or delete the Personal Information
        furnished by a user in their user account (including any imported
        contacts) by contacting us at{" "}
        <a href="mailto:hello@joinacrew.com">hello@joinacrew.com</a> or by accessing
        your user account. For your protection, we may only share and update the
        Personal Information associated with the specific email address that you
        use to send us your request, and we may need to verify your identity
        before doing so. We will try to comply with such requests in a
        reasonably timely manner. If you completely and permanently delete all
        of your Personal Information, then your user account may become
        deactivated. If you wish to cancel your account, you may do so through
        your account page, and any personally identifiable information
        associated with your account will be deleted as soon as is reasonably
        practical or as required by applicable law. Please note that we may
        retain information that is otherwise deleted in anonymized and
        aggregated form, in archived or backup copies as required pursuant to
        records retention obligations, or otherwise as required by law. We may
        retain an archived copy of your records as required by law or for
        legitimate business purposes.
      </p>

      <h3>Download or Access Personal Information.</h3>
      <p>
        You can ask us for a copy of your Personal Information and can ask for a
        copy of Personal Information you provided in machine readable form by
        emailing us at{" "}
        <a href="mailto:hello@joinacrew.com">hello@joinacrew.com</a>.
      </p>

      <h3>Opting Out of Email Communications.</h3>
      <p>
        We may use some of the information we collect for marketing purposes,
        including to send you promotional communications about new Company
        features, products, events, or other opportunities. If you wish to stop
        receiving these communications or to opt out of use of your information
        for these purposes, please follow the opt-out instructions, such as
        clicking "Unsubscribe" (or similar opt-out language) in those
        communications. You can also contact us at{" "}
        <a href="mailto:hello@joinacrew.com">hello@joinacrew.com</a> to opt out.{" "}
        <strong>
          Despite your indicated e-mail preferences, we may send you service
          related communications, including notices of any updates to our terms
          of service or privacy policy.
        </strong>
      </p>

      <h2>Links to Third Party Websites</h2>

      <p>
        We may provide links to other websites. We have no control over these
        websites and they are subject to their own terms of use and privacy
        policies. As such, we do not endorse and are not responsible for the
        availability of, or for any content, advertising, products, or other
        materials on or available from, these third party websites.
      </p>

      <p>
        By using the Services, you agree that we will not be liable for any
        damage or loss caused by your use of or reliance on any content,
        advertising, products, or other materials on or available from these
        third party websites.
      </p>

      <h2>How We Respond to Do Not Track Signals</h2>

      <p>
        Your browser settings may allow you to automatically transmit a Do Not
        Track signal to websites and other online services you visit. We do not
        alter our practices when we receive a Do Not Track signal from a
        visitor’s browser because we do not track our visitors to provide
        targeted advertising. To find out more about Do Not Track, please visit{" "}
        <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
      </p>

      <h2>Children Under 16</h2>

      <p>
        The Services are not directed to individuals who are under age of
        sixteen (16) and we do not solicit nor knowingly collect Personal
        Information from children under the age of sixteen (16). If you believe
        that we have unknowingly collected any Personal Information from someone
        under the age of sixteen (16), please contact us immediately at{" "}
        <a href="mailto:hello@joinacrew.com">hello@joinacrew.com</a> and the
        information will be deleted.
      </p>

      <h2>Third Party Direct Marketing</h2>
      <p>
        We currently do not share personal data with third parties for their
        direct marketing purposes without your consent.
      </p>

      <h2>A Note to Users Outside the United States</h2>

      <p>
        Our Company is based in the United States. The Services are controlled
        and operated by us from the United States and are not intended to
        subject us to the laws or jurisdiction of any state, country or
        territory other than that of the United States. Your Personal
        Information may be stored and processed in any country where we have
        facilities or in which we engage service providers, and by using the
        Services you consent to the transfer of information to countries outside
        of your country of residence, including the United States, which may
        have data protection rules that are different from those of your
        country. In certain circumstances, courts, law enforcement agencies,
        regulatory agencies or security authorities in those other countries may
        be entitled to access your Personal Information.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions about this Policy, your Personal Information,
        or the Services, you can contact{" "}
        <a href="mailto:hello@joinacrew.com">hello@joinacrew.com</a>.
      </p>
    </div>
  );
};
