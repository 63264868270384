import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Crews/index.scss";
import { Sidebar } from "../Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faChartBar,
  faCopy,
  faEdit,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../../hooks/isAdmin";
import DatePicker from "react-datepicker";

export const Crews = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [duplicating, setDuplicating] = useState(false);
  const [crew, setCrew] = useState({});
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [crews, setCrews] = useState([]);

  useEffect(() => {
    getMe();
    getCrews();
  }, []);

  const saveDuplicateCrew = async () => {
    const token = await getAccessTokenSilently();
    if (!crew.name || !crew.startDate || !crew.endDate || !crew.inviteCode) {
      return alert("Please fill out all fields");
    }

    const data = { ...crew };

    const config = {
      url: `${apiServerUrl}/api/crews/duplicate/${duplicating.crewId}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.status === 200) {
      setDuplicating(false);
      getCrews();
      history.push(`/admin/crews/times/${response.data.id}`);
    }
  };

  const deleteCrew = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    getCrews();
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getCrews = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setCrews(response.data);
  };

  return (
    <div className="crews">
      <h2 className="heading1 highlight blue">Crew Management</h2>

      <Sidebar />

      <div className="right white-background">
        <div className="row">
          <h3 className="heading2 underline yellow">Crews</h3>
        </div>
        <div className="crews">
          {crews.length === 0 && <p>No Crews Right Now.</p>}

          {crews.map((crew) => (
            <div className="program" key={crew.id}>
              <div className="crew">
                <div className="title">
                  <div className="full-title">
                    <h6>{crew.name}</h6>
                  </div>
                  <p className="body-text">
                    <FontAwesomeIcon icon={faCalendar} />{" "}
                    {moment(crew.startDate).format("MM/DD/YYYY")}
                  </p>
                  <p className="body-text">
                    <FontAwesomeIcon icon={faUser} />{" "}
                    {crew.coach ? crew.coach.first_name + " " + crew.coach.last_name : 'No coach'}
                  </p>
                  <p className="body-text">
                    <FontAwesomeIcon icon={faChartBar} />{" "}
                    {crew.userCount} Members
                  </p>
                </div>
                <div className="actions">
                  <NavLink to={`/admin/crews/times/${crew.id}`}>
                    <button className="btn secondary edit purple">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </NavLink>
                  <NavLink to={`/admin/crews/stats/${crew.id}`}>
                    <button className="btn secondary edit purple">
                      <FontAwesomeIcon icon={faChartBar} />
                    </button>
                  </NavLink>
                  <button
                    onClick={() => {
                      setDuplicating({
                        crewId: crew.id,
                      });
                    }}
                    className="btn secondary edit purple"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                  <button
                    onClick={() => {
                      deleteCrew(crew.id);
                    }}
                    className="btn secondary edit purple"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {duplicating && (
        <div className="modal">
          <div className="modal-content">
            <h2>Duplicate Crew</h2>
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => {
                  setCrew({
                    ...crew,
                    name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="input-group">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                dateFormat="Pp"
                selected={crew.startDate}
                showTimeSelect
                onChange={(e) => {
                  setCrew({
                    ...crew,
                    startDate: e,
                  });
                }}
              />
            </div>

            <div className="input-group">
              <label htmlFor="endDate">End Date</label>
              <DatePicker
                dateFormat="Pp"
                selected={crew.endDate}
                showTimeSelect
                onChange={(e) => {
                  setCrew({
                    ...crew,
                    endDate: e,
                  });
                }}
              />
            </div>

            <div className="input-group">
              <label htmlFor="endDate">Invite Code</label>
              <input
                onChange={(e) => {
                  setCrew({ ...crew, inviteCode: e.target.value });
                }}
                type="text"
                name="inviteCode"
              />
            </div>

            <button
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                saveDuplicateCrew();
              }}
              className="btn primary"
            >
              Save
            </button>
          </div>
          <div className="skrim" onClick={() => setDuplicating(false)}></div>
        </div>
      )}
    </div>
  );
};
