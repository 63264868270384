import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Values/valuesCreation.scss";
import { Analytics } from "../../hooks/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { OnboardedValueFlow } from "./components/OnboardedValueFlow";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const FeedbackInvite = (props) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState();
  const [values, setValues] = useState([]);
  const [valuesOnboarding, setValuesOnboarding] = useState([]);
  const [activeValue, setActiveValue] = useState(); // {id: 1, text: "value", description: "description", image: "https://via.placeholder.com/150"
  const [activeImages, setActiveImages] = useState([]); // [{id: 1, url: "https://via.placeholder.com/150"}
  const id = props.match.params.id;
  const [showFeedback, setShowFeedback] = useState(true);
  const [invite, setInvite] = useState();
  const [valueImages, setValueImages] = useState([]);
  const [enteringInsight, setEnteringInsight] = useState(false);
  const [insight, setInsight] = useState("");

  useEffect(() => {
    getMe();
    getInvite(id);
    getValues(true);
    getValueImages();

    Analytics("Started Values Creation");

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width:96rem";
      }
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const getValueImages = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/value-image`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValueImages(response.data.valueImages);
  };

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insight,
        type: 'values1',
        toolId: 9,
        question: `What did others see that you didn't see in yourself?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);

    Analytics("10 Images - Added Insight");

    history.push("/values");
  };


  const getInvite = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/invite/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    const invite = response.data;
    setInvite(invite);
  };

  const saveActiveValue = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/${activeValue.id}/images`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        images: activeImages.map((img) => img.id),
        summary: activeValue.summary,
        text: activeValue.text,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("10 Images - Added Value to Image");
    getValues();
    setActiveImages([]);
  };

  const deleteValue = async (valueId) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/values/${valueId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    getValues();
  };

  const getValues = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/values/all`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const filteredValues = response.data.reduce(
      (acc, value) => {
        if (value.fromOnboarding) {
          acc.onBoarding.push(value);
        } else {
          acc.values.push(value);
        }
        return acc;
      },
      { onBoarding: [], values: [] }
    );

    setValues(filteredValues.values);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  if (!invite) {
    return null;
  }

  return (
    <div className="valuesCreation insight">
      <div className="white-background">
        {!enteringInsight && (
          <div className="left">
            <h2>Review your values</h2>

            <p
              style={{
                fontSize: "1.2rem",
              }}
            >
              Utilizing your new feedback take a minute and make sure you values
              are still aligned with who you are.
            </p>

            <div className="valuesScrolling">
              {values.length > 0 &&
                (!valuesOnboarding || valuesOnboarding.length === 0) &&
                values.map((value) => (
                  <OnboardedValueFlow
                    key={value.id}
                    setActiveImages={(images) => {
                      setActiveImages(images);
                    }}
                    remove={async (value) => {
                      await deleteValue(value.id);
                      setActiveImages([]);
                      setActiveValue({});
                    }}
                    setActiveValue={(value) => {
                      setActiveValue(value);
                      setShowFeedback(false);
                    }}
                    activeImages={activeImages}
                    value={value}
                    save={async () => {
                      saveActiveValue();
                    }}
                  />
                ))}
            </div>

            <div className="bottom">
              <button
                className="btn blank"
                onClick={() => {
                  history.push("/values");
                }}
              >
                Back to Values
              </button>
              <button
                onClick={() => {
                  setShowFeedback(true);
                  setEnteringInsight(true);
                }}
                className="btn secondary"
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {enteringInsight && (
          <div className="left">
            <h2>Feedback on values</h2>

            <h5>ADD YOUR INSIGHTS</h5>

            <h4>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <circle cx="13.2998" cy="13.2998" r="13.2998" fill="#FF9E67" />
                <path
                  d="M11.6714 18.4309C11.6714 18.7159 11.8885 19.0009 12.2142 19.0009H14.3856C14.7114 19.0009 14.9285 18.7159 14.9285 18.4309V17.8609H11.6714V18.4309ZM13.2999 7.60107C11.1828 7.60107 9.5 9.36805 9.5 11.591C9.5 12.959 10.1514 14.156 11.1285 14.84V16.1509C11.1285 16.4359 11.3457 16.7209 11.6714 16.7209H14.9285C15.2542 16.7209 15.4713 16.4359 15.4713 16.1509V14.84C16.4485 14.099 17.0999 12.902 17.0999 11.591C17.0999 9.36805 15.4171 7.60107 13.2999 7.60107Z"
                  fill="#013247"
                />
              </svg>
              What did others see that you didn't see in yourself?
            </h4>

            <div className="valuesScrolling">
              <textarea
                onChange={(e) => {
                  setInsight(e.target.value);
                }}
                className="insight"
                placeholder="Add your insight here"
              />
            </div>

            <div className="bottom">
              <button
                className="btn blank"
                onClick={() => {
                  history.push("/values");
                }}
              >
                Back to Values
              </button>
              <button
                onClick={() => {
                  saveInsight();
                }}
                disabled={!insight}
                className="btn secondary"
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {showFeedback && (
          <div
            style={{
              backgroundColor: "#0B455F",
            }}
            className="right feedback"
          >
            <div className="top">
              <div className="left">
                <h3>{invite?.firstName}'s Feedback</h3>
                <p>
                  As you review these, think about how they interact with your
                  current values on the left
                </p>
              </div>
              <div className="right">
                <button
                  onClick={() => {
                    setShowFeedback(false);
                  }}
                  className="btn secondary c"
                >
                  See Images
                </button>
              </div>
            </div>

            <div className="feedback">
              {Object.keys(invite?.response).map((key) => {
                const item = invite.response[key];
                return (
                  <div className="feedback__item">
                    <div className="desc">
                      <h4>{item.description}</h4>
                      <hr />
                      <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!showFeedback && (
          <div className="right">
            <div className="top">
              <div className="left">
                <h3>My Images</h3>
                <p>
                  Add photos that represent who you are and what matters to you
                </p>
              </div>
              <div className="right">
                <button
                  onClick={() => {
                    setShowFeedback(true);
                  }}
                  className="btn secondary c"
                >
                  Back to Feedback
                </button>
              </div>
            </div>
            <ResponsiveMasonry
              columnsCount={3}
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 3,
                1200: 3,
                1400: 4,
              }}
              className="masonry-grid"
            >
              <Masonry gutter={15}>
                {valueImages.map((highlight) => (
                  <div
                    onClick={() => {
                      if (!activeValue.id) return false;
                      if (
                        activeImages.filter((img) => img.id === highlight.id)
                          .length > 0
                      ) {
                        const newActiveImages = activeImages.filter(
                          (img) => img.id !== highlight.id
                        );
                        setActiveImages(newActiveImages);
                      } else {
                        const newActiveImages = [...activeImages, highlight];
                        setActiveImages(newActiveImages);
                      }
                    }}
                    key={highlight.id}
                    className="single-highlight"
                  >
                    <div
                      className={`${!activeValue?.id ? "noHover" : ""} photo ${
                        activeImages.filter((item) => {
                          return item.id === highlight.id;
                        }).length === 1
                          ? "selected"
                          : ""
                      }`}
                    >
                      <img src={highlight.url} alt="value" />
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
      </div>
    </div>
  );
};
