import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import "../../../styles/components/Chat/addons/highlight.scss";
import { StrengthInviteModal } from "./strengthInviteModal";

export const StrengthInvite = ({ chat, continueLoop, divRef, me }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [loading, setLoading] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div id="chatMessage" className="chatMessage addon-strengths addon">
      <div className="chatMessageBox">
        <img
          alt="avatar"
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
          <h4>Roxanne Burbach {chat.app}</h4>
          <div className={`bubble ${!loading ? "yellow" : ""}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Who are three people who know you well?</h3>
                  <span>
                  These might be managers, colleagues, mentors, or friends
                    <br />
                    <br />
                    We'll ask them to provide their input on your strengths.
                  </span>
                </div>
                <div className="right">
                  <button onClick={async () => {
                    setShowInviteModal(true);
                  }} className="btn secondary">
                    Lets Begin
                  </button>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
      {showInviteModal && (
        <StrengthInviteModal me={me} setShowInviteModal={setShowInviteModal} />
        )}
    </div>
  );
};
