import React, { useEffect, useState } from "react";
import "../../styles/pages/Lifeline/add.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";
import ReactSlider from "react-slider";
import { LifelineWidgetSolo } from "../Path/components/LifelineWidgetSolo";
import moment from "moment";
import empty from "./svgs/blank.svg";
import { ConnectiveTissue } from "./components/ConnectiveTissue";
import Intro from "../../images/intro.png";

export const LifelineAdd = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [me, setMe] = useState(null);
  const { apiServerUrl } = useEnv();
  const [highlight, setHighlight] = useState({
    featured: false,
  });
  const [insights, setInsights] = useState({
    description: "",
  });
  const [step, setStep] = useState(1);
  const [moments, setMoments] = useState([]);
  const [personalMoments, setPersonalMoments] = useState([]);
  const [professionalMoments, setProfessionalMoments] = useState([]);
  const history = useHistory();
  const [showConnectiveTissue, setShowConnectiveTissue] = useState(false);
  const [highlightReelMoments, setHighlightReelMoments] = useState([]);
  const [showIntro, setShowIntro] = useState(true);
  const [error, setError] = useState(null);
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("me"));
    setMe(me);
    getMoments();
    getInsights();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const getMoments = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/moments`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const reducedMoments = response.data.reduce(
      (acc, item) => {
        if (item.type === "personal") {
          acc.personalMoments.push(item);
        } else {
          acc.professionalMoments.push(item);
        }
        const year = moment(item.date).year();
        if (!acc.years.includes(year)) {
          acc.years.push(year);
        }
        return acc;
      },
      {
        years: [],
        personalMoments: [],
        professionalMoments: [],
      }
    );
    setPersonalMoments(reducedMoments.personalMoments);
    setProfessionalMoments(reducedMoments.professionalMoments);
    setMoments(response.data);
  };

  const saveToDatabase = async (item, type) => {
    const token = await getAccessTokenSilently();
    const date = moment(item.date);

    setError(null);

    if (!item.title || !item.date || !item.score || !item.description) {
      setError("Please fill our all fields");
      return;
    }

    const config = {
      url: `${apiServerUrl}/api/moments`,
      method: "POST",
      data: {
        score: item.score,
        title: item.title,
        date: date.format("YYYY-MM-DD HH:mm:ss"),
        description: item.description,
        type: type,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    Analytics("Created Moment");
    if (response.status === 200) {
      getMoments();

      if (item.score > 70) {
        setHighlightReelMoments([...highlightReelMoments, response.data]);
      }
      setHighlight({
        title: "",
        description: "",
        date: "",
        score: 50,
      });
      return true;
    } else {
      throw new Error("Something went wrong");
    }
  };

  const getEmoji = (score) => {
    if (score > 80) {
      return "🤩";
    } else if (score > 60) {
      return "😃";
    } else if (score > 40) {
      return "🙂";
    } else if (score > 20) {
      return "😐";
    } else {
      return "😕";
    }
  };

  const saveInsights = async (insight) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insight.description,
        type: insight.type,
        toolId: 1,
        question: insight.question,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios(config);
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/1`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // If they have already done their insights update it.
    setInsights(response.data);
  };

  const goBack = () => {
    if (moments.length === 0) {
      history.push("/learn/activities");
    } else {
      history.goBack();
    }
  };

  // If they need to see the intro screen
  if (showIntro === true) {
    return (
      <div className="lifeline white-background LifeLine-intro">
        <div className="left">
          <h4> Learn from your highs and lows</h4>
          <p>
            You may be at the start of your career design journey, but you’re
            not starting from scratch. You already have life and work
            experience; you’ve experienced highs and lows. <br />
            <br />
            In fact, you’ve already gathered a ton of useful data about what
            works for you and what doesn’t. But, you might not know how to make
            sense of your experiences and turn that data into insights that can
            help you on your career design journey. <br />
            <br />
            That’s where Lifeline comes in. In this activity, you will plot a
            visual history of your life and career stories, helping you to
            understand where you’re coming from and what matters to you. <br />
            <br />
            We think looking back is a great way to start on your path forward.
          </p>
          <button
            className="btn primary"
            onClick={() => {
              localStorage.setItem("seenLifelineIntro", "true");
              setShowIntro(false);
            }}
          >
            I'm Ready To Begin
          </button>
        </div>
        <div className="right">
          <img src={Intro} alt="lifeline intro" />
        </div>
      </div>
    );
  }

  return (
    <div className="lifeline-add">
      <div className="white-background">
        <div className="form">
          {step === 1 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    goBack();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Step 1: Professional Lifeline</h2>
              </div>

              <p
                style={{
                  padding: "30px",
                }}
              >
                Spend a few moments taking a mental walk through your career,
                starting from your first job.
                <br />
                <br />
                As you think about each step of your career journey, take note
                of the high points. Which moments in your career left you
                feeling great? When did you find flow or feel like you had
                purpose? On the flip side, what moments left you feeling
                frustrated or uninspired? What moments helped you learn what
                didn't want? <br />
                <br />
                When you're ready, we're going to ask you to add at least three
                of these significant moments to your lifeline.
              </p>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    goBack();
                  }}
                >
                  Go Back
                </button>
                <button
                  className="btn primary"
                  type="button"
                  onClick={async () => {
                    setStep(2);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    goBack();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Step 1: Professional Lifeline</h2>
              </div>
              <div className="form-group">
                <p>
                  Let's begin by adding significant moments from your
                  professional life.{" "}
                </p>
                {error && <p className="error">{error}</p>}
                <label htmlFor="type">
                  Significant Moment <span>Required</span>
                </label>
                <input
                  placeholder="E.g Received great feedback on Project X"
                  type="text"
                  onChange={(event) => {
                    setHighlight({ ...highlight, title: event.target.value });
                  }}
                  value={highlight.title}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Timeframe <span>Required</span>
                </label>
                <input
                  type="month"
                  id="start"
                  name="start"
                  min="1920-01"
                  onChange={(event) => {
                    setHighlight({
                      ...highlight,
                      date: event.target.value,
                    });
                  }}
                  value={highlight.date}
                ></input>
              </div>

              <div className="form-group slider-group">
                <label htmlFor="type">
                  Associated Emotion <span>Required</span>
                </label>
                <ReactSlider
                  className="horizontal-slider"
                  onAfterChange={(value) => {
                    setHighlight({ ...highlight, score: value });
                  }}
                  max={100}
                  min={0}
                  value={highlight.score}
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props, state) => (
                    <div {...props}>{getEmoji(state.valueNow)}</div>
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Why was this moment significant? <span>Required</span>
                </label>
                <textarea
                  placeholder=""
                  type="text"
                  onChange={(event) => {
                    setHighlight({
                      ...highlight,
                      description: event.target.value,
                    });
                  }}
                  value={highlight.description}
                  name="type"
                  id="type"
                />
              </div>

              <div className="status">
                {professionalMoments.length < 3 && (
                  <div>
                    <p>
                      {professionalMoments.length}/3 Professional Moments Added
                    </p>
                    <button
                      className="btn secondary"
                      onClick={() => {
                        saveToDatabase(highlight, "professional");
                      }}
                    >
                      Add Moment
                    </button>
                  </div>
                )}
                {professionalMoments.length >= 3 && (
                  <div>
                    <p>
                      All professional moments added. You can add more or hit
                      "Continue" below and add your personal moments
                    </p>
                    <button
                      className="btn secondary"
                      onClick={() => {
                        saveToDatabase(highlight, "professional");
                      }}
                    >
                      Add Moment
                    </button>
                  </div>
                )}
              </div>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    setStep(1);
                  }}
                >
                  Discard
                </button>
                <button
                  className="btn primary"
                  disabled={professionalMoments.length < 3}
                  type="button"
                  onClick={async () => {
                    setStep(3);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    goBack();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Step 2: Personal Lifeline</h2>
              </div>

              <p
                style={{
                  padding: "30px",
                }}
              >
                Great Job! For the next step we would like you to think of some
                moments in your personal life that have been significant to you.
                <br />
                <br />
                Think of both the highs and the lows, and try to think of
                moments that have had a significant impact on you.
                <br />
                <br />
                Once you have thought of a moment, click the button below to
                start adding personal moments to your lifeline.
              </p>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    setStep(2);
                  }}
                >
                  Go Back
                </button>
                <button
                  className="btn primary"
                  disabled={professionalMoments.length < 3}
                  type="button"
                  onClick={async () => {
                    setStep(4);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    goBack();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Step 2: Personal Lifeline</h2>
              </div>
              <div className="form-group">
                <p>Now, let's add a few significant personal moments</p>

                {error && <p className="error">{error}</p>}

                <label htmlFor="type">
                  Significant Moment <span>Required</span>
                </label>
                <input
                  placeholder="E.g Received great feedback on Project X"
                  type="text"
                  onChange={(event) => {
                    setHighlight({ ...highlight, title: event.target.value });
                  }}
                  value={highlight.title}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Timeframe <span>Required</span>
                </label>
                <input
                  type="month"
                  id="start"
                  name="start"
                  min="1920-01"
                  onChange={(event) => {
                    setHighlight({
                      ...highlight,
                      date: event.target.value,
                    });
                  }}
                  value={highlight.date}
                ></input>
              </div>

              <div className="form-group slider-group">
                <label htmlFor="type">
                  Associated Emotion <span>Required</span>
                </label>
                <ReactSlider
                  className="horizontal-slider"
                  onAfterChange={(value) => {
                    setHighlight({ ...highlight, score: value });
                  }}
                  max={100}
                  min={0}
                  value={highlight.score}
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props, state) => (
                    <div {...props}>{getEmoji(state.valueNow)}</div>
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">
                  Why was this moment significant? <span>Required</span>
                </label>
                <textarea
                  placeholder=""
                  type="text"
                  onChange={(event) => {
                    setHighlight({
                      ...highlight,
                      description: event.target.value,
                    });
                  }}
                  value={highlight.description}
                  name="type"
                  id="type"
                />
              </div>

              <div className="status">
                {personalMoments.length < 3 && (
                  <div>
                    <p>{personalMoments.length}/3 Personal Moments Added</p>
                    <button
                      className="btn secondary"
                      onClick={() => {
                        saveToDatabase(highlight, "personal");
                      }}
                    >
                      {" "}
                      Add Moment
                    </button>
                  </div>
                )}
                {personalMoments.length >= 3 && (
                  <div>
                    <p>
                      All personal moments added. You can add more or hit
                      "Continue" below to reflect and add insights
                    </p>{" "}
                    <button
                      className="btn secondary"
                      onClick={() => {
                        saveToDatabase(highlight, "personal");
                      }}
                    >
                      {" "}
                      Add Moment
                    </button>
                  </div>
                )}
              </div>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    setStep(3);
                  }}
                >
                  Go Back
                </button>
                <button
                  className="btn primary"
                  disabled={personalMoments.length < 3}
                  type="button"
                  onClick={async () => {
                    setStep(5);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 5 && (
            <div className="left">
              <div className="title">
                <FontAwesomeIcon
                  onClick={() => {
                    goBack();
                  }}
                  className="close"
                  icon={faTimes}
                />
                <h2>Step 3: Reflect</h2>
              </div>

              <div className="form-group insight">
                <label htmlFor="type">
                  Notice the 'happy' moments on your career lifeline. What was
                  happening that helped you to thrive (think skills, behaviors,
                  working environment)? What patterns do you notice across your
                  happy moments?
                </label>
                <textarea
                  placeholder=""
                  type="text"
                  onChange={(event) => {
                    setQuestion1({
                      question:
                        'Notice the "happy" moments on your career lifeline. What was happening that helped you to thrive (think skills, behaviors, working environment)? What patterns do you notice across your happy moments?',
                      type: "lifeline1",
                      description: event.target.value,
                    });
                  }}
                  value={question1.description}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group insight">
                <label htmlFor="type">
                  Now repeat this process for moments where you were less happy.
                  What is happening here? Can you notice any patterns or trends
                  in what makes you unhappy?
                </label>
                <textarea
                  placeholder=""
                  type="text"
                  onChange={(event) => {
                    setQuestion2({
                      question:
                        "Now repeat this process for moments where you were less happy. What is happening here? Can you notice any patterns or trends in what makes you unhappy?",
                      type: "lifeline2",
                      description: event.target.value,
                    });
                  }}
                  value={question2.description}
                  name="type"
                  id="type"
                />
              </div>

              <div className="form-group insight">
                <label htmlFor="type">
                  How do your career and personal lifelines interact? What is
                  the relationship between your happiness at work and your
                  happiness at home?
                </label>
                <textarea
                  placeholder=""
                  type="text"
                  onChange={(event) => {
                    setQuestion3({
                      question:
                        "How do your career and personal lifelines interact? What is the relationship between your happiness at work and your happiness at home?",
                      type: "lifeline3",
                      description: event.target.value,
                    });
                  }}
                  value={question3.description}
                  name="type"
                  id="type"
                />
              </div>

              <div className="buttonActions">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={async () => {
                    setStep(4);
                  }}
                >
                  Go Back
                </button>
                <button
                  className="btn primary"
                  disabled={
                    question1.description === "" ||
                    question2.description === "" ||
                    question3.description === ""
                  }
                  type="button"
                  onClick={async () => {
                    const reflections = [question1, question2, question3];

                    const savingMoments = reflections.map((reflection) => {
                      return saveInsights(reflection);
                    });

                    await Promise.all(savingMoments);

                    Analytics("Saved Lifeline Insights");
                    Analytics("Saved Insight");
                    Analytics("Completed Lifeline");

                    if (highlightReelMoments.length > 0) {
                      setShowConnectiveTissue(true);
                    } else {
                      history.push("/lifeline");
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}

          <div className="right">
            <div className="white-background">
              <div className="preview">
                {moments.length > 0 && <h5>Preview</h5>}
                <div className="highlightArea">
                  {moments.length > 0 && (
                    <LifelineWidgetSolo moments={moments} />
                  )}
                  {moments.length === 0 && (
                    <div className="graphs__empty">
                      <img src={empty} alt="empty" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showConnectiveTissue && (
          <ConnectiveTissue
            close={() => {
              history.push("/lifeline");
            }}
            highlightReelMoments={highlightReelMoments}
          />
        )}
      </div>
    </div>
  );
};
