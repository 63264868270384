import React, { useEffect, useState } from "react";
import "./styles/EnterPages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Analytics } from "../../hooks/analytics";

export const EnterPages = ({ setStep, me, location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [visions, setVisions] = useState(null);
  const [activeVision, setActiveVision] = useState({});
  const [nonActivePages, setNonActivePages] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  useEffect(() => {
    getVisions();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/visions/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const newActiveVision = { ...activeVision };
    newActiveVision.image = upload.data.image;
    setActiveVision(newActiveVision);
  };

  const getCategory = (category) => {
    switch (category) {
      case "growth":
        return "PERSONAL GROWTH";
      case "relationships":
        return "RELATIONSHIPS";
      case "impact":
        return "IMPACT";
      default:
        return "PERSONAL GROWTH";
    }
  };

  const resetNonActive = (page) => {
    const newNonActive = allPages.filter((nonActivePage) => {
      return nonActivePage.id !== page.id;
    });

    setNonActivePages(newNonActive);
  };

  const editVision = async (page) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions/page/${page.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: page,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    setActiveVision({
      response: null,
      summary: null,
      image: null,
    });
    getVisions();

    Analytics("Visions - Finished Page");
  };

  const getVisions = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const nonFinished = response.data[0].pages.filter((vision) => {
      return vision.response === null;
    });

    // Get all the pages that are not the active page
    const nonActivePages = response.data[0].pages.filter((vision) => {
      if (!nonFinished[0]) {
        return true;
      }
      if (vision.id === nonFinished[0].id) {
        return false;
      }

      return true;
    });

    setAllPages(response.data[0].pages);
    setNonActivePages(nonActivePages);
    setVisions(nonFinished);
    setActiveVision({
      ...nonFinished[0],
      response: "",
      summary: "",
      image: null,
    });

    if (location.state?.page) {
      const page = response.data[0].pages.filter((page) => {
        return page.id === location.state.page.id;
      })[0];
      resetNonActive(page);
      setActiveVision(page);
      location.state = {};
    }
  };

  return (
    <div className="enterPages">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              setStep(0);
            }}
          />

          <h3>One-Year Vision Planning</h3>
        </div>

        {activeVision.id && (
          <div className="inner">
            <div className="inputBox">
              <label>Respond as yourself one year in the future</label>
              <div className="prompt">{activeVision?.prompt}</div>
            </div>

            <div className="inputBox">
              <label>Your response</label>
              <textarea
                onChange={(e) => {
                  if (e.target.value.length > 500) {
                    return;
                  }
                  const newActiveVision = { ...activeVision };
                  newActiveVision.response = e.target.value;
                  setActiveVision(newActiveVision);
                }}
                placeholder="Write your response. Don't worry about coming up with the perfect copy."
                value={activeVision.response}
              ></textarea>

              <div className="counter">
                {activeVision?.response?.length || 0}/500
              </div>
            </div>

            <div className="inputBox">
              <label>Your bottom-line summary</label>
              <textarea
                onChange={(e) => {
                  if (e.target.value.length > 50) {
                    return;
                  }
                  const newActiveVision = { ...activeVision };
                  newActiveVision.summary = e.target.value;
                  setActiveVision(newActiveVision);
                }}
                placeholder="Summarize your response in a few words..."
                value={activeVision.summary}
              ></textarea>
              <div className="counter">
                {activeVision?.summary?.length || 0}/50
              </div>
            </div>

            <div className="inputBox">
              <label>Make it visual</label>
              <div className="photoPlaceholder">
                <input
                  accept="image/*"
                  type="file"
                  onChange={(event) => {
                    uploadAvatar(event.target.files);
                  }}
                />
                {activeVision?.image && (
                  <img src={activeVision?.image} alt="" />
                )}
                {!activeVision?.image && <FontAwesomeIcon icon={faCamera} />}

                <h4>Add a photo</h4>

                <span>Optional</span>
              </div>
            </div>
          </div>
        )}

        {!activeVision.id && (
          <div className="empty">
            <h1>Congratulations!</h1>
            <p>You have completed all your pages!</p>
            <p>
              You can still edit your page by clicking it over on the right
              side.
            </p>
          </div>
        )}

        <div className="bottom">
          <button
            onClick={() => {
              history.goBack();
            }}
            className="btn blank"
          >
            Back
          </button>
          {activeVision.id && (
            <button
              onClick={() => {
                editVision(activeVision);
              }}
              className="btn secondary"
            >
              Save Page
            </button>
          )}
          {!activeVision.id && (
            <button
              onClick={() => {
                history.push("/visions/dashboard", {
                  celebrate: true,
                });
              }}
              className="btn secondary"
            >
              Continue
            </button>
          )}
        </div>
      </div>

      <div className="right">
        <div className="slides">
          {allPages.map((page) => {
            if (page.id === activeVision.id) {
              return (
                <div className={`box ${activeVision.category}`}>
                  <span>{getCategory(activeVision.category)}</span>

                  <h2>{activeVision.summary || "Summarize in a few words"}</h2>

                  <p>
                    {activeVision.response ||
                      "Your response to the prompt will show up in this space."}
                  </p>

                  {activeVision.image && (
                    <img src={activeVision.image} alt="" />
                  )}
                </div>
              );
            }

            return (
              <div
                onClick={() => {
                  setActiveVision(page);

                  resetNonActive(page);
                }}
                className={`box ${page.category} nonActive`}
              >
                <span>{getCategory(page.category)}</span>

                <h2>{page.summary || "Summarize in a few words"}</h2>

                <p>
                  {page.response ||
                    "Your response to the prompt will show up in this space."}
                </p>

                {page.image && <img src={page.image} alt="" />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
