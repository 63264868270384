import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/highlightReel/components/comment.scss";

export const Comment = (props) => {
  useEffect(() => {}, []);

  const getAvatar = (collaborator) => {
    if (collaborator.avatar) {
      return collaborator.avatar;
    } else {
      if (collaborator.first_name && collaborator.last_name) {
        return `https://placehold.co/100x100?text=${
          collaborator.first_name.charAt(0) + collaborator.last_name.charAt(0)
        }`;
      }

      return `https://placehold.co/100x100?text=${
        collaborator.firstName.charAt(0) + collaborator.lastName.charAt(0)
      }`;
    }
  };

  return (
    <div className="comment-item">
      <div className="user">
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `https://placehold.co/100x100?text=${
              props.comment.sendingUser.first_name.charAt(0) +
              props.comment.sendingUser.last_name.charAt(0)
            }`;
          }}
          src={getAvatar(props.comment.sendingUser)}
          alt={props.comment.sendingUser.firstName}
        />
        <div className="text">
          <span>
            {props.comment.sendingUser.first_name}{" "}
            {props.comment.sendingUser.last_name}
          </span>
          <p>{props.comment.text}</p>
        </div>
      </div>
    </div>
  );
};
