import { useAuth0 } from "@auth0/auth0-react";
import { faClock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import "../../../styles/components/Chat/addons/strengths.scss";

export const Strengths = ({ chat, continueLoop, divRef, me }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const [loading, setLoading] = useState(true);
  const [doingExercise, setDoingExercise] = useState(false);
  const [finished, setFinished] = useState(false);
  const [myStrengths, setMyStrengths] = useState([]);
  const [strengths, setStrengths] = useState([
    "Researching",
    "Assessing",
    "Analyzing",
    "Ideating",
    "Visioning",
    "Planning",
    "Experimenting",
    "Presenting",
    "Writing",
    "Maintaining",
    "Arranging",
    "Coordinating",
    "Problem solving",
    "Optimizing",
    "Measuring",
    "Evaluating",
    "Managing ambiguity",
    "Designing",
    "Building",
    "Creating",
    "Facilitating",
    "Mentoring",
    "Coaching",
    "Training",
    "Teaching",
    "Negotiating",
    "Influencing",
    "Persuading",
    "Debating",
    "Delegating",
    "Networking",
    "Championing",
    "Motivating",
    "Organizing",
    "Directing",
    "Consulting",
    "Advising",
    "Collaborating",
    "Resilient",
    "Efficient",
    "Focused",
    "Action-oriented",
    "Purposeful",
    "Visionary",
    "Strategic",
    "Proactive",
    "People-oriented",
    "Decisive",
    "Detail oriented",
    "Flexible",
    "Driven",
    "Compassionate",
    "Tough-minded",
    "Learner",
    "Passionate",
    "Kind",
    "Confident",
    "Friendly",
    "Risk-taker",
    "Thorough",
    "Creative",
    "Curious",
    "Honest",
    "Thoughtful",
    "Empathetic",
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  const saveStrengths = async () => {
    if (myStrengths.length < 3) {
      alert("Please fill in all strengths");
      return;
    }

    Analytics("Saved Strengths");

    try {
      const token = await getAccessTokenSilently();

      const saved = myStrengths.map((strength) => {
        return axios.post(
          `${apiServerUrl}/api/strengths`,
          {
            text: strength,
            userId: me.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      });

      await Promise.all(saved);

      return true;
    } catch (error) {
      return true;
    }
  };

  if (doingExercise) {
    return (
      <div
        id="strengthsExercise"
        className="strengthsExercise addon-strengths addon"
      >
        <div className="content">
          <img
            alt="avatar"
            src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
          />
          <div className="exerciseContainer">
            <h4>Roxanne Burbach</h4>
            <div className="bubble">
              <h5>Strengths Exercise</h5>
              <p>
                Choose three strengths from the list below. You'll be able to
                add to and edit these later on.
              </p>
              <div className="activity">
                <div className="left">
                  <h4>Strengths List</h4>
                  <div className="values">
                    {strengths.map((value) => (
                      <div
                        onClick={() => {
                          const newValues = [...myStrengths];
                          newValues.push(value);
                          setMyStrengths(newValues);
                          setStrengths(strengths.filter((v) => v !== value));
                        }}
                        className="value"
                        key={value.id}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="right">
                  <h4>My Strengths</h4>
                  <div className="values">
                  <div className={`myValue ${myStrengths[0]  ? 'filled' : ''}`}>
                      <span>1</span>
                      {myStrengths[0] && <p>{myStrengths[0]}</p>}
                    </div>
                    <div className={`myValue ${myStrengths[1]  ? 'filled' : ''}`}>
                      <span>2</span>
                      {myStrengths[1] && <p>{myStrengths[1]}</p>}
                    </div>
                    <div className={`myValue ${myStrengths[2]  ? 'filled' : ''}`}>
                      <span>3</span>
                      {myStrengths[2] && <p>{myStrengths[2]}</p>}
                    </div>
                  </div>

                  {!finished && (
                    <button
                      onClick={async () => {
                        setFinished(true);
                        try {
                          await saveStrengths(myStrengths);
                          continueLoop();
                        } catch (error) {
                          continueLoop();
                        }
                      }}
                      disabled={myStrengths.length < 3}
                      className="btn secondary"
                    >
                      I'm Finished
                    </button>
                  )}
                  {finished && <p>Great! All Set!</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="chatMessage" className="chatMessage addon-strengths addon">
      <div className="chatMessageBox">
      <img alt="avatar" src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg" />
        <div className="chatMessageTitle">
          <div className={`bubble ${!loading ? 'yellow' : ''}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Identify your strengths</h3>
                  <span>
                    We'll take you through an interactive activity designed to
                    help you quickly uncover a few of your strengths.
                  </span>
                </div>
                <div className="right">
                  <span>
                    <FontAwesomeIcon icon={faClock} /> Will take 2-3 minutes
                  </span>
                  <button
                    onClick={() => {
                      setDoingExercise(true);
                      setTimeout(() => {
                        divRef.current.scrollToBottom();
                      }, 400);
                    }}
                    className="btn secondary"
                  >
                    Lets Begin
                  </button>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
