import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../styles/pages/Admin/coupons.scss";
import { isAdmin } from "../../hooks/isAdmin";
import { Sidebar } from "./Sidebar";

export const LearningActivitiesEdit = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [learningActivity, setLearningActivity] = useState({ uses: 0 });
  const [me, setMe] = useState({
    id: null,
  });
  const id = props.match.params.id;

  useEffect(() => {
    getMe();
    getActivity();
  }, []);

  const getActivity = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/learning-activity/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setLearningActivity(response.data);
  };

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/learning-activity/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    setLearningActivity({
      ...learningActivity,
      image: upload.data.avatar,
    });
  };

  const createLearningActivity = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/learning-activity/${id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.data = {
      ...learningActivity,
    };
    const response = await axios(config);

    history.push("/admin/learningActivities");
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  return (
    <div className="couponsAdmin">
      <h2 className="heading1 highlight blue">Edit Learning Activity</h2>

      <Sidebar />

      <div className="right white-background add">
        <div className="input">
          <label>Title</label>
          <input
            onChange={(e) => {
              setLearningActivity({
                ...learningActivity,
                title: e.target.value,
              });
            }}
            value={learningActivity.title}
            type="text"
            placeholder="Title"
          />
        </div>
        <div className="input">
          <label>Description</label>
          <textarea
            onChange={(e) => {
              setLearningActivity({
                ...learningActivity,
                description: e.target.value,
              });
            }}
            value={learningActivity.description}
            type="text"
            placeholder="Description"
          ></textarea>
        </div>
        <div className="input">
          <label>Time To Complete</label>
          <input
            onChange={(e) => {
              setLearningActivity({
                ...learningActivity,
                timeToComplete: e.target.value,
              });
            }}
            value={learningActivity.timeToComplete}
            type="text"
            placeholder="Description"
          />
        </div>

        <div className="input">
          <label>URL</label>
          <input
            onChange={(e) => {
              setLearningActivity({
                ...learningActivity,
                url: e.target.value,
              });
            }}
            value={learningActivity.url}
            type="text"
            placeholder="URL"
          />
        </div>

        <div className="input">
          {learningActivity.image && (
            <img
              alt="learning"
              src={learningActivity.image}
              style={{ width: "100px", height: "100px" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(event) => {
              uploadAvatar(event.target.files);
            }}
          />
        </div>

        <button
          className="btn primary"
          onClick={async () => {
            createLearningActivity();
          }}
        >
          Edit Learning Activity
        </button>
      </div>
    </div>
  );
};
