import React, { useEffect, useState, useRef } from "react";
import "../../../styles/pages/highlightReel/components/highlightReelOnboarding.scss";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import topRight from "./images/topRight.svg";
import topLeft from "./images/TopLeft.png";
import dots from "./images/dots.svg";
import hr from "./images/hr.png";
import Fist from "./images/fist.svg";
import people from "./images/people.svg";
import trophy from "./images/trophy.svg";
import BlueBox from "./images/blueBox.svg";
import OrangeBox from "./images/orangeBox.svg";
import YellowBox from "./images/yellowBox.svg";
import logo from "./images/Logo.svg";

export const HighlightReelOnboarding = ({ setStep, me, next }) => {
  const [slideNumber, setSlideNumber] = useState(0);

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style.display = "none";
    }

    window.Intercom("update", {
      hide_default_launcher: true,
    });

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;width:100%";

    const navMobile = document.getElementsByClassName(
      "nav-bar__container__mobile"
    )[0];

    if (navMobile) {
      navMobile.style.display = "none";
    }

    setTimeout(() => {
      let moose = new Reveal();
      moose.initialize();
      moose.on("slidechanged", (event) => {
        setSlideNumber(event.indexh);

        localStorage.setItem("hasSeenHighlightReelWelcome", true);

        if (event.indexh === 3) {
          next();
        }
      });
    }, 100);

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName(
        "nav-bar__container__mobile"
      )[0];
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
      if (nav) {
        nav.style.display = "flex";
      }
      if (navMobile) {
        navMobile.style.display = "flex";
      }

      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <div
      id="HighlightReelOnboarding"
      className={`HighlightReelOnboarding ${slideNumber === 0 ? "start" : ""}`}
    >
      {slideNumber !== 0 && (
        <img alt="top right" src={topRight} className="topright" />
      )}

      {slideNumber === 0 && (
        <img alt="top left" src={topLeft} className="topleft" />
      )}

      {slideNumber === 0 && (
        <img alt="logo" src={logo} className="logo" />
      )}

      <div className="reveal">
        <div className="slides">
          <section className="content">
            <div className="left">
              <h2>
                Welcome to
                <br />
                Highlight Reel
              </h2>
              <img alt="dots" src={dots} className="dots" />
              <p className="subline">
                Visualize the career moments that make you proud and put a smile
                on your face.
              </p>
            </div>
          </section>
          <section>
            <div className="content second">
              <div className="left">
                <h2>What is a Highlight Reel?</h2>
                <p>
                  It's your go-to place to capture and revisit the moments that
                  make you feel great about your career. You might use it to:
                </p>
                <div className="box">
                  <img src={Fist} alt="fist" />
                  <p>Document positive feedback you received at work</p>
                </div>
                <div className="box">
                  <img src={people} alt="people" />
                  <p>Capture joyful career moments</p>
                </div>
                <div className="box">
                  <img src={trophy} alt="people" />
                  <p>Celebrate awards and achievements</p>
                </div>
              </div>
              <div className="right">
                <img src={hr} alt="hr" />
              </div>
            </div>
          </section>
          <section>
            <h2 style={{
              textAlign: "center",
            }}>Keeping your career highlights top of mind, helps you...</h2>
            <div className="boxImages">
              <img src={BlueBox} alt="blue box" />
              <p>Stay connected to your purpose</p>
            </div>
            <div className="boxImages">
              <img src={OrangeBox} className="orange" alt="orange box" />
              <p>Tell a better story in performance reviews</p>
            </div>
            <div className="boxImages">
              <img src={YellowBox} className="yellow" alt="blue box" />
              <p>Feel confident and ready to take on what's next</p>
            </div>
          </section>
          <section></section>
        </div>
      </div>
    </div>
  );
};
