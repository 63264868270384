import React, { useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";

export const InviteProgramRow = ({ invite, index, resend, finished }) => {
  const [success, setSuccess] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [resentInvite, setResentInvite] = React.useState(false);
  const [addExternalStrengths, setAddExternalStrengths] = React.useState(false);
  const [inputStrengths, setInputStrengths] = React.useState(new Array(10));
  const { apiServerUrl } = useEnv();

  useEffect(() => {}, []);

  const saveStrengths = async () => {
    const nonFilledIn = inputStrengths.filter((strength) => {
      return strength === null || strength === "";
    });

    if (nonFilledIn.length > 7) {
      alert("Please fill in all strengths");
      return;
    }

    const saved = inputStrengths.map((strength) => {
      if (!strength || strength === "") {
        return null;
      }

      return axios.post(`${apiServerUrl}/api/strengths`, {
        text: strength,
        userId: invite.userId,
        strengthInviteId: invite.id,
      });
    });

    await Promise.all(saved);

    setInputStrengths(new Array(10));
    setAddExternalStrengths(false);
    finished();
  };

  return (
    <div className="invite" key={index}>
      <img
        alt="icon"
        src={`https://placehold.co/100x100?text=${invite.firstName.charAt(0)}`}
      />
      <span>
        {invite.firstName} {invite.lastName}
      </span>
      <span className="small">
        {invite.type === "email" ? "Digital Feedback" : "In-person feedback"}
      </span>
      <span>{invite.email}</span>
      <div className="actions">
        {invite.strengths.length > 0 && (
          <button
            onClick={() => {
              setShowResults(!showResults);
            }}
            className="btn blank"
          >
            View Results
          </button>
        )}
        {invite.strengths.length === 0 && invite.type === "email" && !resentInvite && (
          <button
            onClick={() => {
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              resend(invite);
              setResentInvite(true);
            }}
            className="btn blank"
          >
            Resend Request
          </button>
        )}

        {invite.strengths.length === 0 && invite.type === "email" && resentInvite && (
          <button
            style={{
              opacity:.5
            }}
            disabled={true}
            className="btn blank off"
          >
            Request Resent
          </button>
        )}

        {invite.strengths.length === 0 && invite.type === "in_person" && (
          <button
            onClick={() => {
              setAddExternalStrengths(true);
            }}
            className="btn primary"
          >
            Add offline input
          </button>
        )}
      </div>
      {showResults && (
        <div className="modal">
          <div className="modal-content">
            <FontAwesomeIcon
              className="close"
              icon={faTimes}
              onClick={() => setShowResults(false)}
            />

            <div className="header">
              <div className="left">
                <img
                  alt="icon"
                  src={`https://placehold.co/100x100?text=${invite.firstName.charAt(
                    0
                  )}`}
                />
              </div>
              <div className="right">
                <h3>{invite.firstName}'s Feedback</h3>
                <h4>Left on: {moment(invite.createdAt).format("MM/DD/YY")}</h4>
              </div>
            </div>

            <h2>These were the words they used to describe your strengths:</h2>


            <div className="list">
              {invite.strengths.map((strength, index) => {
                return (
                  <div className="strength" key={index}>
                    <span>{strength.text}</span>
                  </div>
                );
              })}
            </div>

            {invite.comment && (
              <>
                <h4>{invite.firstName} chose these strengths because...</h4>
                <div className="comment">
                  <div className="left">
                    <img
                      alt="icon"
                      src={`https://placehold.co/100x100?text=${invite.firstName.charAt(
                        0
                      )}`}
                    />
                  </div>
                  <div className="right">
                    <h4>{invite.firstName}</h4>
                    <p>{invite.comment}</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            onClick={() => {
              setShowResults(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}

      {addExternalStrengths && (
        <div className="modal inviteProgramRow-modal">
          <div className="modal-content">
            <FontAwesomeIcon
              className="close"
              icon={faTimes}
              onClick={() => setAddExternalStrengths(false)}
            />
            <h3>{invite.firstName}'s feedback on your strengths</h3>
            <p>
            Enter the 10 words that Jake used to describe your strengths.
            </p>

            <div className="inputs">
              <div className="left">
                <div className="input">
                  <span>1</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[0] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[0]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>2</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[1] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[1]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>3</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[2] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[2]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>4</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[3] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[3]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>5</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[4] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[4]}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="right">
                <div className="input">
                  <span>6</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[5] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[5]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>7</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[6] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[6]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>8</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[7] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[7]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>9</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[8] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[8]}
                    placeholder=""
                  />
                </div>
                <div className="input">
                  <span>10</span>
                  <input
                    type="text"
                    onChange={(e) => {
                      const newInputStrengths = [...inputStrengths];
                      newInputStrengths[9] = e.target.value;
                      setInputStrengths(newInputStrengths);
                    }}
                    value={inputStrengths[9]}
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                saveStrengths();
              }}
              className="btn primary"
            >
              Save
            </button>
          </div>
          <div
            onClick={() => {
              setAddExternalStrengths(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
