import React, { useEffect, useRef, useState } from "react";
import "./styles/ChooseGoals.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import modalLightbulb from "../images/modalLightbulb.png";
import { Analytics } from "../../../hooks/analytics";

export const ChooseGoals = ({ setStep, me }) => {
  const [showIntro, setShowIntro] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [activeGoal, setActiveGoal] = useState("");
  const [coach, setCoach] = useState({});
  const [showOther, setShowOther] = useState(false);
  const otherBox = useRef(null);
  const history = useHistory();

  const [goals, setGoals] = useState([
    "I want to achieve better work-life balance",
    "I want to get promoted or advance within the organization",
    "I want to transition into a people management role",
    "I want to find out more about other areas of my business",
    "I want to grow and learn within my current role",
  ]);

  useEffect(() => {
    getMe();
    getVision();
  }, []);

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios(config);
      if (response.data.crew.length > 0) {
        setCoach(response.data.crew[0].coach);
      }

      return response.data;
    } catch (error) {
      return null;
    }
  };

  const getVision = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    const cover = response.data[0]?.cover;

    if (cover) {
      const newGoals = [...goals];
      const newVision = `I want to achieve my one year vision: ${cover.title}`;
      // Add newvision into new goals in the first positioin
      newGoals.unshift(newVision);
      setGoals(newGoals);
    }
  };

  const finish = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator/goals`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        goal: activeGoal,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    Analytics("Career Collaborators - Saved Goal");
    Analytics("Career Collaborators - Started");
    history.push("/board-of-advisors/enter");
  };

  return (
    <div className="chooseGoals">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              setStep(0);
            }}
          />

          <h3>Board of Advisors</h3>
        </div>

        <div className="inner">
          <div className="subtitle">
            <h4>Getting to know your goals</h4>
            <p>
              Your Board of Advisors should be crafted with your current career
              goal in mind. Choose a goal from the list below that best reflects
              what you want to achieve.
            </p>
            <p className="opaque">Please select one.</p>
          </div>

          <div className="goals">
            {goals.map((goal, index) => (
              <button
                key={index}
                className={`goal ${activeGoal === goal ? "active" : ""}`}
                onClick={() => {
                  setActiveGoal(goal);
                  setShowOther(false);
                }}
              >
                {goal}
              </button>
            ))}
            <button
              className={`goal ${showOther ? "active" : ""}`}
              onClick={() => {
                setShowOther(!showOther);
                setTimeout(() => {
                  otherBox.current.focus();
                }, 200);
                setActiveGoal("");
              }}
            >
              Other
            </button>

            {showOther && (
              <>
                <textarea
                  ref={otherBox}
                  value={activeGoal}
                  onChange={(e) => setActiveGoal(e.target.value)}
                />
              </>
            )}
          </div>

          <div className="bottom">
            <button className="btn blank">Back</button>
            <button
              disabled={!activeGoal || activeGoal === ""}
              onClick={() => {
                finish();
              }}
              className="btn secondary"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div className="right">
        <div className="white-background">
          <h3>My current career goal</h3>
          <p>The statement you select will appear in these empty boxes</p>

          <div className="goal">
            <h3>{activeGoal}</h3>
          </div>
        </div>
      </div>
      {showIntro && (
        <div className="modal">
          <div className="modal-content">
            <div className="left">
              <img src={modalLightbulb} alt="" />
            </div>
            <div className="right">
              <h2>Here's what to expect</h2>
              <p>
                In this activity, you'll build your Board of Advisors and
                identify gaps that may exist.
              </p>
              <h5>Here's what to do before your next session</h5>
              <div className="icons">
                <div className="item">
                  <span>1</span>
                  <div className="content">
                    <h4>
                      First, you'll identify your current career goal. You'll
                      choose different people for your Board based on what
                      you're trying to accomplish!
                    </h4>
                  </div>
                </div>
                <div className="item">
                  <span>2</span>
                  <div className="content">
                    <h4>
                      Then, you'll start to populate your board with people from
                      your life, company, and broader career network who might
                      support you in your journey.
                    </h4>
                  </div>
                </div>
                <div className="item">
                  <span>3</span>
                  <div className="content">
                    <h4>
                      Finally, you'll take a step back and evaluate the strength
                      of your network. What's working well and where might you
                      need to add or deepen relationships?.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="coachTips step">
                <div className="number">
                  <img src={coach?.avatar} alt="" />
                  <h4>Coach Tips from {coach?.first_name}</h4>
                </div>
                <div className="right">
                  <ul>
                    <li>
                      Don't get caught up with whether a relationship is formal
                      or acknowledged. If someone feels like a mentor (or
                      sponsor, etc.), add them!
                    </li>
                    <li>
                      Would you like to add someone to your board, but feel you
                      haven't done a great job staying in touch? That's ok!
                      Adding them to your board will help you get more
                      intentional about staying in touch moving forward.
                    </li>
                    <li>
                      We won't be notifying people that you've added them to
                      your board, so feel free to play around with it!
                    </li>
                  </ul>
                </div>
              </div>

              <div className="actions">
                <button
                  className="btn secondary"
                  onClick={() => {
                    setShowIntro(false);
                  }}
                >
                  Let's Begin
                </button>
              </div>
            </div>
          </div>
          <div
            className="skrim"
            onClick={() => {
              setShowIntro(false);
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
