import React, { useEffect, useState } from "react";
import "./styles/VisionsOnboarding.scss";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import { ChatMessage } from "../../../components/Chat/message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import TopLeft from "../images/TopLeft.svg";
import Logo from "../images/pngLogo.png";
import here from "../images/here.png";
import TopRight from "../images/TopRight.svg";
import example1 from "../images/example1.png";

export const VisionsOnboarding = ({ setStep, me }) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [rotate1, setRotate1] = useState(false);
  const [rotate2, setRotate2] = useState(false);
  const [rotate3, setRotate3] = useState(false);

  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: true,
    });
    setTimeout(() => {
      let moose = new Reveal();
      moose.initialize();
      moose.on("slidechanged", (event) => {
        setSlideNumber(event.indexh);

        if (event.indexh === 4) {
          setStep(1);
        }
      });
    }, 100);

    return () => {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <div
      id="VisionsOnboarding"
      className={`VisionsOnboarding ${slideNumber === 0 ? "blue" : ""}`}
    >
      {slideNumber === 0 && (
        <>
          <img src={TopLeft} className="topLeft" alt="topLeft" />
          <img src={Logo} className="logo" alt="logo" />
        </>
      )}

      {slideNumber !== 0 && (
        <img src={TopRight} className="topRight" alt="topRight" />
      )}

      <div className="reveal">
        <div className="slides">
          <section className="titleSlide">
            <div className="left">
              <h2>Session Pre-Work</h2>
              <h1>One-Year Vision</h1>
              <p>
                <FontAwesomeIcon icon={faClock} /> This will take you about 30
                minutes
              </p>
            </div>
          </section>

          <section className="content">
            <h2>You can't predict the future, but you can influence it</h2>
            <p>
              <b>
                That's why, in this activity, you'll imagine what you want your
                work life to look like one year from now.
              </b>
              <br />
              Creating a clear and compelling vision of your future can help you
              to:{" "}
            </p>
            <p>
              <ul>
                <li>
                  <span className="bold">Clarify your goals</span> so that they
                  guide your actions and decisions right now
                </li>
                <li>
                  <span className="bold">Boost your motivation</span> and help
                  you feel energized to achieve your goals
                </li>
                <li>
                  <span className="bold">Stay on track</span> and prioritize
                  what matters most
                </li>
              </ul>
            </p>
            <p>
              In short, it helps you be intentional so that you're actively
              moving your career in the direction you want it to go.
            </p>

            <img className="here" src={here} alt="here" />
          </section>

          <section className="content">
            <h2>How do you create a clear vision?</h2>
            <p>
              Here at Crew the answer involves time travel. We're kidding, kind
              of.
            </p>
            <p>
              You see, we'll get you started by giving you five prompts to
              answer. But, wed like you to answer the prompts like you're
              talking to us from one year in the future and all of the things in
              your one-year vision have already happened.
            </p>
            <p>
              This can feel tough for some people, but that's ok. Just give it
              your best shot.
            </p>

            <div className="flipBoxes">
              <div
                onClick={() => {
                  setRotate1(!rotate1);
                }}
                className={`box ${rotate1 ? "rotate" : ""}`}
              >
                <div className="top">
                  <h4>Personal Growth</h4>
                  <p>Who you hope to become</p>
                </div>
                <div className="inner">
                  <div className="front">
                    <h3>Looking back on the year...</h3>
                    <h2>How did you use your strengths?</h2>
                    <h5>Tap to see an example</h5>
                  </div>
                  <div className="back">
                    <h3>Looking back on the year...</h3>
                    <h2>Seizing opportunities in a dynamic environment</h2>
                    <p>
                      I've used my strengths in strategic planning and team
                      leadership to drive growth, resulting in a 25% market
                      share increase and consistently achieving project goals
                      through effective team management.
                    </p>
                    <h5>Tap to see category</h5>
                  </div>
                </div>
              </div>

              <div
                onClick={() => {
                  setRotate2(!rotate2);
                }}
                className={`box ${rotate2 ? "rotate" : ""}`}
              >
                <div className="top">
                  <h4>Relationship</h4>
                  <p>How you interact with others</p>
                </div>
                <div className="inner">
                  <div className="front yellow">
                    <h3>Looking back on the year...</h3>
                    <h2>
                      How have you contributed to the culture of your team?
                    </h2>
                    <h5>Tap to see an example</h5>
                  </div>

                  <div className="back yellow">
                    <h3>Looking back on the year...</h3>
                    <h2>Collaborating for team success</h2>
                    <p>
                      My colleagues have consistently praised my collaborative
                      approach, noting that I am a dependable team player who
                      consistently adds value. They appreciate my effective
                      communication skills, which have fostered a positive and
                      productive working environment.
                    </p>
                    <h5>Tap to see category</h5>
                  </div>
                </div>
              </div>

              <div
                onClick={() => {
                  setRotate3(!rotate3);
                }}
                className={`box ${rotate3 ? "rotate" : ""}`}
              >
                <div className="top">
                  <h4>Impact</h4>
                  <p>What results you hope to achieve</p>
                </div>
                <div className="inner">
                  <div className="front red">
                    <h3>Looking back on the year...</h3>
                    <h2>What results have you achieved?</h2>
                    <h5>Tap to see an example</h5>
                  </div>
                  <div className="back red">
                    <h3>Looking back on the year...</h3>
                    <h2>Empowering company progress</h2>
                    <p>
                      I will have successfully increased our quarterly sales
                      revenue by 15% through targeted marketing strategies,
                      expanded our customer base by 20%, and streamlined our
                      operations, reducing costs by 10% while maintaining high
                      levels of customer satisfaction.
                    </p>
                    <h5>Tap to see category</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <h2>
              All you need to do to complete the pre-work is to answer the five
              prompts (with as much detail and specificity as you can.)
            </h2>
            <p>
              In your Crew session, your coach and crew will help you get
              clearer on your vision and create a visual magazine that tells the
              story of your year ahead.
            </p>

            <div className="exampleBoxes">
              <div
                style={{
                  backgroundImage: `url(${example1})`,
                }}
                className="cover box"
              >
                <span>ONE-YEAR VISION</span>
                <h2>Leader of Sustainability Initiatives in Construction</h2>

                <div className="bottom">
                  <p>
                    <b>Champion Sustainable Building Solutions</b>
                    <br /> I'm on a mission close to my heart...
                  </p>
                </div>
              </div>

              <div className="box blue">
                <span>PERSONAL GROWTH</span>
                <h2>Seizing opportunities in a dynamic environment</h2>

                <p>
                  I viewed the dynamic and ever-changing business landscape as a
                  continuous opportunity for my personal and professional
                  development. The rapidly evolving market trends, emerging
                  technologies, and shifting consumer preferences serve as a
                  challenging yet rewarding platform for me to thrive and expand
                  my skill set.
                </p>
              </div>

              <div className="box yellow">
                <span>RELATIONSHIPS</span>
                <h2>Collaborating for team success</h2>

                <p>
                  I worked closely with colleagues to create a supportive team,
                  fostering an environment of collaboration and open
                  communication, where we collectively tackled challenges and
                  leveraged our individual strengths to not only meet but exceed
                  our goals.
                </p>
              </div>

              <div className="box red">
                <span>IMPACT</span>
                <h2>Empowering company progress</h2>

                <p>
                  Maintaining a high level of customer satisfaction and
                  retention is an ongoing challenge. I will focus on
                  understanding customer needs, addressing issues promptly, and
                  continuously improving our products and services.
                </p>
              </div>
            </div>
          </section>

          <section></section>
        </div>
      </div>
    </div>
  );
};
