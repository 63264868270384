import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../../styles/pages/storyboard/create.scss";

import { PreviewVideo } from "../../components/create/PreviewVideo";
import { WrittenPreview } from "../../components/create/WrittenPreview";

export const Resend = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { id } = useParams();
  const [story, setStory] = React.useState();
  const [me, setMe] = React.useState();

  useEffect(() => {
    getStory(id);
    getMe();
  }, []);

  const getStory = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/story/id/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setStory(response.data.story);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  if (!story || !me) {
    return <div>Loading...</div>;
  }

  return (
    <div className="view">
      {story.type === "video" && (
        <PreviewVideo standalone={true} me={me} story={story} />
      )}
      {story.type === "text" && (
        <WrittenPreview standalone={true} me={me} story={story} />
      )}
    </div>
  );
};
