import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Strengths/overview.scss";
import ZoomableBubbleChart from "../Path/components/ZoomableBubbleChart";
import { StrengthInviteModal } from "../../components/Chat/addons/strengthInviteModal";
import { InviteRow } from "./components/InviteRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPencil } from "@fortawesome/free-solid-svg-icons";
import { InviteProgramRow } from "./components/InviteProgramRow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const StrengthOverview = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [strengths, setStrengths] = useState([]);
  const [insights, setInsights] = useState([]);
  const [invites, setInvites] = useState([]);
  const [me, setMe] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [programUser, setProgramUser] = useState(false);
  const [tops, setTops] = useState([]);
  const [myStrengths, setMyStrengths] = useState([]);
  const [insight, setInsight] = useState({});
  const [showPresenterView, setShowPresenterView] = useState(false);
  const [addingInsight, setAddingInsight] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getMe();
    getStrengths();
    getInsights();
    getInvites();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const me = response.data;

    if (me.crew.length > 0) {
      setProgramUser(true);
    }
    setMe(me);
  };

  const saveInsight = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${apiServerUrl}/api/strengths/insights`,
      insight,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    getInsights();
    setAddingInsight(false);
    setInsight({});
  };

  const resend = async (invite) => {
    const token = await getAccessTokenSilently();
    const response = await axios.post(
      `${apiServerUrl}/api/strengths/invite/${invite.id}/email`,
      {
        email: invite.email,
        firstName: invite.firstName,
        personalMessage: invite.message,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/strengths/insights`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setInsights(response.data);
  };

  const getInvites = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let invites = response.data;

    const filteredInvites = invites.filter((invite) => {
      return invite.firstName !== null;
    });

    const fullFilteredInvites = filteredInvites.reduce(
      (accumulator, cv) => {
        if (cv.strengths.length > 0) {
          accumulator.responded.push(cv);
        } else {
          accumulator.nonresponded.push(cv);
        }
        return accumulator;
      },
      {
        responded: [],
        nonresponded: [],
      }
    );

    setInvites(fullFilteredInvites);
  };

  const getStrengths = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/strengths/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const invites = response.data;

    const countedStrengths = invites.reduce((accumulator, cv) => {
      let index = false;
      let found = null;

      // See if we already have this value in the accumulator
      accumulator.forEach((y, number) => {
        let inArray = y._id === cv.text;
        if (inArray && !found) {
          found = true;
          index = number;
          return;
        }
        return index;
      });

      // If its found add to the index
      if (index !== false) {
        accumulator[index].value++;
        return accumulator;
      }

      // If not add it to the accumulator
      accumulator.push({
        _id: cv.text,
        value: 1,
        createdAt: cv.createdAt,
      });

      return accumulator;
    }, []);

    const myStrengths = invites.filter((strength) => {
      if (!strength.strengthInviteId) return true;

      return false;
    });

    setMyStrengths(myStrengths);

    const sortedStrengths = countedStrengths.sort((a, b) => {
      return b.value - a.value;
    });

    const topStrengths = invites.filter((strength) => {
      if (strength.top) return true;

      return false;
    });

    setTops(topStrengths);

    setStrengths(sortedStrengths);
  };

  if (strengths.length < 1) return <div className="strengthsOverview"></div>;

  if (programUser) {
    return (
      <div className="strengthsOverview partner">
        <div className="top">
          <div className="left">
            <h3>Activity</h3>
            <h2>Strengths</h2>
          </div>
        </div>

        <div className="collaborators">
          <div className="top">
            <h3>Collaborators</h3>
            <button
              onClick={() => {
                history.push("/strengths", {
                  invite: true,
                });
              }}
              className="btn secondary"
            >
              Edit Collaborators
            </button>
          </div>

          {invites?.responded?.length === 0 && (
            <div className="nextSteps">
              <h5>Next Steps</h5>
              <p>
                <b>
                  If you chose to get feedback on your strengths in-person from
                  one or more collaborators:
                </b>{" "}
                be sure to manually input the strengths they identified for you.
                You can do this by selecting “Add offline input” and putting in
                their feedback.
                <br /> <br />
                <b>If you sent an email request to a collaborator,</b> you may
                have to follow up to ensure you have their feedback before your
                coaching session.
              </p>
            </div>
          )}

          {invites?.responded?.length > 0 && (
            <div className="nonResponded invites">
              <h5>Responded</h5>
              {invites?.responded?.map((invite, index) => {
                return (
                  <InviteProgramRow
                    key={index}
                    invite={invite}
                    resend={resend}
                    me={me}
                    finished={() => {
                      getInvites();
                    }}
                  />
                );
              })}
            </div>
          )}

          <div className="nonResponded invites">
            <h5>Not Responded</h5>
            {invites?.nonresponded?.map((invite, index) => {
              return (
                <InviteProgramRow
                  key={index}
                  invite={invite}
                  resend={resend}
                  me={me}
                  finished={() => {
                    getInvites();
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="topfive">
          <div className="top">
            {(!tops || tops.length === 0) && <h3>Your Strengths</h3>}
            {tops && tops.length > 0 && <h3>Your Top Strengths</h3>}
            <div className="buttons">
              {tops && tops.length > 0 && (
                <button
                  onClick={() => {
                    setShowPresenterView(true);
                  }}
                  className="btn"
                >
                  Presenter View
                </button>
              )}
              {tops && tops.length > 0 && (
                <button
                  onClick={() => {
                    history.push("/strengths/five");
                  }}
                  className="btn"
                >
                  <FontAwesomeIcon icon={faPencil} /> Edit my strengths
                </button>
              )}
            </div>
          </div>
          {(!tops || tops.length === 0) && (
            <h5 className="sub">
              Here is a list of the 10 strengths you’ve chosen so far
            </h5>
          )}

          {(!tops || tops.length === 0) && (
            <div className="strengths sidebyside">
              <div className="left">
                {myStrengths.map((strength, index) => {
                  if (index > 4) return null;
                  return (
                    <div className="strength" key={index}>
                      <span>{index + 1}</span>
                      <span>{strength.text}</span>
                    </div>
                  );
                })}
              </div>
              <div className="right">
                {myStrengths.map((strength, index) => {
                  if (index < 5) return null;
                  return (
                    <div className="strength" key={index}>
                      <span>{index + 1}</span>
                      <span>{strength.text}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {tops && tops.length > 0 && (
            <div className="strengths">
              {tops.map((strength, index) => {
                return (
                  <div className="strength" key={index}>
                    <span>{index + 1}</span>
                    <span>{strength.text}</span>
                  </div>
                );
              })}
            </div>
          )}

          {(!tops || tops.length === 0) && (
            <div className="laststeps">
              <h3>LAST STEPS</h3>
              <p>
                Once you have received feedback from your collaborators, reflect
                on what you have learned about your strengths. Narrow down your
                list to what you feel are your top five strengths. You'll share
                this with your crew in the session.
              </p>
              <button
                onClick={() => {
                  history.push("/strengths/five");
                }}
                className="btn secondary"
              >
                Add top 5 strengths
              </button>
            </div>
          )}
        </div>

        <div className="insights">
          <h3>My Insights</h3>
          {!insights ||
            (insights.length === 0 && (
              <div className="blue">
                <div className="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                      stroke="#0B455F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <h4>INSIGHT</h4>
                </div>

                <div className="input">
                  <h4>
                    What strengths did others notice that you didn't see
                    yourself?
                  </h4>
                  <textarea
                    onChange={(e) => {
                      setInsight({
                        description: e.target.value,
                        question:
                          "What strengths did others notice that you didn't see yourself?",
                        type: "strengths1",
                      });
                    }}
                    placeholder="Type your insight here..."
                  ></textarea>
                  <button
                    onClick={() => {
                      saveInsight();
                    }}
                    className="btn secondary"
                  >
                    Save
                  </button>
                </div>
              </div>
            ))}

          {insights && insights.length > 0 && !addingInsight && (
            <div className="insightsContainer">
              {insights.map((insight, index) => (
                <div className="insight">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                      stroke="#ECDDD8"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                      stroke="#1E6484"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
              <div className="insight add">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                    stroke="#ECDDD8"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                    stroke="#1E6484"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <h4>
                  What else are you taking away from completing this activity?
                </h4>
                <button
                  onClick={() => {
                    setAddingInsight(true);
                  }}
                  className="btn blank"
                >
                  <FontAwesomeIcon icon={faAdd} /> Add my own insight
                </button>
              </div>
            </div>
          )}

          {insights && insights.length > 0 && addingInsight && (
            <div className="blue">
              <div className="top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                    stroke="#0B455F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <h4>INSIGHT</h4>
              </div>

              <div className="input">
                <h4>
                  What other insights are you taking away from this activity?
                </h4>
                <textarea
                  onChange={(e) => {
                    setInsight({
                      description: e.target.value,
                      question:
                        "What other insights are you taking away from this activity?",
                      type: "strengths2",
                    });
                  }}
                  placeholder="Type your insight here..."
                ></textarea>
                <div className="buttons">
                  <button
                    onClick={() => {
                      saveInsight();
                    }}
                    className="btn secondary"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setAddingInsight(false);
                    }}
                    className="btn blank"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {showPresenterView && (
          <div className="modal presenterView">
            <div className="modal-content">
              <div className="top">
                <h3>My Top Strengths</h3>
                <button
                  onClick={() => {
                    setShowPresenterView(false);
                  }}
                >
                  Exit Presenter View
                </button>
              </div>
              {tops.map((strength, index) => {
                return (
                  <div className="strength" key={index}>
                    <span>{index + 1}</span>
                    <span>{strength.text}</span>
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                setShowPresenterView(false);
              }}
              className="skrim"
            ></div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="strengthsOverview">
      <div className="top">
        <div className="left">
          <h3>Activity</h3>
          <h2>Strengths</h2>
        </div>
      </div>

      <div className="content">
        <div className="card">
          <h4>Strengths</h4>
          <ZoomableBubbleChart data={strengths} />
        </div>
        <div className="card row">
          <h4>Top 10 Strengths</h4>
          <div className="left">
            {strengths.slice(0, 5).map((strength, index) => {
              return (
                <div className="strength" key={index}>
                  <span>{index + 1}</span>
                  <span>{strength._id}</span>
                </div>
              );
            })}
          </div>
          <div className="right">
            {strengths.length > 5 &&
              strengths.slice(5, 10).map((strength, index) => {
                return (
                  <div className="strength" key={index}>
                    <span>{index + 6}</span>
                    <span>{strength._id}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="invites">
        <div className="top">
          <h2>Collaborators Feedback</h2>
          <button
            onClick={() => {
              setShowFeedback(true);
            }}
            className="btn blue"
          >
            Request Feedback
          </button>
        </div>
        {!invites ||
          (invites.length === 0 && (
            <div className="noInvites">No invites yet!</div>
          ))}
        {invites &&
          invites.length > 0 &&
          invites.map((invite, index) => {
            return (
              <InviteRow key={index} invite={invite} resend={resend} me={me} />
            );
          })}
      </div>

      {showFeedback && (
        <StrengthInviteModal
          close={() => {
            setShowFeedback(false);
          }}
          me={me}
          setShowInviteModal={setShowInviteModal}
        />
      )}
    </div>
  );
};
