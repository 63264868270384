/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/roleModelValuesCreation.scss";
import { Analytics } from "../../hooks/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { OnboardedValueFlow } from "../Values/components/OnboardedValueFlow";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { OnboardedValueFlowAdd } from "../Values/components/OnboardedValueFlowAdd";

export const RoleModelValuesCreation = ({me}) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [roleModels, setRoleModels] = useState([]); // [{id: 1, url: "https://via.placeholder.com/150"}
  const [values, setValues] = useState([]);
  const [valuesOnboarding, setValuesOnboarding] = useState([]);
  const [activeValue, setActiveValue] = useState(); // {id: 1, text: "value", description: "description", image: "https://via.placeholder.com/150"
  const [activeImages, setActiveImages] = useState([]); // [{id: 1, url: "https://via.placeholder.com/150"}

  useEffect(() => {
    getValueImages();
    getValues(true);

    Analytics("Started Role Model Values Creation");

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width:96rem";
      }
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const saveActiveValue = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model/values/${activeValue.id}/images`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        roleModels: activeImages.map((img) => img.id),
        summary: activeValue.summary,
        text: activeValue.text,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);

    // Reset the active images
    setActiveImages([]);

    Analytics("Role Models - Edited Onboarding Value");

    // Remove this from onboarded values
    const newValuesOnboarding = valuesOnboarding.filter(
      (v) => v.id !== activeValue.id
    );
    setValuesOnboarding(newValuesOnboarding);

    // Reset the active value
    setActiveValue({});
    getValues();
  };

  const deleteValue = async (valueId) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/role-model/values/${valueId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("Role Models - Deleted Value");

    getValues();
  };

  const getValues = async (fromOnboarding) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/role-model/values/all`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValues(response.data);
  };


  const getValueImages = async () => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/role-model`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setRoleModels(response.data);
  };

  return (
    <div className="roleModelValuesCreation">
      <div className="white-background">
        <div className="left">
          <h2>
            <FontAwesomeIcon
              onClick={() => {
                history.goBack();
              }}
              icon={faTimes}
            />{" "}
            Identify Values
          </h2>

          <h3>Write out your values</h3>

          <p>
            <b>Instructions:</b>
            <ol>
              <li>
                Take a look at your list of role models. Identify some of the
                values that you see in them.
              </li>
              <li>Provide a description of what that value means to you.</li>
              <li>
                Click a photo on the right to associate that value with the role
                model that embodies it.
              </li>
            </ol>
          </p>

          <p>
            Aim to write out at least 3-5 values. You'll be able to add to and
            edit this list later.
          </p>

          <div className="valuesScrolling">
            <OnboardedValueFlowAdd
              key={"add"}
              setActiveImages={(images) => {
                setActiveImages(images);
              }}
              me={me}
              remove={async (value) => {
                await deleteValue(value.id);
                setActiveImages([]);
                setActiveValue({});
              }}
              setActiveValue={(value) => {
                setActiveValue(value);
              }}
              activeImages={activeImages}
              getValues={getValues}
            />

            {values.length > 0 &&
              values.map((value) => (
                <OnboardedValueFlow
                  key={value.id}
                  setActiveImages={(images) => {
                    setActiveImages(images);
                  }}
                  remove={async (value) => {
                    await deleteValue(value.id);
                    setActiveImages([]);
                    setActiveValue({});
                  }}
                  setActiveValue={(value) => {
                    setActiveValue(value);
                  }}
                  activeImages={activeImages}
                  value={value}
                  save={async () => {
                    saveActiveValue();
                  }}
                />
              ))}
          </div>

          <div className="bottom">
            <button
              className="btn blank"
              onClick={() => history.push("/dashboard")}
            >
              Close &amp; Exit
            </button>

            <button
              className="btn primary"
              onClick={async () => {
                history.push("/role-models/dashboard");
              }}
            >
              Continue
            </button>
          </div>
        </div>

        <div className="right">
          <div className="top">
            <div className="left">
              <h3>My Role Models</h3>
            </div>
            <div className="right">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/role-models", {
                    editing: true,
                  });
                }}
                to="/values/"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Role Models
              </a>
            </div>
          </div>
          <ResponsiveMasonry
            columnsCount={3}
            columnsCountBreakPoints={{
              350: 1,
              750: 1,
              900: 2,
              1200: 3,
              1400: 4,
            }}
            className="masonry-grid"
          >
            <Masonry gutter={15}>
              {roleModels.map((roleModel) => (
                <div
                  onClick={() => {
                    if (!activeValue?.id) return false;
                    if (
                      activeImages.filter((img) => img.id === roleModel.id)
                        .length > 0
                    ) {
                      const newActiveImages = activeImages.filter(
                        (img) => img.id !== roleModel.id
                      );
                      setActiveImages(newActiveImages);
                    } else {
                      const newActiveImages = [...activeImages, roleModel];
                      setActiveImages(newActiveImages);
                    }
                  }}
                  key={roleModel.id}
                  className="single-highlight"
                >
                  <div
                    className={`${!activeValue?.id ? "noHover" : ""} photo ${
                      activeImages.filter((item) => {
                        return item.id === roleModel.id;
                      }).length === 1
                        ? "selected"
                        : ""
                    }`}
                  >
                    <img src={roleModel.image} alt="value" />
                    <h3>{roleModel.name}</h3>
                  </div>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </div>
  );
};
