import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/pages/Membership/index.scss";
import { useEnv } from "../../context/env.context";
import { Analytics } from "../../hooks/analytics";
import {
  PaymentElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import MembershipBottomLeft from "../../images/membership_bottom_left.png";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

export const MembershipsCompany = (props) => {
  const [membership, setMembership] = useState([]);
  const [me, setMe] = useState();
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [newPrice, setNewPrice] = useState(null);
  const [key, setKey] = useState(null);
  const { companyId } = queryString.parse(props.location.search);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [freeError, setFreeError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paid, setPaid] = useState(false);
  const history = useHistory();
  const [coupon, setCoupon] = useState(null);

  useEffect(() => {
    const getData = async () => {
      Analytics("Visited Membership Purchase Page");
      if (companyId) {
        history.push(`/membership/company`);
      }
      await getMemberships();
      await getMe();
    };

    getData();

    const nav = document.querySelector(".nav-bar__container");
    const container = document.getElementsByClassName("content_container")[0];

    if (container) {
      container.style = "max-width: 100%;width:100%";
    }

    if (nav) {
      nav.style.display = "none";
    }

    return () => {
      nav.style.display = "block";
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const getMe = async () => {
    setLoading(true);
    let token = null;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      loginWithRedirect({
        appState: { returnTo: "/membership" },
      });
    }

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      setLoading(false);

      setMe(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const saveFreeUser = async () => {
    setFreeError(false);
    let token = null;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      if (companyId) {
        return loginWithRedirect({
          appState: { returnTo: "/membership/company" },
        });
      } else {
        return loginWithRedirect({
          appState: { returnTo: "/membership" },
        });
      }
    }

    const config = {
      url: `${apiServerUrl}/api/memberships/free`,
      method: "POST",
      data: {
        code: code,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      history.push("/onboarding");
    } catch (error) {
      setFreeError(true);
      console.log(error);
    }
  };

  const getKey = async () => {
    let token = null;

    if (coupon && coupon.type === "FREE") {
      return saveFreeUser();
    }
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      return loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }

    const config = {
      url: `${apiServerUrl}/api/users/membership`,
      method: "POST",
      data: {
        membershipId: membership.id,
        discountCode: code,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      setKey(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDiscountCode = async () => {
    try {
      const response = await axios.get(`${apiServerUrl}/api/coupons/${code}`);
      const data = response.data;

      setCoupon(data);

      if (data.type === "PERCENT") {
        let newPrice =
          membership.price - membership.price * (data.amount / 100);
        setNewPrice(newPrice);
      } else {
        let newPrice = membership.price - data.amount;
        setNewPrice(newPrice);
      }
      Analytics(code);
    } catch (error) {
      setError({
        message: "Invalid Code",
      });
    }
  };

  const getMemberships = async () => {
    const response = await axios.get(`${apiServerUrl}/api/memberships/1`);
    const data = response.data;

    setMembership(data);
    return data;
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="membershipApplication">
      {!key && (
        <div className="left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.0294 11.0294C36.0294 12.2573 35.8287 13.4384 35.4584 14.5416C36.5616 14.1713 37.7427 13.9706 38.9706 13.9706C45.062 13.9706 50 18.9086 50 25C50 31.0914 45.062 36.0294 38.9706 36.0294C37.7427 36.0294 36.5616 35.8288 35.4584 35.4584C35.8287 36.5616 36.0294 37.7427 36.0294 38.9706C36.0294 45.062 31.0914 50 25 50C18.9086 50 13.9706 45.062 13.9706 38.9706C13.9706 37.7427 14.1712 36.5616 14.5416 35.4584C13.4384 35.8287 12.2573 36.0294 11.0294 36.0294C4.93804 36.0294 0 31.0914 0 25C0 18.9086 4.93804 13.9706 11.0294 13.9706C12.2573 13.9706 13.4384 14.1712 14.5416 14.5416C14.1712 13.4384 13.9706 12.2573 13.9706 11.0294C13.9706 4.93804 18.9086 0 25 0C31.0914 0 36.0294 4.93804 36.0294 11.0294ZM21.4878 21.4878C21.8582 22.591 22.0588 23.7721 22.0588 25C22.0588 26.2279 21.8582 27.409 21.4878 28.5122C22.591 28.1418 23.7721 27.9412 25 27.9412C26.2279 27.9412 27.409 28.1418 28.5122 28.5122C28.1418 27.409 27.9412 26.2279 27.9412 25C27.9412 23.7721 28.1418 22.591 28.5122 21.4878C27.409 21.8582 26.2279 22.0588 25 22.0588C23.7721 22.0588 22.591 21.8582 21.4878 21.4878ZM31.6176 11.0294C31.6176 14.6842 28.6548 17.6471 25 17.6471C21.3452 17.6471 18.3824 14.6842 18.3824 11.0294C18.3824 7.37459 21.3452 4.41176 25 4.41176C28.6548 4.41176 31.6176 7.37459 31.6176 11.0294ZM31.6176 38.9706C31.6176 42.6254 28.6548 45.5882 24.9999 45.5882C21.3451 45.5882 18.3823 42.6254 18.3823 38.9706C18.3823 35.3158 21.3451 32.3529 24.9999 32.3529C28.6548 32.3529 31.6176 35.3158 31.6176 38.9706ZM38.9706 31.6176C42.6254 31.6176 45.5882 28.6548 45.5882 25C45.5882 21.3452 42.6254 18.3824 38.9706 18.3824C35.3158 18.3824 32.3529 21.3452 32.3529 25C32.3529 28.6548 35.3158 31.6176 38.9706 31.6176ZM17.6471 24.9999C17.6471 28.6547 14.6843 31.6175 11.0295 31.6175C7.37463 31.6175 4.41181 28.6547 4.41181 24.9999C4.41181 21.345 7.37463 18.3822 11.0295 18.3822C14.6843 18.3822 17.6471 21.345 17.6471 24.9999Z"
              fill="white"
            />
          </svg>

          <img alt="icon" src={MembershipBottomLeft} className="bottom-left" />

          <h2>Welcome To Crew</h2>

          <p>
            Congratulations on starting your journey toward clarity and
            confidence in your career. We're so excited to have you.
            <br /> <br /> <b>Get started with a Crew membership 👉</b>
          </p>
        </div>
      )}
      {key && (
        <div className="left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.0294 11.0294C36.0294 12.2573 35.8287 13.4384 35.4584 14.5416C36.5616 14.1713 37.7427 13.9706 38.9706 13.9706C45.062 13.9706 50 18.9086 50 25C50 31.0914 45.062 36.0294 38.9706 36.0294C37.7427 36.0294 36.5616 35.8288 35.4584 35.4584C35.8287 36.5616 36.0294 37.7427 36.0294 38.9706C36.0294 45.062 31.0914 50 25 50C18.9086 50 13.9706 45.062 13.9706 38.9706C13.9706 37.7427 14.1712 36.5616 14.5416 35.4584C13.4384 35.8287 12.2573 36.0294 11.0294 36.0294C4.93804 36.0294 0 31.0914 0 25C0 18.9086 4.93804 13.9706 11.0294 13.9706C12.2573 13.9706 13.4384 14.1712 14.5416 14.5416C14.1712 13.4384 13.9706 12.2573 13.9706 11.0294C13.9706 4.93804 18.9086 0 25 0C31.0914 0 36.0294 4.93804 36.0294 11.0294ZM21.4878 21.4878C21.8582 22.591 22.0588 23.7721 22.0588 25C22.0588 26.2279 21.8582 27.409 21.4878 28.5122C22.591 28.1418 23.7721 27.9412 25 27.9412C26.2279 27.9412 27.409 28.1418 28.5122 28.5122C28.1418 27.409 27.9412 26.2279 27.9412 25C27.9412 23.7721 28.1418 22.591 28.5122 21.4878C27.409 21.8582 26.2279 22.0588 25 22.0588C23.7721 22.0588 22.591 21.8582 21.4878 21.4878ZM31.6176 11.0294C31.6176 14.6842 28.6548 17.6471 25 17.6471C21.3452 17.6471 18.3824 14.6842 18.3824 11.0294C18.3824 7.37459 21.3452 4.41176 25 4.41176C28.6548 4.41176 31.6176 7.37459 31.6176 11.0294ZM31.6176 38.9706C31.6176 42.6254 28.6548 45.5882 24.9999 45.5882C21.3451 45.5882 18.3823 42.6254 18.3823 38.9706C18.3823 35.3158 21.3451 32.3529 24.9999 32.3529C28.6548 32.3529 31.6176 35.3158 31.6176 38.9706ZM38.9706 31.6176C42.6254 31.6176 45.5882 28.6548 45.5882 25C45.5882 21.3452 42.6254 18.3824 38.9706 18.3824C35.3158 18.3824 32.3529 21.3452 32.3529 25C32.3529 28.6548 35.3158 31.6176 38.9706 31.6176ZM17.6471 24.9999C17.6471 28.6547 14.6843 31.6175 11.0295 31.6175C7.37463 31.6175 4.41181 28.6547 4.41181 24.9999C4.41181 21.345 7.37463 18.3822 11.0295 18.3822C14.6843 18.3822 17.6471 21.345 17.6471 24.9999Z"
              fill="white"
            />
          </svg>

          <img alt="icon" src={MembershipBottomLeft} className="bottom-left" />

          <h2>Crew Membership</h2>

          <h4>Included in your membership</h4>

          <ul>
            <li>Founding member discounted prices</li>
            <li>Access to the Crew tools and platform for a year</li>
          </ul>

          <h4>Total: ${newPrice || membership.price}</h4>
        </div>
      )}
      {!key && (
        <div className="right">
          <div className="white-background">
            <button
              className="btn link"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </button>
            <h3>
              <span className="highlight yellow">Crew Company Code</span>
            </h3>

            <div className="discountCode">

              <div className="code">
                  {error && <div className="error">{error.message}</div>}
                  <input
                    type="text"
                    placeholder="Enter code"
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                  <button
                    className="btn secondary"
                    onClick={() => {
                      getDiscountCode();
                    }}
                  >
                    Apply
                  </button>
                </div>
            </div>

            <div className="price">
              <h3>
                Price: <span>${membership.price} USD billed annually</span>
              </h3>
              {newPrice !== null && (
                <h3>
                  Discount: <span>${membership.price - newPrice} USD</span>
                </h3>
              )}
              {newPrice !== null && (
                <h3>
                  Total Price : <span>${newPrice} USD</span>
                </h3>
              )}
            </div>

            <button
              className="btn primary"
              onClick={() => {
                getKey();
              }}
            >
              Let Me In!
            </button>
            <div className="links">
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push("/membership");
              }}
            >
              Don't have a company code?
            </a>
            <a href="https://find-your-crew.typeform.com/to/RSyViDfj" target="_blank">Want your employer to sponsor your Crew membership?</a>
            </div>
          </div>
        </div>
      )}
      {key && !paid && (
        <div className="right">
          <div className="white-background">
            <button
              className="btn link"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </button>
            <h3>
              <span className="highlight yellow">Checkout</span>
            </h3>
            {!showCompanyModal && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: key.client_secret,
                }}
              >
                <Checkoutform membership={membership} me={me} />
              </Elements>
            )}
          </div>
        </div>
      )}
      {showCompanyModal && (
        <div className="modalContainer">
          <div className="modal">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Getting Started</h2>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowCompanyModal(false);
                  }}
                  icon={faTimes}
                />
              </div>
              <div className="modal-main">
                {freeError && <div className="error">Invalid Code</div>}
                <h3>Welcome! Have a secret code from your employer?</h3>
                <label>Enter the code below</label>
                <input
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  placeholder="Company Code"
                  type="text"
                />
                <a
                  onClick={() => {
                    setShowCompanyModal(false);
                  }}
                  href="#"
                >
                  I don't have a code yet
                </a>
              </div>
              <div className="modal-bottom">
                <button
                  disabled={code === ""}
                  className="btn secondary"
                  onClick={() => {
                    saveFreeUser();
                  }}
                >
                  Let me in!
                </button>
              </div>
            </div>
            <div
              className="skrim"
              onClick={() => {
                setShowCompanyModal(false);
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

const Checkoutform = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    Analytics("Bought Membership");

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.origin + `/membership/success`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error);
      console.log(result.error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form>
      {error && <div className="error">{error.message}</div>}
      <PaymentElement />
      <button
        className="btn primary"
        style={{
          marginTop: "2rem",
        }}
        onClick={(event) => {
          handleSubmit(event);
        }}
      >
        Purchase
      </button>
    </form>
  );
};
