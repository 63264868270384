import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Crews/edit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isAdmin } from "../../../hooks/isAdmin";

export const CrewsEdit = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [newCrew, setNewCrew] = useState({
    name: "",
    description: "",
  });
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const id = props.match.params.id;

  useEffect(() => {
    getMe();
    getCrew();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const editProgram = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        name: newCrew.name,
        description: newCrew.description,
        startDate: newCrew.startDate,
        time: newCrew.time,
        endtime: newCrew.endtime,
        groupId: newCrew.groupId,
        adminId: newCrew.adminId,
        authorId: newCrew.authorId,
        type: newCrew.type,
        link: newCrew.link
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      history.push("/admin/crews");
    }
  };

  const getCrew = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const data = { ...response.data };

    if (data.startDate) {
      data.startDate = moment(data.startDate).toDate();
    } else {
      data.startDate = null;
    }

    if (data.time) {
      data.time = moment(data.time).toDate();
    } else {
      data.time = null;
    }

    if (data.endtime) {
      data.endtime = moment(data.endtime).toDate();
    } else {
      data.endtime = null;
    }
    setNewCrew(data);
  };

  if (!newCrew.id) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ProgramEdit">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>crews</NavLink> /{" "}
        </div>
        <div className="breadcrumb">
          <div className="breadcrumb">
            <NavLink to={`/admin/crews/${newCrew.id}`}>{newCrew.name}</NavLink>{" "}
          </div>
        </div>
      </div>
      <div className="container white-background">
        <h2 className="heading1 highlight blue">Edit {newCrew.name}</h2>
        <div className="row">
          <div className="inputBox">
            <label className="body-text">Program Name</label>
            <input
              onChange={(e) => {
                const newCrewObject = { ...newCrew };
                newCrewObject.name = e.target.value;
                setNewCrew(newCrewObject);
              }}
              type="text"
              value={newCrew.name}
              className="input"
            />
          </div>

          <button
            className="btn secondary"
            onClick={() => {
              editProgram();
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Crew
          </button>
        </div>
      </div>
    </div>
  );
};
