import { faMessage, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../styles/components/video.scss";
import { CrewImage } from "./CrewImage";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../context/env.context";
import axios from "axios";

export const Video = (props) => {
  const history = useHistory();
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [awaitingReload, setAwaitingReload] = useState(false);


  const threeDaysAgo = () => {
    const today = new Date();
    var diff = moment(today).diff(props.story.createdAt, "days");
    return diff > 2;
  };

  const hide = async (type) => {
    setAwaitingReload(true);
    const token = await getAccessTokenSilently();

    let data = {};

    if (type === "to") {
      data = {
        to: true,
      };
    } else {
      data = {
        from: true,
      };
    }

    const response = await axios.patch(
      `${apiServerUrl}/api/story/${props.story.id}/hide`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTimeout(() => {
      props.refresh();
      setAwaitingReload(false);
    }, 2000);
  };

  if(!props.story) return null

  return (
    <div className="videoPlayer">
      {props.type === "received" && !awaitingReload && (
        <div
          onClick={() => {
            hide("from");
          }}
          className="hide"
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      {props.type === "sent" && !awaitingReload && (
        <div
          onClick={() => {
            hide("to");
          }}
          className="hide"
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      {awaitingReload && (
        <div className="awaiting">
          <p>Story Hidden</p>
        </div>
      )}
      {props.type === "sent" && !props.story.recievingUser && (
        <div
          onClick={(e) => {
            e.preventDefault();
            history.push("/storyboard/story/resend/" + props.story.id);
          }}
          className="notSeen"
        >
          This story has not been viewed.
          {threeDaysAgo(props.story.createdAt) && <span>Resend</span>}
        </div>
      )}
      <div
        onClick={() => {
          if(props.modalFlow) {
            return props.clicked();
          }
          if (!props.signup) {
            history.push(`/view/${props.story.id}`);
          } else {
            loginWithRedirect({
              appState: { returnTo: window.location.pathname },
            });
          }
        }}
        className="container"
      >
        <div
          style={{
            backgroundImage: `url(${props.story.thumbnail})`,
          }}
          className="image"
        >
          <div className="icon">
            {props.type === "sent" && !props.story.recievingUser ? (
              <svg
                style={{
                  height: "4.5rem",
                  width: "auto",
                  margin: "0 auto",
                  textAlign: "center",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="221"
                height="222"
                viewBox="0 -4 45 60"
                fill="none"
              >
                <path
                  d="M30.6287 38.6699L6.20105 14.2425V45.8272C6.20105 47.0829 6.51497 48.0923 7.14282 48.8552C7.77065 49.6184 8.6158 50 9.67829 50C10.2578 50 10.8084 49.8986 11.33 49.6959C11.8516 49.4929 12.4021 49.2175 12.9817 48.8698L30.6287 38.6699ZM40.8574 32.7586L46.7687 29.3394C47.9858 28.6247 48.831 27.992 49.3042 27.4414C49.7776 26.8909 50.0142 26.2292 50.0142 25.4565C50.0142 24.6838 49.7776 24.0222 49.3042 23.4716C48.831 22.921 47.9858 22.298 46.7687 21.6026L12.9817 2.04318C12.4601 1.73409 11.9723 1.47813 11.5183 1.27529C11.0643 1.07245 10.5862 0.971034 10.084 0.971034C9.94875 0.971034 9.83766 1.02416 9.75072 1.13041C9.6638 1.23666 9.61068 1.36705 9.59135 1.5216L40.8574 32.7586ZM43.5234 46.6387C43.9677 47.083 44.4989 47.3052 45.1171 47.3052C45.7353 47.3052 46.2568 47.083 46.6817 46.6387C47.1068 46.1944 47.3241 45.668 47.3336 45.0596C47.3433 44.4509 47.126 43.9244 46.6817 43.4801L3.82494 0.652287C3.38063 0.227294 2.84939 0.00996723 2.23122 0.000308265C1.61305 -0.0093507 1.0818 0.207976 0.637493 0.652287C0.212498 1.07728 0 1.6037 0 2.23153C0 2.85936 0.212498 3.38577 0.637493 3.81076L43.5234 46.6387Z"
                  fill="white"
                  fill-opacity="0.8"
                />
              </svg>
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </div>
        </div>

        {/* {!props.hide && (
        <CardContextMenu hide={props.hide} refresh={props.refresh} story={props.story} /> 
        )} */}
      </div>

      {props.type === "how" && (
        <div
          onClick={() => {
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.user}
          />
          <p>
            Welcome! <br />
            <span>{moment(props.story.createdAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {props.type === "video" && !props.modalFlow && (
        <div
          onClick={() => {
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.user}
          />
          <p>
            You <br />
            <span>{moment(props.story.createdAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "received" && !props.modalFlow  && !props.signup && (
        <div
          onClick={() => {
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story?.sendingUser}
          />
          <p>
            {props.story?.sendingUser?.first_name}{" "}
            {props.story?.sendingUser?.last_name}
            <br />
            <span>{moment(props.story?.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "received" && !props.modalFlow  && props.signup && (
        <div
          onClick={() => {
            loginWithRedirect({
              appState: { returnTo: window.location.pathname },
            });
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story?.sendingUser}
          />
          <p>
            {props.story?.sendingUser?.first_name}{" "}
            {props.story?.sendingUser?.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "received" && props.modalFlow && (
        <div
          onClick={() => {
            props.clicked();
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
            }}
            me={props.story?.sendingUser}
          />
          <p>
            {props.story?.sendingUser?.first_name}{" "}
            {props.story?.sendingUser?.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "sent" && props.story.recievingUser && (
        <div
          onClick={() => {
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.recievingUser}
          />
          <p>
            {props.story.recievingUser.first_name}{" "}
            {props.story.recievingUser.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "sent" && !props.story.recievingUser && (
        <div
          onClick={() => {
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="info empty"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={{
              first_name: "jake",
              avatar: `https://placehold.co/100x100?text=${props.story.toFirstName.charAt(
                0
              )}`,
            }}
          />
          <p>
            {props.story.toFirstName} <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {!props.hideAction && (
        <div
          onClick={() => {
            if(props.modalFlow) {
              return props.clicked();
            }
            history.push(`/storyboard/view/${props.story.id}`);
          }}
          className="actionBox"
        >
          <div className="responses">
            <FontAwesomeIcon icon={faMessage} />{" "}
            <span>{props.story?.responses?.length} replies</span>
          </div>
        </div>
      )}
    </div>
  );
};
