import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useEnv } from "../context/env.context";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../styles/pages/profile.scss";

export const Profile = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = React.useState();
  const history = useHistory();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if(response.data.waitlist) {
      return history.push({
        pathname: "/waitlist",
      });
    }

    setMe(response.data);
  };

  return (
    <div className="profile">
      {/* <h3>My Crew</h3>

      <div className="crew_container">
      <div
          style={{
            position: "relative",
          }}
          className="crew"
          onClick={()=>{
            history.push("/create");
          }}
        >
          <img src='https://place-hold.it/80/80/fff?text=' alt="crew" />
          <span>Add Story</span>
          <FontAwesomeIcon
            style={{
              position: "absolute",
              padding: "2px 3px",
              border: "2px solid black",
              borderRadius: "50%",
              bottom: "28px",
              right: "5px",
              backgroundColor: "white",
              fontSize: "16px",
            }}
            icon={faPlus}
          />
        </div>
        {crewImages.map((image) => (
        <div
          style={{
            position: "relative",
          }}
          className="crew"
        >
          <img onError={(e)=>{
              e.target.src = `https://place-hold.it/80/80/fff&text=${image.noImage}&fontsize=14`
          }} src={image.user.avatar} alt="crew" />
          <span>{image.user.first_name}</span>
        </div>
        ))}
      </div> */}

      <div className="dashboard__header">
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `https://placehold.co/100x100?text=${me.first_name.charAt(0) + me.last_name.charAt(0)}`;
          }}
          src={me?.avatar}
          alt="avatar"
        />
        <h3>
          {me?.first_name} {me?.last_name}
        </h3>

        <Link className="btn empty" to="/profile/edit">Edit Profile</Link>
      </div>

    </div>
  );
};
