import React, { useEffect, useState } from "react";
import "../../../styles/pages/storyboard/components/StoryboardWelcomeModal.scss";
import { Analytics } from "../../../hooks/analytics";
import onboardingimage from "../svgs/onboarding.svg";
export const StoryboardWelcomeModal = ({ clicked, story, me }) => {
  useEffect(() => {}, []);

  return (
    <div className="StoryboardWelcomeModal">
      <div className="modal">
        <div className={`left`}>
          <div className={`story`}>
            <img src={onboardingimage} alt="onboarding" />
          </div>
          <div className="info"></div>
        </div>
        <div className="right">
          <h3>Welcome to Stories!</h3>
          <p>
            Meet Stories, your space for personalizing gratitude. Here, you
            create and share messages that celebrate growth and impact. This
            journey of storytelling allows for reflection, self-awareness, and a
            unique way to say 'thank you.'
          </p>

          <h5>How it works</h5>
          <div className="box">
            <svg
              width="43"
              height="44"
              viewBox="0 0 43 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.4505" cy="22.177" r="21.4505" fill="#013247" />
              <path
                d="M25 18.5C25 17.7044 24.6839 16.9413 24.1213 16.3787C23.5587 15.8161 22.7956 15.5 22 15.5C21.2044 15.5 20.4413 15.8161 19.8787 16.3787C19.3161 16.9413 19 17.7044 19 18.5C19 19.2956 19.3161 20.0587 19.8787 20.6213C20.4413 21.1839 21.2044 21.5 22 21.5C22.7956 21.5 23.5587 21.1839 24.1213 20.6213C24.6839 20.0587 25 19.2956 25 18.5ZM17.5 18.5C17.5 17.3065 17.9741 16.1619 18.818 15.318C19.6619 14.4741 20.8065 14 22 14C23.1935 14 24.3381 14.4741 25.182 15.318C26.0259 16.1619 26.5 17.3065 26.5 18.5C26.5 19.6935 26.0259 20.8381 25.182 21.682C24.3381 22.5259 23.1935 23 22 23C20.8065 23 19.6619 22.5259 18.818 21.682C17.9741 20.8381 17.5 19.6935 17.5 18.5ZM15.5406 28.5H28.4594C28.2 26.8 26.7313 25.5 24.9594 25.5H19.0406C17.2688 25.5 15.8031 26.8 15.5406 28.5ZM14 29.0406C14 26.2562 16.2563 24 19.0406 24H24.9594C27.7438 24 30 26.2562 30 29.0406C30 29.5719 29.5687 30 29.0406 30H14.9594C14.4313 30 14 29.5687 14 29.0406Z"
                fill="white"
              />
            </svg>
            <div className="content">
              <h4>Choose your hero</h4>
              <p>
                Consider the people who have had a significant impact on your
                life or career. A trusted mentor? An inspiring manager? A
                supportive family member? Your Stories are for them.
              </p>
            </div>
          </div>
          <div className="box">
            <svg
              width="43"
              height="44"
              viewBox="0 0 43 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.4505" cy="22.4231" r="21.4505" fill="#FF9E67" />
              <path
                d="M28.0966 15.2441C28.0744 15.2441 28.0523 15.245 28.0297 15.2466C24.5871 15.4935 13.7077 17.0856 16.2594 27.3224L14.1373 29.4416C13.9542 29.6247 13.9542 29.9215 14.1373 30.1043C14.2288 30.1963 14.3488 30.2431 14.4686 30.2431C14.5885 30.2431 14.7085 30.1974 14.8 30.1058L16.9192 27.9837C17.9761 28.2471 18.944 28.368 19.8249 28.368C27.4738 28.368 28.7745 19.3013 28.9972 16.2136C29.0341 15.6847 28.5917 15.2441 28.0966 15.2441ZM19.8238 27.4308C19.1585 27.4308 18.4407 27.336 17.7084 27.1962L19.3553 25.5493C19.3697 25.5501 19.3785 25.556 19.3902 25.556H24.5637C23.2864 26.7951 21.7045 27.4308 19.8238 27.4308ZM25.3605 24.642C25.3224 24.6332 25.2873 24.6185 25.2492 24.6185H20.2866L22.1682 22.7369C22.182 22.7378 22.1908 22.7436 22.2025 22.7436H26.4854C26.4409 22.8399 26.4034 22.9386 26.3564 23.0338C26.0577 23.6371 25.7208 24.1615 25.3605 24.642ZM26.878 21.8062H23.1253L24.2001 20.7314C24.3832 20.5483 24.3832 20.2515 24.2001 20.0687C24.017 19.8859 23.7203 19.8856 23.5375 20.0687L17.075 26.5312C16.5521 24.0824 16.8746 22.0259 18.0338 20.403C20.4536 17.0663 25.8234 16.3222 28.0586 16.1581C27.9648 17.4486 27.6836 19.6618 26.878 21.8062Z"
                fill="#013247"
              />
            </svg>

            <div className="content">
              <h4>Craft your story</h4>
              <p>
                Take a moment to reflect and then pour your thoughts into words.
                A kudos for a job well done, a thank you for unwavering support,
                or a reflection on a shared memory – your Story can be anything
                you want it to be.
              </p>
            </div>
          </div>
          <div className="box">
            <svg
              width="43"
              height="44"
              viewBox="0 0 43 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.4505" cy="22.4348" r="21.4505" fill="#FEDE66" />
              <path
                d="M22.3229 19.9401L24.3324 18.9127C24.8311 18.658 25.3814 18.5251 25.9415 18.5251H27.4425C28.3269 18.5251 28.914 19.4414 28.5431 20.2447C28.0886 21.2274 27.3148 22.0258 26.3469 22.5097L19.6304 25.868C19.4658 25.9515 19.2865 25.9932 19.1023 25.9932H15.6851C15.3711 25.9932 15.0714 25.868 14.8501 25.6469L13.2825 24.0795C12.916 23.7135 12.9983 23.0993 13.4483 22.8414L14.0315 22.5073C14.3575 22.323 14.7526 22.3034 15.0955 22.4336L16.3287 23.0035L18.3677 21.9595L15.5573 20.23C15.0454 19.8983 15.0965 19.1049 15.6679 18.8457L16.5744 18.434C17.0436 18.2202 17.5792 18.2003 18.0631 18.3779L22.3229 19.9401ZM15.9946 19.5618L19.967 22.0258L16.3533 23.8732L14.7764 23.173C14.6619 23.1214 14.5302 23.1288 14.4217 23.1902L13.8384 23.5243L15.406 25.0917C15.4787 25.1654 15.5795 25.2071 15.6851 25.2071H19.1023C19.1637 25.2071 19.2251 25.1924 19.2791 25.1654L25.9956 21.8071C26.8038 21.4043 27.4499 20.7361 27.8282 19.9155C27.9584 19.633 27.7521 19.3112 27.4425 19.3112H25.9415C25.5067 19.3112 25.0768 19.4144 24.6886 19.6134L22.3745 20.795L17.7929 19.1171C17.503 19.0093 17.1812 19.0213 16.8987 19.1491L15.9946 19.5618ZM28.3294 28.3516C28.5455 28.3516 28.7224 28.5285 28.7224 28.7447C28.7224 28.9608 28.5455 29.1377 28.3294 29.1377H13.3931C13.176 29.1377 13 28.9608 13 28.7447C13 28.5285 13.176 28.3516 13.3931 28.3516H28.3294Z"
                fill="#013247"
              />
            </svg>

            <div className="content">
              <h4>Deliver happiness</h4>
              <p>
                Click send and watch your Story take flight. You're not just
                sending a message, you're sending joy, appreciation, and
                recognition.
              </p>
            </div>
          </div>

          <div className="final">
            <p>Every journey is made of stories. Ready to share yours?</p>
            <button className="btn secondary" onClick={()=>{
                clicked()
            }}>Start your Story</button>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          clicked();
        }}
        className="skrim"
      ></div>
    </div>
  );
};
