import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth0 } from "@auth0/auth0-react";

export const InterestFormSignup = ({ match }) => {
  const { loginWithRedirect, logout } = useAuth0();

  // Todo: Refactor and pull info from the Database
  const tenantInfo = {
    "ddd287f7-f1cf-47b5-af53-74d0d0433898": {
      imageUrl: "https://crewassets.s3.us-east-2.amazonaws.com/Lennar_Logo.svg",
      name: "Lennar",
      hideSocials: true,
    },
  };

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) nav.style = "display:none";
    const container = document.getElementsByClassName("content_container")[0];
    if (container) container.style = "max-width: 100%;width:100%;";

    const params = new URLSearchParams(window.location.search);
    const loggedOut = params.get("loggedOut");
    const queryParamId = params.get("id");
    const tenantId = params.get("tenantId");
    // match.params.id contains the ID if we're passing it as a route param. Otherwise get the query param ID
    const id = match.params.id || queryParamId;

    if (!loggedOut) {
      // Log the user out and bring them back to this page
      const searchParams = new URLSearchParams({
        id,
        loggedOut: true,
        tenantId,
      }).toString();
      logout({
        returnTo: `${window.location.origin}/signup/interest?${searchParams}`,
      });
    } else {
      // If we already logged the user out, redirect user to log in

      const redirectData = {
        appState: {
          returnTo: `/interest/onboarding/${id}`,
        },
        prompt: "login",
      };

      if (tenantId) {
        const hintData = tenantInfo[tenantId] || {};
        redirectData.login_hint = JSON.stringify(hintData);
      }
      loginWithRedirect(redirectData);
    }
  });

  return <div></div>;
};
