import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../styles/pages/Admin/coupons.scss";
import { isAdmin } from "../../hooks/isAdmin";
import { Sidebar } from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";

export const AdminCoupons = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [coupons, setCoupons] = useState(null);

  useEffect(() => {
    getMe();
    getCoupons();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getCoupons = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/coupons`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setCoupons(response.data);
  };

  const deleteCoupon = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/coupons/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    getCoupons();
  };

  if (!coupons) {
    return <div>Loading...</div>;
  }

  return (
    <div className="couponsAdmin">
      <h2>
        <span className="heading1 highlight blue">Coupons</span>
      </h2>

      <Sidebar />

      <div className="right white-background">
        <button className="btn secondary link">
          <NavLink to="/admin/coupons/create">+ Create Coupon</NavLink>
        </button>
        <div className="coupon top">
          <p className="body1">Code</p>
          <p className="body1">Type</p>
          <p className="body1">Amount</p>
          <p className="body1">Uses</p>
          <p></p>
        </div>
        {coupons.map((coupon) => {
          return (
            <div className="coupon">
              <p className="body1">{coupon.code}</p>
              <p className="body1">{coupon.type}</p>
              <p className="body1">
                {coupon.amount}
                {coupon.type === "PERCENT" ? "%" : "$"}{" "}
              </p>
              <p className="body1">{coupon.uses}</p>
              <button
                onClick={() => {
                  deleteCoupon(coupon.id);
                }}
                className="btn secondary"
              >
                Delete
              </button>
              {coupon.users.length > 0 && (
                <div className="people">
                  <h3>Users</h3>
                  {coupon.users.map((user) => {
                    return (
                      <div className="person">
                        <p>
                          <FontAwesomeIcon icon={faUser} />
                          {user.first_name} {user.last_name}
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faEnvelope} /> {user.email}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
