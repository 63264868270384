import React, { useEffect } from "react";
import "chartjs-adapter-moment";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dater from "moment";
import { useHistory } from "react-router-dom";

export const ContentBox = (props) => {
  useEffect(() => {}, []);

  const history = useHistory();

  const convertToEmoji = (score) => {
    if (score > 80) {
      return "🤩";
    } else if (score > 60) {
      return "😃";
    } else if (score > 40) {
      return "🙂";
    } else if (score > 20) {
      return "😐";
    } else {
      return "😕";
    }
  };

  return (
    <div className="cBox">
      <div className="emoji">
        <span role="img" aria-label="emoji">
          {convertToEmoji(props.moment?.score)}
        </span>
      </div>
      <div className="content">
        <h3>
          {props.moment?.type === "personal" && (
            <span>{props.moment?.title}</span>
          )}
          {props.moment?.type === "professional" && (
            <span>{props.moment?.title}</span>
          )}
        </h3>
        <p>{props.moment?.description}</p>

        <p className="year">{dater(props.moment?.date).format("MMMM YYYY")}</p>

        <div className="insights">
          <button
            onClick={() => {
              history.push(`/lifeline/moment/${props.moment?.id}/edit`);
            }}
            className="btn primary edit"
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Moment
          </button>
        </div>
      </div>
    </div>
  );
};
