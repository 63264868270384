import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";

export const SidebarEntry = ({
  saveEntry,
  placeholder,
  deleteEntry,
  data,
  type,
}) => {
  const [entryData, setEntryData] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [usersQuestions, setUsersQuestions] = useState([]);
  const [collabsQuestions, setCollabsQuestions] = useState([]);

  // Get all the nodes of a specific type
  const getDataByType = (type) => {
    if (!data) {
      return [];
    }

    const item = data.data?.filter((item) => item.title === type);

    if (!item || !item[0]) {
      return [];
    }

    if (item[0].nodes && item[0].nodes.length > 0) {
      return item[0].nodes;
    }
    return [];
  };

  // Sort the data we have into users and collabs questions
  // This is done so we can display them in different sections
  useMemo(() => {
    setShowInput(false);

    const data = getDataByType(type);

    const reducedIntoUsersAndCollabs = data.reduce(
      (prev, current) => {
        if (current.collab) {
          return {
            ...prev,
            collabs: [...prev.collabs, current],
          };
        } else {
          return {
            ...prev,
            users: [...prev.users, current],
          };
        }
      },
      { users: [], collabs: [] }
    );

    setUsersQuestions(reducedIntoUsersAndCollabs.users);
    setCollabsQuestions(reducedIntoUsersAndCollabs.collabs);
  }, [data]);

  return (
    <>
      {usersQuestions.length === 0 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveEntry(entryData);
            setEntryData({
              value: "",
            });
          }}
        >
          <input
            type="text"
            value={entryData.value || ""}
            onChange={(e) => {
              setEntryData({
                type,
                value: e.target.value,
              });
            }}
          ></input>

          <span
            onClick={() => {
              saveEntry(entryData);
              setEntryData({
                value: "",
              });
            }}
          >
            Save
          </span>

          <input
            style={{
              display: "none",
            }}
            placeholder={placeholder}
            type="submit"
            value="Save"
          ></input>
        </form>
      )}
      {usersQuestions.map((item, idx) => {
        return (
          <div key={idx + item.title} className="entry">
            <p>{item.title}</p>
            <FontAwesomeIcon
              onClick={() => {
                deleteEntry(type, item);
                setEntryData({
                  value: "",
                });
              }}
              icon={faTrash}
            />
          </div>
        );
      })}

      {usersQuestions.length > 0 && !showInput && (
        <div
          onClick={() => {
            setShowInput(true);
          }}
          className="addBox"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" rx="12" fill="#013247" />
            <path
              d="M10.8857 16.9155V7.08594H13.1158V16.9155H10.8857ZM7.08594 13.1158V10.8857H16.9155V13.1158H7.08594Z"
              fill="white"
            />
          </svg>

          <h5>Add {type}</h5>
        </div>
      )}

      {showInput === true && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveEntry(entryData);
            setEntryData({
              value: "",
            });
          }}
        >
          <input
            type="text"
            value={entryData.value || ""}
            placeholder={placeholder}
            onChange={(e) => {
              setEntryData({
                type,
                value: e.target.value,
              });
            }}
          ></input>
          <span
            onClick={() => {
              saveEntry(entryData);
              setEntryData({
                value: "",
              });
            }}
          >
            Save
          </span>
          <input
            style={{
              display: "none",
            }}
            type="submit"
            value="Save"
          ></input>
        </form>
      )}

      {collabsQuestions.length > 0 && (
        <h4 className="collabTitle">Collaborators Added</h4>
      )}
      {collabsQuestions.map((item, idx) => {
        return (
          <div key={idx + item.title} className="entry collab">
            <p>{item.title}</p>
            <FontAwesomeIcon
              onClick={() => {
                deleteEntry(type, item);
                setEntryData({
                  value: "",
                });
              }}
              icon={faTrash}
            />
          </div>
        );
      })}
    </>
  );
};

export default SidebarEntry;
