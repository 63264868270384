import React, { useEffect, useState } from "react";
import "../../../styles/pages/Path/components/Widget.scss";
import "../../../styles/pages/Path/components/LifelineWidgetSolo.scss";
import { LineChart } from "../../lifeline/components/LineChart";
import dater from "moment";
import { useHistory } from "react-router-dom";
import moment from "moment";

export const LifelineWidgetSolo = (props) => {
  const [personalMoments, setPersonalMoments] = useState([]);
  const [professionalMoments, setProfessionalMoments] = useState([]);
  const [lineData, setLineData] = useState({
    index: 0,
    datasetIndex: 0,
  });
  const [data, setData] = useState();
  const [dates, setDates] = useState();

  useEffect(() => {
    if (!props.moments || props.moments.length === 0) return;
    const reducedMoments = props.moments.reduce(
      (acc, moment) => {
        if (moment.type === "personal") {
          acc.personalMoments.push(moment);
        } else {
          acc.professionalMoments.push(moment);
        }
        const year = dater(moment.date).year();
        if (!acc.years.includes(year)) {
          acc.years.push(year);
        }
        return acc;
      },
      {
        years: [],
        personalMoments: [],
        professionalMoments: [],
      }
    );

    const data = {
      datasets: [
        {
          label: "Personal",
          data: reducedMoments.personalMoments.map((moment, index) => {
            return {
              x: dater(moment.date).format("MM/DD/YYYY"),
              y: moment.score,
              score: moment.score,
              title: moment.title,
            };
          }),
          borderColor: "rgba(79, 114, 130, 1)",
          pointBorderColor: "rgba(181, 196, 202, 1)",
          radius: 15,
          pointBorderWidth: 0,
          borderWidth: 8,
          backgroundColor: "rgba(181, 196, 202, 1)",
          datalabels: {
            align: "start",
            anchor: "start",
            clip: false,
            padding: 6,
            size: 11,
          },
        },
        {
          label: "Professional",
          data: reducedMoments.professionalMoments.map((moment, index) => {
            return {
              x: dater(moment.date).format("MM/DD/YYYY"),
              y: moment.score,
              score: moment.score,
              title: moment.title,
            };
          }),
          borderColor: "rgba(255, 158, 103, 1)",
          radius: 15,
          pointBorderWidth: 0,
          borderWidth: 8,
          backgroundColor: "rgba(255, 158, 103, 1)",
          datalabels: {
            align: "start",
            anchor: "start",
            clip: false,
            padding: 6,
            size: 11,
          },
        },
      ],
    };

    const sortedMoments = props.moments.sort((a, b) => {
      return moment(a.createdAt) > moment(b.createdAt);
    });

    setDates(sortedMoments);
    setData(data);
    setPersonalMoments(reducedMoments.personalMoments);
    setProfessionalMoments(reducedMoments.professionalMoments);
  }, [props.moments]);

  if (!data && !data?.datasets) {
    return <div></div>;
  }

  return (
    <div
      className={`LifelineWidgetSolo Widget ${props.fullSize ? "full" : ""}`}
    >
      <div className="middle">
        <LineChart
          personalMoments={personalMoments}
          professionalMoments={professionalMoments}
          input={lineData}
          noToolTip={true}
          hideButtons={true}
          data={data}
        />
      </div>
    </div>
  );
};
