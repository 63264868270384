import React, { useEffect, useState, useRef } from "react";
import "../../../styles/pages/Values/components/OnboardedValueFlow.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

export const OnboardedValueFlow = ({
  value,
  remove,
  setActiveValue,
  activeImages,
  save,
  setActiveImages,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [existing, setExisting] = useState(false);
  const [summaryText, setSummaryText] = useState("");
  const [titleText, setTitleText] = useState("");
  useEffect(() => {
    if (!value.fromOnboarding) {
      setIsActive(true);
      setExisting(true);
      setSummaryText(value.summary);
      setTitleText(value.text);
    } else {
      setIsActive(false);
      setExisting(false);
    }
  }, []);

  return (
    <div
      className={`OnboardedValueFlow ${existing ? "editing" : ""}`}
      key={value.id}
    >
      <div className="value__image">
      {!isActive || existing &&
        <h3>{value.text}</h3>
      }
       {isActive && !existing &&
       <input type="text" value={titleText} onChange={(e) => {
        const newValue = { ...value };
        newValue.text = e.target.value;
        setTitleText(e.target.value);
        setActiveValue(newValue);
        }}/>
        }


        {!isActive && (
          <div className="actionBox row">
            <button
              className="btn blank"
              onClick={() => {
                setIsActive(true);
                setActiveValue(value);
              }}
            >
              Yes
            </button>
            <button
              className="btn blank"
              onClick={() => {
                remove(value);
              }}
            >
              No
            </button>
          </div>
        )}
        {existing && (
          <div className="actionBox">
            <p>{value.summary}</p>
            <div className="box">
              <p className="pill">
                {value?.valueImage?.length || value?.roleModel?.length || 0} photos selected
              </p>

              <div className='boxActions'>
              <button
                className="btn blank"
                onClick={() => {
                  setIsActive(true);
                  setExisting(false);
                  setActiveImages(value.valueImage || value.roleModel || []);
                  setActiveValue(value);
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <button
                className="btn blank"
                onClick={() => {
                  remove(value);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              </div>
            </div>
          </div>
        )}
        {isActive && !existing && (
          <div className="actionBox">
            <textarea
              onChange={(e) => {
                const newValue = { ...value };
                newValue.summary = e.target.value;
                setSummaryText(e.target.value);
                setActiveValue(newValue);
              }}
              value={summaryText}
              className="textArea"
              placeholder="Add a summary"
            ></textarea>
            {!activeImages || activeImages.length < 1 ? (
              <div className="pill">
                <p>Tap on photo(s) that relate to this value</p>
              </div>
            ) : (
              <div className="pill">
                <p>{activeImages.length} selected</p>
              </div>
            )}
            <button
              className="btn blank save"
              disabled={
                !activeImages || activeImages.length < 1 || !summaryText
              }
              onClick={() => {
                save();
                // If its not onboarding value, then we need to set back to exisiting mode
                if (!value.fromOnboarding) {
                  setExisting(true);
                }
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
