import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Programs/index.scss";
import { Sidebar } from "../Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isAdmin } from "../../../hooks/isAdmin";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";

export const Programs = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [addProgram, setAddProgram] = useState(false);
  const [newCrew, setNewCrew] = useState({
    name: "",
    description: "",
  });
  const { apiServerUrl } = useEnv();

  const [outcomesEditorState, setOutcomesEditorState] = useState(null);
  const [forYouEditorState, setForYouEditorState] = useState(null);
  const [outcomesHtml, setOutcomesHTML] = useState(null);
  const [forYouHtml, setForYouHTML] = useState(null);
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const addProgramToDatabase = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/programTemplates`,
      method: "POST",
      data: newCrew,
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    history.push(`/admin/crews`);
  };

  const getPrograms = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/programTemplates`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setPrograms(response.data);
  };

  return (
    <div className="programs">
      <h2 className="heading1 highlight blue">Program Management</h2>

      <Sidebar />

      <div className="right white-background">
        <div className="row">
          <h3 className="heading2 underline yellow">Programs</h3>
          <button
            onClick={() => {
              setAddProgram(true);
            }}
            className="btn primary"
          >
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
        <div className="programs">
          {programs.length === 0 && <p>No Programs Right Now.</p>}
          {programs.map((program) => (
            <div className="program" key={program.id}>
              <NavLink to={`/admin/programs/${program.id}`}>
                <div className="title">
                  <div className="full-title">
                    <h6>{program.name}</h6>
                  </div>
                  <p>{program.description}</p>
                </div>
                <div className="actions">
                  <div className="info">
                    <FontAwesomeIcon icon={faBook} /> {program.crews.length}{" "}
                    Crews(s)
                  </div>

                  <NavLink to={`/admin/programs/${program.id}/edit`}>
                    <button className="btn secondary edit purple">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </NavLink>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>

      {addProgram && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="heading2 highlight yellow">Add Program</h2>
            <div className="row">
              <div className="inputBox">
                <label className="body-text">Name</label>
                <textarea
                  onChange={(e) => {
                    const newCrewObject = { ...newCrew };
                    newCrewObject.name = e.target.value;
                    setNewCrew(newCrewObject);
                  }}
                  value={newCrew.name}
                  className="input"
                />
              </div>

              <div className="inputBox">
                <label className="body-text">Description</label>
                <textarea
                  onChange={(e) => {
                    const newCrewObject = { ...newCrew };
                    newCrewObject.description = e.target.value;
                    setNewCrew(newCrewObject);
                  }}
                  value={newCrew.description}
                  className="input"
                />
              </div>

              <div className="inputBox">
                <label className="body-text">Title</label>
                <textarea
                  onChange={(e) => {
                    const newCrewObject = { ...newCrew };
                    newCrewObject.title = e.target.value;
                    setNewCrew(newCrewObject);
                  }}
                  value={newCrew.title}
                  className="input"
                />
              </div>

              <div className="inputBox">
                <label className="body-text">Long Description</label>
                <textarea
                  onChange={(e) => {
                    const newCrewObject = { ...newCrew };
                    newCrewObject.longDescription = e.target.value;
                    setNewCrew(newCrewObject);
                  }}
                  value={newCrew.longDescription}
                  className="input"
                />
              </div>

              <div className="inputBox">
                <label className="body-text">Outcomes</label>
                <Editor
                  handlePastedText={() => false}
                  onEditorStateChange={(e) => {
                    setOutcomesEditorState(e);
                    let raw = convertToRaw(e.getCurrentContent());
                    let html = draftToHtml(raw);
                    const newCrewObject = { ...newCrew };
                    newCrewObject.outcomes = html;
                    setNewCrew(newCrewObject);
                  }}
                  editorState={outcomesEditorState}
                />
              </div>

              <div className="inputBox">
                <label className="body-text">This is for you if...</label>
                <Editor
                  handlePastedText={() => false}
                  onEditorStateChange={(e) => {
                    setForYouEditorState(e);
                    let raw = convertToRaw(e.getCurrentContent());
                    let html = draftToHtml(raw);
                    const newCrewObject = { ...newCrew };
                    newCrewObject.forYou = html;
                    setNewCrew(newCrewObject);
                  }}
                  editorState={forYouEditorState}
                />
              </div>

              <button
                className="btn secondary"
                onClick={() => {
                  addProgramToDatabase();
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Crew
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setAddProgram(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
