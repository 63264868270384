import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "../../../context/env.context";
import "../../../styles/pages/Strengths/components/CollaboratorAdd.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../svgs/logo.png";
import box from "../svgs/box2.svg";

export const CollaboratorAdd = ({ me, setShowInviteModal, close }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [currentInvite, setCurrentInvite] = useState({
    firstName: "",
  });
  const [invites, setInvites] = useState([]);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState("");
  const [activeInvite, setActiveInvite] = useState();
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getInvites();
  }, []);

  const getInvites = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let invites = response.data;

    invites = invites.filter(
      (invite) =>
        invite.firstName !== null &&
        invite.firstName !== undefined &&
        invite.firstName !== ""
    );

    setInvites(invites);
  };

  const createInvite = async () => {
    try {
      if(!currentInvite.firstName){
        return
      }
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${apiServerUrl}/api/strengths/invite/partner`,
        {
          firstName: currentInvite.firstName,
          email: currentInvite.email,
          message: currentInvite.note,
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invite = response.data;
      setCurrentInvite({
        firstName: "",
        email: "",
        message: "",
        type: "",
      });
      getInvites();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="left collaboratorAdd">
        <h2>
          <FontAwesomeIcon
            onClick={() => {
              setShowInviteModal(false);
              close();
            }}
            icon={faTimes}
          />{" "}
          Share Your Strengths
        </h2>

        <div className="formData">
        <h3>
          Send to a collaborator <span>{invites.length} / 3</span>
        </h3>

        <p>Decide who you would like to give feedback on your strengths.</p>
        <div className="inputField">
          <label>What is their name?</label>
          <input
            value={currentInvite.firstName}
            onChange={(e) => {
              const currentInviteCopy = { ...currentInvite };
              currentInviteCopy.firstName = e.target.value;
              setCurrentInvite(currentInviteCopy);
            }}
            placeholder="Type name here"
            type="text"
          />
        </div>

        <div className="inputField grouping">
          <label>How would you like to request their feedback?</label>
          <div className="group">
            <button
              onClick={() => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.note = ``;
                setCurrentInvite(currentInviteCopy);
                setType("email");
              }}
              className={`${type === "email" ? "active" : ""}`}
            >
              Email
            </button>
            <button
              onClick={() => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.note = ``;
                setCurrentInvite(currentInviteCopy);
                setType("in_person");
              }}
              className={`${type === "in_person" ? "active" : ""}`}
            >
              In Person
            </button>
          </div>
        </div>

        {type === "email" && (
          <div className="inputField">
            <label>What is their email address?</label>
            <input
              value={currentInvite.email}
              onChange={(e) => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.email = e.target.value;
                setCurrentInvite(currentInviteCopy);
              }}
              placeholder="janedoe@gmail.com"
              type="text"
            />
          </div>
        )}

        {type === "email" && (
          <div className="inputField">
            {type === "email" ? (
              <label>Add a personal note</label>
            ) : (
              <label>Edit the note (optional)</label>
            )}
            <textarea
              value={currentInvite.message}
              onChange={(e) => {
                const currentInviteCopy = { ...currentInvite };
                currentInviteCopy.message = e.target.value;
                setCurrentInvite(currentInviteCopy);
              }}
              placeholder="Include anything that might be helpful for your collaborators to know..."
              type="text"
            />
          </div>
        )}

        {type === 'email' && <p>Click “Send” to share with a collaborator.</p>}


        {type === "in_person" && <p>Click "Save" to save your preference</p>}

        {type === "email" ? (
          <button
            onClick={() => {
              createInvite();
            }}
            disabled={currentInvite.firstName === ""}
            className="btn secondary"
          >
            Send
          </button>
        ) : (
          <div></div>
        )}

        {type === "in_person" ? (
          <button
            onClick={() => {
              createInvite();
            }}
            disabled={currentInvite.firstName === ""}
            className="btn secondary"
          >
            Save
          </button>
        ) : (
          <div></div>
        )}
        {success && (
          <div className="success">
            <p>
              <FontAwesomeIcon icon={faCheck} /> {success}
            </p>
          </div>
        )}
        </div>

        <div className="inviteList inputField">
          <label>Your collaborators </label>
          {invites.length === 0 && <p>No requests sent yet</p>}
          {invites.map((invite) => {
            return (
              <div className="inviteContainer">
                <FontAwesomeIcon icon={faCheck} />
                <p>{invite.firstName}</p>
                <p>{invite.type === "email" ? "Email" : "In Person"}</p>
              </div>
            );
          })}
        </div>

        {invites.length > 2 && (
        <div className="actions">
          <button
            onClick={() => {
              setShowInviteModal(false);
            }}
            className="btn blank"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              history.push("/strengths/overview");
            }}
            className="btn primary"
          >
            Continue
          </button>
        </div>
        )}
      </div>
      <div className="right collaboratorRight">
        <h3>Invite others to give you their perspective</h3>
        <p>Here's the invite you'll be sending them</p>
        <div className="email">
          <div className="header">
            <img src={logo} alt="logo" />
          </div>
          <div className="content">
            <h3>Hi {currentInvite.firstName || "[Collaborator]"}, </h3>
            <p>
            {me.first_name} is requesting your
              help!
            </p>

            <p>
              <b>Here's the Context:</b>
            </p>
            <p>
              {me.first_name} is working on further understanding themselves,
              who they are, and what is important to them in their career and
              life. Right now, they are learning about strengths.
            </p>

            <p>
              <b>This is where you come in!</b>
            </p>
            <p>
              {me.first_name} would like your input on their strengths. They
              think you can help because you know them well. You share some
              strengths that maybe they wouldn't have though of themselves.
            </p>

            <div className="box">
              <p>Note from {me.first_name}</p>
              <p>{currentInvite.message}</p>
              <button className="btn primary">Share {me.first_name}'s Strengths</button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal ProgramModal FurtherModal">
          <div className="modal-content">
            <div className="left">
              <div className="box">
                <img src={box} alt="box" />
              </div>
            </div>
            <div className="right">
            <h3>THIS STRENGTHS LIST IS LOOKING GREAT</h3>
            <h2>Let’s take it a level deeper by getting some outside perspectives.</h2>
            <p>Sometimes it’s difficult for us to see ourselves clearly. Getting input on our strengths from people who know us well can help us identify strengths we may not be aware of.</p>
            <h4>Before your next session, ask three people who know you well to provide their input on your strengths.</h4>
            <p>These might be managers, colleagues, mentors, or friends. You can collect input on your strengths in one of two ways:</p>
            <ol>
              <li>
                <p>Via email (they'll enter their input into the Crew platform)</p>
              </li>
              <li>
                <p>
                In person (you'll manually enter their feedback into the Crew platform)
                </p>
              </li>
            </ol>

            <button onClick={()=>{
              setShowModal(false)
            }} className="btn secondary">Let's Begin</button>
            
            </div>
          </div>
          <div
            onClick={() => {
              setShowModal(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </>
  );
};
