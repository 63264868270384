import React, { useEffect, useState } from "react";
import "./styles/Values.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/valuesIcon.svg";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import ContextMenu from "./ContextMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ValuesWidget = ({ id, size, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [values, setValues] = useState([]);
  const [valueImages, setValueImages] = useState([]);
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [adjustedSize, setAdjustedSize] = useState(size);
  useEffect(() => {
    getData(id);
  }, [adjustedSize]);

  const getData = async (id) => {
    await getValues(id);
    await getValueImages(id);
  };

  const getValues = async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/values/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.length === 0) return;

    setActive(response.data[0]);
    setValues(response.data);
  };

  const getValueImages = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/value-image`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setValueImages(response.data.valueImages);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  // If there are no values and less than 10 value images
  if (
    (!valueImages || valueImages.length < 10)
  ) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Values</h3>
        {!programUser && (
          <button
            className="btn"
            onClick={() => {
              history.push("/Values");
            }}
          >
            Go to Values
          </button>
        )}
      </div>
    );
  }

  // If there are no values and 10 value images.
  if ((!values || values.length === 0) && valueImages.length >= 10) {
    return (
      <div className={`Widget Values inProgress Size-${size}`}>
        <button
          className="btn"
          onClick={() => {
            history.push("/values/dashboard");
          }}
        >
          Resume
        </button>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
        >
          <circle cx="15.5703" cy="15" r="15" fill="#013247" />
          <path
            d="M19.9685 10C19.9537 10 19.9389 10.0006 19.9239 10.0017C17.6287 10.175 10.3754 11.2924 12.0767 18.4766L10.6619 19.9638C10.5398 20.0923 10.5398 20.3006 10.6619 20.4289C10.7229 20.4934 10.8029 20.5263 10.8828 20.5263C10.9626 20.5263 11.0427 20.4942 11.1037 20.4299L12.5165 18.9406C13.2212 19.1255 13.8665 19.2104 14.4538 19.2104C19.5533 19.2104 20.4204 12.8473 20.5689 10.6803C20.5935 10.3092 20.2986 10 19.9685 10ZM14.453 18.5526C14.0094 18.5526 13.5309 18.4861 13.0427 18.388L14.1407 17.2322C14.1503 17.2327 14.1561 17.2368 14.1639 17.2368H17.6131C16.7615 18.1065 15.7069 18.5526 14.453 18.5526ZM18.1443 16.5954C18.1189 16.5892 18.0955 16.5789 18.0701 16.5789H14.7616L16.016 15.2584C16.0252 15.259 16.0311 15.2632 16.0389 15.2632H18.8943C18.8646 15.3307 18.8396 15.4 18.8083 15.4668C18.6091 15.8902 18.3845 16.2582 18.1443 16.5954ZM19.156 14.6053H16.6541L17.3707 13.8509C17.4928 13.7225 17.4928 13.5142 17.3707 13.3859C17.2486 13.2576 17.0508 13.2574 16.9289 13.3859L12.6204 17.9213C12.2718 16.2027 12.4868 14.7595 13.2597 13.6205C14.8729 11.2788 18.4529 10.7566 19.9431 10.6414C19.8806 11.5471 19.6931 13.1003 19.156 14.6053Z"
            fill="white"
          />
        </svg>

        <h5>10 Images</h5>
        <p>Get a clear understanding of your values so that you can align your work with what you care about most</p>

        <div className="bar">
          <div className="progress"></div>
        </div>
      </div>
    );
  }

  // Size 1 widget
  if (adjustedSize === 1) {
    let activeMoment = active;
    return (
      <div className={`Widget Size-${adjustedSize} Values`}>
        <h2>Values</h2>
        <ContextMenu
          link="/Values"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className={`item ${activeMoment.score > 50 ? "happy" : "sad"}`}>
          <img
            className="image"
            src={activeMoment.valueImage[0]?.url}
            alt="icon"
          />
          <div className="activeMoment">
            <div
              className={`item ${activeMoment.score > 50 ? "happy" : "sad"}`}
            >
              <h2>{activeMoment.text}</h2>
              <p>{truncate(activeMoment.summary, 60)}</p>
            </div>
          </div>
        </div>

        <button
          className="empty"
          onClick={() => {
            history.push("/values/creation");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Review Values{" "}
        </button>

        {adjustedSize === 1 && (
          <div className="navigation">
            {values.map((moment, index) => (
              <button
                className={active === moment ? "active" : ""}
                onClick={() => setActive(moment)}
              ></button>
            ))}
          </div>
        )}
      </div>
    );
  }

  // Size 2 widget
  if (adjustedSize === 2) {
    return (
      <div className={`Widget Size-${adjustedSize} Values`}>
        <h2>Values</h2>
        <ContextMenu
          link="/Values"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className="sides">
          <div className="left">
            {values.map((value, index) => (
              <button
                className={active === value ? "active" : ""}
                onClick={() => setActive(value)}
              >
                {value.text}
              </button>
            ))}
          </div>

          <div className="right">
            <div className="top">
              <img
                className="image"
                src={active?.valueImage[0]?.url}
                alt="icon"
              />
            </div>
            <div className="bottom">
              {active?.valueImage.map((image, index) => {
                if (index === 0 || index > 5) return null;
                return (
                  <img
                    className={`image ${
                      active?.valueImage.length === 1 ? "full" : ""
                    }`}
                    src={image.url}
                    alt="icon"
                  />
                );
              })}
            </div>
          </div>
          <button
            className="empty"
            onClick={() => {
              history.push("/values/creation");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clipPath="url(#clip0_1004_31548)">
                <path
                  d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                  stroke="#ECDDD8"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                  stroke="#155673"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.14795 5.54785V10.8662"
                  stroke="#ECDDD8"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.14795 5.54785V10.8662"
                  stroke="#155673"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.48877 8.20703H10.8071"
                  stroke="#ECDDD8"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.48877 8.20703H10.8071"
                  stroke="#155673"
                  strokeWidth="1.99437"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1004_31548">
                  <rect
                    width="15.955"
                    height="15.955"
                    fill="white"
                    transform="translate(0.17041 0.229492)"
                  />
                </clipPath>
              </defs>
            </svg>{" "}
            Review Values{" "}
          </button>
        </div>
      </div>
    );
  }
};
