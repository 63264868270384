import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Path/index.scss";
import { Lifeline } from "./Tools/lifeline";
import { Storyboard } from "./Tools/storyboard";
import { RosesAndThorns } from "./Tools/rosesAndThorns";
import { HighlightReel } from "./Tools/highlightReel";
import { Strengths } from "./Tools/strengths";

export const Tool = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [tool, setTool] = React.useState(null);

  const id = props.match.params.id;

  useEffect(() => {
    getTool();

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;";

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
    };
  }, []);

  const getTool = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/tools/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setTool(response.data);
  };

  if (!tool || !tool.name) {
    return <div></div>;
  }

  if (tool.name === "lifeline") {
    return <Lifeline tool={tool} />;
  }
  if (tool.name === "storyboard") {
    return <Storyboard tool={tool} />;
  }
  if (tool.name === "rosesAndThorns") {
    return <RosesAndThorns tool={tool} />;
  }
  if (tool.name === "highlightReel") {
    return <HighlightReel tool={tool} />;
  }
  if (tool.name === "strengths") {
    return <Strengths tool={tool} />;
  }
};
