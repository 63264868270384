import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../../styles/pages/Admin/users.scss";
import { isAdmin } from "../../hooks/isAdmin";
import { Sidebar } from "./Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import "../../styles/pages/Admin/users.scss";
import { CSVLink } from "react-csv";

export const AdminUsers = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [me, setMe] = useState({
    id: null,
  });
  const [users, setUsers] = useState(null);

  const columns = [
    { field: "firstName", headerName: "First Name", width: 120 },
    { field: "lastName", headerName: "Last Name", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "crew", headerName: "Crews", width: 200 },
  ];

  useEffect(() => {
    getMe();
    getUsers();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getUsers = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios(config);
    const rows = response.data.map((user) => {
      return {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        crew: user.crew.map((crew) => crew.name).join(", "),
      };
    });
    setRows(rows);
    setUsers(response.data);
  };

  if (!users) {
    return <div>Loading...</div>;
  }

  return (
    <div className="usersAdmin">
      <h2>
        <span className="heading1 highlight blue">Users</span>
      </h2>

      <Sidebar />

      <div className="right white-background">
        <div className="csv-button-container">
          <CSVLink className="btn secondary csv-button" data={selectedRows}>
            Download
          </CSVLink>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
            },
          }}
          onRowSelectionModelChange={(ids) => {
            const selectedRowData = rows.filter((row) => {
              return ids.includes(row.id);
            });
            setSelectedRows(selectedRowData);
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
    </div>
  );
};
