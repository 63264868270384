import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Learn/path.scss";
import Logo from "../../images/foundationsLogo-blue.png";
import { LearningSection } from "./components/LearningSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const LearnPath = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [data, setData] = useState();

  useEffect(() => {
    getPath();
  }, []);

  const getPath = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/learning-path/${props.match.params.id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);
    localStorage.setItem("activePath", response.data.id);
    setData(response.data);
  };

  return (
    <div className="LearnPath">
      <div className="title">
        <div>
          <span>LEARNING PATH</span>
          <h3>{data?.title}</h3>
          <div className="progress">
            In Progress
          </div>
        </div>
      </div>

      <div className="LearningSection">
        <div className="top">
          <h4>Get Started</h4>
          <div className="done">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
      </div>

      {data &&
        data?.learningSections?.map((section) => {
          return (
            <LearningSection
              reload={() => {
                getPath();
              }}
              section={section}
            />
          );
        })}
    </div>
  );
};
