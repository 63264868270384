import React, { useEffect, useState } from "react";
import "./styles/Lifeline.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/icon.svg";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import moment from "moment/moment";
import { LineChart } from "../../lifeline/components/LineChart";
import dater from "moment";
import ContextMenu from "./ContextMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const LifelineWidget = ({ id, size, location, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [moments, setMoments] = useState([]);
  const { apiServerUrl } = useEnv();
  const [active, setActive] = useState(0);
  const [data, setData] = useState();
  const history = useHistory();
  const [personalMoments, setPersonalMoments] = useState([]);
  const [professionalMoments, setProfessionalMoments] = useState([]);
  const [index, setIndex] = useState(0);
  const [lineData, setLineData] = useState({
    index: 0,
    datasetIndex: 0,
  });
  const [adjustedSize, setAdjustedSize] = useState(size);
  useEffect(() => {
    getMoments(id);
  }, [adjustedSize]);

  const getMoments = async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/moments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setMoments(response.data);

    if (adjustedSize === 2) {
      let moments = response.data;
      if (!moments || moments.length === 0) return;
      const reducedMoments = moments.reduce(
        (acc, moment) => {
          if (moment.type === "personal") {
            acc.personalMoments.push(moment);
          } else {
            acc.professionalMoments.push(moment);
          }
          const year = dater(moment.date).year();
          if (!acc.years.includes(year)) {
            acc.years.push(year);
          }
          return acc;
        },
        {
          years: [],
          personalMoments: [],
          professionalMoments: [],
        }
      );

      const data = {
        datasets: [
          {
            label: "Personal",
            data: reducedMoments.personalMoments.map((moment, index) => {
              return {
                x: dater(moment.date).format("MM/DD/YYYY"),
                y: moment.score,
                score: moment.score,
                title: moment.title,
              };
            }),
            borderColor: "rgba(79, 114, 130, 1)",
            pointBorderColor: "rgba(181, 196, 202, 1)",
            radius: 5,
            pointBorderWidth: 0,
            borderWidth: 3,
            backgroundColor: "rgba(181, 196, 202, 1)",
            datalabels: {
              align: "start",
              anchor: "start",
              clip: false,
              padding: 6,
              size: 11,
            },
          },
          {
            label: "Professional",
            data: reducedMoments.professionalMoments.map((moment, index) => {
              return {
                x: dater(moment.date).format("MM/DD/YYYY"),
                y: moment.score,
                score: moment.score,
                title: moment.title,
              };
            }),
            borderColor: "rgba(255, 158, 103, 1)",
            radius: 5,
            borderWidth: 3,
            pointBorderWidth: 0,
            backgroundColor: "rgba(255, 158, 103, 1)",
          },
        ],
      };

      setData(data);
      setPersonalMoments(reducedMoments.personalMoments);
      setProfessionalMoments(reducedMoments.professionalMoments);
    }
  };

  const ClickedItemFromGraph = (indexItem, datasetIndex) => {
    let momentSearched = null;

    // The data in the graph is split lets find the moment object so we can get the index.
    if (datasetIndex === 0) {
      momentSearched = personalMoments[indexItem];
    } else {
      momentSearched = professionalMoments[indexItem];
    }

    // Get the index of the moment in the full moment array.
    const index = moments.indexOf(momentSearched);
    setIndex(index);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };



  if (!moments || moments.length === 0) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Lifeline</h3>
        {!programUser && (
          <button
            className="btn"
            onClick={() => {
              history.push("/lifeline");
            }}
          >
            Go to Lifeline
          </button>
        )}
      </div>
    );
  }

  const getTimeAgo = (momentDate) => {
    const yearsAgo = moment().diff(momentDate.date, "years");

    if (yearsAgo === 0) {
      return "Less than a year";
    }

    return `${yearsAgo} years`;
  };

  // Size 1 widget
  if (adjustedSize === 1) {
    let activeMoment = moments[active];
    return (
      <div className={`Widget Size-${adjustedSize} Lifeline`}>
        <h2>Lifeline</h2>
        <ContextMenu
          link="/lifeline"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className={`item ${activeMoment.score > 50 ? "happy" : "sad"}`}>
          <h4>
            {getTimeAgo(activeMoment)} ago you were{" "}
            {activeMoment.score > 50 ? "celebrating" : "navigating"}...
          </h4>

          <div className="icon">
            {activeMoment.score > 50 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <rect width="28" height="28" rx="14" fill="#ECDDD8" />
                <rect width="28" height="28" rx="14" fill="#155673" />
                <path
                  d="M14.7778 10.1109H21M21 10.1109V16.3331M21 10.1109L14.7778 16.3331L11.6667 13.222L7 17.8887"
                  stroke="white"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <rect width="28" height="28" rx="14" fill="#D0530C" />
                <path
                  d="M14.7778 17.8891H21M21 17.8891V11.6669M21 17.8891L14.7778 11.6669L11.6667 14.778L7 10.1113"
                  stroke="white"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>

          <h2>{activeMoment.title}</h2>
          <p>{truncate(activeMoment.description, 60)}</p>
        </div>

        <button
          className="empty"
          onClick={() => {
            history.push("/lifeline", {
              add: true,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Add a moment{" "}
        </button>

        {adjustedSize === 1 && (
          <div className="navigation">
            {moments.map((moment, index) => (
              <button
                className={active === index ? "active" : ""}
                onClick={() => setActive(index)}
              ></button>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`Widget Size-${adjustedSize} Lifeline`}>
      <h2>Lifeline</h2>
      <ContextMenu
        link="/lifeline"
        size={adjustedSize}
        setSize={(size) => {
          setAdjustedSize(size);
        }}
      />

      {data && data.datasets && (
        <LineChart
          personalMoments={personalMoments}
          professionalMoments={professionalMoments}
          input={lineData}
          showGrid={true}
          noToolTip={true}
          showLegend={false}
          hideButtons={true}
          tickSize={10}
          data={data}
          clicky={ClickedItemFromGraph}
        />
      )}

      <div className="activeMoment">
        <div className={`item ${moments[index].score > 50 ? "happy" : "sad"}`}>
          <h4>
            <div className="icon">
              {moments[index].score > 50 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <rect width="28" height="28" rx="14" fill="#ECDDD8" />
                  <rect width="28" height="28" rx="14" fill="#155673" />
                  <path
                    d="M14.7778 10.1109H21M21 10.1109V16.3331M21 10.1109L14.7778 16.3331L11.6667 13.222L7 17.8887"
                    stroke="white"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <rect width="28" height="28" rx="14" fill="#D0530C" />
                  <path
                    d="M14.7778 17.8891H21M21 17.8891V11.6669M21 17.8891L14.7778 11.6669L11.6667 14.778L7 10.1113"
                    stroke="white"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            {getTimeAgo(moments[index])} ago
          </h4>

          <h2>{moments[index].title}</h2>
          <p>{truncate(moments[index].description, 60)}</p>
        </div>
      </div>
      <button
        className="empty"
        onClick={() => {
          history.push("/lifeline", {
            add: true,
          });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <g clipPath="url(#clip0_1004_31548)">
            <path
              d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14795 5.54785V10.8662"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14795 5.54785V10.8662"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.48877 8.20703H10.8071"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.48877 8.20703H10.8071"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1004_31548">
              <rect
                width="15.955"
                height="15.955"
                fill="white"
                transform="translate(0.17041 0.229492)"
              />
            </clipPath>
          </defs>
        </svg>{" "}
        Add a moment{" "}
      </button>
    </div>
  );
};
