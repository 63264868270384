import React, { useEffect, useState } from "react";
import { Chat } from "../components/Chat";
import {OnboardingIntro} from "../components/OnboardingIntro";
import "../styles/pages/onboarding.scss";
import Logo from "../images/Vector.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Bottom from "../images/bottom_left_chat.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../context/env.context";
import axios from "axios";

export const Onboarding = (props) => {
  const [step, setStep] = useState(1);
  const [finishedStart , setFinishedStart] = useState(false);
  const {getAccessTokenSilently} = useAuth0;
  const [me, setMe] = useState({});
  const { apiServerUrl } = useEnv();
  const [chats, setChats] = useState([
    {
      id: 1,
      message: `Hi! I'm Roxanne, professional coach and career guide at Crew.`,
      loadingTime: 2000,
      showImage: true,
      type: "coach",
      delay: 2500,
    },
    {
      id: 2,
      showImage: false,
      message:
        "I'm here to help you figure out what you want your career to look like and to support you in making it happen using a mix of coaching techniques and digital tools.",
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 3,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "startingValues",
      break: true,
      delay: 1000,
    },
    {
      id: 4,
      showImage: true,
      message:
        "Thanks! This will help me know what activities and learning paths to recommend.",
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 5,
      showImage: false,
      message:
        "Before I take you to your dashboard, let's explore a few bite-sized design exercises together to give you a sense of what this journey will be like (and to start populating your Career Canvas).",
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 6,
      showImage: false,
      message:
        "First, let’s talk about what you want from work. We all have different reasons for working, and knowing what motivates us can help us craft our jobs and chose the right career path. ",
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 7,
      showImage: true,
      loadingTime: 2000,
      type: "program",
      program: "statement",
      break: true,
      delay: 2500,
    },
    {
      id: 8,
      showImage: false,
      loadingTime: 0,
      message: `{statement}`,
      type: "user",
      delay: 100,
    },
    {
      id: 9,
      showImage: true,
      message:
        "That's great! You're starting to develop your perspective on what work is and what it means to you.",
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 10,
      showImage: false,
      message: `Let's take it a little deeper. Your "workview" can give you some insight into what really matters to you (your values).`,
      loadingTime: 2000,
      type: "coach",
      delay: 2500,
    },
    {
      id: 11,
      showImage: false,
      message: `With your workview in mind, try adding a couple of values that resonate with you to your Career Canvas.`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 12,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "values",
      break: true,
    },
    {
      id: 13,
      showImage: false,
      message: `Great! I'll add those values to your Career Canvas. This is just the start of your values exploration. You'll add more and go deeper throughout your Crew journey.`,
      loadingTime: 2000,
      type: "coach",
      delay: 200,
    },
    {
      id: 14,
      showImage: false,
      message: `Now let's shift gears to another element of career design that helps bring clarity and confidence: your strengths. We'll talk a lot about strengths later on, but for now I'll just say that there are a ton of research-based benefits to knowing what you're really good at. Let's add a few of your strengths to your Career Canvas.`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 15,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "strengths",
      break: true,
    },
    {
      id: 16,
      showImage: false,
      message: `Great! I'll add those strengths to your Career Canvas. You'll have the opportunity to add more strengths (and get input from people who know you well) later on in your Crew journey.`,
      loadingTime: 2000,
      type: "coach",
      delay: 200,
    },
    {
      id: 17,
      showImage: false,
      message: `Lastly, I'd love for you to tell me about one of your career highlights: a work moment that you're proud of, or that makes you smile when you think about it. We'll be adding this to your Highlight Reel on your Career Canvas so that you can revisit it when you're feeling low or need a little confidence boost.`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 18,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "highlight",
      break: true,
    },
    {
      id: 19,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "highlightTitle",
      break: true,
      delay:100
    },
    {
      id: 20,
      showImage: false,
      loadingTime: 0,
      message: `{highlightTitle}`,
      type: "user",
      delay: 100,
    },
    {
      id: 21,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "highlightDescription",
      break: true,
    },
    {
      id: 22,
      showImage: false,
      loadingTime: 0,
      message: `{highlightDescription}`,
      type: "user",
      delay: 100,
    },
    {
      id: 23,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "highlightImage",
      break: true,
    },
    {
      id: 24,
      showImage: true,
      message: `What a great highlight! I'll add that to your Career Canvas now. You'll be able to add more career highlights later as well`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 25,
      showImage: false,
      message: `All right, you've thought about the role of work in your life and added values, strengths, and a career highlight to your Career Canvas. It's only the beginning of your career design journey, but sometimes getting started is the hardest part. Well done!`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 26,
      showImage: false,
      message: `It's time for you to see your Career Canvas and start down your first learning path with Crew. I'll be there along the way as a partner in your career design journey.`,
      loadingTime: 2000,
      type: "coach",
    },
    {
      id: 27,
      showImage: false,
      loadingTime: 2000,
      type: "program",
      program: "final",
      break: true,
    }
  ]);

  useEffect(() => {
    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "width: 100%;max-width: 100%;";
    }

    window.Intercom("update", {
      hide_default_launcher: true,
    });

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width:92rem;width:calc(100% - 205px)";
      }
    };
  }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getMe = async () => {
      try {
        const token = await getAccessTokenSilently();
  
        const config = {
          url: `${apiServerUrl}/api/users/me`,
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        };
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios(config);
        setMe(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="onboardingHome">
      {finishedStart && (
      <div className="sidebar">
        <img className="accent" src={Bottom} alt="accent image" />
        <img src={Logo} alt="logo" />
        <ul>
          <li className={`${step > 0 ? "active" : ""}`}>
            {step === 0 && (
              <div className={`dot ${step === 0 ? "active" : ""}`}>1</div>
            )}
            {step > 0 && (
              <div className={`dot ${step > 0 ? "active" : ""}`}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )}
            Learn about Crew
          </li>
          <li className={`${step > 10 ? "active" : ""}`}>
            {step <= 10 && (
              <div className={`dot ${step > 10 ? "active" : ""}`}>2</div>
            )}
            {step > 10 && (
              <div className={`dot ${step > 10 ? "active" : ""}`}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )}
            Meet the Coach
          </li>
          <li className={`${step > 13 ? "active" : ""}`}>
            {step <= 13 && (
              <div className={`dot ${step > 13 ? "active" : ""}`}>3</div>
            )}
            {step > 13 && (
              <div className={`dot ${step > 13 ? "active" : ""}`}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )}
            Start Your Career Canvas
          </li>
        </ul>
      </div>
      )}
      {!finishedStart && (
        <OnboardingIntro continueOn={()=>{
          setFinishedStart(true);
        }} /> 
        )}
      {finishedStart && (
      <Chat
        chats={chats}
        finish={() => {
          console.log("finish");
        }}
        setStep={(i) => {
          setStep(i);
        }}
        me={props.me}
      />
      )}
    </div>
  );
};
