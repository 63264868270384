import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../../styles/pages/storyboard/story.scss";
import { useEnv } from "../../context/env.context";
import { useHistory } from "react-router-dom";
import { EditorState } from "draft-js";
import { Analytics } from "../../hooks/analytics";
import { TextStory } from "../../components/textstory";
import { Video } from "../../components/video";
import Mark from "./svgs/mark.svg";
import YourStory from "./svgs/yourstory.svg";
import Logo from "./svgs/logo.svg";
import Title from "./svgs/title.svg";
import block1 from "./svgs/block1.svg";
import block2 from "./svgs/block2.svg";
import block3 from "./svgs/block3.svg";
import accent from "./svgs/accent.svg";
import { StoryModal } from "./components/StoryModal";

export const Story = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
    useAuth0();
  const [me, setMe] = React.useState();
  const { id } = useParams();
  const [story, setStory] = React.useState({});
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const { apiServerUrl } = useEnv();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getStory();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style.display = "none";
    }
    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;width:100%";

    const navMobile = document.getElementsByClassName(
      "nav-bar__container__mobile"
    )[0];

    if (navMobile) {
      navMobile.style.display = "none";
    }
    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName(
        "nav-bar__container__mobile"
      )[0];
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
      if (nav) {
        nav.style.display = "flex";
      }
      if (navMobile) {
        navMobile.style.display = "flex";
      }

      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  const claimStory = async (recievingUserId, storyId) => {
    let token = "";
    try {
      token = await getAccessTokenSilently();

    } catch (error) {
      return;
    }

    const body = {
      recievingUserId,
    };

    const config = {
      url: `${apiServerUrl}/api/story/${id}`,
      method: "PATCH",
      data: body,
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.status === 200) {
      Analytics("Claimed Story");
      return history.push(`/storyboard/view/${storyId}`);
    }
  };

  const getStory = async () => {
    const config = {
      url: `${apiServerUrl}/api/story/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios(config);

    setStory(response.data.story);

    getMe(response.data.story);
  };

  const getMe = async (story) => {
    setLoading(true);
    let token = "";
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      setLoading(false);
      return;
    }

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    // If the story hasn't been claimed yet and its not the sender, claim it
    if (
      !story.recievingUserId && story.userId !== response.data.id
    ) {
      return claimStory(response.data.id, story.id);
    }

    // If the story has been claimed and you sent it or received it then take you to the other page
    if (
      story &&
      story.recievingUserId > 0 &&
      (story.userId == response.data.id ||
        story.recievingUserId == response.data.id)
    ) {
      return history.push(`/view/${story.id}`);
    }

     // If the story has been claimed and you sent it or received it then take you to the other page
     if (
      (story.userId == response.data.id)
    ) {
      setLoading(false);
      return;
    }

    return history.push(`/`);
  };

  if (!story || loading) {
    return (
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
        }}
        className="container"
      ></div>
    );
  }

  return (
    <div className="story_page">
      <div className="header">
        <img alt="logo" src={Logo} />
        <button onClick={()=>{
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          });
        }} className="btn primary">Join Today</button>
      </div>
      <div className="content">
        <div className="left">
          <img alt="your story" src={YourStory} />
          {story.type === "text" && <TextStory modalFlow={true} clicked={()=>{
            setShowModal(!showModal)
          }} type={"received"} story={story} />}
          {story.type === "video" && (
            <Video modalFlow={true} clicked={()=>{
              setShowModal(!showModal)
            }} refresh={() => {}} type={"received"} story={story} />
          )}
        </div>
        <div className="right">
          <h3>Crew helps you to...</h3>
          <ul>
            <li>
              <img src={Mark} /> Craft your current job to be more fulfilling
            </li>
            <li>
              <img src={Mark} /> Progress within your company
            </li>
            <li>
              <img src={Mark} /> Become a better manager
            </li>
            <li>
              <img src={Mark} /> Prepare for success in a new role
            </li>
            <li>
              <img src={Mark} /> Figure out your next career step
            </li>
            <li>
              <img src={Mark} /> Set yourself up for success in a new role
            </li>
          </ul>
        </div>
      </div>
      <div className="information">
        <img alt="title" src={Title} />
        <div className="blocks">
          <div className="block">
            <img alt="block" src={block1} />
            <h4>Exclusive career design tools</h4>
            <p>Expand your practice by providing your clients with tools that help them reflect and explore their careers in new ways.</p>
          </div>
          <div className="block">
            <img alt="block" src={block2} />
            <h4>Coaching infused curriculum</h4>
            <p>Expand your practice by providing your clients with tools that help them reflect and explore their careers in new ways.</p>
          </div>
          <div className="block">
            <img alt="block" src={block3} />
            <h4>Exclusive career design tools</h4>
            <p>Take our tried-and-tested career design curriculum and apply it to your own coaching practice.</p>
          </div>
        </div>

        <div className="membership">
          <h3>Explore the Crew Membership</h3>
          <button className="btn primary">Explore</button>
          <img src={accent} />
        </div>
      </div>
      {showModal && 
      <StoryModal me={me} story={story} clicked={()=>{
        setShowModal(!showModal)
      }} />}
    </div>
  );
};
