import React, { useEffect, useState } from "react";
import "./styles/HighlightReel.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/icon.svg";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import { useHistory } from "react-router-dom";
import ContextMenu from "./ContextMenu";

export const HighlightReelWidget = ({ id, size, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [highlights, setHighlights] = useState([]);
  const { apiServerUrl } = useEnv();
  const [active, setActive] = useState(0);
  const history = useHistory();
  const [adjustedSize, setAdjustedSize] = useState(size);

  useEffect(() => {
    getMoments(id);
  }, [id]);

  const getMoments = async (id) => {
    if (!id) {
      return;
    }
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${apiServerUrl}/api/highlights/user/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setHighlights(response.data);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  if (!highlights || highlights.length === 0) {
    return (
      <div className={`widget Size-${adjustedSize}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Highlights</h3>
        <button
          className="btn"
          onClick={() => {
            history.push("/highlights");
          }}
        >
          Go to Highlights
        </button>
      </div>
    );
  }

  return (
    <div className={`Widget Size-${adjustedSize} HighlightReel`}>
      <h2>Highlight Reel</h2>
      <ContextMenu
        size={adjustedSize}
        link="/highlights"
        setSize={(size) => {
          setAdjustedSize(size);
        }}
      />
      {adjustedSize === 1 && (
        <div
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url("${highlights[active]?.photo}"), lightgray 50% / cover no-repeat`,
          }}
          className="item"
        >
          <h4>{highlights[active]?.title}</h4>
          <p>{truncate(highlights[active]?.text, 90)}</p>
        </div>
      )}
      {adjustedSize === 2 && (
        <div className="items">
          {highlights.map((highlight, index) => (
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url("${highlight.photo}"), lightgray 50% / cover no-repeat`,
              }}
              className="item"
            >
              <h4>{highlight.title}</h4>
              <p>{truncate(highlight?.text, 90)}</p>
            </div>
          ))}
        </div>
      )}

      <button
        className="empty"
        onClick={() => {
          history.push("/highlights", {
            add: true,
          });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <g clipPath="url(#clip0_1004_31548)">
            <path
              d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14795 5.54785V10.8662"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.14795 5.54785V10.8662"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.48877 8.20703H10.8071"
              stroke="#ECDDD8"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.48877 8.20703H10.8071"
              stroke="#155673"
              strokeWidth="1.99437"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1004_31548">
              <rect
                width="15.955"
                height="15.955"
                fill="white"
                transform="translate(0.17041 0.229492)"
              />
            </clipPath>
          </defs>
        </svg>{" "}
        Add a highlight{" "}
      </button>

      {adjustedSize === 1 && (
        <div className="navigation">
          {highlights.map((highlight, index) => (
            <button
              key={highlight.id}
              className={active === index ? "active" : ""}
              onClick={() => setActive(index)}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};
