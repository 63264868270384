import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/RoleModalProgramModal.scss";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";

export const ProgramModal = ({ setShowModal }) => {
  const [coach, setCoach] = useState({});
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios(config);
      if (response.data.crew.length > 0) {
        setCoach(response.data.crew[0].coach);
      }

      return response.data;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="modal ChooseRoleModelsModal">
      <div className="modal-content">
        <FontAwesomeIcon
          className="close"
          icon={faTimes}
          onClick={() => setShowModal(false)}
        />
        <div className="left">
          <div className="box">
            <img
              src={
                "https://crewavatars.s3.us-east-2.amazonaws.com/LEARNING_ACTIVITY-1719018321719-google-oauth2%7C103425447063116393639.png"
              }
              style={{
                maxWidth: "70%",
              }}
              alt="box"
            />
          </div>
        </div>
        <div className="right">
          <h3>Here's what to expect</h3>
          <h4>Here's what to do before your next session</h4>

          <ol>
            <li>
              <h6>First, choose your topic for exploration</h6>
              <p>
                This could be a role, functional area, organization, or skill.
              </p>
            </li>
            <li>
              <h6>Then, start generating ideas for your mindmap </h6>
              <p>
                You'll brainstorm questions, people, places, and resources that
                could help you learn more.
              </p>
            </li>
          </ol>

          <div className="coachTips step">
            <div className="number">
              <img src={coach?.avatar} alt="" />
              <h4>Coach Tips from {coach?.first_name}</h4>
            </div>
            <div className="right">
              <ul>
                <li>
                  Your questions will be about the topic (What skills are
                  required for this role?) and your relationship to it (Would I
                  enjoy working with data all day?)
                </li>
                <li>
                  Make your ideas specific. In the People section, list specific
                  names rather than categories of people. In the Resources
                  section, list specific resources rather than broad cateogries.
                </li>
                <li>
                  Go beyond the obvious. A few ideas will probably come easily
                  to you. Push yourself to generate more.
                </li>
              </ul>
            </div>
          </div>

          <div className="actions">
            <button
              className="btn secondary"
              onClick={() => setShowModal(false)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="skrim"
      ></div>
    </div>
  );
};
