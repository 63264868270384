import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../styles/pages/highlightReel/shareonly.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Comment } from "./components/comment";
import { HighlightReelEdit } from "./edit";

export const HighlightShareSingle = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [highlight, setHighlight] = useState({});
  const [editing, setEditing] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [me, setMe] = useState({});
  const item = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const isCollaborator = queryParams.get("collaborator");
  const history = useHistory();

  useEffect(() => {
    getData();
    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }



    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const getAvatar = (collaborator) => {
    if (collaborator.avatar) {
      return collaborator.avatar;
    } else {
      return `https://placehold.co/100x100?text=${
        collaborator.firstName.charAt(0) + collaborator.lastName.charAt(0)
      }`;
    }
  };

  const saveComment = async () => {
    const token = await getAccessTokenSilently();
    await axios.post(
      `${apiServerUrl}/api/highlights/${props.match.params.id}/comment`,
      {
        text: commentText,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    getSingleHighlight();
    setCommentText("");
  };

  const getData = async () => {
    const highlight = await getSingleHighlight();
    await getMe(highlight);
  };

  const getMe = useCallback(
    async (highlight) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${apiServerUrl}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMe(response.data);

        // Check if user is the poster or if they are a collaborator to the highlight.
        const isCollaboratorFunction = highlight.collaborators.find(
          (collaborator) => collaborator.id == isCollaborator
        );

        // If they are not the poster or a collaborator and its not featured.
        if (highlight.user.id !== response.data.id && !isCollaboratorFunction && highlight.featured !== true)
          return history.push(`/highlights`);
      } catch (error) {
        // They aren't a user so lets see if there ID from the URL is a collaborator
        const isCollaboratorFunction = highlight.collaborators.find(
          (collaborator) => collaborator.id == isCollaborator
        );
        // If its not push them to home
        if (!isCollaboratorFunction && highlight.featured !== true) {
          return history.push(`/highlights`);
        }
      }
    },
    [apiServerUrl]
  );

  const getSingleHighlight = useCallback(async () => {
    const response = await axios.get(
      `${apiServerUrl}/api/highlights/${props.match.params.id}`
    );
    setHighlight(response.data);
    return response.data;
  }, [ props.match.params.id]);

  if (!highlight.user) {
    return <div></div>;
  }

  return (
    <div className="singleHighlight-Share">
      <div className="flexInfo">
      <h3>{highlight.user.first_name}'s Highlight</h3>
      {props.location?.state?.created && (
      <button className="btn secondary" onClick={() => history.push('/highlights')}>
        Back to Highlight Reel
      </button>
      )}
      </div>
    <div className="highlightReel-single-share">
      <div className="content">
        <div className="white-background">
          <div className="left">
            <div className="content">
              <h2>
                {highlight.title}
                {highlight.link && (
                  <span>
                    <a rel="noreferrer" href={highlight.link} target="_blank">
                      <FontAwesomeIcon icon={faLink} />
                      Highlight Link
                    </a>
                  </span>
                )}
              </h2>
              <p>{highlight.text}</p>
            </div>
            {highlight.photo && (
              <div
                style={{
                  backgroundImage: `url(${highlight.photo})`,
                }}
                className="image"
              ></div>
            )}

            {!me && (
            <div className="wantyourown">
              <h3>Want your own Highlight Reel?</h3>
              <p>Create your account and start highlighting!</p>
              <button className="btn secondary">Sign Up</button>
            </div>
            )}
          </div>
          <div className="right">
            <div ref={item} className="collaborators">
              <h3>
                <span>Collaborators</span>
              </h3>
              {highlight.collaborators?.length === 0 && (
                <div className="empty">
                  <p>There are no collaborators on this Highlight yet</p>
                </div>
              )}
              {highlight.collaborators?.length > 0 &&
                highlight.collaborators.map((collaborator) => (
                  <div className="collaborator">
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `https://placehold.co/100x100?text=${
                          collaborator.first_name.charAt(0) +
                          collaborator.last_name.charAt(0)
                        }`;
                      }}
                      src={getAvatar(collaborator)}
                      alt={collaborator.firstName}
                    />
                    <h4>
                      {collaborator.firstName} {collaborator.lastName}
                    </h4>
                  </div>
                ))}
            </div>
            <div className="comments">
              <h3>Comments</h3>

              {highlight.responses.map((comment) => (
                <Comment comment={comment} />
              ))}

              {highlight.responses.length === 0 && (
                <div className="empty">
                  <p>There are no comments on this Highlight yet</p>
                </div>
              )}
            </div>
            {me && me.id && (
              <div className="entryForm">
                <input
                  value={commentText}
                  onChange={(e) => {
                    setCommentText(e.target.value);
                  }}
                  type="text"
                  placeholder="Add a comment..."
                />

                <button
                  onClick={() => {
                    saveComment();
                  }}
                  disabled={commentText.length === 0 || !commentText || commentText.length < 1}
                >
                  Post
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
    <div className="skrim" onClick={() => props.close()}></div>
  </div>
  );
};
