import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const PartialMembership = (props) => {
  const { loginWithRedirect } = useAuth0();
  const { id } = useParams();

  useEffect(() => {
    const nav = document.querySelector(".nav-bar__container");
    nav.style.display = "none";

    loginWithRedirect({
      appState: { returnTo: `/membership/success/${id}` },
    });
  }, []);

  return <></>;
};
