import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Programs/edit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { isAdmin } from "../../../hooks/isAdmin";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

export const ProgramEdit = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [outcomesEditorState, setOutcomesEditorState] = useState(null);
  const [forYouEditorState, setForYouEditorState] = useState(null);

  const [newProgram, setNewProgram] = useState({
    name: "",
    description: "",
  });
  const [outcomesHtml, setOutcomesHTML] = useState(null);
  const [forYouHtml, setForYouHTML] = useState(null);
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [program, setProgram] = useState(null);
  let id = props.match.params.id;

  useEffect(() => {
    getMe();
    getProgram();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const editProgram = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/programTemplates/${id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        name: newProgram.name,
        description: newProgram.description,
        outcomes: outcomesHtml,
        forYou: forYouHtml,
        longDescription: newProgram.longDescription,
        title: newProgram.title,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (response.data) {
      history.push("/admin/programs");
    }
  };

  const getProgram = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/programTemplates/edit/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const data = { ...response.data };

    if(response.data?.outcomes !== null) {
      const blocksFromHtml = htmlToDraft(response.data.outcomes);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setOutcomesEditorState(editorState);
      setOutcomesHTML(response.data.outcomes);
    }

    if(response.data?.forYou !== null) {
      const blocksFromHtml = htmlToDraft(response.data.forYou);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setForYouEditorState(editorState);
      setForYouHTML(response.data.forYou);
    }

    setNewProgram(data);
  };

  if (!newProgram.id) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ProgramEdit">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/program-management"}>Programs</NavLink> /{" "}
        </div>
        <div className="breadcrumb">
          <div className="breadcrumb">
            <NavLink to={`/admin/programs/${newProgram.id}`}>
              {newProgram.name}
            </NavLink>{" "}
          </div>
        </div>
      </div>
      <div className="container white-background">
        <h2 className="heading1 highlight blue">Edit {newProgram.name}</h2>
        <div className="row">
          <div className="inputBox">
            <label className="body-text">Program Name</label>
            <input
              onChange={(e) => {
                const newProgramObject = { ...newProgram };
                newProgramObject.name = e.target.value;
                setNewProgram(newProgramObject);
              }}
              type="text"
              value={newProgram.name}
              className="input"
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Description</label>
            <textarea
              onChange={(e) => {
                const newProgramObject = { ...newProgram };
                newProgramObject.description = e.target.value;
                setNewProgram(newProgramObject);
              }}
              value={newProgram.description}
              className="input"
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Marketing Title</label>
            <input
              onChange={(e) => {
                const newProgramObject = { ...newProgram };
                newProgramObject.title = e.target.value;
                setNewProgram(newProgramObject);
              }}
              type="text"
              value={newProgram.title}
              className="input"
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Marketing Description</label>
            <textarea
              onChange={(e) => {
                const newProgramObject = { ...newProgram };
                newProgramObject.longDescription = e.target.value;
                setNewProgram(newProgramObject);
              }}
              value={newProgram.longDescription}
              className="input"
            />
          </div>

          <div className="inputBox">
            <label className="body-text">Outcomes</label>
            <Editor
              handlePastedText={() => false}
              onEditorStateChange={(e) => {
                setOutcomesEditorState(e);
                let raw = convertToRaw(e.getCurrentContent());
                let html = draftToHtml(raw);
                setOutcomesHTML(html);
              }}
              editorState={outcomesEditorState}
            />
          </div>

          <div className="inputBox">
            <label className="body-text">This is for you if...</label>
            <Editor
              handlePastedText={() => false}
              onEditorStateChange={(e) => {
                setForYouEditorState(e);
                let raw = convertToRaw(e.getCurrentContent());
                let html = draftToHtml(raw);
                setForYouHTML(html);
              }}
              editorState={forYouEditorState}
            />
          </div>

          <button
            className="btn secondary"
            onClick={() => {
              editProgram();
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Program
          </button>
        </div>
      </div>
    </div>
  );
};
