import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Path/components/PathTool.scss";
import { Link, useHistory } from "react-router-dom";

export const PathTool = (props) => {
  const history = useHistory();

  return (
    <div className="PathTool white-background">
      <div className="top">
        <div className="icon">
          <img src={props.tool.image} alt="icon" />
        </div>
      </div>
      <div className="bottom">
        <h4>{props.tool.Tool_Path.title}</h4>
        <p className="body-text">{props.tool.Tool_Path.description}</p>
      </div>
      <div className="buttons">
        <button
          onClick={() => {
            history.push(props.tool.path);
          }}
          className="btn primary"
        >
          Get Started
        </button>
        <Link to={`/tool/${props.tool.id}`}>
          Learn More <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </div>
  );
};
