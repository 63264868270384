import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "../../styles/pages/Strengths/invite.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { Analytics } from "../../hooks/analytics";

export const StrengthInvites = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState();
  const [activeInvite, setActiveInvite] = useState();
  const [type, setType] = useState();
  const [completedInvites, setCompletedInvites] = useState([]);
  const [personalMessage, setPersonalMessage] = useState("");

  useEffect(() => {
    getMe();
    window.analytics.page("Strengths Invites");
    getInvites();

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  const saveInvite = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/strengths/invite/${activeInvite.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: activeInvite,
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if (type === "email") {
      await sendEmail();
    }

    setType(null);
    getInvites();
  };

  const sendEmail = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/strengths/invite/${activeInvite.id}/email`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        personalMessage: personalMessage,
        email: activeInvite.email,
        firstName: activeInvite.firstName,
        lastName: activeInvite.lastName,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    Analytics("Sent Strength Invite Email");
  };

  const getInvites = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const invites = response.data;

      const nonCompletedInvite = invites.filter(
        (invite) => invite.firstName === "" || !invite.firstName
      );

      const completedInvites = invites.filter(
        (invite) => invite.firstName !== "" && invite.firstName
      );

      setCompletedInvites(completedInvites);

      if (nonCompletedInvite.length > 0) {
        setActiveInvite(nonCompletedInvite[0]);
      } else if (invites.length < 3) {
        createInvite();
      } else {
        history.push("/strengths/sort");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createInvite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${apiServerUrl}/api/strengths/invite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const invite = response.data;
      setActiveInvite(invite);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="strengthsInvite">
      <div className="top">
        <h2>What do your friends says?</h2>
        <p>
          Ok, maybe there aren’t friends but that’s not important. It’s
          important to see things from another perspective. So let’s see what
          your friends say about your strengths. Invite 2-3 people to share
          their own words that describe you at your best.
        </p>
      </div>
      <div className="white-background">
        <p>
          Why are we asking for their name? So that we can customize and
          personalize their experience!
        </p>
        <div className="dots">
          {completedInvites.length > 0 ? (
            <span className="active">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          ) : (
            <span>1</span>
          )}
          {completedInvites.length > 1 ? (
            <span className="active">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          ) : (
            <span>2</span>
          )}
          {completedInvites.length > 2 ? (
            <span className="active">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          ) : (
            <span>3</span>
          )}
        </div>

        <div className="form">
          <div className="form-group">
            <label htmlFor="email">
              Your contact’s full name (so that we can personalize our message
              to them)
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="First Name"
                value={activeInvite?.firstName || ""}
                onChange={(e) => {
                  setActiveInvite({
                    ...activeInvite,
                    firstName: e.target.value,
                  });
                }}
              />
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Last Name"
                value={activeInvite?.lastName || ""}
                onChange={(e) => {
                  setActiveInvite({
                    ...activeInvite,
                    lastName: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="form-group half">
            <div className="input-group">
              <label htmlFor="email">What's their email address?</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={activeInvite?.email || ""}
                onChange={(e) => {
                  setActiveInvite({
                    ...activeInvite,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">
                How would you like to send your invitation?
              </label>
              <div className="types">
                <button
                  className={`btn blank ${type === "email" ? "active" : ""}`}
                  onClick={() => {
                    setType("email");
                  }}
                >
                  Email
                </button>
                <a
                  className={`btn blank ${type === "sms" ? "active" : ""}`}
                  onClick={() => {
                    setType("sms");
                    Analytics("Sent Strength Invite SMS");
                  }}
                  href={`sms:?&body=${`${window.location.protocol}//${window.location.host}/strengths/invites/${activeInvite?.id}`}`}
                >
                  SMS
                </a>
                <CopyToClipboard
                  text={`${window.location.protocol}//${window.location.host}/strengths/invites/${activeInvite?.id}`}
                  onCopy={() => {
                    setType("copy");
                    Analytics("Sent Strength Invite Copy");
                  }}
                >
                  <button
                    className={`btn blank ${type === "copy" ? "active" : ""}`}
                  >
                    Copy Url
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>

        {type === "email" && (
          <div className="email">
            <div className="form-group">
              <div className="input-group">
                <label>
                  We've drafted an invitation to your contact to provide them
                  with context and save you some time.
                </label>
                <div className="gray">
                  Hello {activeInvite.firstName}!<br />
                  <br />
                  {me.first_name} is requesting your help! <br />
                  <br />
                  <b>Here's the context:</b>
                  <br />
                  {me.first_name} is working on further understanding
                  themselves, who they are, and what is important to them in
                  their career and life. Right now, they are learning about
                  their strengths. <br />
                  <br />
                  <b>This is where you come in!</b> <br />
                  {me.first_name} would like your input on their strengths. They
                  think you can help because you know them well. You can share
                  some strengths that maybe they wouldn't have thought of
                  themselves.
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <label>
                  Feel free to add your own message below to make it a little
                  more personal.
                </label>
                <textarea
                  onChange={(e) => {
                    setPersonalMessage(e.target.value);
                  }}
                  placeholder="e.g. I would love for you to select some strengths you see in me!"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {completedInvites.length < 2 ? (
        <div className="bottom">
          <button className="btn blank" onClick={() => history.goBack()}>
            Back
          </button>

          <button
            disabled={
              !activeInvite?.first_name &&
              !activeInvite?.last_name &&
              !activeInvite?.email
            }
            className="btn primary"
            onClick={() => saveInvite()}
          >
            Next
          </button>
        </div>
      ) : (
        <div className="bottom">
          <button
            className="btn blank"
            onClick={() =>
              history.push("/strengths", {
                back: true,
              })
            }
          >
            Back
          </button>

          <button
            style={{
              marginLeft: "auto",
              marginRight: "20px",
            }}
            className="btn blank"
            onClick={() => saveInvite()}
          >
            Send Another
          </button>
          <button
            className="btn primary"
            onClick={async () => {
              try {
                await saveInvite();
              } catch (error) {
                history.push("/strengths/sort");
              }

              history.push("/strengths/sort");
            }}
          >
            I'm Done
          </button>
        </div>
      )}
    </div>
  );
};
