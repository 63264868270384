import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/index.scss";
import { MindmapOnboarding } from "./components/MindmapOnboarding";
import { CreateMindmap } from "./components/createMindmap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const Mindmap = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [me, setMe] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const isEditing = props.location?.state?.editing || false;
  const history = useHistory();

  useEffect(() => {
    getMe();

    if(isEditing) {
      setStep(1);
      props.location.state.editing = false;
    }
    getMindmap();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getMindmap = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/mindmap`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const data = response.data;

    if (data.length > 0) {
      history.push(`/mindmap/dashboard`);
    }

  };


  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
    setLoading(false);
  };

  if (loading) {
    return <div></div>;
  }
  if (step === 0) {
    return (
      <MindmapOnboarding
        setStep={(step) => {
          setStep(step);
        }}
      />
    );
  }
  if (step === 1) {
    return (
      <CreateMindmap
        setStep={(step) => {
          setStep(step);
        }}
        me={me}
      />
    );
  }
};
