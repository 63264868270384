import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { StrengthSearch } from "./components/StrengthSearch";
import "../../styles/pages/Strengths/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { InviteSection } from "./components/InviteSection";

export const StrengthsFive = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [showDo, setShowDo] = useState(true);
  const [showHow, setShowHow] = useState(false);
  const [showWho, setShowWho] = useState(false);
  const { apiServerUrl } = useEnv();
  const [programUser, setProgramUser] = useState(false);
  const [me, setMe] = useState();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [top5, setTop5] = useState(new Array(5).fill(""));
  const [strengths, setStrengths] = useState([]);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    getMe();
    getStrengths();
    getInvites();

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width:96rem";
      }
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }
    };
  }, []);

  const saveTopFive = async () => {
    const token = await getAccessTokenSilently();

    // Make sure all top 5 are filled

    for (let i = 0; i < top5.length; i++) {
      if (top5[i] === "") {
        alert("Please fill all 5 strengths");
        return;
      }
    }

    const config = {
      url: `${apiServerUrl}/api/strengths/five`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        strengths: top5,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    history.push("/strengths/overview");
  };

  const getInvites = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${apiServerUrl}/api/strengths/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const onlyCompleted = response.data.filter((item) => {
      if (item.strengths.length > 0) return true;
      return false;
    });

    setInvites(onlyCompleted);
  };

  const addWordFromSide = (strength) => {
    const isFull = top5.filter((item) => item !== "").length === 5;

    if (isFull) {
      alert("Too many strengths, please remove one");
      return;
    }

    const newTop5 = [...top5];

    for (let i = 0; i < newTop5.length; i++) {
      if (newTop5[i] === "") {
        newTop5[i] = strength;
        break;
      }
    }

    const newStrengths = [...strengths];
    const index = newStrengths.findIndex((item) => item.id === strength.id);
    newStrengths.splice(index, 1);
    setStrengths(newStrengths);

    setTop5(newTop5);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }

    setMe(response.data);
  };

  const getStrengths = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${apiServerUrl}/api/strengths/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const strengthData = response.data;

      let strengthsMap = [...strengthData];

      let items = strengthsMap.filter((item, index) => {
        if (!item.strengthInviteId) return true;
        return false;
      });

      let top5 = strengthsMap.filter((item, index) => {
        if (item.top) return true;
        return false;
      });

      const filledArray = new Array(5).fill("");

      top5.forEach((item, index) => {
        filledArray[index] = item;
      });

      setTop5(filledArray);

      setStrengths(items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="strengths">
      <div className="white-background">
        <div className="left">
          <h2>
            <FontAwesomeIcon
              onClick={() => {
                history.goBack();
              }}
              icon={faTimes}
            />{" "}
            Strengths Feedback
          </h2>
          <div className="titleContainer">
            <h4 className="underline yellow">Your 10 Strengths</h4>
            {showDo && (
              <p
                onClick={() => {
                  setShowDo(false);
                }}
              >
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </p>
            )}

            {!showDo && (
              <p
                onClick={() => {
                  setShowDo(true);
                  setShowHow(false);
                  setShowWho(false);
                }}
              >
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </p>
            )}
            {showDo && (
              <div className="wordsContainer">
                {strengths.map((word) => {
                  return (
                    <div
                      className="strengthSuggestion"
                      onClick={() => {
                        addWordFromSide(word);
                      }}
                    >
                      <p>{word.text}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {invites.map((invite) => {
            return (
              <InviteSection
                invite={invite}
                top5={top5}
                setTop5={setTop5}
                setStep={setStep}
              />
            );
          })}

          <div className="bottom">
            <button className="btn blank" onClick={() => history.goBack()}>
              Close &amp; Exit
            </button>

            <button
              disabled={top5.filter((item) => item === "").length > 0}
              className="btn primary"
              onClick={() => saveTopFive()}
            >
              Continue
            </button>
          </div>
        </div>
        <div className="right">
          <h3>Your top 5 Strengths</h3>
          <p>The strengths from the list will appear in these empty boxes</p>
          {top5.map((strength, index) => {
            if (index < 5) {
              return (
                <div className="strength">
                  <StrengthSearch
                    key={index}
                    remove={(strength) => {
                      const newStrengths = [...strengths];
                      newStrengths.push(strength);
                      setStrengths(newStrengths);
                      const newTop5 = [...top5];
                      newTop5[index] = "";
                      setTop5(newTop5);
                    }}
                    index={index}
                    strength={strength}
                    placeholder={""}
                    setText={(text) => {
                      const newStrengths = [...top5];
                      newStrengths[index].text = text;
                      setTop5(newStrengths);
                    }}
                  />
                </div>
              );
            }

            return "";
          })}
        </div>
      </div>
    </div>
  );
};
