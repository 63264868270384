import React, { useEffect, useState } from "react";
import "../../../styles/pages/Values/components/ValuesOnboarding.scss";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import TopLeft from "./svgs/TopLeft.svg";
import Logo from "./svgs/pngLogo.png";

export const ValuesProgramOnboarding = ({ setStep, me }) => {
  const [slideNumber, setSlideNumber] = useState(0);

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style.display = "none";
    }

    window.Intercom("update", {
      hide_default_launcher: true,
    });

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;width:100%";

    const navMobile = document.getElementsByClassName(
      "nav-bar__container__mobile"
    )[0];

    if (navMobile) {
      navMobile.style.display = "none";
    }

    setTimeout(() => {
      let moose = new Reveal();
      moose.initialize();
      moose.on("slidechanged", (event) => {
        setSlideNumber(event.indexh);

        if (event.indexh === 1) {
          setStep(2);
        }
      });
    }, 100);

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName(
        "nav-bar__container__mobile"
      )[0];
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
      if (nav) {
        nav.style.display = "flex";
      }
      if (navMobile) {
        navMobile.style.display = "flex";
      }

      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <div id="ValuesOnboarding" className="ValuesOnboarding">
      <img src={TopLeft} alt="accent" className="topLeft" />
      <img src={Logo} alt="logo" className="logo" />

      <div className="reveal">
        <div className="slides">
          <section className="content">
            <div className="left">
              <h3>Values Pre-Work</h3>
              <h2>10 Images</h2>
              <p className="subline">
                <FontAwesomeIcon icon={faClock} /> This will take you about 15
                minutes
              </p>
            </div>
          </section>
          <section></section>
        </div>
      </div>
    </div>
  );
};
