import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/highlightTitle.scss";

export const HighlightDescription = ({
  chat,
  continueLoop,
  userInputs,
  divRef,
}) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(chat.message);
  const [continued, setContinued] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let message = chat.message?.replace(/{([^{}]+)}/g, function (keyExpr, key) {
      return userInputs[key] || "";
    });

    setMessage(message);

    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div className="highlightTitle">
      <div className="chatMessageBox">
        <img
          alt="avatar"
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
          <h4>Roxanne Burbach {chat.app}</h4>
          <div className="bubble">
            {!loading && (
              <p>
                Great job! Tell me more about it. What was it about this moment
                that made it meaningful or special to you?
              </p>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
      {!continued && !loading && (
        <div className="entryForm">
          <form
            onSubmit={() => {
              continueLoop({
                highlightDescription: inputValue,
              });
              setContinued(true);
            }}
          >
            <textarea
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  continueLoop({
                    highlightDescription: inputValue,
                  });
                  setContinued(true);
                }
              }}
              placeholder="Enter text here"
              autoFocus={true}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
              <FontAwesomeIcon  onClick={(e) => {
                  continueLoop({
                    highlightDescription: inputValue,
                  });
                  setContinued(true);
              }} icon={faPaperPlane} />
          </form>
        </div>
      )}
    </div>
  );
};
