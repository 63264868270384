export const Slides = ({ choosenPrompts, setSelectedBox }) => {
  return (
    <div className="slides">
      {choosenPrompts.growth1 && (
        <div
          onClick={() => {
            setSelectedBox("growth1");
          }}
          className="box"
        >
          <span>PERSONAL GROWTH</span>
          <h5>Looking back on the last year...</h5>
          <p>{choosenPrompts.growth1}</p>
        </div>
      )}
      {!choosenPrompts.growth1 && (
        <div className="box blank">
          <span>PERSONAL GROWTH</span>
          <h5>Looking back on the last year...</h5>
          <p>Choose A Prompt</p>
        </div>
      )}
      {choosenPrompts.growth2 && (
        <div
          onClick={() => {
            setSelectedBox("growth2");
          }}
          className="box"
        >
          <span>PERSONAL GROWTH</span>
          <h5>Looking back on the last year...</h5>
          <p>{choosenPrompts.growth2}</p>
        </div>
      )}
      {!choosenPrompts.growth2 && (
        <div className="box blank">
          <span>PERSONAL GROWTH</span>
          <h5>Looking back on the last year...</h5>
          <p>Choose A Prompt</p>
        </div>
      )}
      {choosenPrompts.relationships && (
        <div
          onClick={() => {
            setSelectedBox("relationships");
          }}
          className="box yellow"
        >
          <span>RELATIONSHIPS</span>
          <h5>Looking back on the last year...</h5>
          <p>{choosenPrompts.relationships}</p>
        </div>
      )}
      {!choosenPrompts.relationships && (
        <div className="box blank">
          <span>RELATIONSHIPS</span>
          <h5>Looking back on the last year...</h5>
          <p>Choose A Prompt</p>
        </div>
      )}
      {choosenPrompts.impact1 && (
        <div
          onClick={() => {
            setSelectedBox("impact1");
          }}
          className="box red"
        >
          <span>IMPACT</span>
          <h5>Looking back on the last year...</h5>
          <p>{choosenPrompts.impact1}</p>
        </div>
      )}
      {!choosenPrompts.impact1 && (
        <div className="box blank">
          <span>IMPACT</span>
          <h5>Looking back on the last year...</h5>
          <p>Choose A Prompt</p>
        </div>
      )}
      {choosenPrompts.impact2 && (
        <div
          onClick={() => {
            setSelectedBox("impact2");
          }}
          className="box red"
        >
          <span>IMPACT</span>
          <h5>Looking back on the last year...</h5>
          <p>{choosenPrompts.impact2}</p>
        </div>
      )}
      {!choosenPrompts.impact2 && (
        <div className="box blank">
          <span>IMPACT</span>
          <h5>Looking back on the last year...</h5>
          <p>Choose A Prompt</p>
        </div>
      )}
    </div>
  );
};
