import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import "../../../styles/pages/Admin/Crews/platformStats.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const PlatformStats = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [filter, setFilter] = useState("lennar");
  const [signupStats, setSignupStats] = useState({});
  const [noShowSessionDidToolState, setNoShowSessionDidToolState] = useState(
    []
  );
  const [noSessionsAtleastOneToolState, setNoSessionsAtleastOneToolState] =
    useState([]);
  const [stats, setStats] = useState({
    strengths: {},
    values: {},
    highlights: {},
    careerCollaborators: {},
    visions: {},
  });

  useEffect(() => {
    getAllStats();
  }, []);

  // Get a session by id and return the attendance object
  const attendedSession = (attendances, id) => {
    const single =
      attendances.filter((a) => a.session.learningActivityId === id).length > 0;

    if (single) {
      return attendances.filter((a) => a.session.learningActivityId === id)[0];
    }

    return false;
  };

  // Get all stats and reduce them to a single object
  const getAllStats = async () => {
    try {
      let url = `${apiServerUrl}/api/crews/stats`;
      if (filter) {
        url = `${apiServerUrl}/api/crews/stats?filter=${filter}`;
      }
      const token = await getAccessTokenSilently();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const reducedStats = response.data.crew.reduce(
        (acc, curr) => {
          const sessions = curr.crew[0].program.sessions;
          var usedTool = false;
          var started = false;

          const sortedSessions = sessions.sort((a, b) => {
            return a.order - b.order;
          });

          // Attendance for highlights
          if (attendedSession(curr.attendances, 3)) {
            acc.totalAttendance += 1;
            const attendance = attendedSession(curr.attendances, 3);
            if (attendance.attended) {
              acc.highlights.attended.push(attendance.user);
              acc.attended += 1;
            } else {
              acc.highlights.didNotAttend.push(attendance.user);
            }
          }

          // Attendance for values
          if (attendedSession(curr.attendances, 13)) {
            acc.totalAttendance += 1;
            const attendance = attendedSession(curr.attendances, 13);
            if (attendance.attended) {
              acc.values.attended.push(attendance.user);
              acc.attended += 1;
            } else {
              acc.values.didNotAttend.push(attendance.user);
            }
          }

          // Attendance for strengths
          if (attendedSession(curr.attendances, 4)) {
            acc.totalAttendance += 1;
            const attendance = attendedSession(curr.attendances, 4);
            if (attendance.attended) {
              acc.strengths.attended.push(attendance.user);
              acc.attended += 1;
            } else {
              acc.strengths.didNotAttend.push(attendance.user);
            }
          }

          // Attendance for visions
          if (attendedSession(curr.attendances, 10)) {
            acc.totalAttendance += 1;
            const attendance = attendedSession(curr.attendances, 10);
            if (attendance.attended) {
              acc.visions.attended.push(attendance.user);
              acc.attended += 1;
            } else {
              acc.visions.didNotAttend.push(attendance.user);
            }
          }

          // Attendance for board of advisors
          if (attendedSession(curr.attendances, 6)) {
            acc.totalAttendance += 1;
            const attendance = attendedSession(curr.attendances, 6);
            if (attendance.attended) {
              acc.careerCollaborators.attended.push(attendance.user);
              acc.attended += 1;
            } else {
              acc.careerCollaborators.didNotAttend.push(attendance.user);
            }
          }

          // Tool Completion: Highlights
          if (isDone(sortedSessions, 3)) {
            acc.highlights.total += 1;
            started = true;

            // if the accumulator doesnt already have this crew then add it
            if (acc.highlights.crews.indexOf(curr.crew[0].name) === -1) {
              acc.highlights.crews.push(curr.crew[0].name);
            }

            if (curr.highlightsCount > 0) {
              acc.highlights.done += 1;
              usedTool = true;
            }
          }

          // Tool Completion: Values
          if (isDone(sortedSessions, 13)) {
            acc.values.total += 1;
            started = true;

            // if the accumulator doesnt already have this crew then add it
            if (acc.values.crews.indexOf(curr.crew[0].name) === -1) {
              acc.values.crews.push(curr.crew[0].name);
            }

            if (curr.valueImagesCount > 0) {
              acc.values.done += 1;
              usedTool = true;
            }
          }

          // Tool Completion: Strengths
          if (isDone(sortedSessions, 4)) {
            acc.strengths.total += 1;
            started = true;

            // if the accumulator doesnt already have this crew then add it
            if (acc.strengths.crews.indexOf(curr.crew[0].name) === -1) {
              acc.strengths.crews.push(curr.crew[0].name);
            }

            if (curr.strengthsCount > 0) {
              acc.strengths.done += 1;
              usedTool = true;
            }
          }

          // Tool Completion: Visions
          if (isDone(sortedSessions, 10)) {
            acc.visions.total += 1;
            started = true;

            if (acc.visions.crews.indexOf(curr.crew[0].name) === -1) {
              acc.visions.crews.push(curr.crew[0].name);
            }

            if (curr.visionsCount > 0) {
              acc.visions.done += 1;
              usedTool = true;
            }
          }

          // Career Collaborators
          if (isDone(sortedSessions, 6)) {
            acc.careerCollaborators.total += 1;
            started = true;

            if (
              acc.careerCollaborators.crews.indexOf(curr.crew[0].name) === -1
            ) {
              acc.careerCollaborators.crews.push(curr.crew[0].name);
            }

            if (curr.careerCollaboratorsCount > 0) {
              acc.careerCollaborators.done += 1;
              usedTool = true;
            }
          }

          // Did they use any tool, and total number of people who started
          if (usedTool) acc.usedTool += 1;
          if (started) acc.started += 1;

          return acc;
        },
        {
          highlights: {
            total: 0,
            done: 0,
            crews: [],
            attended: [],
            didNotAttend: [],
          },
          usedTool: 0,
          started: 0,
          totalAttendance: 0,
          attended: 0,
          values: {
            total: 0,
            done: 0,
            crews: [],
            attended: [],
            didNotAttend: [],
          },
          strengths: {
            total: 0,
            done: 0,
            crews: [],
            attended: [],
            didNotAttend: [],
          },
          visions: {
            total: 0,
            done: 0,
            crews: [],
            attended: [],
            didNotAttend: [],
          },
          careerCollaborators: {
            total: 0,
            done: 0,
            crews: [],
            attended: [],
            didNotAttend: [],
          },
        }
      );

      setStats(reducedStats);
      setSignupStats(response.data?.interests || []);

      const noShowSessionDidTool = await noShowToSessionButDidTool(
        response.data.crew
      );
      const noSessionsAtleastOneTool = await noAttendedSessionsUsedPlatform(
        response.data.crew
      );

      setNoShowSessionDidToolState(noShowSessionDidTool);
      setNoSessionsAtleastOneToolState(noSessionsAtleastOneTool);
    } catch (error) {
      console.error(error.message);
    }
  };

  const noShowToSessionButDidTool = async (data) => {
    return data
      .map((user) => {
        const isNoShow = user.attendances
          .map((attendance) => {
            if (
              attendance.session.learningActivityId === 13 &&
              attendance.attended === false &&
              user.valueImagesCount > 0
            ) {
              attendance.valueImagesCount = user.valueImagesCount;
              return attendance;
            }
            if (
              attendance.session.learningActivityId === 6 &&
              attendance.attended === false &&
              user.careerCollaboratorsCount > 0
            ) {
              attendance.careerCollaboratorsCount =
                user.careerCollaboratorsCount;
              return attendance;
            }
            if (
              attendance.session.learningActivityId === 3 &&
              attendance.attended === false &&
              user.highlightCount > 0
            ) {
              attendance.highlightCount = user.highlightCount;
              return attendance;
            }
            if (
              attendance.session.learningActivityId === 4 &&
              attendance.attended === false &&
              user.strengthsCount > 0
            ) {
              attendance.strengthsCount = user.strengthsCount;
              return attendance;
            }
            if (
              attendance.session.learningActivityId === 10 &&
              attendance.attended === false &&
              user.visionsCount > 0
            ) {
              attendance.visionsCount = user.visionsCount;
              return attendance;
            }
            return false;
          })
          .filter((item) => item !== false);

        if (isNoShow.length > 0) {
          return isNoShow;
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  const noAttendedSessionsUsedPlatform = async (data) => {
    return data
      .map((user) => {
        // Check if user has attended any session
        const attendedSessions = user.attendances.filter((attendance) => {
          if (attendance.attended === true) {
            return true;
          }
          return false;
        });

        // If user has not attended any session and has used at least one tool
        if (attendedSessions.length === 0) {
          if (
            user.valueImagesCount > 0 ||
            user.careerCollaboratorsCount > 0 ||
            user.highlightsCount > 0 ||
            user.strengthsCount > 0 ||
            user.visionsCount > 0
          ) {
            return user;
          }
        }

        return null;
      })
      .filter((item) => item !== null);
  };

  const order = [
    { index: "highlights", title: "Highlights" },
    { index: "values", title: "Values" },
    { index: "strengths", title: "Strengths" },
    { index: "visions", title: "Visions" },
    { index: "careerCollaborators", title: "Board of Advisors" },
  ];

  // Check if a session is done
  const isDone = (sessions, learningActivityId) => {
    const session = sessions.filter((s) => {
      return s.learningActivityId === learningActivityId;
    });

    if (!session.length) return false;
    return moment().isAfter(session[0].endDate);
  };

  if (!stats) return <div>Loading...</div>;

  return (
    <div className="PlatformStats">
      <div className="back">
        <Link to="/admin/crews">
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Link>
      </div>
      <h2>Platform Session Stats</h2>

      <div className="filters">
        <input
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          type="text"
          value={filter}
          placeholder="Filter by Email"
        />
        <button
          onClick={() => {
            getAllStats();
          }}
          className="btn secondary"
        >
          Filter
        </button>
      </div>

      {order.map((o) => {
        return (
          <div className="session">
            <h3>{o.title}</h3>
            <div className="grid">
              <div className="total">
                <h4>Total</h4>
                <p>{stats[o.index].total}</p>
              </div>
              <div className="done">
                <h4>Completed</h4>
                <p>{stats[o.index].done}</p>
              </div>
              <div className="percentage">
                <h4>%</h4>
                <p>
                  {stats[o.index].total > 0
                    ? Math.round(
                        (stats[o.index].done / stats[o.index].total) * 100
                      )
                    : 0}
                  %
                </p>
              </div>
            </div>

            <div className="crewsStats">
              <h4>Crews</h4>
              <ul>
                {stats[o.index]?.crews?.map((c) => (
                  <li>{c}</li>
                ))}
              </ul>
            </div>

            <div className="attendance">
              <h3>Attendance</h3>
              <div className="grid">
                <div className="total">
                  <h4>Attended</h4>
                  <p>{stats[o.index].attended?.length}</p>
                </div>
                <div className="done">
                  <h4>No Show</h4>
                  <p>{stats[o.index].didNotAttend?.length}</p>
                </div>
                <div className="percentage">
                  <h4>%</h4>
                  <p>
                    {stats[o.index].attended?.length > 0
                      ? Math.round(
                          (stats[o.index].attended?.length /
                            (stats[o.index].attended?.length +
                              stats[o.index].didNotAttend?.length)) *
                            100
                        )
                      : 0}
                    %
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="session singleStat">
        <h3>% Who Used At-least One Tool </h3>
        <p className="percent">
          {((stats.usedTool / stats.started) * 100).toFixed(1)}%
        </p>
      </div>

      <div className="session singleStat">
        <h3>% of Attendance </h3>
        <p className="percent">
          {((stats.attended / stats.totalAttendance) * 100).toFixed(1)}%
        </p>
      </div>

      <div className="session singleStat">
        <h3>Sign Up Conversions </h3>
        <p className="percent">
          {(
            (signupStats.signedUpCount / signupStats.interestCount) * 100 || 0
          ).toFixed(1)}
          %
        </p>
      </div>

      <div className="session singleStat">
        <h3>Attended No Sessions But Used Platform </h3>
        <p className="percent">{noSessionsAtleastOneToolState.length} Users</p>
      </div>

      <div className="session singleStat">
        <h3>Didn't Attend Session But Did Tool</h3>
        <p className="percent">{noShowSessionDidToolState.length} Users</p>
      </div>

      <div className="session singleStat">
        <h3>Didn't Attend Session But Did Tool</h3>
        <p
          style={{
            fontSize: "40px",
          }}
          className="percent"
        >
          {noShowSessionDidToolState.flat().length} Sessions
        </p>
      </div>
    </div>
  );
};
