import { faCheck, faChevronDown, faChevronUp, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Learn/components/LearningSection.scss";
import { LearningActivityCard } from "./LearningActivityCard";

export const LearningSection = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [finishedSection, setFinishedSection] = useState(false);

  useEffect(() => {
    if (props.section.learningActivities.length > 0) {
      const count = props.section.learningActivities.filter((activity) => {
        if (activity.users.length > 0) {
          return true;
        }
        return false;
      });

      if (count.length === props.section.learningActivities.length) {
        setFinishedSection(true);
      }
    }
  }, [props.section]);

  return (
    <div className="LearningSection">
      <div
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        className="top"
      >
        <h4>{props.section.title}</h4>
        {finishedSection && (
          <div className="done">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {!finishedSection && (
          <div className="not-done">
            <span>
              {props.section.timeToComplete} min
              {showDropdown && (
                <FontAwesomeIcon
                  icon={faChevronDown}
                />
              )}
              {!showDropdown && (
                <FontAwesomeIcon
                  icon={faChevronUp}
                />
              )}
              </span>
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="dropdown">
          {props.section.learningActivities.map((activity) => {
            return (
              <LearningActivityCard
                reload={() => {
                  props.reload();
                }}
                activity={activity}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
