import React, { useEffect, useState, useRef } from "react";
import "../../styles/components/Chat/index.scss";
import { ChatMessage } from "./message";
import { UserMessage } from "./user";
import ScrollableFeed from "react-scrollable-feed";
import { Purpose } from "./addons/purpose";
import { Values } from "./addons/values";
import { Strengths } from "./addons/strengths";
import { Highlight } from "./addons/highlight";
import { HighlightTitle } from "./addons/highlightTitle";
import { StrengthsFeedbackUser } from "./addons/strengthsFeedbackUser";

import { HighlightDescription } from "./addons/highlightDescription";
import { HighlightImage } from "./addons/highlightImage";
import { Final } from "./addons/final";
import { ExploreStrengthsFinal } from "./addons/exploreStrengthsFinal";

import { StrengthInvite } from "./addons/strengthInvite";
import { StartingValues } from "./addons/startingValues";
import { ValueInvite } from "./addons/valueInvite";


export const Chat = (props) => {
  const [title, setTitle] = useState("Crew Chat");
  const [endingIndex, setEndingIndex] = useState(1);
  const [userInputs, setUserInputs] = useState({});
  const divRef = useRef(null);
  const [chats, setChats] = useState(props.chats);

  const [chatsActive, setChatsActive] = useState([]);

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    const navMobile = document.getElementsByClassName("nav-bar__container__mobile")[0];
    if (nav) {
      nav.style.display = "none";
    }
    if (navMobile) {
      navMobile.style.display = "none";
    }
    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "width: 100%;max-width: 100%;";
    }

    setData();

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName("nav-bar__container__mobile")[0];
      const container = document.getElementsByClassName("content_container")[0];

      if (container) {
        container.style = "max-width:92rem;width:calc(100% - 205px)";
      }
      if (nav) {
        nav.style.display = "flex";
      }
      if (navMobile) {
        navMobile.style.display = "flex";
      }
    };
  }, [chats]);

  const checkFinished = (updatedIndex) => {
    if (updatedIndex.length === chats.length) {
      setTimeout(() => {
      props.finish();
      }, chats[chats.length - 1].delay || 2500);
    }
  };

  // Starting the chat
  const setData = async (newIndex) => {
    setChatsActive([chats[0]]);
    let newChat = [chats[0]];
    for (let i = newIndex || endingIndex; i < chats.length; i++) {
      const update = [...newChat, chats[i]];
      newChat = update;
      await delay(2500);
      setChatsActive(update);
      checkFinished(update);
      props.setStep(chats[i].id);
      if (chats[i].break) {
        setEndingIndex(i);
        break;
      }
    }
    
  };

  // Continuing the chat
  const continueLoop = async (newIndex) => {
    let newChat = [...chatsActive];
    for (let i = newIndex; i < chats.length; i++) {
      const update = [...newChat, chats[i]];
      newChat = update;
      await delay(chats[i].delay || 2500);
      setChatsActive(update);
      checkFinished(update);
      props.setStep(chats[i].id);
      if (chats[i].break) {
        setEndingIndex(i);
        break;
      }
    }

  };

  // Async delay
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));


  return (
    <div id="chatContainer" className="chatContainer">
      <div className="chatBox">
        {props.hideTitle !== true && (
        <div className="title">
          <h3>Getting Started</h3>
        </div>
        )}
        <ScrollableFeed
          ref={divRef}
          className="chatsContainer"
          forceScroll={true}
        >
          {chatsActive.map((chat) => {
            if (chat.type === "coach") {
              return (
                <ChatMessage
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  userInputs={userInputs}
                  key={chat.id}
                  chat={chat}
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "statement") {
              return (
                <Purpose
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "values") {
              return (
                <Values
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  userInputs={userInputs}
                  key={chat.id}
                  chat={chat}
                  me={props.me}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "highlight") {
              return (
                <Highlight
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "highlightTitle") {
              return (
                <HighlightTitle
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "strengthsFeedbackUser") {
              return (
                <StrengthsFeedbackUser
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                    props.inputEntered({ ...userInputs, ...data })
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "highlightDescription") {
              return (
                <HighlightDescription
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "highlightImage") {
              return (
                <HighlightImage
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  userInputs={userInputs}
                  divRef={divRef}
                  me={props.me}
                />
              );
            }
            if (chat.program === "strengths") {
              return (
                <Strengths
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  me={props.me}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "final") {
              return (
                <Final
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if (chat.program === "startingValues") {
              return (
                <StartingValues
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="values"
                  divRef={divRef}
                />
              );
            }
            if(chat.program === 'exploreStrengthsFinal') {
              return (
                <ExploreStrengthsFinal
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if(chat.program === "strengthsInvite") {
              return (
                <StrengthInvite
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  me={props.me}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            if(chat.program === "valuesInvite") {
              return (
                <ValueInvite
                  continueLoop={async (data) => {
                    setUserInputs({ ...userInputs, ...data });
                    continueLoop(endingIndex + 1);
                  }}
                  key={chat.id}
                  me={props.me}
                  chat={chat}
                  program="statement"
                  divRef={divRef}
                />
              );
            }
            return (
              <UserMessage
                continueLoop={async (data) => {
                  setUserInputs({ ...userInputs, ...data });
                  continueLoop(endingIndex + 1);
                }}
                userInputs={userInputs}
                key={chat.id}
                chat={chat}
                divRef={divRef}
                me={props.me}
              />
            );
          })}
        </ScrollableFeed>
      </div>
    </div>
  );
};
