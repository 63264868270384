import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Programs/single.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../../hooks/isAdmin";



export const ProgramsSingle = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [program, setProgram] = useState(null);
  const [addLessonTemplate, setAddLessonTemplate] = useState(false);
  let id = props.match.params.id;

  const [newLessonTemplate, setNewLessonTemplate] = useState({
    name: "",
    description: "",
    length: 120,
    programTemplateId: id,
  });

  useEffect(() => {
    getMe();
    getProgram();
  }, []);




  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const deleteLessonTemplate = async (id) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/lessonTemplates/${id}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    getProgram();
  };

  const addLessonTemplateAPI = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/lessonTemplates`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    config.data = newLessonTemplate;
    await axios(config);
    setNewLessonTemplate({
      name: "",
      description: "",
      type: "",
      timeToComplete: "",
      url: "",
      programTemplateId: id,
    });
    setAddLessonTemplate(false);
    getProgram();
  };

  const getProgram = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/programTemplates/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setProgram(response.data);
  };

  if (!program) {
    return <div>Loading...</div>;
  }

  return (
    <div className="programSingle">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/programs"}>Programs</NavLink> /{" "}
        </div>
        <div className="breadcrumb"> {program.name}</div>
      </div>
      <div className="container white-background">
        <NavLink to={`/admin/programs/${program.id}/edit`} className="edit">Edit</NavLink>
        <h2 className="heading1 highlight blue">{program.name}</h2>
        <p className="body-text ">{program.description}</p>
        <div className="info">

        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
          marginBottom: "50px",
        }}
        className="container white-background"
      >
        <h2 className="heading2 highlight yellow">{program.name} Lesson Templates</h2>

        <button
          onClick={() => {
            setAddLessonTemplate(true);
          }}
          className="btn secondary right"
        >
          <FontAwesomeIcon icon={faPlus} /> Add Lesson Template
        </button>

        {program.lessonTemplates.map((lessonTemplate) => (
          <div className="lessonTemplate">
            <div className="lessonTemplate-info">
              <div className="lessonTemplate-title">
                <h3 className="heading2 underline blue">{lessonTemplate.name}</h3>

              </div>
              <div className="lessonTemplate-description">
                <p className="body-text">{lessonTemplate.description}</p>
              </div>
            </div>
            <div className="lessonTemplate-actions">
                <NavLink to={`/admin/lessonTemplates/${lessonTemplate.id}`}>
                    <button className="btn secondary">
                        <FontAwesomeIcon icon={faBook} /> View Lesson Template
                    </button>
                </NavLink>

                <NavLink to={`/admin/lessonTemplates/${lessonTemplate.id}/edit`}>
                    <button className="btn secondary purple">
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                </NavLink>

                <button onClick={()=>{
                  deleteLessonTemplate(lessonTemplate.id)
                }} className="btn secondary red">
                    <FontAwesomeIcon icon={faTrash} />
                </button> 
            </div>
          </div>
        ))}
      </div>
      {addLessonTemplate && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="heading2 highlight yellow">Add Lesson Template</h2>
            <div className="row">
              <div className="inputBox">
                <label className="body-text">Lesson Template Name</label>
                <input
                  onChange={(e) => {
                    const newLessonTemplateObject = { ...newLessonTemplate };
                    newLessonTemplateObject.name = e.target.value;
                    setNewLessonTemplate(newLessonTemplateObject);
                  }}
                  type="text"
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Description</label>
                <textarea
                  onChange={(e) => {
                    const newLessonTemplateObject = { ...newLessonTemplate };
                    newLessonTemplateObject.description = e.target.value;
                    setNewLessonTemplate(newLessonTemplateObject);
                  }}
                  className="input"
                />
              </div>
              <div className="inputBox">
                <label className="body-text">Length (minutes)</label>
                <input
                  onChange={(e) => {
                    const newLessonTemplateObject = { ...newLessonTemplate };
                    newLessonTemplateObject.length = e.target.value;
                    setNewLessonTemplate(newLessonTemplateObject);
                  }}
                  type="number"
                  className="input"
                />
              </div>



              <button className="btn secondary" onClick={addLessonTemplateAPI}>
                <FontAwesomeIcon icon={faPlus} /> Add Lesson Template
              </button>
            </div>
          </div>
          <div
            onClick={() => {
              setAddLessonTemplate(false);
            }}
            className="skrim"
          ></div>
        </div>
      )}
    </div>
  );
};
