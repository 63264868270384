import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/dashboard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faPlus,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";

export const TopicsDashboard = ({ location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [insights, setInsights] = useState([]);
  const [topic, setTopic] = useState({});
  const [me, setMe] = useState({});
  const [programUser, setProgramUser] = useState(false);
  const [addingInsight, setAddingInsight] = useState(false);
  const [insightText, setInsightText] = useState("");

  const history = useHistory();

  useEffect(() => {
    getMe();
    getTopics();
    getInsights();
  }, []);

  const saveNotes = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/topics/notes`,
      method: "POST",
      data: {
        notes: topic.notes,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setTopic(response.data);

    Analytics("Tying it All Together - Saved Notes");
  };

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "topic1",
        toolId: 9,
        question:
          question ||
          `What's clearer to you now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("Tying it All Together - Added Insight");
    setAddingInsight(false);
    setInsightText("");
    getInsights();
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    // They are a program user.
    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/topic`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setInsights(response.data);
  };

  const getTopics = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/topics`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setTopic(response.data);
  };

  return (
    <div className="topicsDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Tying it All Together</h2>
        </div>

        <div className="right">
          {programUser && (
            <button
              onClick={() => {
                history.push("/program");
              }}
              className="btn secondary"
            >
              Back to Program
            </button>
          )}
        </div>
      </div>

      <div className="white-background allTopics">
        <div className="top">
          <h3>Career Topic</h3>
        </div>
        <div className="topics">
          <div className="topic">
            <h4>What topic would you like coaching on?</h4>
            <p>{topic.topic}</p>
          </div>

          <div className="topic">
            <h4>What's important about this topic?</h4>
            <p>{topic.important}</p>
          </div>

          <div className="topic">
            <h4>
              What do you want to walk away with at the end of the session?
            </h4>
            <p>{topic.walk_away}</p>
          </div>
        </div>
      </div>

      <div className="white-background boardStrengths">
        <div className="top">
          <h3>Discussion Notes</h3>
        </div>

        {programUser && (
          <div className="inSession steps">
            <div className="top">
              <h5>Next Steps</h5>
              <span>During Session</span>
            </div>
            <p>
              You can use this space to take note of the input you receive on
              the topic you bring up. This space can also be used to jot down
              topics and questions that your colleagues bring up during the
              session.
            </p>
          </div>
        )}

        <div className="notes">
        <textarea
          value={topic.notes}
          onChange={(e) => {
            setTopic({ ...topic, notes: e.target.value });
          }}
          placeholder="Type your notes here"
        ></textarea>

        <button
          onClick={() => {
            saveNotes();
          }}
          className="btn secondary"
        >
          Save
        </button>
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you now that you've completed this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
