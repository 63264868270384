import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProgramWidget = ({ me, image }) => {
  const history = useHistory();
  return (
    <div className="currentProgram">
      <div className="top">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M4.91667 2.5V5.83333M3.25 4.16667H6.58333M5.75 14.1667V17.5M4.08333 15.8333H7.41667M11.5833 2.5L13.4881 8.21429L18.25 10L13.4881 11.7857L11.5833 17.5L9.67857 11.7857L4.91667 10L9.67857 8.21429L11.5833 2.5Z"
              stroke="#0B455F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          {me?.crew[0]?.name}
        </span>
      </div>
      <div className="sides">
        <div className="left">
          <img src={me?.crew[0]?.company?.marketing_image} alt="" />
        </div>
        <div className="right">
          <h4>Check out your program page</h4>
          <h2>
            See everything you need for{" "}
            <span>Session {me?.crew[0].activeSession?.order}</span>
          </h2>
          <p>
            This will be where you find your meeting links, your coach and crew
            members, and your pre-work for your upcoming session.
          </p>
        </div>

        <div className="action">
          <button
            onClick={() => {
              history.push(`/program`);
            }}
            className="btn secondary"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramWidget;
