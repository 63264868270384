import React, { useEffect, useState } from "react";
import "chartjs-adapter-moment";
import { faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSlider from "react-slider";

export const Add = (props) => {
  const [moment, setMoment] = useState({
    type: "personal",
    year: "",
    month: "",
    score: 50,
    title: "",
    description: "",
  });
  const [showHelp, setShowHelp] = useState(false);
  useEffect(() => {}, []);

  const getEmoji = (score) => {
    if (score > 80) {
      return "🤩";
    } else if (score > 60) {
      return "😃";
    } else if (score > 40) {
      return "🙂";
    } else if (score > 20) {
      return "😐";
    } else {
      return "😕";
    }
  };

  return (
    <div className="cBoxAdd">
      <div className="emoji"></div>
      <div className="content">
        <FontAwesomeIcon
          onClick={() => {
            setShowHelp(!showHelp);
          }}
          className="help"
          icon={faQuestion}
        />

        <div className="form-group">
          <p>Let's add a new moment! </p>
          <label htmlFor="type">
            Significant Moment <span>Required</span>
          </label>
          <input
            placeholder="E.g Received great feedback on Project X"
            type="text"
            onChange={(event) => {
              setMoment({ ...moment, title: event.target.value });
            }}
            value={moment.title}
            name="type"
            id="type"
          />
        </div>

        <div className="form-group">
          <label htmlFor="type">
            Timeframe <span>Required</span>
          </label>
          <input
            type="month"
            id="start"
            name="start"
            min="1920-01"
            onChange={(event) => {
              setMoment({
                ...moment,
                date: event.target.value,
              });
            }}
            value={moment.date}
          ></input>
        </div>

        <div className="form-group slider-group">
          <label htmlFor="type">
            Associated Emotion <span>Required</span>
          </label>
          <ReactSlider
            className="horizontal-slider"
            onAfterChange={(value) => {
              setMoment({ ...moment, score: value });
            }}
            max={100}
            min={0}
            value={moment.score}
            thumbClassName="example-thumb"
            trackClassName="example-track"
            renderThumb={(props, state) => (
              <div {...props}>{getEmoji(state.valueNow)}</div>
            )}
          />
        </div>

        <div className="form-group">
          <label htmlFor="type">
            Why was this moment significant? <span>Required</span>
          </label>
          <textarea
            placeholder=""
            type="text"
            onChange={(event) => {
              setMoment({
                ...moment,
                description: event.target.value,
              });
            }}
            value={moment.description}
            name="type"
            id="type"
          />
        </div>

        <div className="buttons">
          <button
            onClick={() => {
              const newMoment = { ...moment };
              newMoment.type = "personal";
              props.saveToDatabase(newMoment);
              setMoment({
                title: "",
                description: "",
                date: "",
                score: 50,
              });
            }}
            className="btn secondary"
          >
            Add Personal Moment ({props.personalMoments.length})
          </button>

          <button
            onClick={() => {
              const newMoment = { ...moment };
              newMoment.type = "professional";
              props.saveToDatabase(newMoment);
              setMoment({
                title: "",
                description: "",
                date: "",
                score: 50,
              });
            }}
            className="btn secondary"
          >
            Add Professional Moment ({props.professionalMoments.length})
          </button>
        </div>
      </div>

      {showHelp && (
        <div className="helpBox white-background">
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowHelp(false)} />
          <div className="left">
            <h2 className="underline blue">Some helpful hints</h2>
            <p>
              Click on any text to update and create your own career moment –
              provide details about how it made you feel, the reason it was
              impactful, and the approximate date when the moment happened.
              You'll need to add 3 moments for both personal and professional
              moments to move on to the next step, but we recommend adding
              around 6-8 to maximize your learning!
            </p>

            {/* <p className="bold">
              If you need more help, you can watch this quick video
            </p> */}
          </div>
        </div>
      )}

      {showHelp && (
        <div className="skrim" onClick={() => setShowHelp(false)}></div>
      )}
    </div>
  );
};
