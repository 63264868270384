import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/purpose.scss";

export const Purpose = ({ chat, continueLoop, divRef }) => {
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [continued, setContinued] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div id="chatMessage" className="chatMessage addon-purpose addon">
      <div className="chatMessageBox">
      <img alt="avatar" className={`${chat.showImage ? '' : 'hide'}`} src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg" />
        <div className="chatMessageTitle">
        <h4>Roxanne Burbach {chat.app}</h4>
          <div className="bubble">
            {!loading && (
              <div className="container">
                <h3>What is your workview?</h3>
                <h3>
                  You may be drawn to more than one of these answers, choose the
                  statement that's most aligned with how you think about work.
                  There is no right or wrong answer.
                </h3>
                <div
                  onClick={() => {
                    if (continued) return;
                    setInputValue(1);
                    continueLoop({
                      statement:
                        "Work is a means to an end. I work mainly to support my lifestyle and interests outside the workplace",
                    });
                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>
                    <b>Work is a means to an end.</b> I work mainly to support
                    my lifestyle and interests outside the workplace.
                  </h5>
                  <div
                    className={`check ${inputValue === 1 ? "active" : ""}`}
                  ></div>
                </div>
                <div
                  onClick={() => {
                    if (continued) return;

                    setInputValue(2);
                    continueLoop({
                      statement:
                        "Work is an opportunity to achieve success. I work mainly to develop my skills and advance.",
                    });
                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>
                    <b>Work is an opportunity to achieve success.</b> I work
                    mainly to develop my skills and advance.
                  </h5>
                  <div
                    className={`check ${inputValue === 2 ? "active" : ""}`}
                  ></div>
                </div>
                <div
                  onClick={() => {
                    if (continued) return;

                    setInputValue(3);
                    continueLoop({
                      statement:
                        "Work brings me purpose. I work mainly to find fulfillment and make a difference.",
                    });
                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>
                    <b>Work brings me purpose.</b> I work mainly to find
                    fulfillment and make a difference.
                  </h5>
                  <div
                    className={`check ${inputValue === 3 ? "active" : ""}`}
                  ></div>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
