import React, { useEffect, useRef, useState } from "react";
import "./styles/CreateMindmap.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { ProgramModal } from "./ProgramModal";

export const CreateMindmap = ({ setStep }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [careerRoles, setCareerRoles] = useState([]);
  const [programUser, setProgramUser] = useState(false);
  const [me, setMe] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  // References to the main input fields
  const history = useHistory();

  useEffect(() => {
    const showModal = localStorage.getItem("viewedMindMapModal");

    if (!showModal) {
      setShowModal(true);
    }
    getMindmap();
    getMe();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }

    setMe(response.data);

    return response.data;
  };

  const saveMindMap = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/mindmap`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: {
        title,
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    history.push("/mindmap/diagram");
  };

  const getMindmap = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/mindmap`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    if(response.data[0]?.title) {
      setTitle(response.data[0]?.title);
    }
  };

  return (
    <div className="CreateMindmap">
      {showModal && programUser && (
        <ProgramModal
          setShowModal={(e) => {
            localStorage.setItem("viewedRoleModalModal", true);
            setShowModal(e);
          }}
        />
      )}

      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              history.push("/program");
            }}
          />

          <h3>Choose Your Topic</h3>
        </div>

        <div className="inner">
          <p>What's the interest you'd like to explore? Choose a role, functional area, organization, or skill.</p>
          <input
            type="text"
            placeholder="My Topic"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div className="goals"></div>
        </div>

        <div className="bottom">
          <button
            onClick={() => {
              setStep(0);
            }}
            className="btn blank"
          >
            Back
          </button>
          <button
            disabled={!title}
            onClick={() => {
              saveMindMap();
            }}
            className="btn secondary"
          >
            Continue
          </button>
        </div>
      </div>

      <div className="right">
        <div className="white-background">
          {!title && <h1>My Topic</h1>}
          {title && <h1>{title}</h1>}
        </div>
      </div>
    </div>
  );
};
