import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const InviteSection = ({ invite, top5, setTop5 }) => {
  const [expanded, setExpanded] = useState(false);

  const addWordFromSide = (strength) => {
    const isFull = top5.filter((item) => item !== "").length === 5;

    if (isFull) {
      alert("Too many strengths, please remove one");
      return;
    }

    const newTop5 = [...top5];

    for (let i = 0; i < newTop5.length; i++) {
      if (newTop5[i] === "") {
        newTop5[i] = strength;
        break;
      }
    }

    setTop5(newTop5);
  };
  return (
    <div className="titleContainer">
      <h4 className="underline yellow">{invite.firstName}'s Feedback</h4>
      {expanded && (
        <p
          onClick={() => {
            setExpanded(false);
          }}
        >
          Show Less <FontAwesomeIcon icon={faChevronUp} />
        </p>
      )}

      {!expanded && (
        <p
          onClick={() => {
            setExpanded(true);
          }}
        >
          Show More <FontAwesomeIcon icon={faChevronDown} />
        </p>
      )}
      {expanded && (
        <div className="wordsContainer">
          {invite.strengths.map((word) => {
            if (top5.indexOf(word) !== -1) return null;
            return (
              <div
                className="strengthSuggestion"
                onClick={() => {
                  addWordFromSide(word);
                }}
              >
                <p>{word.text}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
