import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Events/index.scss";
import { Sidebar } from "../Sidebar";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../../hooks/isAdmin";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

export const EventsAdmin = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();

  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getMe();
    getEvents();
  }, []);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const getEvents = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/upcoming`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setEvents(response.data);
  };

  return (
    <div className="eventsAdmin">
      <h2 className="heading1 highlight blue">Upcoming Events</h2>

      <Sidebar />

      <div className="right white-background">
        {events.map((crew) => {
          return (
            <div className="crew">
              <div className="crew__info">
                <h3 className="heading3">
                  <span className="highlight">{crew.programTemplate.name}</span>
                </h3>

                <h4>
                  <FontAwesomeIcon icon={faCalendar} />{" "}
                  {moment(crew.startDate).format("MM/DD/YYYY")}{" "}
                  {moment(crew.time).tz("America/Los_Angeles").format("h:mm A")}{" "}
                  PST
                </h4>

                <div className="members">
                  {crew.group?.users?.map((user) => {
                    return (
                      <div className="crew__member">
                        <img
                          className="crew__member__image"
                          src={user.avatar}
                          alt="avatar"
                        />

                        <p>
                          {user.first_name} {user.last_name} <br />
                          {user.email}
                        </p>
                      </div>
                    );
                  })}

                  {crew.group?.users?.length === 0 && <h5>No members yet</h5>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
