import React, { useEffect, useState } from "react";
import "../../styles/components/Chat/user.scss";

export const UserMessage = ({ chat, continueLoop, userInputs, divRef, me }) => {
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [continued, setContinued] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    let message = chat.message?.replace(/{([^{}]+)}/g, function (keyExpr, key) {
      return userInputs[key] || "";
    });

    setMessage(message);

    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 0);
  }, [chat]);

  return (
    <div id="userMessage" className="userMessage">
      <div className="userMessageBox">
        <div className="userMessageTitle">
          <div className="bubble">
            <div className="text">
              <p>{message}</p>
              {continued && <p>{inputValue}</p>}
            </div>
          </div>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `https://placehold.co/100x100?text=${
                me?.first_name.charAt(0) + me?.last_name.charAt(0)
              }`;
            }}
            alt="avatar"
            className={`${chat.showImage ? "" : "hide"}`}
            src={me?.avatar || `https://placehold.co/100x100?text=${
              me?.first_name.charAt(0) + me?.last_name.charAt(0)
            }`}
          />
        </div>
      </div>
    </div>
  );
};
