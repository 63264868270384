import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import "./styles/dashboard.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faLightbulb,
  faPlus,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { Analytics } from "../../hooks/analytics";

import mentor from "./images/Mentor.png";
import sponsor from "./images/Sponsor.png";
import peerAdvisor from "./images/PeerAdvisor.png";
import careerSupporter from "./images/CareerSupporter.png";
import industryExpert from "./images/IndustryExpert.png";
import skillsAdvisor from "./images/SkillsAdvisor.png";

import mentorFilled from "./images/filled/Mentor.png";
import sponsorFilled from "./images/filled/Sponsor.png";
import peerAdvisorFilled from "./images/filled/PeerAdvisor.png";
import careerSupporterFilled from "./images/filled/CareerSupporter.png";
import industryExpertFilled from "./images/filled/IndustryExpert.png";
import skillsAdvisorFilled from "./images/filled/SkillsAdvisor.png";

export const CareerCollaboratorsDashboard = ({ location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [insights, setInsights] = useState([]);
  const [collaborators, setCollaborators] = useState({});
  const [me, setMe] = useState({});
  const [programUser, setProgramUser] = useState(false);
  const [addingInsight, setAddingInsight] = useState(false);
  const [insightText, setInsightText] = useState("");
  const [presenterView, setPresenterView] = useState(false);
  const [celebrate, setCelebrate] = useState(false);
  const [insight1, setInsight1] = useState({});
  const [insight2, setInsight2] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (location.state?.celebrate) {
      setCelebrate(true);
    }
    getMe();
    getCareerCollaborators();
    getInsights();
  }, []);

  const saveInsight = async (question) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      data: {
        description: insightText,
        type: "careerCollaborators3",
        toolId: 9,
        question:
          question ||
          `What's clearer to you now that you've completed this activity?`,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);
    Analytics("Career Collaborators - Added Insight");
    setAddingInsight(false);
    setInsightText("");
    getInsights();
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);

    // They are a program user.
    if (response.data.crew.length > 0) {
      setProgramUser(true);
    }
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/careerCollaborators`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const insight1 = response.data.filter((insight) => {
      return insight.type === "careerCollaborators1";
    });

    const insight2 = response.data.filter((insight) => {
      return insight.type === "careerCollaborators2";
    });

    setInsight1(insight1[0]);
    setInsight2(insight2[0]);

    const removedData = response.data.filter((insight) => {
      return (
        insight.type !== "careerCollaborators1" &&
        insight.type !== "careerCollaborators2"
      );
    });
    setInsights(removedData);
  };

  const getCareerCollaborators = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/career-collaborator`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setCollaborators(response.data);
  };

  return (
    <div className="visionsDashboard">
      <div className="header">
        <div className="left">
          <h5>Activity</h5>
          <h2>Board of Advisors</h2>
        </div>

        <div className="right">
          {programUser && (
            <button
              onClick={() => {
                history.push("/program");
              }}
              className="btn secondary"
            >
              Back to Program
            </button>
          )}
        </div>
      </div>

      <div className="white-background visions">
        <div className="top">
          <h3>Your board</h3>
          <div className="buttonContainer">
            <button
              className="btn empty"
              onClick={() => {
                setPresenterView(true);
              }}
            >
              Presenter View
            </button>
            <button
              className="btn empty"
              onClick={() => {
                history.push("/board-of-advisors/enter", {
                  editing: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faShare} /> Edit My Collaborators
            </button>
          </div>
        </div>

        {programUser && (
          <div className="inSession steps">
            <div className="top">
              <h5>Next Steps</h5>
              <span>During Session</span>
            </div>
            <p>
              You'll be sharing your Board of Advisors (and its strengths and
              weaknesses) with your crew in your upcoming session.
            </p>
          </div>
        )}

        <div className="slides">
          <div
            style={{
              backgroundColor:
                collaborators.mentor?.length > 0 ? "#0B455F" : "#fff",
            }}
            className={`box ${collaborators.mentor?.length > 0 ? "white" : ""}`}
          >
            <div className="top">
              {!collaborators.mentor ? (
                <img src={mentor} alt="" />
              ) : (
                <img src={mentorFilled} alt="" />
              )}
              <h3>Mentor</h3>
            </div>

            <p>
              Trusted advisor who provides guidance and support based on their
              own journey
            </p>

            {!collaborators?.mentor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.mentor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box ${
              collaborators.sponsor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                collaborators.sponsor?.length > 0 ? "#2A7394" : "#fff",
            }}
          >
            <div className="top">
              {!collaborators.sponsor ? (
                <img src={sponsor} alt="" />
              ) : (
                <img src={sponsorFilled} alt="" />
              )}
              <h3>Sponsor</h3>
            </div>

            <p>Senior leader who uses their influence to advocate for you</p>

            {!collaborators?.sponsor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.sponsor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box ${
              collaborators.peer_advisor?.length > 0 ? "white" : ""
            }`}
            style={{
              backgroundColor:
                collaborators.peer_advisor?.length > 0 ? "#E26C29" : "#fff",
            }}
          >
            <div className="top">
              {!collaborators.peer_advisor ? (
                <img src={peerAdvisor} alt="" />
              ) : (
                <img src={peerAdvisorFilled} alt="" />
              )}
              <h3>Peer Advisor</h3>
            </div>

            <p>
              Colleague or friend at a similar career stage who can serve as a
              thought partner
            </p>

            {!collaborators?.peer_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.peer_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                collaborators.career_supporter?.length > 0 ? "#E1C7BE" : "#fff",
            }}
          >
            <div className="top">
              {!collaborators.career_supporter ? (
                <img src={careerSupporter} alt="" />
              ) : (
                <img src={careerSupporterFilled} alt="" />
              )}
              <h3>Career Supporter</h3>
            </div>

            <p>
              Someone who provides encouragement and motivation you pursue your
              career goals
            </p>

            {!collaborators?.career_supporter && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.career_supporter?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                collaborators.industry_expert?.length > 0 ? "#FF9E67" : "#fff",
            }}
          >
            <div className="top">
              {!collaborators.industry_expert ? (
                <img src={industryExpert} alt="" />
              ) : (
                <img src={industryExpertFilled} alt="" />
              )}
              <h3>Industry Expert</h3>
            </div>

            <p>
              Industry or functional expert who can help you develop necessary
              knowledge
            </p>

            {!collaborators?.industry_expert && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.industry_expert?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>

          <div
            className={`box`}
            style={{
              backgroundColor:
                collaborators.skills_advisor?.length > 0 ? "#FEDE66" : "#fff",
            }}
          >
            <div className="top">
              {!collaborators.skills_advisor ? (
                <img src={skillsAdvisor} alt="" />
              ) : (
                <img src={skillsAdvisorFilled} alt="" />
              )}
              <h3>Skills Advisor</h3>
            </div>

            <p>
              Expert in a specific skill area who can provide guidance on how to
              develop and refine those skills
            </p>

            {!collaborators?.skills_advisor && (
              <div className="empty">
                <h4>No collaborators here (yet!)</h4>
              </div>
            )}

            <div className="names">
              {collaborators?.skills_advisor?.map((mentor) => (
                <div className="person">{mentor.name}</div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="white-background boardStrengths">
        <div className="top">
          <h3>Board Strengths</h3>
        </div>

        <div className="sides">
          {insight1?.description && (
            <div className="side">
              <h2>{insight1.question}</h2>
              <p>{insight1.description}</p>
            </div>
          )}

          {insight2?.description && (
            <div className="side orange">
              <h2>{insight2.question}</h2>
              <p>{insight2.description}</p>
            </div>
          )}
        </div>
      </div>

      <div className="white-background insights">
        <div className="top">
          <h3>My Insights</h3>

          {addingInsight && (
            <button
              onClick={() => {
                setAddingInsight(false);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faEye} /> View All Insights
            </button>
          )}

          {!addingInsight && insights.length > 0 && (
            <button
              onClick={() => {
                setAddingInsight(true);
              }}
              className="btn secondary"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Insight
            </button>
          )}
        </div>
        {!insights.length && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>
              What's clearer to you now that you've completed this activity?
            </h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight();
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
        {insights.length > 0 && !addingInsight && (
          <div className="content empty">
            <div className="allInsights">
              {insights.map((insight) => (
                <div className="insight">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </div>
                  <h4>{insight.question}</h4>
                  <h5>{insight.description}</h5>
                </div>
              ))}
            </div>
          </div>
        )}
        {addingInsight && (
          <div className="content">
            <h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.66347 15H12.3364M9.99995 1V2M16.3639 3.63604L15.6568 4.34315M19 9.99995H18M2 9.99995H1M4.34309 4.34315L3.63599 3.63604M6.46441 13.5356C4.51179 11.5829 4.51179 8.41711 6.46441 6.46449C8.41703 4.51187 11.5829 4.51187 13.5355 6.46449C15.4881 8.41711 15.4881 11.5829 13.5355 13.5356L12.9884 14.0827C12.3555 14.7155 11.9999 15.5739 11.9999 16.469V17C11.9999 18.1046 11.1045 19 9.99995 19C8.89538 19 7.99995 18.1046 7.99995 17V16.469C7.99995 15.5739 7.6444 14.7155 7.01151 14.0827L6.46441 13.5356Z"
                  stroke="#0B455F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              INSIGHT
            </h3>
            <h4>Do you have any more insights?</h4>
            <textarea
              onChange={(e) => {
                setInsightText(e.target.value);
              }}
              value={insightText}
              placeholder="Type your insight here"
            ></textarea>
            <button
              onClick={() => {
                saveInsight("Do you have any more insights?");
              }}
              disabled={!insightText}
              className="btn secondary"
            >
              Save
            </button>
          </div>
        )}
      </div>

      {presenterView && (
        <div className="presenterView">
          <div className="container">
            <div className="header">
              <h3>Board of Advisors</h3>
              <button
                className="btn empty"
                onClick={() => {
                  setPresenterView(false);
                }}
              >
                Exit Presenter View
              </button>
            </div>
            <div className="content">
              <div className="slides">
                <div
                  style={{
                    backgroundColor:
                      collaborators.mentor?.length > 0 ? "#0B455F" : "#fff",
                  }}
                  className={`box ${
                    collaborators.mentor?.length > 0 ? "white" : ""
                  }`}
                >
                  <div className="top">
                    {!collaborators.mentor ? (
                      <img src={mentor} alt="" />
                    ) : (
                      <img src={mentorFilled} alt="" />
                    )}
                    <h3>Mentor</h3>
                  </div>

                  <p>
                    Trusted advisor who provides guidance and support based on
                    their own journey
                  </p>

                  {!collaborators?.mentor && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.mentor?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>

                <div
                  className={`box ${
                    collaborators.sponsor?.length > 0 ? "white" : ""
                  }`}
                  style={{
                    backgroundColor:
                      collaborators.sponsor?.length > 0 ? "#2A7394" : "#fff",
                  }}
                >
                  <div className="top">
                    {!collaborators.sponsor ? (
                      <img src={sponsor} alt="" />
                    ) : (
                      <img src={sponsorFilled} alt="" />
                    )}
                    <h3>Sponsor</h3>
                  </div>

                  <p>
                    Senior leader who uses their influence to advocate for you
                  </p>

                  {!collaborators?.sponsor && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.sponsor?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>

                <div
                  className={`box ${
                    collaborators.peer_advisor?.length > 0 ? "white" : ""
                  }`}
                  style={{
                    backgroundColor:
                      collaborators.peer_advisor?.length > 0
                        ? "#E26C29"
                        : "#fff",
                  }}
                >
                  <div className="top">
                    {!collaborators.peer_advisor ? (
                      <img src={peerAdvisor} alt="" />
                    ) : (
                      <img src={peerAdvisorFilled} alt="" />
                    )}
                    <h3>Peer Advisor</h3>
                  </div>

                  <p>
                    Colleague or friend at a similar career stage who can serve
                    as a thought partner
                  </p>

                  {!collaborators?.peer_advisor && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.peer_advisor?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>

                <div
                  className={`box`}
                  style={{
                    backgroundColor:
                      collaborators.career_supporter?.length > 0
                        ? "#E1C7BE"
                        : "#fff",
                  }}
                >
                  <div className="top">
                    {!collaborators.career_supporter ? (
                      <img src={careerSupporter} alt="" />
                    ) : (
                      <img src={careerSupporterFilled} alt="" />
                    )}
                    <h3>Career Supporter</h3>
                  </div>

                  <p>
                    Someone who provides encouragement and motivation you pursue
                    your career goals
                  </p>

                  {!collaborators?.career_supporter && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.career_supporter?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>

                <div
                  className={`box`}
                  style={{
                    backgroundColor:
                      collaborators.industry_expert?.length > 0
                        ? "#FF9E67"
                        : "#fff",
                  }}
                >
                  <div className="top">
                    {!collaborators.industry_expert ? (
                      <img src={industryExpert} alt="" />
                    ) : (
                      <img src={industryExpertFilled} alt="" />
                    )}
                    <h3>Industry Expert</h3>
                  </div>

                  <p>
                    Industry or functional expert who can help you develop
                    necessary knowledge
                  </p>

                  {!collaborators?.industry_expert && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.industry_expert?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>

                <div
                  className={`box`}
                  style={{
                    backgroundColor:
                      collaborators.skills_advisor?.length > 0
                        ? "#FEDE66"
                        : "#fff",
                  }}
                >
                  <div className="top">
                    {!collaborators.skills_advisor ? (
                      <img src={skillsAdvisor} alt="" />
                    ) : (
                      <img src={skillsAdvisorFilled} alt="" />
                    )}
                    <h3>Skills Advisor</h3>
                  </div>

                  <p>
                    Expert in a specific skill area who can provide guidance on
                    how to develop and refine those skills
                  </p>

                  {!collaborators?.skills_advisor && (
                    <div className="empty">
                      <h4>No collaborators here (yet!)</h4>
                    </div>
                  )}

                  <div className="names">
                    {collaborators?.skills_advisor?.map((mentor) => (
                      <div className="person">{mentor.name}</div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
