import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "../../styles/pages/Membership/index.scss";
import { useEnv } from "../../context/env.context";
import { Analytics } from "../../hooks/analytics";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import MembershipBottomLeft from "../../images/membership_bottom_left.png";
import Congrats from "../../images/Congrats.png";

export const MembershipSuccess = (props) => {
  const [membership, setMembership] = useState([]);
  const [me, setMe] = useState();
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { apiServerUrl } = useEnv();
  const { companyId } = queryString.parse(props.location.search);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      Analytics("Visited Membership Purchase Page");
      if (companyId) {
        setShowCompanyModal(true);
      }
      await getMemberships();
      await getMe();
    };

    getData();

    const nav = document.querySelector(".nav-bar__container");
    const container = document.getElementsByClassName("content_container")[0];

    if (container) {
      container.style = "max-width: 100%;width:100%";
    }

    if (nav) {
      nav.style.display = "none";
    }

    return () => {
      nav.style.display = "block";
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const getMe = async () => {
    let token = null;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      return loginWithRedirect({
        appState: { returnTo: window.location.href },
      });
    }

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios(config);

      // if (response.data.memberships.length > 0) {
      //   history.push("/");
      // }
      setMe(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getMemberships = async () => {
    const response = await axios.get(`${apiServerUrl}/api/memberships/1`);
    const data = response.data;

    setMembership(data);
    return data;
  };

  return (
    <div className="membershipApplication">
      <div className="left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.0294 11.0294C36.0294 12.2573 35.8287 13.4384 35.4584 14.5416C36.5616 14.1713 37.7427 13.9706 38.9706 13.9706C45.062 13.9706 50 18.9086 50 25C50 31.0914 45.062 36.0294 38.9706 36.0294C37.7427 36.0294 36.5616 35.8288 35.4584 35.4584C35.8287 36.5616 36.0294 37.7427 36.0294 38.9706C36.0294 45.062 31.0914 50 25 50C18.9086 50 13.9706 45.062 13.9706 38.9706C13.9706 37.7427 14.1712 36.5616 14.5416 35.4584C13.4384 35.8287 12.2573 36.0294 11.0294 36.0294C4.93804 36.0294 0 31.0914 0 25C0 18.9086 4.93804 13.9706 11.0294 13.9706C12.2573 13.9706 13.4384 14.1712 14.5416 14.5416C14.1712 13.4384 13.9706 12.2573 13.9706 11.0294C13.9706 4.93804 18.9086 0 25 0C31.0914 0 36.0294 4.93804 36.0294 11.0294ZM21.4878 21.4878C21.8582 22.591 22.0588 23.7721 22.0588 25C22.0588 26.2279 21.8582 27.409 21.4878 28.5122C22.591 28.1418 23.7721 27.9412 25 27.9412C26.2279 27.9412 27.409 28.1418 28.5122 28.5122C28.1418 27.409 27.9412 26.2279 27.9412 25C27.9412 23.7721 28.1418 22.591 28.5122 21.4878C27.409 21.8582 26.2279 22.0588 25 22.0588C23.7721 22.0588 22.591 21.8582 21.4878 21.4878ZM31.6176 11.0294C31.6176 14.6842 28.6548 17.6471 25 17.6471C21.3452 17.6471 18.3824 14.6842 18.3824 11.0294C18.3824 7.37459 21.3452 4.41176 25 4.41176C28.6548 4.41176 31.6176 7.37459 31.6176 11.0294ZM31.6176 38.9706C31.6176 42.6254 28.6548 45.5882 24.9999 45.5882C21.3451 45.5882 18.3823 42.6254 18.3823 38.9706C18.3823 35.3158 21.3451 32.3529 24.9999 32.3529C28.6548 32.3529 31.6176 35.3158 31.6176 38.9706ZM38.9706 31.6176C42.6254 31.6176 45.5882 28.6548 45.5882 25C45.5882 21.3452 42.6254 18.3824 38.9706 18.3824C35.3158 18.3824 32.3529 21.3452 32.3529 25C32.3529 28.6548 35.3158 31.6176 38.9706 31.6176ZM17.6471 24.9999C17.6471 28.6547 14.6843 31.6175 11.0295 31.6175C7.37463 31.6175 4.41181 28.6547 4.41181 24.9999C4.41181 21.345 7.37463 18.3822 11.0295 18.3822C14.6843 18.3822 17.6471 21.345 17.6471 24.9999Z"
            fill="white"
          />
        </svg>

        <img src={MembershipBottomLeft} className="bottom-left" />

        <h2>Thank you for your purchase</h2>
        <p>
          Now what? It's time to dig into our events and tools to see what works
          for you.
        </p>

        <button
          className="btn primary"
          onClick={() => history.push("/onboarding")}
        >
          Begin your journey
        </button>
      </div>

      <div className="right">
        <div className="white-background">
          <img
            alt="congrats"
            style={{
              margin: "0px auto",
              display: "block",
              padding: "40px",
            }}
            src={Congrats}
          />
        </div>
      </div>
    </div>
  );
};
