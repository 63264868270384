import { useAuth0 } from "@auth0/auth0-react";
import {
  faCheck,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Tool/Tools/template.scss";
import { useHistory } from "react-router-dom";
import { PathTool } from "../../Path/components/PathTool";

export const RosesAndThorns = (props) => {
  const history = useHistory();
  useEffect(() => {}, []);

  return (
    <div className="templateTool">
      <div className="bluebg"></div>
      <div className="container">
        <div className="intro">
          <div className="left">
            <img src={props.tool.image} alt="roses and thorns" />
          </div>
          <div className="right">
            <div className="top">
              <h3>
                Welcome to <br />
                <span className="highlight yellow">Roses &amp; Thorns</span>
              </h3>
            </div>
            <div className="bottom">
              <button
                onClick={() => {
                  history.push("/roses-and-thorns");
                }}
                className="btn primary"
              >
                Add Your Roses &amp; Thorns Now! <FontAwesomeIcon icon={faRightLong} />{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="content">
          <p className="body-text">
          <b>Roses and Thorns</b> is used as a concept to drive a regular, in the moment, reflective gratitude practice. Gratitude practices are especially useful when we are undergoing a period of change. We know that the concept of practicing regular gratitude routines helps to keep endorphins flowing and maintain positivity e.g. when times are tough or when we are not sure where we are going. Gratitude routines help to maintain confidence and self-esteem in the face of difficulty or apparent failure. Gratitude routines are used a lot by counselling psychologists with people who are experiencing depression or bereavement to help keep focused on what is good in one’s life. Sharing with a collaborative community helps us to let go of annoyances and keep us connected to what is positive in our lives.

          </p>

          <div className="description">
            <div className="left white-background">
              <h3 className="heading2 underline blue">Key Features:</h3>

              <ol>
                <li>
                  <div className="item">1</div>
                  <p>
                    Timeframe is not significant. Client chooses the key events
                    - some may cluster over a couple of years, there could be a
                    significant gap between some of the events.
                  </p>
                </li>

                <li>
                  <div className="item">2</div>
                  <p>2 separate lines - My Personal Story My Career Story </p>
                </li>

                <li>
                  <div className="item">3</div>
                  <p>
                    No right or wrong. Client decides what is meaningful to them
                    e.g. the birth of a first child may have been really
                    significant in shaping you, the birth of subsequent children
                    may have been less monumental in shaping you.
                  </p>
                </li>

                <li>
                  <div className="item">4</div>
                  <p>
                    Maximum number of points per line to encourage
                    prioritisation and analysis of what was really significant
                  </p>
                </li>

                <li>
                  <div className="item">5</div>
                  <p>Purpose</p>
                </li>
              </ol>
            </div>
            <div className="left white-background yellow">
              <h3 className="heading2 underline yellow">Use To:</h3>

              <ol>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                    Create foundation for stories I use to talk about myself, to
                    position myself, to introduce myself in a career context
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                    Prompt in-depth thinking around what I was doing, feeling,
                    how did it change me afterwards, why has it stayed with me,
                    how did I change in skills, attitude or behaviour?
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="moreTools">
        <div className="container">
          <h3>
            <span className="heading1 underline yellow">
              Want to go somewhere else?
            </span>
          </h3>

          <div className="tools">
            <PathTool
              tool={{
                name: "Lifeline",
                id: 1,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/icon_lifeilne.svg",
                Tool_Path: {
                  description: "I want to thank those that have helped me in my career",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Storyboard",
                id: 2,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Storyboard.svg",
                Tool_Path: {
                  description: "I want to thank those that have helped me in my career",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
