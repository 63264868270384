import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/strengthsFeedbackUser.scss";

export const StrengthsFeedbackUser = ({ chat, continueLoop, userInputs, divRef }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(chat.message);
  const [continued, setContinued] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const textArea = React.createRef();

  useEffect(() => {
    let message = chat.message?.replace(/{([^{}]+)}/g, function (keyExpr, key) {
      return userInputs[key] || "";
    });

    setMessage(message);

    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div className="strengthsFeedbackUser">
      <div style={{
        marginLeft:'60px'
      }} className="chatMessageBox">
        <div className="chatMessageTitle">
          <div style={{
            borderRadius:'15px',
            marginTop:'-10px'
          }} className="bubble">
            {!loading && (
              <p>
               Next, let them know why you picked the strengths that you did. You don’t have to, but it would be very helpful!
              </p>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
      {!continued && !loading && (
        <div className="entryForm">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              continueLoop({
                feedback: inputValue,
              });
              setContinued(true);
            }}
          >
            <textarea
              placeholder="I chose these strengths because..."
              autoFocus={true}
              ref={textArea}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  continueLoop({
                    feedback: inputValue,
                  });
                  setContinued(true);
                }
              }}
            />
            <FontAwesomeIcon
              icon={faPaperPlane}
              onClick={() => {
                continueLoop({
                  feedback: inputValue,
                });
                setContinued(true);
              }}
            />
          </form>
        </div>
      )}
    </div>
  );
};
