import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/highlight.scss";
import { StrengthInviteModal } from "./strengthInviteModal";

export const ValueInvite = ({ chat, continueLoop, divRef, me }) => {
  const { loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  return (
    <div id="chatMessage" className="chatMessage addon-strengths addon">
      <div className="chatMessageBox">
        <img
          alt="avatar"
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
          <h4>Roxanne Burbach {chat.app}</h4>
          <div className={`bubble ${!loading ? "yellow" : ""}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Let’s explore my values!</h3>
                  <span>
                  Learn more about a Crew membership
                  </span>
                </div>
                <div className="right">
                  <button onClick={async () => {
                    loginWithRedirect({
                      appState: {
                        returnTo: "/values",
                      },
                    });
                  }} className="btn secondary">
                    Lets Begin
                  </button>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
      {showInviteModal && (
        <StrengthInviteModal me={me} setShowInviteModal={setShowInviteModal} />
        )}
    </div>
  );
};
