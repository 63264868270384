import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import "../../../styles/pages/highlightReel/components/SharableMoment.scss";

export const SharableMoment = (props) => {
  const [featured, setFeatured] = useState(props.moment.featured);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  useEffect(() => {}, []);

  const makeFeatured = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.patch(
      `${apiServerUrl}/api/highlights/${props.moment.id}/featured`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFeatured(response.data.featured);
    props.changed(response.data);
  };

  return (
    <div className="shareable-moment">
      {props.moment.photo && <img src={props.moment.photo} alt="moment" />}
      <div className="moment-info">
        <h4>{props.moment.title}</h4>
      </div>

      <div className="featured">
        <input
          onChange={() => {
            makeFeatured(!featured);
          }}
          checked={featured}
          type="checkbox"
        />
      </div>
    </div>
  );
};
