import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProgramModal = ({ setShowModal, coach }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <FontAwesomeIcon
          className="close"
          icon={faTimes}
          onClick={() => {
            setShowModal(false);
          }}
        />
        <div className="left">
          <div className="box">
          <svg xmlns="http://www.w3.org/2000/svg" width="68" height="67" viewBox="0 0 68 67" fill="none">
<g clipPath="url(#clip0_1508_39715)">
<path fillRule="evenodd" clipRule="evenodd" d="M21.5824 29.0891C22.9096 29.4062 24.3069 29.2262 25.5105 28.5833C26.7141 27.9404 27.6405 26.8789 28.1149 25.5995C30.1528 20.4628 22.9224 17.6712 20.1307 19.7928C19.4282 20.4135 18.8933 21.201 18.5753 22.0828C18.2573 22.9646 18.1664 23.9123 18.311 24.8385C18.4556 25.7646 18.8311 26.6395 19.4028 27.3823C19.9745 28.1252 20.7241 28.7122 21.5824 29.0891ZM21.387 21.0491C22.6153 19.9045 27.9195 21.4958 26.2724 24.8737C25.9478 25.6246 25.3716 26.2389 24.643 26.6108C23.9143 26.9828 23.0789 27.0891 22.2803 26.9116C20.019 26.2416 19.9353 22.3333 21.387 21.0491Z" fill="#FF9E67"/>
<path fillRule="evenodd" clipRule="evenodd" d="M45.2551 34.7842C43.273 26.0462 39.1692 26.2417 36.0426 30.4571C33.8639 33.0739 32.2142 36.0888 31.1851 39.3346C30.6267 38.4412 28.5888 32.2437 25.0155 33.2767C21.4422 34.3096 17.8688 40.0325 17.0034 42.1542C16.138 44.2758 18.0363 44.22 18.0363 43.3546C18.0363 42.4892 18.9576 41.4842 19.4601 40.5629C20.6542 38.1368 22.6173 36.1738 25.0434 34.9796C28.198 33.6396 28.8122 42.7125 31.9388 41.3167C33.2676 39.645 34.4184 37.8392 35.3726 35.9287C39.7276 29.2287 40.9559 25.795 43.7476 38.4971C44.0435 39.4965 44.2215 40.5271 44.278 41.5679C43.4684 41.0096 37.8013 45.3925 25.2667 44.9179C23.8524 44.7019 22.4536 44.3942 21.0792 43.9967C20.9356 43.9761 20.7891 44.0027 20.6619 44.0725C20.5347 44.1423 20.4335 44.2515 20.3737 44.3837C20.3139 44.516 20.2987 44.664 20.3302 44.8057C20.3618 44.9473 20.4385 45.0749 20.5488 45.1692C22.0445 45.7391 23.5987 46.1416 25.183 46.3696C28.6596 46.7145 32.1651 46.6395 35.6238 46.1462C37.913 45.4204 45.5342 44.0804 46.288 42.4333C46.4832 41.927 46.5596 41.3826 46.5113 40.8421C46.3717 40.4233 45.283 34.9237 45.2551 34.7842Z" fill="#FF9E67"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.6932 15.4101C43.0799 15.4552 42.4641 15.4552 41.8507 15.4101C41.9903 14.7959 42.2137 14.0701 42.437 13.2884C43.4699 9.29634 43.8607 5.16467 40.5945 2.65217C40.4429 2.56107 40.2642 2.52615 40.0895 2.55351C39.9148 2.58087 39.7553 2.66877 39.6389 2.80184C39.5225 2.9349 39.4565 3.10466 39.4526 3.28143C39.4487 3.4582 39.507 3.63073 39.6174 3.76884C43.0512 7.17467 39.2545 12.1438 38.8916 15.9405C38.5287 19.7372 47.4899 18.0622 50.5049 16.5268L55.0832 14.3772C55.2685 14.266 55.4118 14.0966 55.4905 13.8954C55.5692 13.6942 55.5791 13.4725 55.5185 13.2651C55.4578 13.0577 55.3302 12.8763 55.1555 12.7492C54.9807 12.6221 54.7688 12.5564 54.5528 12.5626C48.8578 14.9076 48.6066 15.3263 43.6932 15.4101Z" fill="#FF9E67"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.4971 0C39.6725 0 41.4313 0 16.1667 1.14458C15.9372 1.14458 15.7171 1.23576 15.5548 1.39806C15.3925 1.56036 15.3013 1.78048 15.3013 2.01C15.3013 2.23952 15.3925 2.45964 15.5548 2.62194C15.7171 2.78424 15.9372 2.87542 16.1667 2.87542C39.8959 2.03792 40.4542 1.95417 43.4134 2.12167C46.3725 2.28917 54.0775 8.98917 56.0875 11.8367C56.5064 12.2923 56.7702 12.8689 56.8413 13.4838C56.8413 17.755 55.5292 30.2338 55.0267 33.165C54.5242 36.0963 53.9938 38.19 53.603 40.7304C53.4634 41.7354 52.7375 53.5163 49.6667 55.1633C45.3675 57.4525 40.3705 56.28 35.7084 56.8663C32.9167 57.2292 26.803 57.955 23.09 57.9829C8.15462 57.9829 8.85254 58.29 4.74878 56.5592C8.18254 51.3946 10.1367 39.6138 11.1696 32.8858C12.3142 25.3483 11.4767 17.755 11.728 10.2454C11.728 8.93333 12.6771 1.87042 10.6113 3.015C9.77379 3.43375 10.1088 4.71792 10.1088 5.27625C10.1088 9.01708 10.1088 23.5338 9.85754 25.9346C9.19524 35.0036 7.31732 43.9424 4.2742 52.5112C3.85545 53.3767 3.35295 54.1863 2.87837 54.9958C2.52729 55.4997 2.23682 56.0432 2.01295 56.615C1.99856 57.037 2.11042 57.4537 2.33422 57.8118C2.55801 58.1699 2.88356 58.453 3.2692 58.625C5.02368 59.5246 6.90725 60.1461 8.85254 60.4675C17.9225 60.9869 27.0204 60.7161 36.0434 59.6579C40.7055 59.0717 45.9538 60.3279 50.923 57.62C55.4734 55.1633 56.0317 42.88 56.283 41.1213C56.618 38.5808 57.1763 36.0962 57.5671 33.5838C57.958 31.0713 59.1025 18.2854 59.1305 13.4838C59.2421 9.32417 47.3496 0.195417 43.4971 0Z" fill="#FF9E67"/>
<path fillRule="evenodd" clipRule="evenodd" d="M64.1285 15.0749C64.1285 19.3741 62.4535 36.5149 61.951 39.3903C61.4485 42.2658 60.9181 44.4153 60.5272 46.9837C60.3877 47.9608 59.6339 59.7416 56.591 61.3887C52.2639 63.7058 47.2947 62.5053 42.6327 63.0916C38.4487 63.6821 34.2369 64.0549 30.0143 64.2083C15.7768 64.2083 15.8047 64.9062 12.2593 63.3987C10.2214 62.7008 9.77474 64.6549 11.0868 65.4645C12.5425 66.2429 14.1554 66.6819 15.8047 66.7487C24.8754 67.2681 33.9741 66.9879 42.9956 65.9112C47.6577 65.3249 52.906 66.5812 57.8752 63.9012C62.4814 61.4166 63.0118 49.1333 63.2631 47.4583C63.626 44.9178 64.1843 42.4333 64.5752 39.9208C64.966 37.4083 66.641 20.3791 66.6689 15.4378C66.6689 12.8974 64.3239 12.6183 64.1285 15.0749Z" fill="#FF9E67"/>
</g>
<defs>
<clipPath id="clip0_1508_39715">
<rect width="67" height="67" fill="white" transform="translate(0.8125)"/>
</clipPath>
</defs>
</svg>
          </div>
        </div>
        <div className="right">
          <h2>Welcome to 10 Images!</h2>
          <p>
            In this activity, we'll take a first step toward identifying your
            values by getting visual and collaborative
          </p>

          <span>Here's what to do before your next session:</span>

          <div className="step">
            <div className="number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M11.188 4.5C11.188 3.70435 10.8719 2.94129 10.3093 2.37868C9.7467 1.81607 8.98364 1.5 8.18799 1.5C7.39234 1.5 6.62928 1.81607 6.06667 2.37868C5.50406 2.94129 5.18799 3.70435 5.18799 4.5C5.18799 5.29565 5.50406 6.05871 6.06667 6.62132C6.62928 7.18393 7.39234 7.5 8.18799 7.5C8.98364 7.5 9.7467 7.18393 10.3093 6.62132C10.8719 6.05871 11.188 5.29565 11.188 4.5ZM3.68799 4.5C3.68799 3.30653 4.16209 2.16193 5.00601 1.31802C5.84992 0.474106 6.99451 0 8.18799 0C9.38146 0 10.5261 0.474106 11.37 1.31802C12.2139 2.16193 12.688 3.30653 12.688 4.5C12.688 5.69347 12.2139 6.83807 11.37 7.68198C10.5261 8.52589 9.38146 9 8.18799 9C6.99451 9 5.84992 8.52589 5.00601 7.68198C4.16209 6.83807 3.68799 5.69347 3.68799 4.5ZM1.72861 14.5H14.6474C14.388 12.8 12.9192 11.5 11.1474 11.5H5.22861C3.45674 11.5 1.99111 12.8 1.72861 14.5ZM0.187988 15.0406C0.187988 12.2562 2.44424 10 5.22861 10H11.1474C13.9317 10 16.188 12.2562 16.188 15.0406C16.188 15.5719 15.7567 16 15.2286 16H1.14736C0.619238 16 0.187988 15.5687 0.187988 15.0406Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="right">
              <h4>
                Upload 10 images that represent what matters most to you in work
                and life.
              </h4>
            </div>
          </div>

          <div
            style={{
              marginTop: "2rem",
            }}
            className="step"
          >
            <div className="number">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M14.2846 0.244141C14.2624 0.244141 14.2403 0.245017 14.2177 0.246619C10.7751 0.493499 -0.104331 2.08562 2.44743 12.3224L0.325308 14.4416C0.142215 14.6247 0.142215 14.9215 0.325308 15.1043C0.416796 15.1963 0.536818 15.2431 0.656634 15.2431C0.77645 15.2431 0.896501 15.1974 0.987959 15.1058L3.10715 12.9837C4.16412 13.2471 5.13202 13.368 6.01292 13.368C13.6618 13.368 14.9625 4.30125 15.1852 1.21357C15.2221 0.684736 14.7797 0.244141 14.2846 0.244141ZM6.01175 12.4308C5.34646 12.4308 4.62873 12.336 3.89636 12.1962L5.54332 10.5493C5.55767 10.5501 5.56646 10.556 5.57818 10.556H10.7517C9.47441 11.7951 7.89248 12.4308 6.01175 12.4308ZM11.5485 9.64195C11.5104 9.63316 11.4752 9.61852 11.4372 9.61852H6.47461L8.35622 7.7369C8.36999 7.73778 8.37878 7.74364 8.39049 7.74364H12.6734C12.6289 7.83987 12.5914 7.93857 12.5444 8.03378C12.2457 8.63714 11.9088 9.16152 11.5485 9.64195ZM13.066 6.8062H9.31328L10.3881 5.73137C10.5712 5.54828 10.5712 5.25152 10.3881 5.06872C10.205 4.88592 9.90826 4.88563 9.72546 5.06872L3.263 11.5312C2.74009 9.08242 3.06263 7.02592 4.22183 5.40298C6.64159 2.06628 12.0113 1.32219 14.2465 1.15814C14.1528 2.44858 13.8716 4.66182 13.066 6.8062Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="right">
              <h4>
                Be prepared to screen-share your images during the upcoming
                session
              </h4>
            </div>
          </div>

          <div className="coachTips step">
            <div className="number">
              <img src={coach?.avatar} alt="" />
            </div>
            <div className="right">
              <h4>
                Coach Tips from {coach?.first_name}
              </h4>
              <ul>
              <li>Your images could be photos, icons, graphics, or even pictures that you draw yourself.</li>
              <li>Don't worry about doing it "right." Everyone will approach this activity differently. As long as you end up with 10 Images, you've done this exercise perfectly.</li>
              <li>Don't place any labels or descriptions on your images. More will be revealed in our session!</li>
              </ul>
            </div>
          </div>

          <button className="btn secondary" onClick={() => setShowModal(false)}>
            Let's Begin!
          </button>
        </div>
      </div>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="skrim"
      ></div>
    </div>
  );
};
