import {
  faAngleLeft,
  faMessage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import "../styles/components/textstory.scss";
import { CrewImage } from "./CrewImage";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../context/env.context";
import axios from "axios";

export const TextStory = (props) => {
  const [showVideo, setShowVideo] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const vidRef = useRef(null);
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [awaitingReload, setAwaitingReload] = useState(false);

  const threeDaysAgo = () => {
    const today = new Date();
    var diff = moment(today).diff(props.story.createdAt, "days");
    return diff > 2;
  };

  const hide = async (type) => {
    setAwaitingReload(true);
    const token = await getAccessTokenSilently();

    let data = {};

    if (type === "to") {
      data = {
        to: true,
      };
    } else {
      data = {
        from: true,
      };
    }

    const response = await axios.patch(
      `${apiServerUrl}/api/story/${props.story.id}/hide`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTimeout(() => {
      props.refresh();
      setAwaitingReload(false);
    }, 2000);
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <div
      className={`TextStory ${
        props.story.backgroundType !== null
          ? props.story.backgroundType
          : "blue"
      }`}
    >
      {props.type === "received" && !awaitingReload && (
        <div
          onClick={() => {
            hide("from");
          }}
          className="hide"
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      {props.type === "sent" && !awaitingReload && (
        <div
          onClick={() => {
            hide("to");
          }}
          className="hide"
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      {awaitingReload && (
        <div className="awaiting">
          <p>Story Hidden</p>
        </div>
      )}
      {props.type === "sent" && !props.story.recievingUser && !props.modalFlow && (
        <div
          onClick={(e) => {
            e.preventDefault();
            history.push("/story/resend/" + props.story.id);
          }}
          className="notSeen"
        >
          This story has not been viewed.
          {threeDaysAgo(props.story.createdAt) && <span>Resend</span>}
        </div>
      )}
      <div
        className="container"
        onClick={() => {
          if (!props.modalFlow) {
            history.push(`/view/${props.story.id}`);
          } else {
            props.clicked();
          }
        }}
      >
        <div
          style={{
            backgroundColor: props.story.color,
          }}
          className="image"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: truncateString(props.story.text, 400),
            }}
          ></p>
        </div>
      </div>
      {props.type === "received" && props.modalFlow && (
        <div
          onClick={() => {
            props.clicked();
          }}
          className="info"
        >
          <CrewImage me={props.story.sendingUser} clicked={() => {}} />
          <p>
            {props.story.sendingUser?.first_name}{" "}
            {props.story.sendingUser?.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "received" && !props.signup && !props.modalFlow && (
        <div
          onClick={() => {
            history.push(`/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.sendingUser}
          />
          <p>
            {props.story.sendingUser?.first_name}{" "}
            {props.story.sendingUser?.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {props.type === "received" && props.signup === true && !props.modalFlow && (
        <div
          onClick={() => {
            loginWithRedirect({
              appState: { returnTo: window.location.pathname },
            });
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.sendingUser}
          />
          <p>
            {props.story.sendingUser.first_name}{" "}
            {props.story.sendingUser.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {props.type === "sent" && props.story.recievingUser && !props.modalFlow && (
        <div
          onClick={() => {
            history.push(`/view/${props.story.id}`);
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.recievingUser}
          />
          <p>
            {props.story.recievingUser.first_name}{" "}
            {props.story.recievingUser.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "sent" && !props.story.recievingUser && !props.modalFlow && (
        <div
          onClick={() => {
            history.push(`/view/${props.story.id}`);
          }}
          className="info empty"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={{
              first_name: "jake",
              avatar: `https://placehold.co/100x100?text=${props.story.toFirstName.charAt(
                0
              )}`,
            }}
          />
          <p>
            {props.story.toFirstName} <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {props.type === "sent" && props.story.recievingUser && props.modalFlow && (
        <div
          onClick={() => {
            props.clicked();
          }}
          className="info"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={props.story.recievingUser}
          />
          <p>
            {props.story.recievingUser.first_name}{" "}
            {props.story.recievingUser.last_name}
            <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}
      {props.type === "sent" && !props.story.recievingUser && props.modalFlow && (
        <div
          onClick={() => {
            props.clicked();
          }}
          className="info empty"
        >
          <CrewImage
            clicked={() => {
              console.log("clicked");
            }}
            me={{
              first_name: "jake",
              avatar: `https://placehold.co/100x100?text=${props.story.toFirstName.charAt(
                0
              )}`,
            }}
          />
          <p>
            {props.story.toFirstName} <br />
            <span>{moment(props.story.updatedAt).format("MMM Do YYYY")}</span>
          </p>
        </div>
      )}

      {showVideo && (
        <div className="textOverlay">
          <div className="navbar">
            {props.type === "received" && (
              <div className="inside">
                <FontAwesomeIcon
                  onClick={() => {
                    setShowVideo(false);
                    vidRef.current.pause();
                  }}
                  icon={faAngleLeft}
                />
                <div className="information">
                  <CrewImage
                    clicked={() => {
                      console.log("clicked");
                    }}
                    me={props.story.sendingUser}
                  />
                  <p>
                    {props.story.sendingUser.first_name}{" "}
                    {props.story.sendingUser.last_name}
                    <br />
                    <span>
                      {moment(props.story.updatedAt).format("MMM Do YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {props.type === "sent" && props.story.recievingUser && (
              <div className="inside">
                <FontAwesomeIcon
                  onClick={() => {
                    setShowVideo(false);
                    vidRef.current.pause();
                  }}
                  icon={faAngleLeft}
                />
                <div className="information">
                  <CrewImage
                    clicked={() => {
                      console.log("clicked");
                    }}
                    me={props.story.recievingUser}
                  />
                  <p>
                    {props.story.recievingUser.first_name}{" "}
                    <span>{props.story.recievingUser.last_name}</span>
                    <br />
                    <span>
                      {moment(props.story.updatedAt).format("MMM Do YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {props.type === "sent" && !props.story.recievingUser && (
              <div className="inside empty">
                <FontAwesomeIcon
                  onClick={() => {
                    setShowVideo(false);
                    vidRef.current.pause();
                  }}
                  icon={faAngleLeft}
                />
                <div className="information">
                  <CrewImage
                    clicked={() => {
                      console.log("clicked");
                    }}
                    me={{
                      avatar: `https://placehold.co/100x100?text=${props.story.toFirstName.charAt(
                        0
                      )}`,
                    }}
                  />
                  <p>
                    {props.story.toFirstName}
                    <br />
                    <span>
                      {moment(props.story.updatedAt).format("MMM Do YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="videoContainer">
            <p dangerouslySetInnerHTML={{ __html: props.story.text }}></p>
          </div>
        </div>
      )}

      {props.hideAction !== true && (
        <div
          onClick={() => {
            if (!props.modalFlow) {
              history.push(`/view/${props.story.id}`);
            } else {
              props.clicked();
            }
          }}
          className="actionBox"
        >
          <div className="responses">
            <FontAwesomeIcon icon={faMessage} />{" "}
            <span>{props.story?.responses?.length} replies</span>
          </div>
        </div>
      )}
    </div>
  );
};
