import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import dater from "moment";
import "../../styles/pages/lifeline.scss";
import { useEnv } from "../../context/env.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { LineChart } from "./components/LineChart";
import { ContentBox } from "./components/ContentBox";
import { ContentBoxAdd } from "./components/ContentBoxAdd";
import { Add } from "./components/Add";

import Intro from "../../images/intro.png";
import { Analytics } from "../../hooks/analytics";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { ConnectiveTissue } from "./components/ConnectiveTissue";
export const Lifeline = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [years, setYears] = useState([]);
  const [moments, setMoments] = useState([]);
  const [index, setIndex] = useState(-1);
  const [adding, setAdding] = useState(true);
  const [personalMoments, setPersonalMoments] = useState([]);
  const [professionalMoments, setProfessionalMoments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showIntro, setShowIntro] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [lineData, setLineData] = useState({
    datasetIndex: 0,
    index: 0,
  });
  const [showConnective, setShowConnective] = useState(false);
  const [highlightReelMoments, setHighlightReelMoments] = useState([]);
  const history = useHistory();
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    getMoments();
    getInsights();

    const seenLifelineIntro = localStorage.getItem("seenLifelineIntro");

    if (seenLifelineIntro) {
      setShowIntro(false);
    }

    if (props.location?.state?.add) {
      setIndex(-2);
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }
    };
  }, []);

  const saveToDatabase = async (item) => {
    const token = await getAccessTokenSilently();
    const date = moment(item.date);

    const config = {
      url: `${apiServerUrl}/api/moments`,
      method: "POST",
      data: {
        score: item.score,
        title: item.title,
        date: date.format("YYYY-MM-DD HH:mm:ss"),
        description: item.description,
        type: item.type,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    Analytics("Created Moment");
    if (response.status === 200) {
      getMoments();
      if (item.score > 70) {
        setHighlightReelMoments([response.data]);
        setShowConnective(true);
      } else {
        setIndex(-1);
      }
      return true;
    } else {
      throw new Error("Something went wrong");
    }
  };

  const ClickedItemFromGraph = (indexItem, datasetIndex) => {
    let momentSearched = null;

    // The data in the graph is split lets find the moment object so we can get the index.
    if (datasetIndex === 0) {
      momentSearched = personalMoments[indexItem];
    } else {
      momentSearched = professionalMoments[indexItem];
    }

    // Get the index of the moment in the full moment array.
    const index = moments.indexOf(momentSearched);
    setIndex(index);
  };

  const getMoments = async (id = false) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/moments`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    const reducedMoments = response.data.reduce(
      (acc, moment) => {
        if (moment.type === "personal") {
          acc.personalMoments.push(moment);
        } else {
          acc.professionalMoments.push(moment);
        }
        const year = dater(moment.date).year();
        if (!acc.years.includes(year)) {
          acc.years.push(year);
        }
        return acc;
      },
      {
        years: [],
        personalMoments: [],
        professionalMoments: [],
      }
    );

    if (response.data.length > 0) {
      setAdding(false);
      setShowIntro(false);
    } else {
      history.push("/lifeline/add");
    }

    let hasSeenIntro = localStorage.getItem("seenLifelineIntro");

    if (!response.data || (response.data.length === 0 && !hasSeenIntro)) {
      setShowIntro(true);
    }

    setYears(reducedMoments.years);
    setMoments(response.data);
    setPersonalMoments(reducedMoments.personalMoments);
    setProfessionalMoments(reducedMoments.professionalMoments);
    setLoading(false);
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights/tool/lifeline`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // If they have already done their insights update it.
    if (response.data.length >= 3) {
      setInsights(response.data);
    }
  };

  const data = {
    datasets: [
      {
        label: "Personal",
        data: personalMoments.map((moment, index) => {
          return {
            x: dater(moment.date).format("MM/DD/YYYY"),
            y: moment.score,
            score: moment.score,
            title: moment.title,
          };
        }),
        borderColor: "rgba(79, 114, 130, 1)",
        pointBorderColor: "rgba(181, 196, 202, 1)",
        radius: 15,
        pointBorderWidth: 0,
        borderWidth: 8,
        backgroundColor: "rgba(181, 196, 202, 1)",
        datalabels: {
          align: "start",
          anchor: "start",
          clip: false,
          padding: 6,
          size: 11,
        },
      },
      {
        label: "Professional",
        data: professionalMoments.map((moment, index) => {
          return {
            x: dater(moment.date).format("MM/DD/YYYY"),
            y: moment.score,
            score: moment.score,
            title: moment.title,
          };
        }),
        borderColor: "rgba(255, 158, 103, 1)",
        radius: 15,
        borderWidth: 8,
        pointBorderWidth: 0,
        backgroundColor: "rgba(255, 158, 103, 1)",
      },
    ],
  };

  // If its still loading
  if (loading) {
    return (
      <div className="lifeline">
        <div className="fullSlide"></div>
      </div>
    );
  }

  // If they need to see the intro screen
  if (showIntro === true) {
    return (
      <div className="lifeline white-background LifeLine-intro">
        <div className="left">
          <h2>Let's get started, but first...</h2>
          <h4>
            Spend a few moments taking a mental walk through your career,
            starting from your first job.
          </h4>
          <p>
            As you think about each step of your career journey, take note of
            the moments that shaped you. The ones that taught you something
            important about yourself. The ones that sent you off in a new
            direction. The moments that felt especially great, and the ones that
            felt challenging.
          </p>
          <p>
            When you're ready, we're going to ask you to add at least three of
            these moments to your lifeline.
          </p>
          <button
            className="btn primary"
            onClick={() => {
              localStorage.setItem("seenLifelineIntro", "true");
              setShowIntro(false);
            }}
          >
            I'm Ready To Begin
          </button>
        </div>
        <div className="right">
          <img src={Intro} alt="lifeline intro" />
        </div>
      </div>
    );
  }

  return (
    <div className="lifeline">
      {props.location?.state?.message && (
        <div className="lifeline__message">
          <p>{props.location?.state?.message}</p>
        </div>
      )}

      <div className="fullSlide">
        <div className="contentBox">
          {adding === false && (
            <div className="contentBox__title">
              <div className="text">
                <h2>Lifeline</h2>
              </div>
              <div className="buttons">
                <button
                  className="btn primary"
                  onClick={() => {
                    setIndex(-2);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add a moment
                </button>
                {index !== -1 && (
                  <button
                    className="btn primary"
                    onClick={() => {
                      setIndex(-1);
                    }}
                  >
                    <FontAwesomeIcon icon={faLightbulb} />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="controls">
          <div className="slideBox">
            <div className="contentBox">
              {index !== -1 && index !== -2 && (
                <ContentBox moment={moments[index]} />
              )}
              {(index === -1) === true && (
                <ContentBoxAdd
                  professionalMoments={professionalMoments}
                  personalMoments={personalMoments}
                  saveToDatabase={saveToDatabase}
                />
              )}
              {index === -2 && (
                <Add
                  professionalMoments={professionalMoments}
                  personalMoments={personalMoments}
                  saveToDatabase={saveToDatabase}
                />
              )}
            </div>
            <div className="graphs">
              <LineChart
                personalMoments={personalMoments}
                professionalMoments={professionalMoments}
                input={lineData}
                data={data}
                clicky={ClickedItemFromGraph}
              />
            </div>
          </div>
        </div>
      </div>

      {showHelp && (
        <div className="helpBoxMain white-background">
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowHelp(false)} />
          <div className="left">
            <h2 className="underline blue">How to Finish Your Lifeline...</h2>
            <p>
              To move onto the next step of LifeLine you need to add{" "}
              <b>3 personal moments and 3 professional moments</b>. Once you
              have done that you will be asked to reflect on your moments and
              add some insights.
            </p>
          </div>
        </div>
      )}
      {showHelp && (
        <div className="skrim" onClick={() => setShowHelp(false)}></div>
      )}
      {showConnective && (
        <ConnectiveTissue
          close={() => {
            setShowConnective(false);
          }}
          highlightReelMoments={highlightReelMoments}
        />
      )}
    </div>
  );
};
