import React, { useEffect, useState, useRef } from "react";
import "../../styles/components/OnboardingIntro/index.scss";
import Reveal from "reveal.js";
import TopRight from "../../images/top_right_onboarding.png";
import Slide1 from "../../images/slide_1.png";
import Slide2 from "../../images/slide_2.png";
import Slide3 from "../../images/slide_3.png";

import "reveal.js/dist/reveal.css";

export const OnboardingIntro = ({ continueOn }) => {
  let deck = null;
  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style.display = "none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width: 100%;";



    const navMobile = document.getElementsByClassName("nav-bar__container__mobile")[0];

    if (navMobile) {
      navMobile.style.display = "none";
    }

    setTimeout(() => {
      deck = new Reveal();

      deck.initialize();
    }, 500);

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName("nav-bar__container__mobile")[0];
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
      deck = null;
      if (nav) {
        nav.style.display = "flex";
      }
      if(navMobile) {
        navMobile.style.display = "flex";
      }
    };
  }, []);

  return (
    <div id="onboardingIntro" className="onboardingIntro">
      <img src={TopRight} alt="accent" />
      <div className="reveal">
        <div className="slides">
          <section>
            <h2>Welcome to Crew!</h2>
            <p>
              We're so excited for you to embark upon your career design
              journey. Ready to dive in?
            </p>
            <button
              onClick={() => {
                deck.next();
              }}
              className="btn secondary"
            >
              Let's Go!
            </button>
          </section>
          <section>
            <div>
              <h2>Careers today are messy</h2>
            </div>
          </section>
          <section>
            <h3>
              With so many potential paths and no “right” answers, it's easy to
              feel lost, stuck, and disconnected from what we care about most.
            </h3>
          </section>
          <section>
            <h3>
              That's why it's more important than ever to be intentional about
              what we want from work, and to take ownership of our own career
              path.
            </h3>
          </section>
          <section>
            <h2>Crew is a career design platform</h2>
            <h3>
              We use the principles of design thinking to help you clarify what
              you want from work and shape a career that reflects what really
              matters to you.
            </h3>
          </section>
          <section>
            <div className="box">
              <p>This can look like visualizing your career data in new ways</p>
              <img src={Slide1} alt="slide 1" />
            </div>
          </section>
          <section>
            <div className="box middle">
              <img src={Slide2} alt="slide 2" />
            </div>
          </section>
          <section>
            <div className="box middle">
              <img src={Slide3} alt="slide 3" />
            </div>
          </section>
          <section>
            <h2>Ready to begin your career design journey?</h2>
            <h3>Let's introduce you to your digital coach.</h3>
            <button
              onClick={() => {
                continueOn();
              }}
              className="btn primary"
            >
              Let's Go!
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};
