import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/pages/Programs/dashboard.scss";
import { useEnv } from "../../context/env.context";
import "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ProgramComponent } from "./components/ProgramComponent";

export const Program = ({}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [crew, setCrew] = useState([]);
  const [finalStage, setFinalStage] = useState(false);
  const [insights, setInsights] = useState([]);
  const [me, setMe] = useState({});
  const [addingReflection, setAddingReflection] = useState(false);
  const [newReflection, setNewReflection] = useState("");
  const [reflections, setReflections] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [showBio, setShowBio] = useState(false);


  useEffect(() => {
    getCrew();
    getInsights();
    getMe();

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width:100%";

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
    };
  }, []);

  const saveInsight = async (insight) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/insights`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: insight,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    getInsights();
    setAddingReflection(false);
    setNewReflection("");
  };

  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios(config);

      setMe(response.data);
    } catch (error) {}
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${apiServerUrl}/api/insights`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const filteredInsights = response.data.filter((insight) => {
      return insight.type.includes("programReflection");
    });

    const nonFilteredInsights = response.data.filter((insight) => {
      return !insight.type.includes("programReflection");
    });

    setReflections(filteredInsights);

    setInsights(nonFilteredInsights);
  };

  const getFilteredSessions = (sessions) => {
    const now = moment().startOf("day");

    if (!sessions) return [];

    const sortedSessions = sessions.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate));
    });

    return sortedSessions.filter((session) => {
      return moment(session.startDate).isAfter(now);
    });
  };


  const getCrew = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${apiServerUrl}/api/crews/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const sessionsLeft = getFilteredSessions(
      response.data.crew[0].program?.sessions
    );

    const sortedAndMarkedSessions = response.data.crew[0].program?.sessions
      .map((session) => {
        if (moment(session.endDate).isBefore(moment())) {
          session.past = true;
        }
        return session;
      })
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate));
      });

    const sessionsWithSessionRecordings = sortedAndMarkedSessions.filter(
      (session) => {
        return session.sessionRecordingLink;
      }
    ).reverse();

    if (sessionsLeft.length < 2) {
      setFinalStage(true);
    }

    setCrew(response.data.crew[0]);
    setAllSessions(sortedAndMarkedSessions);
    setRecordings(sessionsWithSessionRecordings);
  };

  if (!crew || !crew.program)
    return (
      <div className="programsDashboard">
        <div className="center">
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to Canvas
          </Link>

          <div
            style={{
              backgroundImage: `url(${crew?.company?.header_image})`,
            }}
            className="header"
          >
            <h4>Welcome to {crew.company?.name}</h4>
          </div>
        </div>
      </div>
    );

  return (
    <ProgramComponent
      crew={crew}
      finalStage={finalStage}
      insights={insights}
      me={me}
      addingReflection={addingReflection}
      setAddingReflection={setAddingReflection}
      newReflection={newReflection}
      setNewReflection={setNewReflection}
      saveInsight={saveInsight}
      reflections={reflections}
      allSessions={allSessions}
      recordings={recordings}
      showBio={showBio}
      setShowBio={setShowBio}
    />
  );
};
