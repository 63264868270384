import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import "../../../styles/pages/Strengths/components/StrengthSearch.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export const StrengthSearch = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [phrases, setPhrases] = React.useState([]);
  const [search, setSearch] = React.useState("");

  useEffect(() => {
    if (props.strength) {
      setSearch(props.strength.text);
    }
  }, []);

  const searchPhrases = async (phrase) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(
      `${apiServerUrl}/api/phrases?search=${phrase}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setPhrases(response.data);
  };

  return (
    <div className="strengthSearch">
      <div className="inputContainer">
        {props.strength &&
          props.strength.text !== "" &&
          props.strength.text !== null && (
            <FontAwesomeIcon icon={faCheck} className="check" />
          )}
        {props.strength &&
          (props.strength.text === "" || props.strength.text === null) && (
            <FontAwesomeIcon icon={faCheck} className="hide" />
          )}
        <input
          value={props.strength.text || search}
          type="text"
          disabled={true}
          placeholder={props.placeholder}
          onBlur={() => {
            setTimeout(() => {
              setPhrases([]);
            }, 200);
          }}
          onChange={(e) => {
            searchPhrases(e.target.value);
            setSearch(e.target.value);
            props.setText(e.target.value);
          }}
        />
        {props.strength &&
          props.strength.text !== "" &&
          props.strength.text !== null && (
            <FontAwesomeIcon
              onClick={() => {
                setSearch("");
                if (!props.remove) props.setText("");
                if (props.remove) props.remove(props.strength);
              }}
              icon={faTimes}
              className="close"
            />
          )}
      </div>

      {phrases.length > 0 && search.length > 0 && (
        <div className="dropdown">
          {phrases.map((phrase, index) => {
            return (
              <div
                onClick={() => {
                  setSearch(phrase.text);
                  setPhrases([]);
                  props.setText(phrase.text);
                }}
                key={index}
              >
                {phrase.text}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
