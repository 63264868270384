import { useAuth0 } from "@auth0/auth0-react";
import {
  faCheck,
  faRightFromBracket,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Tool/Tools/template.scss";
import { useHistory } from "react-router-dom";
import { PathTool } from "../../Path/components/PathTool";
import mixpanel from "mixpanel-browser";

export const Lifeline = (props) => {
  const history = useHistory();
  useEffect(() => {
    mixpanel.track("Visited Lifeline Tool Page");
    window.analytics.page('Lifeline Tool');
  }, []);

  return (
    <div className="templateTool">
      <div className="bluebg"></div>
      <div className="container">
        <div className="intro">
          <div className="left">
            <img src={props.tool.image} alt="lifeline" />
          </div>
          <div className="right">
            <div className="top">
              <h3>
                Welcome to <br />
                <span className="highlight yellow">Lifeline</span>
              </h3>

            </div>
            <div className="bottom">
              <button
                onClick={() => {
                  history.push("/lifeline");
                }}
                className="btn primary"
              >
                Start Lifeline Now! <FontAwesomeIcon icon={faRightLong} />{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="content">
          <p className="body-text">
          Our past experiences hold a treasure trove of data about who we are, what we care about, and the circumstances in which we thrive. But it can be challenging to make sense of our own stories and take away actionable insights.<br /><br />
<b>Lifeline</b> helps you do just that. You'll reflect on your life and career to date and identify key moments that shaped who you are today. You’ll walk away with a new perspective on your own story and insights that will inform your future career choices
          </p>

          <div className="description">
            <div className="left white-background">
              <h3 className="heading2 underline blue">What you'll do</h3>

              <ol>
                <li>
                  <div className="item">1</div>
                  <p>
                  Capture key moments in your life and career that have shaped you
                  </p>
                </li>

                <li>
                  <div className="item">2</div>
                  <p>Reflect on what was important about those moments, how you felt, and what you learned</p>
                </li>

                <li>
                  <div className="item">3</div>
                  <p>
                  Look for larger patterns about what's important to you and the circumstances in which you thrive
                  </p>
                </li>
              </ol>
            </div>
            <div className="left white-background yellow">
              <h3 className="heading2 underline yellow">Use To:</h3>

              <ol>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                  Capture what you've learned about yourself throughout your life and career
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                  Help you tell compelling stories about yourself in a career context
                  </p>
                </li>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                  Identify the people who have made an impact on you throughout your journey
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="moreTools">
        <div className="container">
          <h3>
            <span className="heading1 underline yellow">
              Want to go somewhere else?
            </span>
          </h3>

          <div className="tools">
            <PathTool
              tool={{
                name: "Storyboard",
                id: 2,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Storyboard.svg",
                Tool_Path: {
                  description: "I want to thank those that have helped me in my career",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Strengths",
                id: 3,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Strengths.svg",
                Tool_Path: {
                  description: "Identify what your strengths are and take action on them",
                },
              }}
            />

          </div>
        </div>
      </div>
    </div>
  );
};
