import React, {useEffect, useState} from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt, faCalendarWeek, faTrash } from "@fortawesome/free-solid-svg-icons";

export const Past = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [memories, setMemories] = useState({
    roses: [],
    thorns: [],
    });
  const today = moment();
  const from_date = moment().day("Monday").week(props.past.week).format("MM/DD");

  useEffect(() => {
    setShowMore(false);
    const moments = props.past.memories.reduce((acc, memory) => {
        if(memory.type === "rose") {
            acc.roses.push(memory);
        }
        if(memory.type === "thorn") {
            acc.thorns.push(memory);
        }
        return acc;
    }, {roses: [], thorns: []});

    setMemories(moments);
    }, [props.past.memories]);


  return (
    <div className="section past">
      <h3 onClick={()=>{
        setShowMore(!showMore)
      }} className="underline">
        <FontAwesomeIcon icon={faCalendarWeek} />
        {from_date}
      </h3>

      {showMore && (
        <div className="past__memories">
            <div className="roses">
                <h5 className="underline">🌹 Roses</h5>
            {memories?.roses.map((rose) => (
                <div className="past__memory">
                    <p>🌹 {rose.text}</p>
                </div>
            ))}
            </div>
            <div className="thorns">
            <h5 className="underline">🌵 Thorns</h5>
            {memories?.thorns?.map((thorn) => (
                <div className="past__memory">
                    <p>🌵 {thorn.text}</p>
                </div>
            ))}
            </div>
        </div>
        )}
    </div>
  );
};
