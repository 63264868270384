import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/purpose.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";

export const StartingValues = ({ chat, continueLoop, divRef }) => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [inputValue, setInputValue] = useState("");
  const [continued, setContinued] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  const updateUser = async (onboardingQuestions) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        onboardingQuestions,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    return true;
  };

  return (
    <div
      style={{
        marginBottom: "15px",
      }}
      id="chatMessage"
      className="chatMessage addon-purpose addon"
    >
      <div className="chatMessageBox">
        <img
          alt="avatar"
          className={""}
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
          <h4>Roxanne Burbach {chat.app}</h4>
          <div className="bubble">
            {!loading && (
              <div className="container">
                <p
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  To start us off, I'd love to learn what's top of mind for you
                  in your career right now. (Don't worry. Everything you share
                  on the Crew platform is for your eyes only, unless you choose
                  to share it!)
                </p>

                <div
                  onClick={async () => {
                    if (continued) return;
                    await updateUser(
                      "I want to be more satisfied in my current role"
                    );
                    setInputValue(
                      "I want to be more satisfied in my current role"
                    );
                    setContinued(true);
                    continueLoop();
                  }}
                  className="option"
                >
                  <h5>I want to be more satisfied in my current role</h5>
                  <div
                    className={`check ${
                      inputValue ===
                      "I want to be more satisfied in my current role"
                        ? "active"
                        : ""
                    }`}
                  ></div>
                </div>

                <div
                  onClick={async () => {
                    if (continued) return;
                    await updateUser(
                      "I want to figure out what’s next for me in my career"
                    );
                    setInputValue(
                      "I want to figure out what’s next for me in my career"
                    );
                    continueLoop();

                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>I want to figure out what’s next for me in my career</h5>
                  <div
                    className={`check ${
                      inputValue ===
                      "I want to figure out what’s next for me in my career"
                        ? "active"
                        : ""
                    }`}
                  ></div>
                </div>

                <div
                  onClick={async () => {
                    if (continued) return;
                    await updateUser(
                      "I think I know what I want to do next, but I need help getting there"
                    );
                    setInputValue(
                      "I think I know what I want to do next, but I need help getting there"
                    );
                    continueLoop();

                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>
                    I think I know what I want to do next, but I need help
                    getting there
                  </h5>
                  <div
                    className={`check ${
                      inputValue ===
                      "I think I know what I want to do next, but I need help getting there"
                        ? "active"
                        : ""
                    }`}
                  ></div>
                </div>

                <div
                  onClick={async () => {
                    if (continued) return;
                    await updateUser("I’m not sure");
                    setInputValue("I’m not sure");
                    continueLoop();

                    setContinued(true);
                  }}
                  className="option"
                >
                  <h5>I’m not sure</h5>
                  <div
                    className={`check ${
                      inputValue === "I’m not sure" ? "active" : ""
                    }`}
                  ></div>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
