import React, { useEffect, useState } from "react";
import "./styles/index.scss";
import image from "./images/image.png";
import moment from "moment";
import icon from "./images/icon.svg";
import bgTop from "./images/bgTop.svg";
import bgBottom from "./images/bgBottom.svg";
import { HighlightReelWidget } from "./Widgets/HighlightReel";
import { LifelineWidget } from "./Widgets/Lifeline";
import notificationIcon from "./images/notificationIcon.svg";
import ProgramWidget from "./Widgets/ProgramWidget";
import { ValuesWidget } from "./Widgets/Values";
import { StrengthsWidget } from "./Widgets/Strengths";
import axios from "axios";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { VisionsWidget } from "./Widgets/Visions";
import { BoardOfAdvisorsWidget } from "./Widgets/BoardOfAdvisors";
import { RoleModelWidget } from "./Widgets/RoleModel";

export const Canvas = ({ me, id, isProgram, crew }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [crewData, setCrewData] = useState({});
  const [alreadyDoneSessions, setAlreadyDoneSessions] = useState([]);

  useEffect(() => {
    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width:100%";

    // If they are a program user, and have a crew we need to get all there session data
    // to determine what widgets to show.
    if (isProgram && crew) {
      getCrew(crew.id);
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
    };
  }, [id]);

  const getCrew = async (crewId, me) => {
    const token = await getAccessTokenSilently();
    const config = {
      url: `${apiServerUrl}/api/crews/${crewId}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);

      // Sort them by order
      const sortedSessions = response.data.program.sessions.sort((a, b) => {
        return a.order + b.order;
      });

      const alreadyDoneSessionsData = [];

      // Get only the current session and the sessions that came before it.
      sortedSessions.some((session) => {
        if (session.id === response.data.activeSessionId) {
          alreadyDoneSessionsData.push(session);
          return true;
        }
        alreadyDoneSessionsData.push(session);
        return false;
      });

      setAlreadyDoneSessions(alreadyDoneSessionsData);
      setCrewData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="CanvasDashboard">
      <div className="bg">
        <img alt="bg top" src={bgTop} />
        <img alt="bg bottom" src={bgBottom} />
      </div>
      <div className="center">
        <div className="content">
          <div className="header">
            {isProgram && <h1>Welcome, {me?.first_name}!</h1>}
            {!isProgram && <h1>Career Canvas</h1>}
            <p>“If opportunity doesn't knock, build a door.” —Milton Berle</p>
          </div>
          <div className="body">
            <div className="left">
              {isProgram && <ProgramWidget me={me} image={image} />}
              <div className="widgets">
                {/* Show widgets to non program users */}
                {!isProgram && (
                  <>
                    <HighlightReelWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <ValuesWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <LifelineWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <StrengthsWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <VisionsWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      me={me}
                      sessionNumber={1}
                    />
                    <BoardOfAdvisorsWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <RoleModelWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    <div className="widget">
                      <img alt="icon" src={icon} />
                      <h3>Unlocked after Interests</h3>
                      <button className="btn soon" onClick={() => {}}>
                        Coming Soon
                      </button>
                    </div>

                    <div className="widget">
                      <img alt="icon" src={icon} />
                      <h3>Unlocked after Visions</h3>
                      <button className="btn soon" onClick={() => {}}>
                        Coming Soon
                      </button>
                    </div>
                  </>
                )}

                {/* Show only widgets for sessions program users have already done */}
                {isProgram && (
                  <>
                    <HighlightReelWidget
                      programUser={isProgram}
                      size={1}
                      id={me?.id}
                      sessionNumber={1}
                    />
                    {alreadyDoneSessions.map((session) => {
                      if (session.learningActivityId === 13) {
                        return (
                          <ValuesWidget
                            programUser={isProgram}
                            size={1}
                            key={session.id}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }

                      if (session.learningActivityId === 2) {
                        return (
                          <LifelineWidget
                            programUser={isProgram}
                            size={1}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }
                      if (session.learningActivityId === 4) {
                        return (
                          <StrengthsWidget
                            programUser={isProgram}
                            size={1}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }

                      if (session.learningActivityId === 10) {
                        return (
                          <VisionsWidget
                            programUser={isProgram}
                            size={1}
                            me={me}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }

                      if(session.learningActivityId === 6) {
                        return (
                          <BoardOfAdvisorsWidget
                            programUser={isProgram}
                            size={1}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }

                      if(session.learningActivityId === 14) {
                        return (
                          <RoleModelWidget
                            programUser={isProgram}
                            size={1}
                            id={me?.id}
                            sessionNumber={1}
                          />
                        );
                      }

                      return null;
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="right">
              <h2>Notifications</h2>

              <div className="notifications">
                {isProgram && (
                  <div className="notification">
                    <h4>
                      <img src={notificationIcon} alt="notification icon" />{" "}
                      Crew Check-in
                    </h4>
                    <p>
                      Coming up on{" "}
                      <span>
                        {moment(me?.crew[0].activeSession?.startDate).format(
                          "MMMM Do"
                        )}{" "}
                        at{" "}
                        {moment(me?.crew[0].activeSession?.startDate).format(
                          "hh:mm a"
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {!isProgram && (
                  <div className="notification">
                    <h4>
                      <img src={notificationIcon} alt="notification icon" />
                      No New Notifcations
                    </h4>
                    <p></p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
