import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
export const Signup = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    return loginWithRedirect({
      signIn: true,
    });
  }, []);

  return <div className="login"></div>;
};
