import React, { useEffect, useState } from "react";
import "./styles/ChoosePrompts.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import modalLightbulb from "../images/modalLightbulb.png";
import { Analytics } from "../../../hooks/analytics";
import { Slides } from "./Slides";
import { Prompts } from "./Prompts";

export const ChoosePrompts = ({ setStep, me }) => {
  const [choosenPrompts, setChoosenPrompts] = useState({
    growth1: "How have you grown?",
    growth2: "How do you feel?",
    relationships:
      "What do you colleagues or customers say about working with you?",
    impact1: "What challenges have you overcome? How?",
    impact2: "What impact have you made?",
  });
  const [showIntro, setShowIntro] = useState(true);
  const [selectedBox, setSelectedBox] = useState(null);

  const history = useHistory();

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  useEffect(() => {}, []);

  const finish = async () => {
    const token = await getAccessTokenSilently();

    const pages = [
      {
        prompt: choosenPrompts.growth1,
        category: "growth",
        order: 1,
      },
      {
        prompt: choosenPrompts.growth2,
        category: "growth",
        order: 2,
      },
      {
        prompt: choosenPrompts.relationships,
        category: "relationships",
        order: 3,
      },
      {
        prompt: choosenPrompts.impact1,
        category: "impact",
        order: 4,
      },
      {
        prompt: choosenPrompts.impact2,
        category: "impact",
        order: 5,
      },
    ];

    const data = {
      title: "One Year Vision",
      subtitle: "Looking back on the last year, I was...",
      pages,
    };

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);

    Analytics("Visions - Saved Prompts");
    Analytics("Visions - Started");
    history.push("/visions/enter");
  };

  return (
    <div className="choosePrompts">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              setStep(0);
            }}
          />

          <h3>One-Year Vision Planning</h3>
        </div>

        <Prompts
          selectedBox={selectedBox}
          setSelectedBox={setSelectedBox}
          setChoosenPrompts={setChoosenPrompts}
          choosenPrompts={choosenPrompts}
        />

        <div className="bottom">
          <button className="btn blank">Back</button>
          <button
            disabled={
              !choosenPrompts.growth1 ||
              !choosenPrompts.growth2 ||
              !choosenPrompts.relationships ||
              !choosenPrompts.impact1 ||
              !choosenPrompts.impact2
            }
            onClick={() => {
              finish();
            }}
            className="btn secondary"
          >
            Continue
          </button>
        </div>
      </div>

      <div className="right">
        <Slides
          choosenPrompts={choosenPrompts}
          setSelectedBox={setSelectedBox}
        />
      </div>
      {showIntro && (
        <div className="modal">
          <div className="modal-content">
            <div className="left">
              <img src={modalLightbulb} alt="" />
            </div>
            <div className="right">
              <h2>Here's what to expect</h2>
              <p>
                In this activity, you'll create your compelling one-year vision
                Here's what to do before your next session:
              </p>
              <h5>Here's what to do before your next session:</h5>
              <div className="icons">
                <div className="item">
                  <span>1</span>
                  <div className="content">
                    <h4>
                      Answer each of the five prompts provided like you're
                      telling us about the last year from one year in the
                      future.
                    </h4>
                  </div>
                </div>
                <div className="item">
                  <span>2</span>
                  <div className="content">
                    <h4>
                      The more detail you provide, the more impactful this
                      activity will be. Let yourself imagine what could be true
                      in one year.
                    </h4>
                  </div>
                </div>
              </div>
              <p>
                You'll create your cover pages with the help of your coach and
                crew during the session.
              </p>

              <div className="actions">
                <button
                  className="btn secondary"
                  onClick={() => {
                    setShowIntro(false);
                  }}
                >
                  Ready to Begin
                </button>
              </div>
            </div>
          </div>
          <div
            className="skrim"
            onClick={() => {
              setShowIntro(false);
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
