import {
  faCheck,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/pages/Tool/Tools/template.scss";
import { useHistory } from "react-router-dom";
import { PathTool } from "../../Path/components/PathTool";

export const HighlightReel = (props) => {
  const history = useHistory();
  useEffect(() => {}, []);

  const me = localStorage.getItem("me");

  return (
    <div className="templateTool">
      <div className="bluebg"></div>
      <div className="container">
        <div className="intro">
          <div className="left">
            <img src={props.tool.image} alt="highlight reel" />
          </div>
          <div className="right">
            <div className="top">
              <h3>
                Welcome to <br />
                <span className="highlight yellow">Highlight Reel</span>
              </h3>
            </div>
            <div className="bottom">
              <button
                onClick={() => {
                  history.push(`/highlights`);
                }}
                className="btn primary"
              >
                Get Into Highlight Reel <FontAwesomeIcon icon={faRightLong} />{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="content">
          <p className="body-text">
          <b>Highlight reel</b> is a personal collection of your own reflections on and recordings of special moments in your life that you are particularly proud of e.g a moment where you have extended yourself beyond what you thought was possible or when you were in flow using your best strengths. It’s a powerful way of connecting to you at your best. It is not a visual, public CV of your achievements. In the context of career exploration, it is about identifying key moments, some of which might be more private, that make you feel good and connect you to your values, interests and strengths.
          </p>

          <div className="description">
            <div className="left white-background">
              <h3 className="heading2 underline blue">Key Features:</h3>

              <ol>
                <li>
                  <div className="item">1</div>
                  <p>
                    Timeframe is not significant. Client chooses the key events
                    - some may cluster over a couple of years, there could be a
                    significant gap between some of the events.
                  </p>
                </li>

                <li>
                  <div className="item">2</div>
                  <p>2 separate lines - My Personal Story My Career Story </p>
                </li>

                <li>
                  <div className="item">3</div>
                  <p>
                    No right or wrong. Client decides what is meaningful to them
                    e.g. the birth of a first child may have been really
                    significant in shaping you, the birth of subsequent children
                    may have been less monumental in shaping you.
                  </p>
                </li>

                <li>
                  <div className="item">4</div>
                  <p>
                    Maximum number of points per line to encourage
                    prioritisation and analysis of what was really significant
                  </p>
                </li>

                <li>
                  <div className="item">5</div>
                  <p>Purpose</p>
                </li>
              </ol>
            </div>
            <div className="left white-background yellow">
              <h3 className="heading2 underline yellow">Use To:</h3>

              <ol>
                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                    Create foundation for stories I use to talk about myself, to
                    position myself, to introduce myself in a career context
                  </p>
                </li>

                <li>
                  <div className="item">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <p>
                    Prompt in-depth thinking around what I was doing, feeling,
                    how did it change me afterwards, why has it stayed with me,
                    how did I change in skills, attitude or behaviour?
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="moreTools">
        <div className="container">
          <h3>
            <span className="heading1 underline yellow">
              Want to go somewhere else?
            </span>
          </h3>

          <div className="tools">
            <PathTool
              tool={{
                name: "Lifeline",
                id: 1,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/icon_lifeilne.svg",
                Tool_Path: {
                  description: "I want to thank those that have helped me in my career",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Storyboard",
                id: 2,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/tool_Storyboard.svg",
                Tool_Path: {
                  description: "I want to thank those that have helped me in my career",
                },
              }}
            />
            <PathTool
              tool={{
                name: "Roses & Thorns",
                id: 3,
                image:
                  "https://crewassets.s3.us-east-2.amazonaws.com/RosesAndThorns.svg",
                Tool_Path: {
                  description: "I lost my job and need help and  insights now",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
