import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import "./styles/publicVision.scss";

export const PublicVision = ({ setStep, me, location }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [vision, setVision] = useState({});
  const [showIntro, setShowIntro] = useState(true);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getVision();
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:flex";
      }

      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "max-width: 100%;width:100%;";
      }
    };
  }, []);

  const getCategory = (category) => {
    switch (category) {
      case "growth":
        return "PERSONAL GROWTH";
      case "relationships":
        return "RELATIONSHIPS";
      case "impact":
        return "IMPACT";
      default:
        return "PERSONAL GROWTH";
    }
  };

  const getVision = async () => {
    const URL = `${apiServerUrl}/api/visions/${id}`;
    const vision = await axios.get(URL);
    setVision(vision.data);
  };

  if (!vision.id) {
    return <div></div>;
  }

  return (
    <div className="publicVision">
      <div className="container">
        <h2>{vision?.user?.first_name}'s one year vision</h2>
        <div className="slides">
          <div className="coverBox box">
            <div
              style={{
                backgroundImage: `url(${vision?.cover?.image})`,
              }}
              className="inside"
            >
              <div className="content">
                <span>ONE-YEAR VISION</span>
                <h2>{vision.cover?.title || "Title will appear here"}</h2>
                <div className="bottom">
                  {vision.user.first_name && (
                    <p>
                      <b>
                        {vision.user.first_name} {vision.user.last_name}
                      </b>
                    </p>
                  )}

                  <p>{vision.cover?.bio}</p>
                </div>
              </div>
              {vision.cover.color && (
                <div
                  className="overlay"
                  style={{ backgroundColor: vision.cover?.color }}
                ></div>
              )}
            </div>
          </div>

          {vision?.pages?.map((page, i) => (
            <div className={`box`}>
              <div className={`inside ${page.category}`}>
                <div className="top">
                  <span>{getCategory(page.category)}</span>
                </div>

                <h2>{page.summary || "Summarize in a few words"}</h2>

                <p>
                  {page.response ||
                    "Your response to the prompt will show up in this space."}
                </p>

                {page.image && <img src={page.image} alt="" />}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showIntro && (
        <div className="modal">
          <div className="modal-content">
            <div className="left">
              <div className="item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  fill="none"
                >
                  <path
                    d="M40.2645 70.8333H59.7351M49.9998 12.5V16.6667M76.5163 23.4835L73.57 26.4298M87.5 49.9998H83.3333M16.6667 49.9998H12.5M26.4295 26.4298L23.4833 23.4835M35.2684 64.7315C27.1325 56.5956 27.1325 43.4046 35.2684 35.2687C43.4043 27.1328 56.5952 27.1328 64.7312 35.2687C72.8671 43.4046 72.8671 56.5956 64.7312 64.7315L62.4516 67.0111C59.8146 69.6481 58.3331 73.2246 58.3331 76.954V79.1667C58.3331 83.769 54.6021 87.5 49.9998 87.5C45.3974 87.5 41.6664 83.769 41.6664 79.1667V76.954C41.6664 73.2246 40.185 69.6481 37.548 67.0111L35.2684 64.7315Z"
                    stroke="#FF9E67"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="right">
              <h4>WELCOME TO VISIONS</h4>
              <h2>
                {vision?.user?.first_name} wanted to share their one-year vision
                with you!
              </h2>
              <p>
                Feel free to scroll through and see {vision?.user?.first_name}’s
                goals and aspirations for the next year, written from the
                perspective of their future self.
              </p>

              <div className="bottom">
              <button className="btn secondary" onClick={() => setShowIntro(false)}>Next</button>
              </div>
            </div>


          </div>
          <div className="skrim" onClick={() => setShowIntro(false)}></div>
        </div>
      )}
    </div>
  );
};
