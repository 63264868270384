import { faClock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../../styles/components/Chat/addons/highlight.scss";

export const Highlight = ({ chat, continueLoop, divRef }) => {
  const [loading, setLoading] = useState(true);
  const [doingExercise, setDoingExercise] = useState(false);
  const [finished, setFinished] = useState(false);
  const [myStrengths, setMyStrengths] = useState([]);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);


    
  }, [chat]);



  return (
    <div id="chatMessage" className="chatMessage addon-strengths addon">
      <div className="chatMessageBox">
      <img alt="avatar" src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg" />

        <div className="chatMessageTitle">
        <h4>Roxanne Burbach {chat.app}</h4>
        <div className={`bubble ${!loading ? 'yellow' : ''}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Celebrate a Career Highlight</h3>
                  <span>
                  We'll take you through an interactive activity designed to help you capture a career moment worth remembering.
                  </span>
                </div>
                <div className="right">
                  <span>
                    <FontAwesomeIcon icon={faClock} /> Will take 2-3 minutes
                  </span>
                  <button
                    onClick={() => {
                      setDoingExercise(true);
                      continueLoop();

                    }}
                    className="btn secondary"
                  >
                    Lets Begin
                  </button>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
