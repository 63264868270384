import { useAuth0 } from "@auth0/auth0-react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import "../../../styles/components/Chat/addons/highlightTitle.scss";
import axios from "axios";
import { Analytics } from "../../../hooks/analytics";

export const HighlightImage = ({
  chat,
  continueLoop,
  userInputs,
  divRef,
  me,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(chat.message);
  const [continued, setContinued] = useState(false);
  const { apiServerUrl } = useEnv();
  const [image, setImage] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat]);

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/highlights/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    setContinued(true);
    setImage(upload.data.avatar);
    await submitNewHighlight(upload.data.avatar);
    continueLoop({
      highlightImage: upload.data.avatar,
    });
  };

  const submitNewHighlight = async (image) => {
    const token = await getAccessTokenSilently();

    const newHighlight = {
      text: userInputs.highlightDescription,
      title: userInputs.highlightTitle,
      photo: image || null,
    };

    await axios.post(`${apiServerUrl}/api/highlights`, newHighlight, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    Analytics("Created Highlight");

    return true;
  };

  return (
    <div>
      <div className="highlightTitle">
        <div className="chatMessageBox">
          <img
            alt="avatar"
            src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
          />
          <div className="chatMessageTitle">
            <h4>
              Roxanne Burbach {chat.app}
            </h4>
            <div className="bubble">
              {!loading && (
                <p>
                  Thanks! Do you have a photo or screenshot that you could use
                  to represent this moment? Adding a visual can help you to keep
                  these highlights top of mind, which will help with your growth
                  and confidence.
                </p>
              )}
              {loading && <div class="dot-flashing"></div>}
            </div>

            {!continued && !loading && (
              <div className="actions">
                <button className="btn btn-primary">
                  Yes, I do!{" "}
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      uploadAvatar(event.target.files);
                    }}
                  />
                </button>
                <button
                  onClick={async () => {
                    setContinued(true);
                    await submitNewHighlight();
                    continueLoop();
                  }}
                  className="btn btn-secondary"
                >
                  Skip for now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {continued && image && (
        <div id="userMessage" className="userMessage">
          <div className="userMessageBox">
            <div className="userMessageTitle">
              <div className="bubble">
                <div className="text">
                  <img src={image} />
                </div>
              </div>
              <img
                alt="avatar"
                className={`${chat.showImage ? "" : "hide"}`}
                src={me?.avatar}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
