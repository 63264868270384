import React, { useEffect, useState } from "react";
import "../../styles/components/create/previewVideo.scss";
import { NavigationBox } from "./NavigationBox";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import mixpanel from "mixpanel-browser";
import { Analytics } from "../../hooks/analytics";

export const WrittenPreview = (props) => {
  const [type, setType] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [copyText, setCopyText] = useState("Link");
  const history = useHistory();
  let array = Array(100).fill("");
  const [email, setEmail] = useState({
    email: "",
    text: `Hi ${props.story.toFirstName}! 
    
Sending along a story I just made for you about a time I saw you at your best. We don't share these stories enough, but we should! I'm sending this via Storyboard which is a new product that makes it easy to send/receive this kind of story.
    
I hope you enjoy it,

${props.me.first_name}`,
  });
  useEffect(() => {
    return () => {};
  }, []);

  const sendInvite = async () => {
    const token = await getAccessTokenSilently();

    let body = {
      email: email.email,
      text: email.text,
    };

    const config = {
      url: `${apiServerUrl}/api/story/invite/${props.story.id}`,
      method: "POST",
      data: body,
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const story = await axios(config);
      Analytics("Sent Written Story Email");
      history.push("/storyboard", {
        message: `Your story to ${props.story.toFirstName} was successfully sent!`,
      });
    } catch (error) {
      alert("Couldn't send email, please try again");
    }
  };

  return (
    <div className="PreviewVideo">
      <h2 className="highlight heading1">Let's review before we send</h2>
      <div className="white-background">
        <div
          style={{
            backgroundColor: props.story.color,
          }}
          className="customize"
        >
          {array.map((item, index) => {
            return <span>{props.story.emoji}</span>;
          })}
        </div>
        <p
          className="textBox"
          dangerouslySetInnerHTML={{ __html: props.story.text }}
        ></p>
        <h3>How would you like to send their story?</h3>
        <div className="box-container">
          <div
            onClick={() => {
              setType("email");
            }}
            className={`${type === "email" ? "active" : ""} box`}
          >
            Email
          </div>
          <a
            onClick={(e) => {
              Analytics("SMS Written Story");
              setType("sms");
            }}
            href={`sms:?&body=${`Hi ${props.story.toFirstName}, I just sent you a new Story! Check it out at ${window.location.protocol}//${window.location.host}/story/${props.story.claimUrl}`}`}
            className={`${type === "sms" ? "active" : ""} box`}
          >
            SMS
          </a>
          <CopyToClipboard
            text={`${window.location.protocol}//${window.location.host}/story/${props.story.claimUrl}`}
            onCopy={() => {
              setCopyText("Copied!");
              setType("Copy");
              Analytics("Copied Written Story Link");
              setTimeout(() => {
                setCopyText("Link");
              }, 5000);
            }}
          >
            <div className={`${type === "Copy" ? "active" : ""} box`}>
              {copyText}
            </div>
          </CopyToClipboard>
        </div>
        {type === "" && props.standalone !== true && (
          <p
            onClick={() => {
              props.back();
            }}
            className="tryagain"
          >
            Nope, let's try again
          </p>
        )}
        {type === "email" && (
          <div className="emailContainer">
            <div className="inputBox">
              <h3>What's {props.story.toFirstName}'s email address?</h3>
              <input
                type="text"
                onChange={(e) => {
                  const newEmail = { ...email };
                  newEmail.email = e.target.value;
                  setEmail(newEmail);
                }}
                placeholder="name@domain.com"
              />
            </div>

            <div className="inputBox">
              <h3>What would you like say in the email?</h3>
              <textarea
                value={email.text}
                onChange={(e) => {
                  const newEmail = { ...email };
                  newEmail.text = e.target.value;
                  setEmail(newEmail);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <NavigationBox step={3} />

      <div className="continueBox">
        {props.standalone !== true && (
          <button
            onClick={() => {
              props.back();
            }}
            className="btn secondary"
          >
            Back
          </button>
        )}

        {props.standalone === true && (
          <button
            onClick={() => {
              history.goBack();
            }}
            className="btn secondary"
          >
            Back
          </button>
        )}

        {type === "email" && (
          <button
            onClick={() => {
              sendInvite();
            }}
            className="btn primary"
          >
            Looks good
          </button>
        )}
        {type !== "email" && (
          <button
            onClick={() => {
              history.push("/storyboard", {
                message: `Your story to ${props.story.toFirstName} was successfull!`,
              });
            }}
            className="btn primary"
          >
            Looks good
          </button>
        )}
      </div>
    </div>
  );
};
