import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Crews/single.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../../hooks/isAdmin";



export const CrewsSingle = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [me, setMe] = useState({
    id: null,
  });
  const [crew, setCrew] = useState(null);
  let id = props.match.params.id;


  useEffect(() => {
    getMe();
    getCrew();
  }, []);



  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };





  const getCrew = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);
    setCrew(response.data);
  };

  if (!crew) {
    return <div>Loading...</div>;
  }

  return (
    <div className="crewsingle">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>Eventsw</NavLink> /{" "}
        </div>
        <div className="breadcrumb"> {crew.name}</div>
      </div>
      <div className="container white-background">
        <NavLink to={`/admin/crews/${crew.id}/edit`} className="edit">Edit</NavLink>
        <h2 className="heading1 highlight blue">{crew.name}</h2>
        <p className="body-text ">{crew.description}</p>
        <div className="info">
          <div className="info-item">
            <p className="body-text">
              <FontAwesomeIcon icon={faCalendar} />{" "}
              {moment(crew.startDate).format("MM/DD/YYYY")} {moment(crew.time).format("hh:mm A")}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="crew">
            <h4 className="underline green">Members</h4>
            <div className="members">
              {crew.crew?.users.map((user) => (
                <div className="crew-member">
                  <div className="imageBox">
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `https://placehold.co/100x100?text=${
                          user.first_name.charAt(0) + user.last_name.charAt(0)
                        }`;
                      }}
                      src={user.avatar}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="coach">
            <h4 className="underline green">Host</h4>
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `https://placehold.co/100x100?text=${
                  crew.author.first_name.charAt(0) +
                  crew.author.last_name.charAt(0)
                }`;
              }}
              src={crew?.author?.avatar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
