import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import { Line, getElementAtEvent } from "react-chartjs-2";

export const LineChart = (props) => {
  const chartRef = useRef(null);
  const [personalHidden, setPersonalHidden] = useState(true);
  const [professionalHidden, setProfessionalHidden] = useState(true);

  const onClick = (event, props) => {
    const elem = getElementAtEvent(chartRef.current, event);

    if (elem.length > 0) {
      const index = elem[0].index;
      const datasetIndex = elem[0].datasetIndex;
      props.clicky(index, datasetIndex);
    }
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart && props.noToolTip === false) {
      try {
        triggerTooltip(chart, props.input.datasetIndex, props.input.index);
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.input]);

  const convertToSmily = (score) => {
    switch (score) {
      case 20:
        return "😔";
      case 40:
        return "😑";
      case 60:
        return "🙂";
      case 80:
        return "😀";
      case 100:
        return "🤩";
      default:
        return "";
    }
  };

  const triggerTooltip = (chart, datasetIndex, index) => {
    const tooltip = chart.tooltip;
    tooltip.setActiveElements([
      {
        datasetIndex,
        index,
      },
    ]);

    chart.update();
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

  const options = {
    responsive: true,
    events: ["click"],
    maintainAspectRatio: false,
    lineTension: 0.8,
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
        grid: {
          display: props.showGrid,
        },
        ticks: {
          min: 0,
          max: 100,
          font: {
            size: 30,
          },
          stepSize: 20,
          suggestedMin: 0,
          suggestedMax: 100,
          callback: function (label, index, labels) {
            switch (label) {
              case 20:
                return "😔";
              case 100:
                return "🤩";
              default:
                return "";
            }
          },
        },
      },
      x: {
        type: "time",
        grid: {
          display: props.showGrid,
        },
        time: {
          unit: "year",
        },
        ticks: {
          font: {
            size: props.tickSize || 17,
          },
          stepSize: 15,
        },
      },
    },
    layout: {
      padding: {
        left: 2,
        right: 52,
      },
    },
    plugins: {
      legend: {
        display: props.showLegend,
        position: "bottom",
        labels: {
          usePointStyle: false,
          pointStyleWidth: 0,
          useBorderRadius: false,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        backgroundColor: "#FFDE67",
        bodyColor: "#7B6002",
        titleColor: "#7B6002",
        titleFont: {
          size: 14,
        },
        titleSpacing: 14,
        titleMarginBottom: 0,
        padding: 10,
        enabled: props.noToolTip === false,
        callbacks: {
          title: function (tooltipItem, data) {
            return `${convertToSmily(tooltipItem[0].raw.score)} ${
              tooltipItem[0].raw.title
            }`;
          },
          label: function (context) {
            return "";
          },
          afterBody: function (tooltipItem, data) {
            return "";
          },
        },
      },
    },
  };

  return (
    <div className="LineChart">
      {props.personalMoments.length === 0 &&
        props.professionalMoments.length === 0 && (
          <div className="no-data">
            <h3>Your moments will appear on this line graph</h3>
          </div>
        )}
      {props.personalMoments.length > 0 &&
        props.professionalMoments.length > 0 &&
        !props.hideButtons && (
          <div className="legend">
            <button
              style={{
                opacity: personalHidden ? 1 : 0.2,
              }}
              onClick={() => {
                const chart = chartRef.current;
                chart.data.datasets[0].hidden = !chart.data.datasets[0].hidden;
                setPersonalHidden(!chart.data.datasets[0].hidden);
                const tooltip = chart.tooltip;
                tooltip.setActiveElements([]);
                chart.update();
              }}
              className="btn secondary green"
            >
              Personal
            </button>
            <button
              style={{
                opacity: professionalHidden ? 1 : 0.2,
              }}
              onClick={() => {
                const chart = chartRef.current;
                chart.data.datasets[1].hidden = !chart.data.datasets[1].hidden;
                setProfessionalHidden(!chart.data.datasets[1].hidden);
                const tooltip = chart.tooltip;
                tooltip.setActiveElements([]);
                chart.update();
              }}
              className="btn secondary blue"
            >
              Professional
            </button>
          </div>
        )}
      <div className="chart">
        <Line
          ref={chartRef}
          onClick={(events) => {
            onClick(events, props);
          }}
          id="chart"
          options={options}
          data={props.data}
        />
      </div>
    </div>
  );
};
