import React, { useEffect, useState } from "react";
import "../../styles/pages/Company/intro.scss";
import Reveal from "reveal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../context/env.context";
import axios from "axios";
import footer from "./svgs/good_logo.png";
import bg from "./svgs/56.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CompanyIntro = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [me, setMe] = useState();
  const { apiServerUrl } = useEnv();
  const [activeDepartment, setActiveDepartment] = useState();
  const [title, setTitle] = useState();
  const [step, setStep] = useState(0);
  const [length, setLength] = useState(3);
  const [reveal, setReveal] = useState();
  const history = useHistory();
  const { id } = match.params;

  const departments = [
    "Accounting",
    "Administrative",
    "Architecture",
    "Business Development",
    "Closing/Escrow",
    "Construction",
    "Customer Service",
    "Design",
    "Financial",
    "Human Resources",
    "IT",
    "Land",
    "Marketing",
    "Mortgage",
    "Operations",
    "Product",
    "Purchasing",
    "Sales",
    "Title",
  ];

  useEffect(() => {
    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;width:100%;";
    }

    getMe();

    if(id > 0) {
      patchUser();
    }

    setTimeout(() => {
      const newReveal = new Reveal();
      newReveal.initialize({
        controls: false,
        progress: false,
        history: false,
        keyboard: false,
      });
      newReveal.on("slidechanged", (event) => {
        setStep(event.indexh);
      });

      setReveal(newReveal);
    }, 100);

    return () => {
      const nav = document.getElementsByClassName("nav-bar__container")[0];
      const navMobile = document.getElementsByClassName(
        "nav-bar__container__mobile"
      )[0];
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
      if (nav) {
        nav.style.display = "flex";
      }
      if (navMobile) {
        navMobile.style.display = "flex";
      }
      reveal.destroy();
    };
  }, []);

  const nextSlide = () => {
    reveal.next();
  };

  const previousSlide = () => {
    reveal.prev();
  };

  const saveData = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me/company`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data: {
        department: activeDepartment,
        title: title,
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    await axios(config);

    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setMe(response.data);
  };

  const patchUser = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/interest/${id}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    }

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);
  };

  const uploadAvatar = async (files) => {
    const token = await getAccessTokenSilently();
    const file = files[0];
    let data = new FormData();
    data.append("file", file, file.name);
    let URL = `${apiServerUrl}/api/users/upload`;
    const upload = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const avatar = upload.data.avatar;
    let newMe = { ...me };
    newMe.avatar = avatar;
    setMe(newMe);
  };

  return (
    <div className="CompanyIntro">
      <div className="bar">
        <div
          style={{
            width: `${(step / length) * 100}%`,
          }}
          className="stepBar"
        ></div>
      </div>
      <div className="reveal">
        <div className="slides">
          <section className="photo">
            <h3>Show your crew the real you!</h3>
            <h2>Add a profile photo</h2>
            {!me?.avatar ? (
              <div className="inputBox">
                <input
                  name="file"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    uploadAvatar(e.target.files);
                  }}
                  className="btn empty"
                />
                <FontAwesomeIcon icon={faPlus} />
              </div>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${me?.avatar})`,
                }}
                className="inputBox"
              >
                <input
                  name="file"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    uploadAvatar(e.target.files);
                  }}
                  className="btn empty"
                />
              </div>
            )}
            <div className="controlsItem">
              <button
                onClick={() => {
                  nextSlide();
                }}
                disabled={!me?.avatar}
                className="btn primary"
              >
                Next
              </button>
            </div>
          </section>
          <section className="photo">
            <h3>You play a role</h3>
            <h2>Select your department</h2>

            <div className="departments">
              {departments.map((department) => {
                return (
                  <button
                    onClick={() => {
                      setActiveDepartment(department);
                    }}
                    className={`department ${
                      activeDepartment === department ? "active" : ""
                    }`}
                  >
                    {department}
                  </button>
                );
              })}
            </div>
            <div className="controlsItem">
              <button
                onClick={() => {
                  previousSlide();
                }}
                className="btn secondary blank"
              >
                Back
              </button>
              <button
                onClick={() => {
                  nextSlide();
                }}
                disabled={!activeDepartment}
                className="btn primary"
              >
                Next
              </button>
            </div>
          </section>
          <section className="photo">
            <h3>You play a role</h3>
            <h2>Add your title</h2>

            <input
              placeholder="Type your title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              type="text"
            ></input>
            <div className="controlsItem">
              <button
                onClick={() => {
                  previousSlide();
                }}
                className="btn secondary blank"
              >
                Back
              </button>
              <button
                onClick={() => {
                  nextSlide();
                }}
                disabled={!title}
                className="btn primary"
              >
                Next
              </button>
            </div>
          </section>
          <section className="photo">
            <h3>Everything looking good?</h3>

            <div
              style={{
                backgroundImage: `url(${bg})`,
              }}
              className="userInfoBox"
            >
              <div className="left">
                <img src={me?.avatar} alt="avatar" />
              </div>
              <div className="right">
                <h2>
                  {me?.first_name} {me?.last_name}
                </h2>
                <h3>{title}</h3>
                <h4>{activeDepartment}</h4>
              </div>
            </div>
            <div className="controlsItem">
              <button
                onClick={() => {
                  previousSlide();
                }}
                className="btn secondary blank"
              >
                Back
              </button>
              <button
                onClick={() => {
                  saveData();
                }}
                className="btn primary"
              >
                Done
              </button>
            </div>
          </section>
          <div className="bottom">
            <span>Powered By </span>
            <img src={footer} alt="footer" />
          </div>
        </div>
      </div>
    </div>
  );
};
