import { useAuth0 } from "@auth0/auth0-react";
import { faClock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import "../../../styles/components/Chat/addons/values.scss";

export const Values = ({ chat, continueLoop, divRef, me, userInputs }) => {
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [doingExercise, setDoingExercise] = useState(false);
  const [finished, setFinished] = useState(false);
  const [statement, setStatement] = userInputs.statement;
  const [myValues, setMyValues] = useState([]);
  const valuesRef = React.createRef();
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (userInputs.statement) {
      if (
        userInputs.statement ===
        "Work is a means to an end. I work mainly to support my lifestyle and interests outside the workplace"
      ) {
        setValues([
          { id: 1, value: "Balance" },
          { id: 2, value: "Freedom" },
          { id: 4, value: "Adventure" },
          { id: 5, value: "Stability" },
          { id: 6, value: "Flexibility" },
          { id: 7, value: "Relationships" },
          { id: 8, value: "Creativity" },
          { id: 9, value: "Community" },
          { id: 10, value: "Wellness" },
          { id: 11, value: "Security" },
        ]);
      } else if(userInputs.statement === 'Work is an opportunity to achieve success. I work mainly to develop my skills and advance.') {
        setValues([
          { id: 1, value: "Learning" },
          { id: 2, value: "Recognition" },
          { id: 3, value: "Achievement" },
          { id: 4, value: "Challenge" },
          { id: 5, value: "Expertise" },
          { id: 6, value: "Prosperity" },
          { id: 7, value: "Dedication" },
          { id: 8, value: "Responsibility" },
          { id: 9, value: "Creativity" },
          { id: 10, value: "Excellence" },
        ]);
      } else if(userInputs.statement === 'Work brings me purpose. I work mainly to find fulfillment and make a difference.') {
        setValues([
          { id: 1, value: "Passion" },
          { id: 2, value: "Service to others" },
          { id: 3, value: "Commitment" },
          { id: 4, value: "Creativity" },
          { id: 5, value: "Collaboration" },
          { id: 6, value: "Gratitude" },
          { id: 7, value: "Authenticity" },
          { id: 8, value: "Fairness" },
          { id: 9, value: "Inclusivity" },
          { id: 10, value: "Learning" },
        ]);
      }
    }
    setTimeout(() => {
      setLoading(false);
      divRef.current.scrollToBottom();
    }, chat.loadingTime || 3000);
  }, [chat, userInputs.statement]);

  const saveValues = async () => {
    if (myValues.length < 3) {
      alert("Please fill in all values");
      return;
    }

    Analytics("Saved Values");

    const token = await getAccessTokenSilently();

    const saved = myValues.map((value) => {
      return axios.post(
        `${apiServerUrl}/api/values`,
        {
          text: value.value,
          userId: me.id,
          fromOnboarding: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });

    await Promise.all(saved);

    return true;
  };

  if (doingExercise) {
    return (
      <div id="valuesExercise" className="valuesExercise addon-values addon">
        <div className="content">
          <img
            alt="avatar"
            src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
          />
          <div className="exerciseContainer">
            <h4>Roxanne Burbach</h4>
            <div className="bubble">
              <h5>Values Exercise</h5>
              <p>
                You said {userInputs.statement}. Do any of these values resonate
                with you? Choose three to add to your Career Canvas!
              </p>
              <div className="activity">
                <div className="left">
                  <h4>Values List</h4>
                  <div className="values">
                    {values.map((value) => (
                      <div
                        onClick={() => {
                          const newValues = [...myValues];
                          newValues.push(value);
                          setMyValues(newValues);
                          setValues(values.filter((v) => v.id !== value.id));
                        }}
                        className="value"
                        key={value.id}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span>{value.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="right">
                  <h4>My Values</h4>
                  <div className="values">
                    <div className={`myValue ${myValues[0]  ? 'filled' : ''}`}>
                      <span>1</span>
                      {myValues[0] && <p>{myValues[0].value}</p>}
                    </div>
                    <div className={`myValue ${myValues[1] ? 'filled' : ''}`}>
                      <span>2</span>
                      {myValues[1] && <p>{myValues[1].value}</p>}
                    </div>
                    <div className={`myValue ${myValues[2] ? 'filled' : ''}`}>
                      <span>3</span>
                      {myValues[2] && <p>{myValues[2].value}</p>}
                    </div>
                  </div>

                  {!finished && (
                    <button
                      onClick={async () => {
                        setFinished(true);
                        await saveValues();
                        continueLoop();
                      }}
                      disabled={myValues.length < 3}
                      className="btn secondary"
                    >
                      I'm Finished
                    </button>
                  )}
                  {finished && <p>Great! All Set!</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="chatMessage" className="chatMessage addon-values addon">
      <div className="chatMessageBox">
        <img
          alt="avatar"
          src="https://crewavatars.s3.us-east-2.amazonaws.com/1678825587247-google-oauth2%7C103604631623770300891.jpg"
        />
        <div className="chatMessageTitle">
          <div className={`bubble ${!loading ? "yellow" : ""}`}>
            {!loading && (
              <div className="container">
                <div className="left">
                  <h3>Kickstart your values exploration</h3>
                  <span>
                    We'll take you through an interactive activity designed to
                    get you thinking about what matters to you.
                  </span>
                </div>
                <div className="right">
                  <button
                    onClick={() => {
                      setDoingExercise(true);
                      setTimeout(() => {
                        divRef.current.scrollToBottom();
                      }, 400);
                    }}
                    className="btn primary"
                  >
                    Lets Begin
                  </button>
                  <span>
                    <FontAwesomeIcon icon={faClock} /> Will take 2-3 minutes
                  </span>
                </div>
              </div>
            )}
            {loading && <div class="dot-flashing"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
