import React, { useCallback, useEffect, useState } from "react";
import "../../styles/pages/highlightReel/share.scss";
import axios from "axios";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useAuth0 } from "@auth0/auth0-react";
import "chartjs-adapter-moment";
import { useEnv } from "../../context/env.context";
import { Link, useHistory } from "react-router-dom";
import logo from "./../../images/small_logo.png";
import YellowBG from "./../../images/share_bg-1.png";
import BlueBG from "./../../images/share_bg-2.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const HighlightShare = (props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [highlights, setHighlights] = useState([]);
  const id = props.match.params.id;

  useEffect(() => {
    getData();
    const container = document.getElementsByClassName("content_container")[0];
    if (container) {
      container.style = "max-width: 100%;";
    }

    const body = document.getElementsByTagName("body")[0];
    if (body) {
      body.style = "background-color: #f4eeec";
    }

    const nav = document.getElementsByClassName("nav-bar__container")[0];
    if (nav) {
      nav.style = "display:none";
    }

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      if (container) {
        container.style = "1240px";
      }

      const nav = document.getElementsByClassName("nav-bar__container")[0];
      if (nav) {
        nav.style = "display:block";
      }

      const body = document.getElementsByTagName("body")[0];
      if (body) {
        body.style = "background-color: #fff";
      }
    };
  }, []);

  const getData = async () => {
    const highlight = await getHighlights(id);
  };

  const getHighlights = useCallback(
    async (id) => {
      try {
        const response = await axios.get(
          `${apiServerUrl}/api/highlights/${id}/share`
        );
        setHighlights(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    [getAccessTokenSilently, apiServerUrl]
  );

  return (
    <div className="highlightReel-share">
      <img src={YellowBG} alt="yellow-bg" className="yellow-bg" />
      <img src={BlueBG} alt="blue-bg" className="blue-bg" />
      <div className="content">
        <div className="logo">
          <img src={logo} alt="logo" />
          <div className="button">
            <h4>Want your own Highlight Reel? </h4>
            <button
              onClick={() => {
                loginWithRedirect();
              }}
            >
              Sign Up
            </button>
          </div>
        </div>

        <div className="top">
          <div className="avatar">
            <img onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `https://placehold.co/100x100?text=${highlights[0]?.user?.first_name.charAt(0) + highlights[0]?.user?.last_name.charAt(0)}`;
          }} src={highlights[0]?.user?.avatar} alt="avatar" />
            <h4>
              {highlights[0]?.user?.first_name} {highlights[0]?.user?.last_name}
            </h4>
          </div>
          <div className="infoBox">
            <h2>
              Welcome to {highlights[0]?.user?.first_name}'s Highlight Reel 👋
            </h2>
            <p>
              Highlight Reel helps you visualize the career moments that make
              you proud and put a smile on your face.
            </p>
          </div>
        </div>

        <div className="highlights">
          <h3>
            <span className="underline orange">Public Highlights</span>
          </h3>
          <div className="grid">
            <ResponsiveMasonry
              columnsCount={3}
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter={15}>
                {highlights.map((highlight) => (
                  <div className="single-highlight">
                    {highlight.photo && (
                      <div className="photo">
                        <img src={highlight.photo} alt="photo" />
                      </div>
                    )}
                    <h4>{highlight.title}</h4>
                    <p>{highlight.text}</p>
                    <Link
                      to={`/highlights/highlight/${highlight.id}`}
                      className="button"
                    >
                      View Highlight
                    </Link>
                  </div>
                ))}

                <div className="holder">
                  <h2>Want your own Highlight Reel?</h2>
                  <p>Create your account & start highlighting!</p>
                  <button
                    onClick={() => {
                      loginWithRedirect();
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </div>
    </div>
  );
};
