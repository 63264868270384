import React, { useEffect, useState } from "react";
import "./styles/RoleModels.scss";
import { useAuth0 } from "@auth0/auth0-react";
import icon from "../images/strengthsIcon.png";
import axios from "axios";
import { useEnv } from "../../../context/env.context";
import ContextMenu from "./ContextMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const RoleModelWidget = ({ id, size, programUser }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [roleModels, setRoleModels] = useState([]);
  const { apiServerUrl } = useEnv();
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [adjustedSize, setAdjustedSize] = useState(size);
  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {
    getRoleModels();
  }, []);

  const getRoleModels = async (id) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${apiServerUrl}/api/role-model`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.length === 0) return;

    const data = response.data;

    setRoleModels(data);
  };

  if (!roleModels || roleModels.length === 0) {
    return (
      <div className={`widget Size-${size}`}>
        <img alt="icon" src={icon} />
        <h3>Unlocked after Role Models</h3>
        {!programUser && (
          <button
            className="btn"
            onClick={() => {
              history.push("/role-models", {
                editing: true,
              });
            }}
          >
            Edit Role Models
          </button>
        )}
      </div>
    );
  }

  // Size 1 widget
  if (adjustedSize === 1) {
    return (
      <div className={`Widget Size-${adjustedSize} RoleModels`}>
        <h2>Role Models</h2>
        <ContextMenu
          link="/role-models"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <div className="list">
          <ul>
            <li className={active === sliderIndex ? "active" : ""}>
              <img
                src={roleModels[sliderIndex].image}
                alt={roleModels[sliderIndex].name}
              />
              <p>{roleModels[sliderIndex].name}</p>
            </li>
          </ul>
        </div>

        <button
          className="empty"
          onClick={() => {
            history.push("/role-models", {
              editing: true,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit Role Models{" "}
        </button>

        <div className="navigation">
          {roleModels.map((roleModel, index) => (
            <button
              key={index}
              className={index === sliderIndex ? "active" : ""}
              onClick={() => setSliderIndex(index)}
            ></button>
          ))}
        </div>
      </div>
    );
  }
  if (adjustedSize === 2) {
    return (
      <div className={`Widget Size-${adjustedSize} RoleModels`}>
        <h2>Role Models</h2>
        <ContextMenu
          link="/strengths"
          size={adjustedSize}
          setSize={(size) => {
            setAdjustedSize(size);
          }}
        />

        <ul>
          <li className={active === sliderIndex ? "active" : ""}>
            <div className="left">
              <img
                src={roleModels[sliderIndex].image}
                alt={roleModels[sliderIndex].name}
              />
              <p>{roleModels[sliderIndex].name}</p>
            </div>
            <div className="right">
              {roleModels[sliderIndex]?.values?.map((value, index) => {
                return (
                  <div className="value">
                    <FontAwesomeIcon icon={faCheck} />
                    <p>{value.text}</p>
                  </div>
                );
              })}
            </div>
          </li>
        </ul>

        <button
          className="empty"
          onClick={() => {
            history.push("/role-models");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <g clipPath="url(#clip0_1004_31548)">
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14791 14.8549C11.8194 14.8549 14.7958 11.8785 14.7958 8.20699C14.7958 4.53545 11.8194 1.55908 8.14791 1.55908C4.47637 1.55908 1.5 4.53545 1.5 8.20699C1.5 11.8785 4.47637 14.8549 8.14791 14.8549Z"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.14795 5.54785V10.8662"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#ECDDD8"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.48877 8.20703H10.8071"
                stroke="#155673"
                strokeWidth="1.99437"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1004_31548">
                <rect
                  width="15.955"
                  height="15.955"
                  fill="white"
                  transform="translate(0.17041 0.229492)"
                />
              </clipPath>
            </defs>
          </svg>{" "}
          Edit my role models{" "}
        </button>
      </div>
    );
  }
};
