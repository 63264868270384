import { useAuth0 } from "@auth0/auth0-react";
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEnv } from "../../../context/env.context";
import { Analytics } from "../../../hooks/analytics";
import "../../../styles/pages/Lifeline/components/ConnectiveTissue.scss";

export const ConnectiveTissue = (props) => {
  const [highlightReelMoments, setHighlightReelMoments] = useState(
    props.highlightReelMoments
  );
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [step, setStep] = useState(1);
  const [index, setIndex] = useState(0);

  const removeMoment = async (moment) => {
    const newHighlights = highlightReelMoments.filter(
      (item) => item.id !== moment.id
    );
    setHighlightReelMoments(newHighlights);
  };

  const submitNewHighlight = async () => {
    const addedMoments = highlightReelMoments.map(async (moment) => {
      const token = await getAccessTokenSilently();

      const newHighlight = {
        title: moment.title,
        text: moment.description,
        featured: false,
        photo: null,
      };

      const highlightCreated = axios.post(
        `${apiServerUrl}/api/highlights`,
        newHighlight,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Analytics("Created Highlight");
      return highlightCreated;
    });

    Promise.all(addedMoments).then((values) => {
      setStep(2);
    });
  };

  return (
    <div className="connective-tissue modal">
      {step === 1 && (
        <div className="modal-content">
          <div className="title">
            <h3>
              Do you want to include these great moments in your Highlight Reel?
            </h3>
            <p>
              Highlight Reel is a tool that makes it easy to celebrate your
              career accomplishments in one place.{" "}
            </p>
          </div>

          <div className="moments">
            {highlightReelMoments.length > 0 &&
              highlightReelMoments.map((moment, index) => {
                if (moment.score < 70) return null;
                return (
                  <div className="moment" key={index}>
                    <p>{moment.title}</p>
                    <FontAwesomeIcon
                      onClick={() => {
                        removeMoment(moment);
                      }}
                      icon={faTrash}
                    />
                  </div>
                );
              })}
          </div>
          <div className="buttonActions">
            <button
              className="btn secondary"
              type="button"
              onClick={async () => {
                props.close();
              }}
            >
              No Thanks
            </button>
            <button
              className="btn primary"
              type="button"
              onClick={async () => {
                submitNewHighlight();
              }}
            >
              Yes, Add to Highlight Reel
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="modal-content highlights">
          <div className="title">
            <h3>Great - they've been added!</h3>
            <p>Explore your Highlight Reel.</p>
          </div>

          <div className="highlight">
            <div className="image"></div>
            <div className="middle">
              <h4>{highlightReelMoments[index].title}</h4>
              <p>{highlightReelMoments[index].description}</p>
            </div>
            <div className="right">
              <button
                onClick={() => {
                  history.push("/highlights");
                }}
                className="btn secondary"
              >
                View
              </button>
            </div>
          </div>

          <div className="actions">
            <button
              disabled={index == 0 || highlightReelMoments.length === 1}
              onClick={() => {
                setIndex(index - 1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            <button
              disabled={index === highlightReelMoments.length - 1}
              onClick={() => {
                setIndex(index + 1);
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>

          <hr />

          <div className="bottom">
            <h3>Do you want to continue exploring your Lifeline?</h3>
            <button
              onClick={() => {
                props.close();
              }}
              className="btn secondary"
            >
              Yes Please!
            </button>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          props.close();
        }}
        className="skrim"
      ></div>
    </div>
  );
};
