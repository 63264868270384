import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/pages/Programs/dashboard.scss";
import { useEnv } from "../../context/env.context";
import "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ProgramComponent } from "../Programs/components/ProgramComponent";

export const CoachingProgram = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [crew, setCrew] = useState([]);
  const params = useParams();
  const id = params.id;
  const [finalStage, setFinalStage] = useState(false);
  const [reflections, setReflections] = useState([]);
  const [insights, setInsights] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [showBio, setShowBio] = useState(false);

  useEffect(() => {
    getCrew();
    getInsights();

    const container = document.getElementsByClassName("content_container")[0];
    container.style = "max-width:100%";

    return () => {
      const container = document.getElementsByClassName("content_container")[0];
      container.style = "1240px";
    };
  }, []);

  const getFilteredSessions = (sessions) => {
    const now = moment().endOf("day");

    if (!sessions) return [];

    return sessions
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate));
      })
      .filter((session) => {
        return moment(session.startDate).isAfter(now);
      });
  };

  const getCrew = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(
      `${apiServerUrl}/api/coach/program/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const sessionsLeft = getFilteredSessions(response.data.program?.sessions);

    const sortedAndMarkedSessions = response.data.program?.sessions
      .map((session) => {
        if (moment(session.endDate).isBefore(moment())) {
          session.past = true;
        }
        return session;
      })
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate));
      });

      const sessionsWithSessionRecordings = sortedAndMarkedSessions.filter(
        (session) => {
          return session.sessionRecordingLink;
        }
      ).reverse();

    if (sessionsLeft.length < 2) {
      setFinalStage(true);
    }

    setAllSessions(sortedAndMarkedSessions);
    setCrew(response.data);
    setRecordings(sessionsWithSessionRecordings);
  };

  const getInsights = async () => {
    const token = await getAccessTokenSilently();

    const response = await axios.get(`${apiServerUrl}/api/insights`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const filteredInsights = response.data.filter((insight) => {
      return insight.type.includes("programReflection");
    });

    const nonFilteredInsights = response.data.filter((insight) => {
      return !insight.type.includes("programReflection");
    });

    setReflections(filteredInsights);
    setInsights(nonFilteredInsights);
  };

  return (
    <ProgramComponent
      crew={crew}
      finalStage={finalStage}
      insights={insights}
      reflections={reflections}
      allSessions={allSessions}
      recordings={recordings}
      showBio={showBio}
      setShowBio={setShowBio}
    />
  );
};
